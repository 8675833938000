<style>
.create_pass .navbar {
    background-color: #000;
    border-radius: 0px !important;
    padding-bottom: 22px;
}

.create_pass .header-container {
    margin-top: 0px !important;
    width: 100% !important;
    padding-left: 22px;
}

.create_pass .parent_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: auto;
    margin: 0;
    padding: 10px;
    margin-top: 32px;
}

.create_pass .main_heading {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    /* margin-bottom: 32px; */
}

.create_pass .main_heading h1 {
    font-size: 48px;
}

.create_pass .input_label {
    color: #000 !important;
    font-size: 12px;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
}

.create_pass .tooltip_div {
    display: flex;
}

.create_pass .tooltip_svg {
    margin-left: 4px;
    cursor: pointer;
}

.create_pass .input_div {
    margin-bottom: 32px;
    position: relative;

}

.create_pass .input_spacing {
    margin-bottom: 16px !important;
    position: relative;

}


.create_pass .error_div {
    margin-bottom: 12px;
    display: flex;
    color: var(--vds-elements-text-icons-borders-secondary-onlight, #6F7171);
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 384px;
}

.create_pass .pass_strength {
    margin-bottom: 24px;
    color: var(--vds-elements-text-icons-borders-secondary-onlight, #6F7171);
}

.create_pass .show_hide_link {
    position: absolute;
    top: 14px;
    right: 10px;
    text-decoration: underline;
    cursor: pointer;
    height: 20px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    display: flex;
}

.create_pass .input_field {
    border-radius: 4px !important;
    height: 44px !important;
    border: 1px solid #000;
    width: 384px;
    padding: 11px 0 11px 10px;
}

.create_pass .input_field_success {
    border-radius: 4px !important;
    height: 44px !important;
    border: 1px solid #008331;
    width: 384px;
    padding: 11px 0 11px 10px;
    background-color: #DCF5E6;
}

.create_pass .input_field_error {
    border-radius: 4px !important;
    height: 44px !important;
    border: 1px solid #B95319;
    width: 384px;
    padding: 11px 0 11px 10px;
    background-color: #FFECE0;
}

.create_pass .btn_div {
    text-align: center;
    margin-bottom: 40px;
}

.create_pass .save_btn {
    border-radius: 24px;
    background: #000;
    color: #fff;
    padding: 12px 24px;
    text-align: center;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-top: 20px;
}
.create_pass .save_btn_disabled {
    border-radius: 24px;
    background: var(--vds-interactive-disabled-onlight, #D8DADA);
    color: #fff;
    padding: 12px 24px;
    text-align: center;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-top: 20px;
}

.create_pass .save_btn:focus {
    outline: 1px solid #000;
}

@media screen and (max-width: 400px) {
    .create_pass .parent_div {
        padding: 10px;
    }

    .create_pass .tooltip {
        left: 30px !important;
    }

    .create_pass .main_heading h1 {
        font-size: 40px;
        text-align: center;
    }

    .create_pass .form_elements {
        width: 100%;
    }

    .create_pass .input_field {
        border-radius: 4px !important;
        height: 44px !important;
        border: 1px solid #000;
        width: 100%;

    }
}

.create_pass .tooltip_div {
    position: relative;
}

.create_pass .tooltip {
    display: block;
    background-color: #fff;
    border: 1px solid #000;
    padding: 12px;
    top: 24px;
    left: 54px;
    opacity: 1;
    max-width: 224px;
    height: auto;
    border-radius: 4px;
}

.create_pass .tooltip-icon:hover+.tooltip {
    display: block;
}

.create_pass .tooltip-head {
    font-size: 12px;
    font-weight: 700;
    color: #000;
    line-height: 16px;
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
    margin-bottom: 6px;
}

.create_pass .tooltip-para {
    font-size: 12px;
    line-height: 16px;
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;

}

.create_pass .tooltip_div li {
    list-style-type: disc;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-left: 30px !important;
    /* margin-bottom: 3px; */
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;

}

.create_pass .tooltip_div ul {
    padding: 0px 0px;
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;

}

.create_pass li::marker {
    font-size: 20px;
    font-weight: bolder;
}

.create_pass .tick_icon {
    padding-right: 8px;
}

.create_pass .password-req {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.create_pass .weak_color {
    color: rgb(255, 188, 61);
    font-weight: bold;
}

.create_pass .medium_color {
    color: rgb(237, 112, 0);
    font-weight: bold;

}

.create_pass .strong_color {
    color: rgb(0, 172, 62);
    font-weight: bold;

}

.create_pass .input_wrapper {
    position: relative;
    max-width: 384px !important;
}

.create_pass .error_banner {
    display: flex;
    border-radius: 4px;
    background: var(--vds-feedback-error-background-onlight, #FFECE0);
    max-width: 384px;
    padding: 16px;
    color: #000;
    margin-bottom: 32px;

}
.create_pass .error_banner:focus{
outline: 1px solid #000;
}

.create_pass .warning_icon,
.middle_content {
    padding-right: 8px;
}
.create_pass .middle_content:focus{
    outline: 1px solid #000;
}
.create_pass .middle_line1:focus{
    outline: 1px solid #000;
}
.create_pass .middle_line2:focus{
    outline: 1px solid #000;
}

.create_pass .middle_line1 {
    font-weight: 700;
    margin-bottom: 4px;
}

.create_pass .close_icon {
    cursor: pointer;
}
.create_pass .close_icon:focus {
    outline: 1px solid #000;
}
.create_pass .visible_error {
    position: absolute;
    top: 12px;
    right: 10px;
}

.create_pass .return_div {
    padding-left: 18px;
}

.create_pass .return_caret {
    padding-right: 6px;
}

.create_pass .return_to_signIn {
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    font-feature-settings: 'clig' off, 'liga' off;

    /* [VDS] DeskTab/🖥 Body LG Bold */
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.5px;
}

.create_pass .return_to_signIn a:focus {
    color: #000;
}
@media screen and (max-width: 767px){
    .create_pass .main_heading h1{
    font-size: 40px
}
}
</style>

<template>
    <div class="create_pass">
    <div id="vz-gh20"></div>
        <!-- <nav class="navbar">
            <div class="container header-container">
                <a class="navbar-brand" id="navbar-brand" href="void()">
                    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="checkmark" clip-path="url(#clip0_3873_589)">
                            <path id="Check" d="M0 20H6.2176L12.4344 33.3336L27.9784 0H34.196L15.5432 40H9.3264L0 20Z"
                                fill="#EE0000" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3873_589">
                                <rect width="35" height="40" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
        </nav> -->
        <!-- <div class="return_div">
            <span class="return_caret">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Left Caret Icon">
                        <path id="Vector"
                            d="M7.83301 11.5L2.33301 6L7.83301 0.5L9.2219 1.88889L5.16634 5.94444L9.2219 10L7.83301 11.5Z"
                            fill="black" />
                    </g>
                </svg>

            </span>
            <span class="return_to_signIn" v-if="!forgotFlowEnable">
                <a href="#" @click="$router.back()" tabindex="0">{{ $t("message.back_link") }}</a>
            </span>
            <span class="return_to_signIn" v-if="forgotFlowEnable">
                <a href="#" @click="redirectToSign" tabindex="0">{{ $t("message.return_to_signin_link") }}</a>
            </span>
        </div> -->
        <div class="parent_div">
            <div class="error_banner" id="toast-active" v-if="getVsbdrForgotAlerts.msg" tabindex="0">              
                <div class="warning_icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Notification Icon">
                            <path id="Vector"
                                d="M14.3751 6.50811L9.4937 1.62673C9.09799 1.23107 8.56132 1.00879 8.00173 1.00879C7.44214 1.00879 6.90547 1.23107 6.50976 1.62673L1.6284 6.50811C1.23337 6.90416 1.01154 7.4407 1.01154 8.00008C1.01154 8.55945 1.23337 9.096 1.6284 9.49205L6.50904 14.3734C6.70496 14.5695 6.9376 14.725 7.19366 14.8312C7.44973 14.9373 7.72419 14.9919 8.00137 14.9919C8.27856 14.9919 8.55302 14.9373 8.80908 14.8312C9.06515 14.725 9.29778 14.5695 9.4937 14.3734L14.3751 9.49203C14.7701 9.09598 14.9919 8.55944 14.9919 8.00007C14.9919 7.4407 14.7701 6.90415 14.3751 6.50811ZM13.3276 8.44462L8.44553 13.326C8.3262 13.4408 8.16701 13.505 8.00138 13.505C7.83574 13.505 7.67655 13.4408 7.55722 13.326L2.67585 8.44462C2.55808 8.32666 2.49193 8.16679 2.49193 8.00011C2.49193 7.83342 2.55808 7.67355 2.67585 7.55559L7.55722 2.67422C7.67523 2.55656 7.83509 2.49048 8.00174 2.49048C8.16839 2.49048 8.32824 2.55656 8.44625 2.67422L13.3276 7.55559C13.4454 7.67355 13.5115 7.83342 13.5115 8.00011C13.5115 8.16679 13.4454 8.32666 13.3276 8.44462ZM7.37041 9.90166H8.62967V11.1483H7.37041V9.90166ZM7.37041 4.85203H8.62967V6.69682L8.20781 8.64867H7.78597L7.37041 6.69682V4.85203Z"
                                fill="black" />
                        </g>
                    </svg>
                </div>
                <div class="middle_content">
                    <div class="middle_line1">
                        {{getVsbdrForgotAlerts.msg}}
                    </div>
                    <div class="middle_line2" v-if="getVsbdrForgotAlerts.subMsg">
                        {{getVsbdrForgotAlerts.subMsg}}
                    </div>
                </div>
                <div class="close_icon return_to_signIn" @click="hide_error_div" @keydown.enter="hide_error_div" aria-label="errorCloseIcon" tabindex="0" role="application">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/close">
                            <path id="Vector"
                                d="M8.58517 7.9998L13.8518 13.2591L13.2592 13.8517L7.99998 8.58499L2.74072 13.8517L2.14813 13.2591L7.40739 7.9998L2.14813 2.74054L2.74072 2.14795L7.99998 7.40721L13.2592 2.14795L13.8518 2.74054L8.58517 7.9998Z"
                                fill="black" />
                        </g>
                    </svg>
                </div>
            </div>
            <div class="main_heading">
                <h1 tabindex="0" class="return_to_signIn">{{ $t("message.create_pd_main_heading") }}</h1>
            </div>
            <div class="form_elements">
                <div class="input_label" v-if="!forgotFlowEnable">
                    {{ $t("message.create_pd_current_pass") }}
                </div>
                <div class="input_div" v-if="!forgotFlowEnable">
                    <input class="input_field" type="text" v-model="newPassword" @input="validations" ref="pass_input1" />
                    <!-- <span class="show_hide_link">Show</span> -->
                </div>
                <div id="tooltip_info">
                    <div class="input_label tooltip_div">
                        <div>
                            {{ $t("message.create_pd_enter_new_pass") }}
                        </div>
                        <span role="application" aria-label="Password requirement tooltip" class="tooltip_svg tooltip-icon"
                            @mouseover="showTooltip" @mouseout="hideTooltip">
                            <a class="" aria-label="Password requirement tooltip" role="button" tabindex="0"
                                @keydown.enter.prevernt="openTooltip" @keydown.esc="closeToolip">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.6666 7.99992C14.6666 6.68138 14.2757 5.39245 13.5431 4.29612C12.8106 3.19979 11.7694 2.34531 10.5512 1.84072C9.33303 1.33614 7.99259 1.20412 6.69938 1.46135C5.40617 1.71859 4.21829 2.35353 3.28594 3.28588C2.35359 4.21823 1.71865 5.40611 1.46141 6.69932C1.20418 7.99253 1.3362 9.33297 1.84079 10.5511C2.34537 11.7693 3.19985 12.8105 4.29618 13.5431C5.39251 14.2756 6.68144 14.6666 7.99998 14.6666C9.76746 14.6645 11.462 13.9615 12.7117 12.7117C13.9615 11.4619 14.6646 9.7674 14.6666 7.99992ZM13.8334 7.99992C13.8334 9.15366 13.4913 10.2815 12.8503 11.2408C12.2093 12.2001 11.2982 12.9478 10.2323 13.3893C9.1664 13.8308 7.9935 13.9463 6.86192 13.7213C5.73035 13.4962 4.69093 12.9406 3.87511 12.1248C3.05929 11.3089 2.50371 10.2695 2.27863 9.13795C2.05355 8.00638 2.16907 6.83347 2.61059 5.76755C3.05211 4.70163 3.7998 3.79058 4.7591 3.14959C5.7184 2.50861 6.84624 2.16648 7.99998 2.16648C9.54656 2.16825 11.0293 2.78341 12.1229 3.87701C13.2165 4.97061 13.8316 6.45334 13.8334 7.99992H13.8334ZM8.61109 5.52475H7.37035V4.28401H8.61109V5.52475ZM7.37653 6.75297H8.62961V11.7159H7.37653V6.75297Z"
                                        fill="black" />
                                </svg>
                            </a>
                        <div class="tooltip" @keydown.esc="closeToolip" v-if="showingTooltip">
                            <h2 class="tooltip-head" tabindex="0">
                                {{ $t("message.create_pd_requirements") }}
                            </h2>
                            <div class="tooltip-para new_outline" tabindex="0">
                                {{ $t("message.create_pd_requirements_security") }}
                            </div>
                            <div>
                                <ul @keydown.esc="closeToolip">
                                    <li tabindex="0" class="new_outline">
                                        {{ $t("message.create_pd_requirements_8_20") }}
                                    </li>
                                    <li tabindex="0" class="new_outline">
                                        {{ $t("message.create_pd_requirements_lowercase") }}
                                    </li>
                                    <li tabindex="0" class="new_outline">
                                        {{ $t("message.create_pd_requirements_upprecase") }}
                                    </li>
                                    <li tabindex="0" class="new_outline">
                                        {{ $t("message.create_pd_requirements_one_num") }}
                                    </li>
                                    <li tabindex="0" class="new_outline">
                                        {{ $t("message.create_pd_requirements_not_contain") }}
                                    </li>
                                    <li tabindex="0" class="new_outline">
                                        {{ $t("message.create_pd_strength_must_have_splsymbols") }}
                                    </li>
                                    <li tabindex="0" class="new_outline">
                                        {{ $t("message.create_pd_strength_shouldnot_be_prev") }}
                                    </li>
                                    <li tabindex="0" class="new_outline">
                                        {{ $t("message.create_pd_strength_cannotbe_email") }}
                                    </li>
                                    <li tabindex="0" class="new_outline" @keydown.tab="closeToolip">
                                        {{ $t("message.create_pd_strength_cannotbe_easy") }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </span>
                    </div>
                    <div class="input_div input_spacing">
                        <div class="input_wrapper">
                            <input ref="pass_input" type="password" class="input_field" @input="validations" maxlength="20"
                                v-model="password" aria-label="Edit new Password"
                                :class="{ 'input_field': passwordErr, 'input_field_success': this.pass_strength === 'Medium' || this.pass_strength === 'Strong', 'input_field_error': !password && isVisible }" />
                            <div v-if="!password && isVisible" class="visible_error">
                                <span class="">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g id="Notification Icon">
                                            <path id="Vector"
                                                d="M14.3751 6.50811L9.4937 1.62673C9.09799 1.23107 8.56132 1.00879 8.00173 1.00879C7.44214 1.00879 6.90547 1.23107 6.50976 1.62673L1.6284 6.50811C1.23337 6.90416 1.01154 7.4407 1.01154 8.00008C1.01154 8.55945 1.23337 9.096 1.6284 9.49205L6.50904 14.3734C6.70496 14.5695 6.9376 14.725 7.19366 14.8312C7.44973 14.9373 7.72419 14.9919 8.00137 14.9919C8.27856 14.9919 8.55302 14.9373 8.80908 14.8312C9.06515 14.725 9.29778 14.5695 9.4937 14.3734L14.3751 9.49203C14.7701 9.09598 14.9919 8.55944 14.9919 8.00007C14.9919 7.4407 14.7701 6.90415 14.3751 6.50811ZM13.3276 8.44462L8.44553 13.326C8.3262 13.4408 8.16701 13.505 8.00138 13.505C7.83574 13.505 7.67655 13.4408 7.55722 13.326L2.67585 8.44462C2.55808 8.32666 2.49193 8.16679 2.49193 8.00011C2.49193 7.83342 2.55808 7.67355 2.67585 7.55559L7.55722 2.67422C7.67523 2.55656 7.83509 2.49048 8.00174 2.49048C8.16839 2.49048 8.32824 2.55656 8.44625 2.67422L13.3276 7.55559C13.4454 7.67355 13.5115 7.83342 13.5115 8.00011C13.5115 8.16679 13.4454 8.32666 13.3276 8.44462ZM7.37041 9.90166H8.62967V11.1483H7.37041V9.90166ZM7.37041 4.85203H8.62967V6.69682L8.20781 8.64867H7.78597L7.37041 6.69682V4.85203Z"
                                                fill="black" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                            <span class="show_hide_link" v-if="password" >

                                <span v-if="this.pass_strength === 'Medium' || this.pass_strength === 'Strong'">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g id="Icon/&#226;&#157;&#140;DEPRECATED checkmark-alt">
                                            <path id="Vector"
                                                d="M10.0001 18.3337C8.35191 18.3337 6.74074 17.8449 5.37033 16.9292C3.99992 16.0136 2.93182 14.7121 2.30109 13.1894C1.67036 11.6666 1.50533 9.99109 1.82687 8.37458C2.14842 6.75807 2.94209 5.27321 4.10753 4.10777C5.27297 2.94234 6.75782 2.14866 8.37433 1.82712C9.99084 1.50558 11.6664 1.6706 13.1891 2.30133C14.7118 2.93206 16.0133 4.00017 16.929 5.37058C17.8447 6.74099 18.3334 8.35215 18.3334 10.0003C18.3308 12.2097 17.452 14.3278 15.8898 15.89C14.3275 17.4523 12.2094 18.3311 10.0001 18.3337ZM10.0001 2.70855C8.55791 2.70855 7.14812 3.13621 5.94899 3.93744C4.74986 4.73867 3.81526 5.87749 3.26336 7.20989C2.71146 8.54228 2.56706 10.0084 2.84842 11.4229C3.12977 12.8374 3.82425 14.1366 4.84402 15.1564C5.86379 16.1762 7.16306 16.8706 8.57753 17.152C9.99199 17.4334 11.4581 17.2889 12.7905 16.7371C14.1229 16.1852 15.2617 15.2506 16.063 14.0514C16.8642 12.8523 17.2919 11.4425 17.2919 10.0003C17.2897 8.0671 16.5207 6.21368 15.1537 4.84668C13.7867 3.47968 11.9333 2.71073 10.0001 2.70852V2.70855ZM13.6574 7.99986L12.9211 7.26871L8.91081 11.2694L7.07714 9.45048L6.34259 10.1834L8.91261 12.7317L13.6574 7.99986Z"
                                                fill="black" />
                                        </g>
                                    </svg>
                                </span>
                                <a tabindex="0"  @click="toggleshow_hide" @keydown.enter.prevernt="toggleshow_hide">
                                    {{ showPassword ? $t("message.create_pd_hide") :
                                        $t("message.create_pd_show") }}
                                </a>

                            </span>
                        </div>
                    </div>
                    <div class="pass_strength" v-if="password" tabindex="0">
                        {{ $t("message.create_pd_strength") }} <span :class="this.pass_class">{{$t(this.pass_valid_strength) }}</span>
                    </div>


                    <div class="error_div" v-if="password">
                        <div class="password-req">
                            <span class="tick_icon" v-if="passwordErr">
                                <!-- {{ passwordErr }} -->
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector"
                                        d="M6.99992 13.6664C6.76523 13.6669 6.53276 13.6209 6.31598 13.531C6.09919 13.4411 5.90238 13.3091 5.73696 13.1427L0.855697 8.26133C0.521154 7.92629 0.333252 7.47217 0.333252 6.99869C0.333252 6.52522 0.521154 6.0711 0.855697 5.73605L5.73766 0.854748C6.07294 0.520743 6.52687 0.333147 7.00013 0.333008C7.47339 0.332869 7.92743 0.520199 8.2629 0.854008L13.1441 5.73603C13.4787 6.07108 13.6666 6.5252 13.6666 6.99867C13.6666 7.47214 13.4787 7.92626 13.1441 8.26131L8.2629 13.1426C8.09752 13.3092 7.90072 13.4412 7.68391 13.5311C7.46711 13.621 7.23462 13.667 6.99992 13.6664ZM1.44451 6.32487C1.26627 6.50383 1.16619 6.74612 1.16619 6.99869C1.16619 7.25127 1.26627 7.49356 1.44451 7.67251L6.32649 12.5538C6.50779 12.7275 6.74919 12.8245 7.0003 12.8245C7.2514 12.8245 7.4928 12.7275 7.6741 12.5538L12.5553 7.67251C12.7336 7.49356 12.8337 7.25127 12.8337 6.99869C12.8337 6.74612 12.7336 6.50383 12.5553 6.32487L7.67409 1.44358C7.49497 1.26566 7.25275 1.1658 7.00028 1.1658C6.74781 1.1658 6.50559 1.26566 6.32647 1.44358L1.44451 6.32487ZM6.36871 10.1469H7.62797V8.90027H6.36871V10.1469ZM6.36871 5.69543L6.78427 7.64728H7.20613L7.62799 5.69543V3.85064H6.36871V5.69543Z"
                                        fill="#EE0000" />
                                </svg>
                            </span>
                            <span class="tick_icon" v-if="!passwordErr">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.6666 3.91112L5.88146 12.6963L1.33331 8.14075L1.95554 7.51853L5.88146 11.4519L14.0296 3.30371L14.6666 3.91112Z"
                                        fill="#008331" />
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_strength_8_20_char") }}</p>
                        </div>
                        <div class="password-req">
                            <span class="tick_icon" v-if="!lowserCase">
                                <!-- {{ passwordErr }} -->
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector"
                                        d="M6.99992 13.6664C6.76523 13.6669 6.53276 13.6209 6.31598 13.531C6.09919 13.4411 5.90238 13.3091 5.73696 13.1427L0.855697 8.26133C0.521154 7.92629 0.333252 7.47217 0.333252 6.99869C0.333252 6.52522 0.521154 6.0711 0.855697 5.73605L5.73766 0.854748C6.07294 0.520743 6.52687 0.333147 7.00013 0.333008C7.47339 0.332869 7.92743 0.520199 8.2629 0.854008L13.1441 5.73603C13.4787 6.07108 13.6666 6.5252 13.6666 6.99867C13.6666 7.47214 13.4787 7.92626 13.1441 8.26131L8.2629 13.1426C8.09752 13.3092 7.90072 13.4412 7.68391 13.5311C7.46711 13.621 7.23462 13.667 6.99992 13.6664ZM1.44451 6.32487C1.26627 6.50383 1.16619 6.74612 1.16619 6.99869C1.16619 7.25127 1.26627 7.49356 1.44451 7.67251L6.32649 12.5538C6.50779 12.7275 6.74919 12.8245 7.0003 12.8245C7.2514 12.8245 7.4928 12.7275 7.6741 12.5538L12.5553 7.67251C12.7336 7.49356 12.8337 7.25127 12.8337 6.99869C12.8337 6.74612 12.7336 6.50383 12.5553 6.32487L7.67409 1.44358C7.49497 1.26566 7.25275 1.1658 7.00028 1.1658C6.74781 1.1658 6.50559 1.26566 6.32647 1.44358L1.44451 6.32487ZM6.36871 10.1469H7.62797V8.90027H6.36871V10.1469ZM6.36871 5.69543L6.78427 7.64728H7.20613L7.62799 5.69543V3.85064H6.36871V5.69543Z"
                                        fill="#EE0000" />
                                </svg>
                            </span>
                            <span class="tick_icon" v-if="lowserCase">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.6666 3.91112L5.88146 12.6963L1.33331 8.14075L1.95554 7.51853L5.88146 11.4519L14.0296 3.30371L14.6666 3.91112Z"
                                        fill="#008331" />
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_strength_must_have_lowercase") }}</p>
                        </div>
                        <div class="password-req">
                            <span class="tick_icon" v-if="!upperCase">
                                <!-- {{ passwordErr }} -->
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector"
                                        d="M6.99992 13.6664C6.76523 13.6669 6.53276 13.6209 6.31598 13.531C6.09919 13.4411 5.90238 13.3091 5.73696 13.1427L0.855697 8.26133C0.521154 7.92629 0.333252 7.47217 0.333252 6.99869C0.333252 6.52522 0.521154 6.0711 0.855697 5.73605L5.73766 0.854748C6.07294 0.520743 6.52687 0.333147 7.00013 0.333008C7.47339 0.332869 7.92743 0.520199 8.2629 0.854008L13.1441 5.73603C13.4787 6.07108 13.6666 6.5252 13.6666 6.99867C13.6666 7.47214 13.4787 7.92626 13.1441 8.26131L8.2629 13.1426C8.09752 13.3092 7.90072 13.4412 7.68391 13.5311C7.46711 13.621 7.23462 13.667 6.99992 13.6664ZM1.44451 6.32487C1.26627 6.50383 1.16619 6.74612 1.16619 6.99869C1.16619 7.25127 1.26627 7.49356 1.44451 7.67251L6.32649 12.5538C6.50779 12.7275 6.74919 12.8245 7.0003 12.8245C7.2514 12.8245 7.4928 12.7275 7.6741 12.5538L12.5553 7.67251C12.7336 7.49356 12.8337 7.25127 12.8337 6.99869C12.8337 6.74612 12.7336 6.50383 12.5553 6.32487L7.67409 1.44358C7.49497 1.26566 7.25275 1.1658 7.00028 1.1658C6.74781 1.1658 6.50559 1.26566 6.32647 1.44358L1.44451 6.32487ZM6.36871 10.1469H7.62797V8.90027H6.36871V10.1469ZM6.36871 5.69543L6.78427 7.64728H7.20613L7.62799 5.69543V3.85064H6.36871V5.69543Z"
                                        fill="#EE0000" />
                                </svg>
                            </span>
                            <span class="tick_icon" v-if="upperCase">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.6666 3.91112L5.88146 12.6963L1.33331 8.14075L1.95554 7.51853L5.88146 11.4519L14.0296 3.30371L14.6666 3.91112Z"
                                        fill="#008331" />
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_strength_must_have_uppercase") }}</p>
                        </div>
                        <div class="password-req">
                            <span class="tick_icon" v-if="!numCase">
                                <!-- {{ passwordErr }} -->
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector"
                                        d="M6.99992 13.6664C6.76523 13.6669 6.53276 13.6209 6.31598 13.531C6.09919 13.4411 5.90238 13.3091 5.73696 13.1427L0.855697 8.26133C0.521154 7.92629 0.333252 7.47217 0.333252 6.99869C0.333252 6.52522 0.521154 6.0711 0.855697 5.73605L5.73766 0.854748C6.07294 0.520743 6.52687 0.333147 7.00013 0.333008C7.47339 0.332869 7.92743 0.520199 8.2629 0.854008L13.1441 5.73603C13.4787 6.07108 13.6666 6.5252 13.6666 6.99867C13.6666 7.47214 13.4787 7.92626 13.1441 8.26131L8.2629 13.1426C8.09752 13.3092 7.90072 13.4412 7.68391 13.5311C7.46711 13.621 7.23462 13.667 6.99992 13.6664ZM1.44451 6.32487C1.26627 6.50383 1.16619 6.74612 1.16619 6.99869C1.16619 7.25127 1.26627 7.49356 1.44451 7.67251L6.32649 12.5538C6.50779 12.7275 6.74919 12.8245 7.0003 12.8245C7.2514 12.8245 7.4928 12.7275 7.6741 12.5538L12.5553 7.67251C12.7336 7.49356 12.8337 7.25127 12.8337 6.99869C12.8337 6.74612 12.7336 6.50383 12.5553 6.32487L7.67409 1.44358C7.49497 1.26566 7.25275 1.1658 7.00028 1.1658C6.74781 1.1658 6.50559 1.26566 6.32647 1.44358L1.44451 6.32487ZM6.36871 10.1469H7.62797V8.90027H6.36871V10.1469ZM6.36871 5.69543L6.78427 7.64728H7.20613L7.62799 5.69543V3.85064H6.36871V5.69543Z"
                                        fill="#EE0000" />
                                </svg>
                            </span>
                            <span class="tick_icon" v-if="numCase">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.6666 3.91112L5.88146 12.6963L1.33331 8.14075L1.95554 7.51853L5.88146 11.4519L14.0296 3.30371L14.6666 3.91112Z"
                                        fill="#008331" />
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_strength_must_have_onenumber") }}</p>
                            
                        </div>
                        <div class="password-req">
                            <span class="tick_icon" v-if="!splSymbol">
                                <!-- {{ passwordErr }} -->
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector"
                                        d="M6.99992 13.6664C6.76523 13.6669 6.53276 13.6209 6.31598 13.531C6.09919 13.4411 5.90238 13.3091 5.73696 13.1427L0.855697 8.26133C0.521154 7.92629 0.333252 7.47217 0.333252 6.99869C0.333252 6.52522 0.521154 6.0711 0.855697 5.73605L5.73766 0.854748C6.07294 0.520743 6.52687 0.333147 7.00013 0.333008C7.47339 0.332869 7.92743 0.520199 8.2629 0.854008L13.1441 5.73603C13.4787 6.07108 13.6666 6.5252 13.6666 6.99867C13.6666 7.47214 13.4787 7.92626 13.1441 8.26131L8.2629 13.1426C8.09752 13.3092 7.90072 13.4412 7.68391 13.5311C7.46711 13.621 7.23462 13.667 6.99992 13.6664ZM1.44451 6.32487C1.26627 6.50383 1.16619 6.74612 1.16619 6.99869C1.16619 7.25127 1.26627 7.49356 1.44451 7.67251L6.32649 12.5538C6.50779 12.7275 6.74919 12.8245 7.0003 12.8245C7.2514 12.8245 7.4928 12.7275 7.6741 12.5538L12.5553 7.67251C12.7336 7.49356 12.8337 7.25127 12.8337 6.99869C12.8337 6.74612 12.7336 6.50383 12.5553 6.32487L7.67409 1.44358C7.49497 1.26566 7.25275 1.1658 7.00028 1.1658C6.74781 1.1658 6.50559 1.26566 6.32647 1.44358L1.44451 6.32487ZM6.36871 10.1469H7.62797V8.90027H6.36871V10.1469ZM6.36871 5.69543L6.78427 7.64728H7.20613L7.62799 5.69543V3.85064H6.36871V5.69543Z"
                                        fill="#EE0000" />
                                </svg>
                            </span>
                            <span class="tick_icon" v-if="splSymbol">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.6666 3.91112L5.88146 12.6963L1.33331 8.14075L1.95554 7.51853L5.88146 11.4519L14.0296 3.30371L14.6666 3.91112Z"
                                        fill="#008331" />
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_requirements_not_contain_symbol") }}</p>
                            
                        </div>
                        <div class="password-req">
                            <span class="tick_icon" v-if="!splChar">
                                <!-- {{ passwordErr }} -->
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector"
                                        d="M6.99992 13.6664C6.76523 13.6669 6.53276 13.6209 6.31598 13.531C6.09919 13.4411 5.90238 13.3091 5.73696 13.1427L0.855697 8.26133C0.521154 7.92629 0.333252 7.47217 0.333252 6.99869C0.333252 6.52522 0.521154 6.0711 0.855697 5.73605L5.73766 0.854748C6.07294 0.520743 6.52687 0.333147 7.00013 0.333008C7.47339 0.332869 7.92743 0.520199 8.2629 0.854008L13.1441 5.73603C13.4787 6.07108 13.6666 6.5252 13.6666 6.99867C13.6666 7.47214 13.4787 7.92626 13.1441 8.26131L8.2629 13.1426C8.09752 13.3092 7.90072 13.4412 7.68391 13.5311C7.46711 13.621 7.23462 13.667 6.99992 13.6664ZM1.44451 6.32487C1.26627 6.50383 1.16619 6.74612 1.16619 6.99869C1.16619 7.25127 1.26627 7.49356 1.44451 7.67251L6.32649 12.5538C6.50779 12.7275 6.74919 12.8245 7.0003 12.8245C7.2514 12.8245 7.4928 12.7275 7.6741 12.5538L12.5553 7.67251C12.7336 7.49356 12.8337 7.25127 12.8337 6.99869C12.8337 6.74612 12.7336 6.50383 12.5553 6.32487L7.67409 1.44358C7.49497 1.26566 7.25275 1.1658 7.00028 1.1658C6.74781 1.1658 6.50559 1.26566 6.32647 1.44358L1.44451 6.32487ZM6.36871 10.1469H7.62797V8.90027H6.36871V10.1469ZM6.36871 5.69543L6.78427 7.64728H7.20613L7.62799 5.69543V3.85064H6.36871V5.69543Z"
                                        fill="#EE0000" />
                                </svg>
                            </span>
                            <span class="tick_icon" v-if="splChar">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.6666 3.91112L5.88146 12.6963L1.33331 8.14075L1.95554 7.51853L5.88146 11.4519L14.0296 3.30371L14.6666 3.91112Z"
                                        fill="#008331" />
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_strength_must_have_splsymbols") }}</p>

                        </div>

                        <div class="password-req">
                            <span class="tick_icon">
                                <!-- {{ passwordErr }} -->

                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="Icon/minus">

                                        <path id="Vector" d="M13.5556 8.41459H2.44446V7.58496H13.5556V8.41459Z"
                                            fill="#A7A7A7" />
                                    </g>
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_strength_shouldnot_be_prev") }}</p>
                        </div>
                        <div class="password-req">
                            <span class="tick_icon">
                                <!-- {{ passwordErr }} -->

                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="Icon/minus">

                                        <path id="Vector" d="M13.5556 8.41459H2.44446V7.58496H13.5556V8.41459Z"
                                            fill="#A7A7A7" />
                                    </g>
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_strength_cannotbe_email") }}</p>
                        </div>
                        <div class="password-req">
                            <span class="tick_icon">
                                <!-- {{ passwordErr }} -->

                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="Icon/minus">

                                        <path id="Vector" d="M13.5556 8.41459H2.44446V7.58496H13.5556V8.41459Z"
                                            fill="#A7A7A7" />
                                    </g>
                                </svg>
                            </span>
                            <p>{{ $t("message.create_pd_strength_cannotbe_easy") }}</p>

                        </div>
                    </div>
                </div>
                <div class="btn_div" >
                    <button :class="{'save_btn_disabled': isButtonDisabled, 'save_btn': !isButtonDisabled }" type="button" @click="onSubmit" tabindex="0" :disabled="isButtonDisabled">
                        {{ $t("message.create_pd_save_btn") }}
                    </button>
                </div>
            </div>
        </div>
  <div id="vz-gf20" style = "clear:both"></div>
</div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default ({
    name: 'login',
    el: '#tooltip_info',
    data() {
        return {
            showingTooltip: false,
            passwordErr: null,
            lowserCase: null,
            upperCase: null,
            numCase: null,
            splChar: null,
            splSymbol: null,
            pass_strength: null,
            pass_class: "Weak",
            pass_valid_strength:"",
            showPassword: false,
            isVisible: false,
            medium_strength: false,
            return_to_signIn: "https://mblogin.verizonwireless.com/citizen/login/",
            isButtonDisabled:true,
            selectedLanguage: this.$i18n.locale,
            forgotFlowEnable: url.includes('/displayResetPassword'),
            supportedLanguages: [
                { code: 'en', name: 'English' },
                { code: 'es', name: 'Español' },
            ]
        }
    },
    methods: {
        ...mapActions("login", [
            "updateCitizenPassword",
            "updateVsbdrForgotAlerts",
            "updateCitizenForgotPassword",
            "updateExpireForgotAlerts",
            "updateVsbdrLocale"
        ]),
        isButtonDisabled(){
            if(!this.forgotFlowEnable) {
              this.isButtonDisabled = (this.password.trim() !=='' && this.newPassword.trim() !=='') ? false: true;
            } else {
              this.isButtonDisabled = (this.password.trim() !=='') ? false: true;
            }
        },
        openTooltip() {
            this.showingTooltip = !this.showingTooltip;
        },
        closeToolip() {
            this.showingTooltip = false;
        },
        hide_error_div() {
            this.isVisible = false;
            this.updateVsbdrForgotAlerts({});
        },
        redirectToSign() {
            window.location.href = "https://"+ this.getHostName(window.location.href) + '/citizen/login/';
        },
        isMedium() {
            this.medium_strength = !this.passwordErr && this.lowserCase && this.upperCase && this.numCase && this.splChar && this.splSymbol;
        },
        toggleshow_hide() {
            this.showPassword = !this.showPassword;
            const input = this.$refs.pass_input;
            input.type = this.showPassword ? 'text' : 'password'
        },
        showTooltip() {
            this.showingTooltip = true;
        },
        hideTooltip() {
            this.showingTooltip = false;
        },
        onSubmit() {
            let payLoad;
            const searchParams = new URLSearchParams(window.location.search);
            if(this.forgotFlowEnable) {
                payLoad = {
                    userPasword: this.password,
                    token: this.encryptedToken,
                    clientId: "VAG"
                }
                this.updateCitizenForgotPassword(payLoad);
            } else {
                payLoad = {
                    userPasword: this.password
                }
                this.updateCitizenPassword(payLoad);
            }
        },
        getHostName (url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                return match[2];
            }
            else {
                return null;
            }
        },
        validations() {
            const password = this.password;
            if ((password && password.length > 0) && (password && password.length < 8)) {
                this.passwordErr = true;
            } else {
                this.passwordErr = false
            }
            if ((/^(?=.*?[a-z])/).test(this.password)) {
                this.lowserCase = true;
            } else {
                this.lowserCase = false
            }
            if ((/^(?=.*?[A-Z])/).test(this.password)) {
                this.upperCase = true;
            } else {
                this.upperCase = false
            }
            if ((/^(?=.*?[0-9])/).test(this.password)) {
                this.numCase = true;
            } else {
                this.numCase = false
            }
            if (/[*@!#%&()^~{}]+/.test(this.password)) {
                this.splChar = true;
            } else {
                this.splChar = false
            }
            if (/^(?:(?!<|>|").)*$/.test(this.password)) {
                this.splSymbol = true;
            } else {
                this.splSymbol = false
            }
            // const strength_check = this.pass_strength === 'Medium' || this.pass_strength === 'Strong';
            const medium = !this.passwordErr && this.lowserCase && this.upperCase && this.numCase && this.splChar && this.splSymbol

            if (medium) {
                this.pass_strength = "Medium"
                this.pass_valid_strength = "message.create_pd_strength_medium"
                this.pass_class = "medium_color"
            } else {
                this.pass_strength = "Weak"
                this.pass_valid_strength = "message.create_pd_strength_weak"
                this.pass_class = "weak_color"
            }
            if (medium && password.length > 10) {
                this.pass_strength = "Strong"
                this.pass_valid_strength = "message.create_pd_strength_strong"
                this.pass_class = "strong_color"
            }
            if(!this.forgotFlowEnable) {
                this.isButtonDisabled = ((this.password && this.password.trim() !=='' && this.newPassword && this.newPassword.trim() !=='') && (this.pass_strength === 'Medium' || this.pass_strength === 'Strong')) ? false: true ;
            } else {
                this.isButtonDisabled = ((this.password && this.password.trim() !=='') && (this.pass_strength === 'Medium' || this.pass_strength === 'Strong')) ? false: true ;
            }
        }
    },
    computed: {
        ...mapGetters('login', [
            "getVsbdrForgotAlerts"
        ]),
     },
    watch: {
    getVsbdrForgotAlerts: function(newVal) {
      if(newVal) {
        setTimeout(() => {
         if(document.getElementById("toast-active")){
            document.getElementById("toast-active").focus();
         }
        });
      }
    }
  }, 
  created: function () {
    const searchParams = new URLSearchParams(window.location.search);
    const storedLanguage = this.forgotFlowEnable ? localStorage.getItem('lng'): localStorage.getItem('ciamlng');
    const browserLanguage = navigator.language.substring(0, 2);
    const localeValue = !this.forgotFlowEnable && searchParams.get('ciamLang');
    if (storedLanguage) {
        this.$i18n.locale = storedLanguage;
        this.selectedLanguage = storedLanguage;
    } else if(!storedLanguage && localeValue) {
        this.$i18n.locale = localeValue;
        this.selectedLanguage = localeValue;
        this.forgotFlowEnable ? localStorage.setItem("lng",localeValue) : localStorage.setItem("ciamlng",localeValue);
    } else if (this.supportedLanguages.some(lang => lang.code === browserLanguage)) {
        this.$i18n.locale = browserLanguage;
        this.selectedLanguage = browserLanguage;
    } 
  },
  mounted () {
    if(localStorage.getItem('ciamlng')) {
        localStorage.setItem("defaultlng",localStorage.getItem('ciamlng'));
        localStorage.removeItem('ciamlng')
    }
    this.updateVsbdrLocale(this.$i18n.locale);
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('token') && this.forgotFlowEnable) {
      let queryParamToken = atob(
        decodeURIComponent(searchParams.get('token'))
      );
      this.tokenValue = queryParamToken.split(";")[0];
      let epochTime = queryParamToken.split(";")[1];
      let currentEpochTime = Math.round(new Date().getTime() / 1000);
      if (currentEpochTime >= epochTime) {
        this.updateExpireForgotAlerts(this.$i18n.locale);
        this.$router.push({ path: 'forgotPasswordLanding' })
      } else {
        this.encryptedToken = this.tokenValue;
      }
    }
    document.documentElement.lang = this.$i18n.locale;
     (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window, document, 'script', 'dataLayer', 'GTM-KJFSV78');
    setTimeout(() => {
        const langSwitchLink = document.querySelector('.gnav20-lang-link');
         if(langSwitchLink) {
          document.addEventListener('click', (e)=> {
          const langValue = e.target.getAttribute('data-lang');     
          if(langValue) {
            if(langValue === 'English'  || langValue === 'Inglés') {
                this.forgotFlowEnable ? localStorage.setItem("lng","en") : localStorage.setItem("ciamlng","en");
            } else {
                this.forgotFlowEnable ? localStorage.setItem("lng","es") : localStorage.setItem("ciamlng","es");
            }
          }
          })
          var locale = this.$i18n.locale;
          let variationValue;
          if(this.forgotFlowEnable ) {
            variationValue = locale == 'es' ? 'prospect-es' : 'prospect';
          } else {
            variationValue = locale == 'es' ? 'authenticated-es' : 'authenticated';
          } 
          var globalNavdl = {
            "bu": "corporate",
            "appid": "vsbdr",
            "variation": variationValue,
            "options":{"hide-item":["search"]}
          }
          window.gnavdl = globalNavdl;
         }
       }, 1500);
       var globalNav = document.createElement('script')
       globalNav.setAttribute('src', 'https://www.verizon.com/etc/designs/vzwcom/gnav20/corporate.js')
       globalNav.setAttribute('async', 'async');
       document.head.appendChild(globalNav)
  }
})
</script>
