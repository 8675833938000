<template>
<div style="overflow: hidden; font-family:'VzNHGeDS-reg11'">
    <div id="vz-gh20"></div>
    <div v-if="getUnifiedLoginAlerts.length > 0" class="marbottom20">
        <div class="row bannerMarginUnset" :class="bannerContainer()" v-for="j in getUnifiedLoginAlerts" :key="j">
            <div class="col-sm-12 newMessagesInfo" v-bind:class="getTypeOfAlert(j)" style="padding:0px;">
                <div v-if="j.type== 'information' || j.type== 'success'" class="newMessagesInfo" v-bind:class="getTypeOfAlert(j)" style="max-width:1271px">
                  <div class="messageWrapper">
                    <div class="messageIcon" style="padding:0px;margin:0px">
                      <div class="messageImage" :class="messageImage(j)"></div>
                    </div>
                      <p class="messageContent col-xs-9" style="padding:0px;margin:0px;color:#fff;" v-html="j.msg"></p>
                    <div class="closeMessage col-xs-1" style="padding:0px;margin:0px">
                      <img class="closeMessageImg successbannercloseimg" :src="getCloseIcon()" @click="deleteAlert(j)" />
                    </div>
                  </div>
                </div>
                <div v-else class="newMessagesInfo" v-bind:class="getTypeOfAlert(j)" style="max-width:1271px">
                  <div class="messageWrapper">
                    <div class="messageIcon" style="padding:0px;margin:0px">
                      <div class="messageImage" :class="messageImage(j)"></div>
                    </div>
                      <p class="messageContent col-xs-9" style="padding:0px;margin:0px;color:#000000" v-html="j.msg"></p>
                      <div class="helpblock" v-if="getHelpCheck">
                        <button class="helpbutton" @click="gethpop('gethelp')">
                          <span class="buttontext"> Get Help</span>
                        </button>
                      </div>
                        <div class="closeMessage col-xs-1" style="padding:0px;margin:0px">
                      <img class="closeMessageImg errorbannercloseimg" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                    </div>
                      </div>       
                  </div>
                </div>
            </div>
        </div>
    <div class="container martop20 iL-Container">
        <div class="col-sm-8 col-md-4 container-to-your-business-acc loginmar20" style="padding: 2px;">
            <h2 class="log-in">Log in</h2>
            <p class="to-your-business-acc">to your business account</p>
            <div style="font-family:'VzNHGeDS-reg11'">
                <BlockUI v-if="submitLoader" :url="url"></BlockUI>
                <form class="signin" @submit.prevent="submitLoginForm">
                    <div class="user-id">
                        <label for class="-input-label">User ID</label>
                        <input class="input-f" id="ilogin_userid"  name="ilogin_userid" type="text" required v-model="formData[0].input[0].value" maxlength="60"/>
                        <p class="forgot-link" id="ilogin_forgot_userid" name="ilogin_forgot_userid" @click="FrUsername(); mybizzForget(false);">
                            Forgot user ID?
                        </p>
                    </div>
                    <div class="user-id">
                        <label for class="-input-label">Password</label>
                        <input class="input-f" id="ilogin_password"  name="ilogin_password" :type="show1 ? 'text' : 'password'" required v-model="formData[1].input[0].value" maxlength="20"/>
                        <span class="showBtn" v-if="!show1 && formData[1].input[0].value" @click="show1 = !show1">Show</span>
                        <span class="showBtn" v-if="show1 && formData[1].input[0].value" @click="show1 = !show1">Hide</span>
                        <p class="forgot-link" id="ilogin_forgot_password" name="ilogin_forgot_password" @click="FrPassword(); mybizzForget(false);">
                            Forgot password?
                        </p>
                    </div>
                    <div class="user-captcha">
                        <div style="margin-bottom: 42px;">
                            <div class="captcha-blk">
                                <div class="captcha-txt-blk"><img id="captchaImg" :src="getCaptchaImgUrl()"/></div>
                            </div>
                            <p class="forgot-link" v-on:click="refreshCaptchaImgUrl()" style="margin-bottom: 0px;" id="ilogin_refresh_image" name="ilogin_refresh_image">
                                Refresh image
                            </p>
                        </div>
                        <div>
                            <label for class="-input-label" style="width: 124px;" for="captchaInput">Type the characters from the above image</label>
                            <input class="input-f" type="text" required id="captchaInput" v-model="captchaText" maxlength="60"/>
                        </div>
                    </div>
                    <div v-if="this.gotoPage">
                        <p>
                            <input class="tc" type="checkbox" id="ilogin_remember_user_id" name="ilogin_remember_user_id" v-model="rememberMe" />
                            <label >Remember my user ID</label>
                        </p>
                    </div>
                    <div>
                        <button
                        class="btn primary_black" :callback="submitLoginForm" id="ilogin_login_button" name="ilogin_login_button"
                        style="width: 100%; margin: 12px 0px 12px;font-size: 16px !important; font-family: 'VzNHGeDS-bold11';"
                        type="submit"
                        >
                            Log in
                        </button>
                    </div>
                    <p>
                        <label>Need access? 
                            <a :href="supportUrl" class="register-link" style="text-decoration:none;" id="ilogin_need_access" name="ilogin_need_access"><b style="font-family:VzNHGeDS-bold11">Register for an account</b>&nbsp;<img
                            style="width: 10px; height: 14px"
                            :src="caret"
                        /></a></label>
                    </p>
                    <hr class="vz-horizontal" />
                    <div>
                        <p class="vz-login-link" style="margin-bottom: 12px" id="ilogin_resend_welemail" name="ilogin_resend_welemail">
                            <a :href="resendWelcomeEmail">Resend welcome email for MyBusiness Wireless</a>
                        </p>
                        <p class="vz-login-link" style="margin-bottom: 12px" 
                            @click="forgotPopup('payWithoutLogin')" id="ilogin_pay_without_login" name="ilogin_pay_without_login">
                            <a >Pay without logging in</a>
                        </p>
                        <p class="vz-login-link" style="margin-bottom: 12px" id="ilogin_quick_tasks" name="ilogin_quick_tasks">
                            <a :href="quick_login">Complete quick tasks without logging in</a>
                        </p>
                        <p class="vz-login-link" style="margin-bottom: 12px" id="ilogin_adtl_portals" name="ilogin_adtl_portals">
                            <a :href="Mgmt_portal_login"> Manage additional portals</a>
                        </p>
                        <p class="vz-login-link" style="margin-bottom: 36px" id="ilogin_personal_account" name="ilogin_personal_account"><a :href="vcg_personal_login">Log in to your personal account</a></p>
                    </div>
                </form>
                <div>
                    <div id="noAccessToOtpModal" class="modal fade">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content modal-content-gethelp" style="overflow: auto">
                          <!--<a
                            class="col-xs-1 pull-right close-icon-tc"
                            data-dismiss="modal"
                            style="background-size: 20px; background-position-x: -2%;	background-position-y: 0px;	margin: 20px 20px 0px 0px;padding: 0px; top: -20px; height: 74px; width: 84px"
                          ><img src="@/common/assets/styles/corp/images/close_black.png" /></a>-->
                          <div class="close-icon-container">
                            <a
                              data-dismiss="modal"
                            >
                              <img src="@/common/assets/styles/corp/images/close_black.png" style="height:20px; width: 20px;" />
                            </a>
                          </div>

                          <pop-up-component></pop-up-component>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="vz-gf20"></div>
</div>
</template>

<script>
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import PopUpComponent from "@/vbgauth/components/hybridExp/popups/PopUpComponent";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {setPageDetails} from '../../../../public/adobeTagging';
import commonMixin from '@/common/mixin/commonMixins';
import {eventTracking,errorSet} from '../../../../public/adobeTagging';
import ClearWhite from "@/common/assets/styles/corp/images/clear_white.png";
import translations from "@/common/assets/json/messages1.json";
import CommonErrorMixin from "@/vbgauth/components/VbgLoginNew/common/common-error-mixin";

export default{
    name: "iLoginComponent",
    components: { PopUpComponent },
    mixins: [commonMixin, CommonErrorMixin],
    data() {
        return {
            url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
            vec2Registration: vec2_ent_portal_base_url,
            caret: caret_blk,
            resendWelcomeEmail: iamvar_resendWelcomeEmail,
            quick_login : iamvar_Quick_links,
            Mgmt_portal_login : iamvar_Mgmt_Portals,
            vcg_personal_login : iamvar_vcg_login,
            userId: '',
            userPassword: '',
            rememberMe: false,
            canSubmit: false,
            captchaOn: true,
            SignUpUrl:'',
            showClickHereMsg: false,
            idmRedirect: '',
            displaySignUpFalse: '',
            gotoPage: true,
            jCaptchaFlag: false,
            captchaText: null,
            show1: false,
            downlinkSpeed: null,
            isSlowConnection: false,
        }
    },
    async created() {
        setPageDetails('Login:landing');
        this.getLoginCaptchaEnabledEnvVar();
        this.checkForCaptchaOffConditions();
        gnavdl = {
            "bu": "smb",
            "appid" : "unified"
        }
    },
    mounted(){
        iamvar_pwdLessFlowType = 'basic'
        this.setNavHistory();
        var globalNav = document.createElement('script')
        globalNav.setAttribute('src', 'https://www.verizon.com/business/ngnav/smb.js')
        globalNav.setAttribute('async', 'async');
        document.head.appendChild(globalNav)
        // f.setAttribute('id',"login-form");
        // f.setAttribute('name',"loginForm");
        // get gotourl from mixins
        this.$store.dispatch('login/updateGotoUrl', this.getGotoUrl());
        this.$store.dispatch('login/updateEncodedGotoUrl', this.getGotoUrl());
        this.removeExistingVBGCCookie();
        this.getSessionInfo();
        this.updateUrlOnMount(window.location.href);
        this.resetState();
        const hostUrl = window.location.href
        if(hostUrl.includes('/account/business/odasignin')) {
            this.gotoPage = false;
        }
        else if(hostUrl.includes('/account/business/ilogin')) {
            this.jCaptchaFlag = true;
        }
        else if(hostUrl.includes('/account/business/login/ilogin')) {
            this.jCaptchaFlag = true;
                    }
        // call rememberMe function only if uid query parameter is not present
        // let params = new URLSearchParams(window.location.search);
        if(!this.getParameterByName('uid')) {
            this.callRememberMe();
        }
        this.processQueryParameters();
        // console.log('idm url in tsd ', iamvar_idmUrl);
        if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='mdp_agent'){
            this.SignUpUrl=iamvar_mdpSignUpUrl 
        }
        else if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='tsd_agent'){
            this.SignUpUrl=iamvar_thingSpaceSignUpUrl
        }
        else if(this.getParameter('appGroup')==='TSM' || this.getParameter('client_id')==='tss_agent_gz'){
            this.SignUpUrl=iamvar_ccSignUpUrl
        }
        else{
            this.displaySignUpFalse = 'vz-error'
        }
        if(this.getParameterByName('success') =='true' || this.getParameterByName('success') == true) {
            var alertMsg = translations.en.message["forgot_username_display"]
            var msg = [{msg: alertMsg.replace('$0', this.getParameterByName('uid')), type:'success'}]
            this.updateUnifiedLoginAlerts(msg)
        }
        window.localStorage.setItem("InitialRoute", window.location.href);
        //browser back button disabled
        window.onpopstate = () => {
            window.history.forward();
        };
        // Clearing the pwd fields for when sucessfull pwd reset.
        this.formData[1].input[0].value = ""
        this.updateClearFormFields(false);
        this.changeContextBiocatch('ilogin_login_page')
    },
    methods: {
        ...mapMutations('login', ['setPwdUsername', 'setRedirectToStepupPage', 'setUnifiedLoginAlerts']),
        ...mapActions('login', [
            'UpdatePopupValue',
            'submitAMLoginForm',
            'callRememberMe',
            'removeExistingVBGCCookie',
            'getSessionInfo',
            'updateUrlOnMount',
            'resetState',
            'updateCaptchaToken',
            'updateFederalPortalIndicator',
            'mybizzForget',
            'selectAccount',
            'updateUnifiedLoginAlerts',
            'updateClearFormFields'
        ]),
        ...mapActions('cvsstepup', [ 'initializeStepup', 'updateUserIdFlow' ]),
            submitLoginForm: function (recaptchaToken) {
                let payload = {
                formData: this.formData,
                rememberUser: this.rememberMe,
                captchaAuth: this.captchaText,
                additionalAuth:'VBG_LOGIN'
            };
            // update state with new captcha token
            if(this.captchaOn)
                this.updateCaptchaToken(recaptchaToken);
            this.submitAMLoginForm(payload);
            this.setPwdUsername(this.formData[0].input[0].value)
            // might not be needed since submit button is disabled when form is empty
            /*if(this.$refs.loginForm.validate()) {
                this.submitAMLoginForm(payload);
            }*/
            // TODO: implement valid/invalid logic. For right now, redirect to OTP regardless of result
            // this.$router.push({ path: '/vbg-tsd-login/otp' });
        },
        getCloseIcon(){
          // return  (
          //   iamvar_cdnUrl +
          //   iamvar_imgSourceContext +
          //   "/business/login/includes/img/close.7e170800.svg"
          // )
          //return this.getUnifiedLoginAlerts[0].type == "success"
          // ? CloseBlack : ClearWhite;
          return ClearWhite;
        },
        payWithoutLogin() {
            window.location.href = iamvar_mbt_base_url+'/mbt/manageservice/payments.go/#/billpay/onetimepayment'
        },
        getParameter(url) {
            let decodeUrl=decodeURIComponent(document.location)
            url = url.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + url + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(decodeUrl);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        processQueryParameters() {
           // let params = new URLSearchParams(window.location.search);
            if(this.getParameterByName('uid')) {
                this.formData[0].input[0].value = this.getParameterByName('uid');
            }
            if(this.getParameterByName('rememberMe')) {
                if(this.getParameterByName('rememberMe') == 'true')
                    this.rememberMe = true;
            }
        },
        getLoginCaptchaEnabledEnvVar() {
            console.log('(Env based) captcha is on = ', iamvar_enableLoginCaptcha);
            this.captchaOn = iamvar_enableLoginCaptcha;
        },
        checkForCaptchaOffConditions() {
            // turn off captcha when instance is related to a federal portal
            let gotoParam = this.getParameterByName('goto');
            // if goto has 'oauth', get redirect_uri and check if redirect_uri has any of the federalPortalKeywords
            if(gotoParam != null && gotoParam.includes('oauth')) {
                // decode goto in order to easily access redirect_uri parameter
                let decodedGoto = decodeURIComponent(gotoParam);
                // let redirectUriParam = this.getParameterByName('redirect_uri');
                let decodedParameters = new URLSearchParams(decodedGoto);
                let redirectUriParam = decodedParameters.get('redirect_uri');
                if(iamvar_federalPortalKeyWords.some(str => redirectUriParam.includes(str))) {
                    this.captchaOn = false;
                    this.updateFederalPortalIndicator(true);
                }
            } else { // check if goto has any of the federalPortalKeywords
                //if(iamvar_federalPortalKeyWords.some(str => gotoParam.includes(str))) {
                //    this.captchaOn = false;
                //    this.updateFederalPortalIndicator(true);
                //}
            }
        },
        addRedirectUrlToErrorBanner() {
            /* if the current authError (banner showed on screen) is for the user being redirected to IDM 
               (errorCode = REDIRECT_TO_IDM_REQUIRED) we need to add text that says "click here", 
               with an href of the IDM url
            */
           // TODO: confirm if 'redirected' is a good key word to identify this error message
        //    let errorMsg = this.$store.getters['login/getUnifiedLoginAlerts'];
        //    if(errorMsg.errorMessage.includes('redirected')) {
        //        this.showClickHereMsg = true;
        //    }
        },
        getHostName(url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                return match[2];
            }
            else {
                return null;
            }
        },
        getCaptchaImgUrl() {
            let captchaImgHref = this.getHostName(window.location.href);
            let srcURL = this.sanitizeUrl("https://" + captchaImgHref + "/vzauth/vbg/captcha");
            return srcURL;
        },
        refreshCaptchaImgUrl() {
            let captchaImgHref = this.getHostName(window.location.href);
            document.getElementById("captchaImg").src = this.sanitizeUrl("https://" + captchaImgHref + "/vzauth/vbg/captcha?cb=" + Math.random());
        },
        FrUsername() {
            this.$router.push({ path: "/login/recovery/forgotusername" });
            this.updateUserIdFlow('BASIC')
        },
        FrPassword() {
            this.$router.push({ path: "/login/recovery/forgotpassword" });
        },
        getTypeOfAlert (index) {
            //when error occurs take the user to the top screen where the orange banner is shown
            window.scrollTo(0,0);
            return index.type
        },
        messageImage(index) {
            return index.type+"MessageImage"
        },
        deleteAlert(alert) {
            this.getUnifiedLoginAlerts.splice(this.getUnifiedLoginAlerts.indexOf(alert),1)
        },
        gethpop(value){
            this.UpdatePopupValue(value);
            $("#noAccessToOtpModal").modal({
                backdrop: "static",
            });
        },
        bannerContainer() {
          return (this.getUnifiedLoginAlerts[0].type == 'success' || this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")) ? 'bannerMargin' : ''
        },
        forgotPopup(value) {
            $("#noAccessToOtpModal").modal({
            backdrop: "static",
            });
            console.log("value here is..", value);
            this.UpdatePopupValue(value);
            var eventPayload = {}
            var eventName = null
            if(value == 'forgotUserId' || value == 'forgotUserPassword') {
            eventName = value == 'forgotUserId' ? 'forgot_user_id' : 'forgot_password'
            eventPayload = {
            "accounts": {
            "1": "My Business Wireless",
            "2": "Verizon Enterprise Center",
            "3": "My Business Wireline Fios, phone, internet & TV",
                }
            }
            eventTracking(eventName,eventPayload)
            } else if(value == 'payWithoutLogin') {
            eventName = 'pay_without_logging_in'
            eventPayload = {
            "accounts": {
            "1": "My Business Wireless",
            "2": "My Business Wireline Fios, phone, internet & TV",
            "3": "Verizon Enterprise Center"
                }
            }
            eventTracking(eventName,eventPayload)
            }
        },
    },
    computed: {
        ...mapGetters('login', [
            'getUnifiedLoginAlerts',
            "getUnifiedErrorCode",
            'submitLoader',
            'iamvar_enableCaptcha',
            'redirectToStepupPage',
            'redirectToSecProfilePage',
            'iamvar_idmUrl',
            'loginCaptchaKey',
            'getAlertType',
            'idmRedirectUrl',
            'rememberMeTrue',
            'remembermeUserName',
            'popupValue',
            'getUserSelectedAccount',
            'clearFormFields'
        ]),
        ...mapGetters('cvsstepup', [ 'currentStep' ]),
        ...mapGetters('forgotflow',[ "usrName" ]),
        supportUrl(){
            let isGoToPresent = window.location.href.split('goto=');
            console.log("isGoToPresent",isGoToPresent)
            if(isGoToPresent.length>1){
            let registration_url = this.vec2Registration.split('#')
            return registration_url[0]+'?goto='+isGoToPresent[1]+'#'+registration_url[1]
            }
            return this.vec2Registration;
    
        },
         isFormComplete() {
            let captchaComplete;
            if(this.jCaptchaFlag) {
                if (this.captchaText != null) {
                    captchaComplete = true;
                }
                else {
                    captchaComplete = false;
                }
            }
            else {
                captchaComplete = true;
            }
            // return this.userId && this.userPassword;
            return this.formData[0].input[0].value.length !== 0 &&
            this.formData[1].input[0].value.length !== 0 &&
            captchaComplete;
        },
        formData: {
            get: function () {
                return this.$store.getters['login/formData'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateFormData', newVal);
            }
        },
        gotoUrl: {
            get: function () {
                return this.$store.getters['login/gotoUrl'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateGotoUrl', newVal);
                this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
            }
        },
        getHelpCheck(){
          return (this.getUnifiedErrorCode == "ACCOUNT_LOCK_PREVENTION" || this.getUnifiedErrorCode == "ACCOUNT_LOCKED" || this.getUnifiedErrorCode == "ACCOUNT_PREVENTION" || this.getUnifiedErrorCode == "ACCOUNT_LOGIN_LOCKED" || this.getUnifiedErrorCode == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") ? true : false
        },
        
    },
    watch: {
        show1: function(newVal) {
          if(newVal){
            setTimeout(() => {
              this.show1 = false
            },10000)
          }
        },
        redirectToStepupPage: function(newVal) {
            if(newVal) {
                this.initializeStepup();
                this.setRedirectToStepupPage(false)
            }
        },
        redirectToSecProfilePage: function(newVal) {
            if(newVal) {
                window.location.href = newVal;
            }
        },
        rememberMeTrue: function(newVal) {
            if(newVal == 'setToTrue') {
                this.rememberMe = true
            }
        },
        currentStep: function(newVal) {
            if(newVal === 'FLOW_INITIALIZED') {
                if(this.gotoUrl != null) {
                    pwd_less_new_login ? this.$router.push({ path: '/login/stepup/otpdelivery', query: { goto: this.gotoUrl }}) :
                    this.$router.push({ name: 'FFStepupRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
                } else {
                    pwd_less_new_login ? this.$router.push({ path: '/login/stepup/otpdelivery'}) :
                    this.$router.push({ name: 'FFStepupRootComponent' });
                }
            }
        },
        idmRedirectUrl: function(newVal) {
            if(newVal)
                this.idmRedirect = newVal;
        },
        getUnifiedLoginAlerts: function(newVal) {
            if(newVal) {
                console.log("UNIFIED ALERT", newVal)
                this.addRedirectUrlToErrorBanner();
                if(this.jCaptchaFlag) {
                    this.refreshCaptchaImgUrl();
                    this.captchaText = null;
                }
            }
        },
        usrName: function(newVal) {
        if(newVal != undefined || newVal != null) {
          console.log("username=",newVal)
          this.formData[0].input[0].value = newVal
        }
      },
        remembermeUserName: function(newval) {
          if(newval != "" || newval != null) {
            console.log("remembermeUserName=", newval)
            this.formData[0].input[0].value = newval
          }
        },
        getUserSelectedAccount: function(selectedAccount){
            $("#noAccessToOtpModal").modal('hide')
            if(selectedAccount && this.popupValue=='interstitial'){
             let payload = {
                formData: this.formData,
                rememberUser: this.rememberMe,
                additionalAuth : "VBG_LOGIN"
             };
             if(this.getUnifiedErrorCode.includes("INVALID")){
                if (selectedAccount == "wireline" && this.getUnifiedErrorCode.includes("INVALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if (selectedAccount == "wireless" && this.getUnifiedErrorCode.includes("INVALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if(selectedAccount == "fios" && this.getUnifiedErrorCode.includes("INVALID")){
                    payload['additionalAuth'] = "INVALID_SMB_LOGIN"
                     // to do - "authenticate" call with additional header parameter as "INVALID_SMB_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }
             }else{
                if(selectedAccount == "fios" && this.getUnifiedErrorCode.includes("VALID")){
                    this.smbFormSubmit({username:this.formData[0].input[0].value,password:this.formData[1].input[0].value,rememberUser:this.rememberMe})
                }else if (selectedAccount == "wireline" && this.getUnifiedErrorCode.includes("VALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if (selectedAccount == "wireless" && this.getUnifiedErrorCode.includes("VALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }
             }
            }else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserId'){
            this.$router.push({ path: "/login/recovery/forgotusername" });
            }else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserPassword'){
            this.$router.push({ path: "/login/recovery/forgotpassword" });
            }else if(selectedAccount=='fios' && this.popupValue=='forgotUserPassword'){
            window.location.href = iamvar_fios_fp_url+'?goto=https%3A%2F%2Fbusiness.verizon.com%2FMyBusinessAccount%2Fauth%2FfromSSO.jsp&clientId=vbm'
            }else if(selectedAccount=='fios' && this.popupValue=='forgotUserId'){
            window.location.href = iamvar_fios_fu_url
            }else if(selectedAccount=='wireless' && this.popupValue=='payWithoutLogin'){
            window.location.href = iamvar_mbt_base_url+'/mbt/manageservice/payments.go/#/billpay/onetimepayment'
            }else if(selectedAccount=='fios' && this.popupValue=='payWithoutLogin'){
            window.location.href = this.wireline_login
            }else if(selectedAccount=='wireline' && this.popupValue=='payWithoutLogin'){
            window.location.href = iamvar_vec_quickpay
            }
          // to reset initial value
            this.selectAccount('null')
        },
        clearFormFields : function (newVal) {
            if(newVal) {
            // Clearing the form fields for when invalid.
                this.formData[0].input[0].value = ""
                this.formData[1].input[0].value = ""
                this.updateClearFormFields(!newVal)
            }
        },
        downlinkSpeed: function(newVal) {
          if(newVal < 1) {
            // this.isSlowConnection = true
            var infoMsg = translations.en.message["slow_internet_msg"]
            this.setUnifiedLoginAlerts([{ type: 'information', msg: infoMsg }])
          }
        },
   }
}

</script>

<style scoped> 
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
    .successbannercloseimg{
      cursor: pointer;
      width: 13px;
      height: 13px;
    }
    .bannerMarginUnset{
      margin:unset;
    }
    .showBtn{
      position: absolute;
      right: 12px;
      margin-top: 12px;
      text-decoration: underline;
      cursor: pointer;
      height: 20px;
      color: #000000;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
    }
    .modal-content-gethelp{
      height: 440px;
    }
    .helpblock{
      Width:170px;
      Height:28px;
      position: absolute;
      margin-left: 485px;
    }
    .helpbutton{
      Width:88px;
      Height:28px;
      border-radius:14px;
      border: solid #000000;
    }
    .buttontext{
      height: 16px;
      width: 56px;
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      top:-1px;
      line-height: 16px;
      text-align: center;
    }
    .close-icon-container {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 14px;
    }
    #noAccessToOtpModal {
      z-index: 105000000;
    }
    .log-in {
        height: 48px;
        width: 386px;
        color: #000000;
        font-family: "VzNHGeDS-bold11",Arial,Helvetica,sans-serif;
        font-size: 48px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 48px;
        margin-bottom: 7px;
    }
    .container-to-your-business-acc {
        width: 384px;
        max-width: 384px;
    }
    .to-your-business-acc {
        height: 24px;
        width: 386px;
        color: #000000;
        /* font-family: "Verizon NHG eDS"; */
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 12px;
    }
    hr.vz-horizontal {
        margin: 12px 0 !important;
    }
    .user-id {
        height: 98px;
        /* width: 385px; */
        margin-bottom: 12px;
    }
    .user-captcha{
        height: 176px;
    }
    .captcha-blk{
        height: 46px;
        margin-bottom: 10px;
        background: linear-gradient(270deg, #00FFF0 0%, #FFB404 100%);
    }
    .-input-label {
        height: 16px;
        width: 45px;
        color: #000000;
        /* font-family: "Verizon NHG eTX"; */
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
    }
    .input-f {
        box-sizing: border-box;
        height: 44px;
        width: 100%;
        border: 1px solid #d8dada;
        background-color: #ffffff;
        margin-bottom: 14px;
        border-bottom: #000000 1px solid !important;
        padding: 6px 12px;
        font-size: 16px;
    }
    .captcha-txt-blk{
        position: absolute;
        margin-top: 24px;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
    .forgot-link {
        height: 20px;
        color: #000000;
        /* font-family: "Verizon NHG DS"; */
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
        float: right;
        cursor: pointer;
        text-decoration: underline;
    }
    input[type="checkbox"].tc {
        width: 20px;
        height: 20px;
        outline: 1px solid #000;
        margin-right: 10px;
        position: relative;
        left: unset;
    }
    p > label {
        height: 20px;
        /* width: 296px; */
        color: #000000;
        /* font-family: "Verizon NHG eDS"; */
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
    }
    .vz-login-link {
        height: 20px;
        /* width: 385px; */
        color: #000000;
        /* font-family: "Verizon NHG DS"; */
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
        display: table;
    }
    a {
        color: #000000;
        text-decoration: underline;
    }
    a:hover {
        color: #000000;
        text-decoration: underline;
        cursor: pointer;
    }

    input.tab {
        appearance: none;
        -webkit-appearance: none;
        border: none;
        display: none;
        background: transparent;
        visibility: hidden;
    }
    input.tab {
        display: inline-block;
        position: absolute;
        margin-left: -6px;
        height: 40px;
        width: 120px;
        cursor: pointer;
    }
    /* input.tab+label { display:none; } */
    input.tab + label {
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        vertical-align: bottom;
        padding: 6px 24px 15px 0;
        margin: 0 0 24px;
        border-bottom: solid 1px #ccc;
        font-size: 16px !important;
    }
    input.tab + label:not(:hover) {
        color: #777;
    }
    input#usePush + label {
        padding-left: 24px;
        padding-right: 0;
    }
    input.tab:checked + label {
        border-bottom: solid 4px #d52b1e;
        padding: 6px 0 12px !important;
        color: #000;
        font-family: VzNHGeDS-bold11;
    }
    .newError{
        background: #ed7000 !important;
    }
    .modal-dialog {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .modal-dialog {
        margin: 0px !important;
    }
    @media only screen and (min-width: 320px) and (max-width: 660px) {
      .helpblock {
          position: relative;
          margin-left: 30px;
          margin-top: 15px;
          float: left;
        }
    }
    @media only screen and (min-width: 712px) and (max-width: 975px){
    .helpblock {
          position: relative;
          margin-left: 30px;
          margin-top: 8px;
          float: left;
        }
}
@media only screen and (min-width: 976px) and (max-width: 1500px){
    .messageWrapper{
        display: flex;
        align-items: flex-start;
    }
    .helpblock {
          position: relative;
          margin-left: 30px;
          /* margin-top: 8px; */
          float: left;
        }
}
    @media only screen and (min-width: 320px) and (max-width: 1200px) {
      .default-promo {
        display: none;
      }
      .default-promo1 {
        display: none;
      }
      .modal-dialog {
        margin: 0px !important;
      }
    }
    @media (max-width: 540px){
      .modal-dialog.modal-dialog-centered{
        height: 100% !important;
        margin: 0px !important;
        width: 100% !important;
    }
      .modal-content{
        width: 100%;
        height: 100%;
      }
    }
    @media (min-width: 270px) and (max-width: 400px) {
        .container-to-your-business-acc {
            width: 100%;
        }
    }
    @media (max-width: 320px) {
       /*.closeMessageImg{
            position: relative;
        }*/
        .messageWrapper{
            position: relative;
        } 
    }
    @media (max-width: 320px) {
        .messageWrapper{
            position: relative;
        }
  
    }
    @media (min-width: 460px){
        .iL-Container{
        width: 384px;
        }
    }
    @media (min-width: 768px){
        .iL-Container{
        display: block;
        }
    }
    @media(min-width: 320px) and (max-width: 768px){
        .loginmar20{
            margin-top: -20px;
        }
    }
    @media (max-width: 330px){
        .register-link{
            white-space: pre;
        }
    }
    @media (max-width: 280px) {
        .closeMessageImg{
          position: absolute;

        }
    }
    @media ( min-width: 768px ) {
        .errorbannercloseimg{
            width: 13px;
            height: 13px;
            margin-top: -12px;
            cursor: pointer;
        }
    }
    @media ( max-width: 320px ) {
        .errorbannercloseimg{
            width: 13px;
            height: 13px;
            cursor: pointer;
        }
    }
    @media (min-width: 375px) and (max-width: 720px) {
        .errorbannercloseimg{
            width: 13px;
            height: 13px;
            margin-top: -6px;
            cursor: pointer;
        }
    }
    @media (max-width: 425px) {
      .bannerMargin {
         margin: 8px 16px 0px;
      }
    } 
    
</style>
