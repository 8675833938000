<template>
  <div>
    <!-- <div v-if="getUnifiedLoginAlerts.length > 0">
      <div class="row" v-for="j in getUnifiedLoginAlerts" :key="j">
        <div class="col-sm-12">
          <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
            <div class="vbg-ff-msg-container">
              <div class="messageWrapper" style="display: flex">
                <div
                  class="messageIcon"
                  style="padding: 0px; margin-right: 12px"
                >
                  <div class="messageImage" :class="messageImage(j)"></div>
                </div>
                <p
                  class="messageContentUn col-xs-11"
                  v-if="j.type == 'list'"
                  v-html="j.msg"
                  style="padding: 0px; margin: 0px"
                >
                  {{ j.msg }}
                </p>
                <p
                  class="messageContentUn col-xs-11"
                  v-else-if="j.type == 'error' || j.type == 'warning'"
                  style="padding: 0px; margin: 0px"
                  v-html="j.msg"
                >
                  {{ j.msg }}
                </p>
                <p
                  class="messageContentUn col-xs-11"
                  v-else
                  style="padding: 0px; margin: 0px; color: #ffffff"
                  v-html="j.msg"
                >
                  {{ j.msg }}
                </p>
                <div
                  class="closeMessage col-xs-1"
                  style="padding: 0px; margin-left: auto"
                >
                  <img
                    class="closeMessageImg"
                    v-if="
                      j.type == 'list' ||
                      j.type == 'error' ||
                      j.type == 'warning'
                    "
                    src="@/common/assets/styles/corp/images/close_black.png"
                    @click="deleteAlert(j)"
                  />
                  <img
                    class="closeMessageImg"
                    v-else
                    src="@/common/assets/styles/corp/images/clear_white.png"
                    @click="deleteAlert(j)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="vbg-rsp-main-container">
      <BlockUI v-if="submitLoader" :url="url"></BlockUI>
      <div class="vbg-rsp-title-container">
        <h1 class="vbg-rsp-title-h1">
          {{ $t("message.reset_security_userid_title") }}
        </h1>
      </div>
      <div>
        <p style="color: #000">
          {{ $t("message.reset_security_userid_des") }}
        </p>
      </div>
      <div class="vbg-rsp-form-container">
        <form @submit.prevent="validateUser()">
          <label class="vbg-ff-input-label" for="userid" :class="gotoComponent == 'resetSecurityProfileNewPwd' ? 'vbg-rsp-disabled-label' : ''">User ID</label>
          <div>
            <input
              id="userid"
              name="userid"
              @keydown.space.prevent
              ref="userIDRef"
              v-model="formData[0].input[0].value"
              type="text"
              class="vbg-rsp-input-field"
              :class="gotoComponent == 'resetSecurityProfileNewPwd' ? 'vbg-rsp-disabled-input' : ''"
              :disabled="gotoComponent == 'resetSecurityProfileNewPwd'? true : false"
            />
          </div>
          <div class="vbg-sp-btn-continue">
            <button
              :disabled="!formData[0].input[0].value || gotoComponent == 'resetSecurityProfileNewPwd'? true : false"
              class="btn vbg-ff-btn-continue vbg-ff-btn-width140 spacing05"
              type="submit"
              :class="((!formData[0].input[0].value) || (gotoComponent == 'resetSecurityProfileNewPwd')) ? 'vbg-ff-disabled' : ''"
              id="validate-userid-btn"
              name="validate-userid-btn"
            >
              Continue
            </button>
          </div>
        </form>
        <ResetSecurityProfileNewPwd v-if="gotoComponent === 'resetSecurityProfileNewPwd'"></ResetSecurityProfileNewPwd>
      </div>
    </div>
  </div>
</template>
<script>
import translations from "@/common/assets/json/messages1.json";
import { mapGetters, mapActions } from "vuex";
import { setPageDetails, errorSet } from "../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
import ResetSecurityProfileNewPwd from "@/vbgauth/components/unlockMyAccount/ResetSecurityProfileNewPwd";
export default {
  name: "ValidateUserIdComponent",
  props: ["tokenVal"],
  components: {
    ResetSecurityProfileNewPwd,
  },
  data() {
    return {
      whichRealm: true,
      userValidationInputClass: "",
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
    };
  },
  beforeDestroy() {
    this.formData[0].input[0].value = "";
    this.formData[1].input[0].value = "";
  },
  mixins: [commonMixin],
  async created() {
    setPageDetails("Recovery Secure Profile: Validate user id");
  },
  mounted() {
    this.changeContextBiocatch('rsp_validate_user_id_page')
    this.$nextTick(() => this.$refs.userIDRef.focus());
    this.updateUrlOnMount(window.location.href);
    // check if user coming from login page sdar user
    if(
        (iamvar_polarisFlowToggle && this.pwdUsername) 
        && (this.sdarUserType === 'PEND_SDAR_1' || this.sdarUserType === 'PEND_SDAR_2')
    ) {
      this.formData[0].input[0].value = this.pwdUsername;
    } else if(iamvar_loginAssistant && this.pwdUsername) {
      this.formData[0].input[0].value = this.pwdUsername
    }
    
  },
  methods: {
    ...mapActions("login", ["submitAMLoginForm", "updateUrlOnMount", "updateUnlockMyAccountAlerts"]),
    validateUser() {
      this.formData[1].input[0].value = this.tokenVal;
      let payload = {
        formData: this.formData,
      };
      this.$refs.userIDRef.focus();
      this.submitAMLoginForm(payload);
    },
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      if (
        index.type == "error" ||
        index.type == "warning" ||
        index.type == "list"
      ) {
        return "newErrorMessageImage";
      } else {
        return index.type + "MessageImage";
      }
    },
    getCloseIcon() {
      return (
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/img/close_white.png"
      );
    },
    getAlertType(index) {
      errorSet(index.msg);
      window.scrollTo(0, 0);
      return index.type;
    },
    deleteAlert(alert) {
      this.getUnifiedLoginAlerts.splice(
        this.getUnifiedLoginAlerts.indexOf(alert),
        1
      );
    },
  },
  computed: {
    ...mapGetters("login", [
      "getUnifiedLoginAlerts",
      "redirectToSecProfilePage",
      "submitLoader",
      "pwdUsername",
    ]),
    ...mapGetters("profile",["gotoComponent", "sdarUserType"]),
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
  },
  watch: {
    getUnifiedLoginAlerts: function () {
      if (this.getUnifiedLoginAlerts.length > 0) {
        window.scrollTo(0, 0);
      }
    },
    redirectToSecProfilePage: function (newVal) {
      if (newVal) {
        window.location.href = newVal;
      }
    },
  },
};
</script>
<style scoped>
.alert-warning {
  background-color: #ffbc3d;
  background-size: 35px 29px;
  color: #000;
  border-color: #ffbc3d;
}
.alert-info {
  background-color: #08c;
  background-size: 35px 29px;
  color: #fff;
  border-color: #08c;
}
.messageContentUn {
  color: #000000;
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  line-height: 20px;
}
.vbg-ff-btn-continue {
  height: 44px !important;
  font-size: 16px !important;
  line-height: 18px;
  padding: 12px;
  text-align: center;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  padding: 12px;
  font-size: 16px;
}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}
@media (min-width: 280px) {
  .closeMessageImg {
    position: static !important;
  }
}
@media (max-width: 1024px) {
  .messageContent {
    flex: 1;
  }
}
.bold75 {
  font-family: VzNHGeDS-bold11;
}
@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
}
@media (max-width: 425px) {
  .messageContent,
  .messageContentUn {
    width: 232px;
  }
}
* {
  box-sizing: border-box;
}
label {
  display: block;
}
.closeMessageImg {
  cursor: pointer;
}
</style>
