<template>
  <v-container class="vz-container vz-header">
    <v-row>
    <v-col cols="12" sm="12" md="6" class="my-4">
      <v-card flat>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-img class="hlogo" :src="require('@/common/assets/styles/corp/images/vzlogoLarge.png')"></v-img>
            <img class="hlogo" :src="require('@/common/assets/styles/corp/images/vzlogoLarge.png')" />
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="6" class="my-4">
      <v-card flat>
        <v-row>
          <template v-for="(menu, i) in headerConfig.globalMenu">
            <a class="vz-header-link vz-link-menu" :key="i" :href="menu.href">
              {{ menu.name }}
            </a>
          </template>
        </v-row>
        <v-row>
          <template v-for="(nav, j) in headerConfig.primaryNav">
            <a class="vz-header-link vz-link-nav" :key="j" :href="nav.href">
              {{ nav.name }}
            </a>
          </template>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  </v-container>
</template>
<script>
import config from '@/common/assets/json/login_page_config.json'
export default {
  name: 'AMHeader',
  components: {
  },
  data () {
    return {
      headerConfig: config.header
    }
  },
  methods: {
    getLogo() {
      return iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/img/vzlogoLarge.21ecaab0.png'
    }
  }
}
</script>
<style scoped>

.vz-container {
  padding: 12px 24px !important; 
}
.vz-header-link {
  font-size: 14px !important;
  padding: 2px 0px !important;
  margin: 10px 0 0 12px;
  text-decoration: none !important;
  border-bottom: 1px solid transparent !important;
  cursor: pointer !important;
}
 .hlogo { display: block; float:left; width: 160px; }
.vz-link-menu {
  color: #747676 !important;
}
.vz-link-menu:hover {
  color: #000 !important;
  border-bottom: 1px solid red !important;
}
.vz-link-nav {
  font-family: NeueHaasGroteskDisplay,Arial,Sans-Serif !important;
  color: #000 !important;
}
.vz-link-nav:hover {
  border-bottom: 1px solid red !important;
}
</style>