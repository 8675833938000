<template>
  <div class="clearfix padding-top-large padding-bottom-xlarge padding-left-small header">
    <p v-if="this.redirectUrl != null || this.redirectUrl != undefined"> The page you are trying to access is not found. Redirecting you to the login page. </p>
    <p v-if="this.redirectUrl === null || this.redirectUrl === undefined"> The page you are trying to access is not found. </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NotFoundComponent',
  components: {
  },
  data () {
    return {
      redirectUrl: null
    }
  },
  created() {
    this.initialize()
  },
  mounted() {
  },
  methods: {
    initialize() {
      // use redirect variable to assign window.location.href if available
      let redirectVar = this.urlOnMount;
      this.redirectUrl = redirectVar;
      
      if(this.redirectUrl != undefined || this.redirectUrl != null) {
        setTimeout(function () {
          window.location.href = redirectVar;
        }, 5000)
      }

    }
  },
  computed: {
    urlOnMount: {
      get: function () {
        return this.$store.getters['login/urlOnMount']
      }
    },
    /* ...mapGetters('login', [
      'urlOnMount'
    ])*/
  }
}
</script>
<style scoped>
</style>
