<style>
.max_attemps_parent{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
}
.first_msg{
font-size: 30px;
font-family: 'VzNHGeDS-reg11';
margin-bottom: 40px;
margin-top: 40px;
max-width: 500px;
text-align: center;
}
.second_msg{
font-size: 30px;
font-family: 'VzNHGeDS-reg11';
max-width: 440px;
margin-bottom: 40px;
text-align: center;
}
@media screen and (max-width: 767px){
.first_msg, .second_msg{
font-size: 24px;
text-align: left;
}
}
</style>

<template>
<div>
    <div id="vz-gh20"></div>

    <div class="max_attemps_parent">
      <div>
        <img src="../././././../../../src/common/assets/styles/corp/images/fail_M@3x.png" alt="fail_attempts">
      </div>
    <div class="first_msg">{{ $t("message.forgot_attempt_unsuccessfull") }}</div>
    <div class="second_msg">{{ $t("message.forgot_attempt_maximum_reached") }}</div>
</div>

    <div id="vz-gf20" style = "clear:both"></div>
</div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default ({
    name: 'login',
    data() {
        return {
            selectedLanguage: this.$i18n.locale,
            supportedLanguages: [
                { code: 'en', name: 'English' },
                { code: 'es', name: 'Español' },
            ]
        }
    },
  created: function () {
    const searchParams = new URLSearchParams(window.location.search);
    const storedLanguage = localStorage.getItem('lng');
    const browserLanguage = navigator.language.substring(0, 2);;
    if (storedLanguage) {
        this.$i18n.locale = storedLanguage;
        this.selectedLanguage = storedLanguage;
    } else if (this.supportedLanguages.some(lang => lang.code === browserLanguage)) {
        this.$i18n.locale = browserLanguage;
        this.selectedLanguage = browserLanguage;
    } 
  },
  mounted () {
    document.documentElement.lang = this.$i18n.locale;
     (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window, document, 'script', 'dataLayer', 'GTM-KJFSV78');
    setTimeout(() => {
        const langSwitchLink = document.querySelector('.gnav20-lang-link');
         if(langSwitchLink) {
          document.addEventListener('click', (e)=> {
          const langValue = e.target.getAttribute('data-lang');     
          if(langValue) {
            if(langValue === 'English'  || langValue === 'Inglés') {
                localStorage.setItem("lng","en");
            } else {
                localStorage.setItem("lng","es");
            }
          }
          })
          var locale = this.$i18n.locale;
          const variationValue = locale == 'es' ? 'prospect-es' : 'prospect';
          var globalNavdl = {
            "bu": "corporate",
            "appid": "vsbdr",
            "variation": variationValue,
            "options":{"hide-item":["search"]}
          }
          window.gnavdl = globalNavdl;
         }
       }, 1500);
       var globalNav = document.createElement('script')
       globalNav.setAttribute('src', 'https://www.verizon.com/etc/designs/vzwcom/gnav20/corporate.js')
       globalNav.setAttribute('async', 'async');
       document.head.appendChild(globalNav)
  }
})
</script>
