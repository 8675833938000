<template>
    <!-- <div class="container center_margin page-wrapper">
      <div class="center_margin site-logo">
        <img src="https://accessmgrqa1.verizonwireless.com/am/images/logo.png" class="logoImg" alt="verizon logo">
      </div> -->
      <div>
      <vpsHeader/>
      <div class="registration-successfull">
        <h2 class="registration-successfull-heading">Your Registration is successful</h2>
        <h2 class="registration-successfull-description">Thank you for registering for the Verizon Wholesale Portal. We'll will email you as soon as your access has been completed.</h2>
      </div>
      <vpsFooter/>
    </div>
  </template>
  
  <script>
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
  export default {
    name: "VPSRegistrationSuccessful",
    components: {
      vpsHeader,
      vpsFooter
    },
    data() {
      return {
        componentName: "/",
      };
    },
    mounted() {
  
    },
  };
  </script>
  <style scoped>
 
  .site-logo {
    border-bottom: 1px solid #ebecec;
    padding: 20px 20px 30px 20px;
    margin-bottom: 20px;
  }
  .registration-successfull {
    text-align: center;
    margin: 20px;
  }
  .registration-successfull-heading {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .registration-successfull-description {
    font-weight: bold;
    margin-bottom: 20px;
    max-width: 360px;
    margin: 0 auto;
  }

  </style>