<template>
    <v-row class="margin-bottom-medium padright0 col-xs-12">
        <strong><span v-if="!fingerprintSaved">
            <a style="border-bottom: 0px" v-if="!fingerprintSaved && sqaEnabled===true" class="vz-a" @click="switchToSqa()">
                <b>Or verify my identity by secret question </b>&nbsp;&nbsp;<img style="width: 10px;height:14px;" :src="caret">
            </a>
            <a style="border-bottom: 0px" v-if="!fingerprintSaved && sqaEnabled===false" class="vz-a-disabled" @click="switchToSqa()">
                <b>Or verify my identity by secret question </b>&nbsp;&nbsp;<img style="width: 10px;height:14px;" :src="caret">
            </a><p v-if="!fingerprintSaved && noSqa" style="color:#FFBC3D">There are no registered secret questions</p>
        </span></strong>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import caret_blk from '@/common/assets/styles/corp/images/CTA-caret_blk.png'
export default {
  name: 'SwitchToSQAComponent',
  components: {
  },
  data () {
    return {
      caret: caret_blk,
    }
  },
  methods: {
    switchToSqa() {
        this.$router.push({ name: 'FFSqaComponent'})
    }
  },
  computed: {
    ...mapGetters('cvsstepup', [
      'fingerprintSaved',
      'sqaEnabled',
      'noSqa'
    ])
  },
  watch: {
    sqaEnabled: function(newVal) {
      if(!newVal) {
        this.linkClass = 'vz-a-disabled'
      }
    }
  }
}
</script>
<style scoped>
.v-row {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
</style>
