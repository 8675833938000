<template>
  <v-wait for="fetch_secret_questions">
    <template slot="waiting">
      <LoaderComponent></LoaderComponent>
    </template>
    <v-container> 
      <Header v-if="this.whichRealm"></Header>
      <router-view></router-view>
    </v-container>
  </v-wait>
</template>
<script>
import Header from '@/vbgauth/components/Header'
// import AMSqaRegComponent from '@/components/csqa/AMSqaRegComponent'
import LoaderComponent from '@/vbgauth/components/LoaderComponent'
// import { mapWaitingActions } from 'vue-wait'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AMSqaRegRootComponent',
  components: {
    Header,
    LoaderComponent
  },
  data () {
    return {
      whichRealm: true
    }
  },
  mounted () {
    this.getSecretQuestions()
    if((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if(hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          this.whichRealm = false;
        }
    }
  },
  methods: {
    /* ...mapWaitingActions('sqareg', {
      getSecretQuestions : 'fetch_secret_questions'
    }), */
    ...mapActions('sqareg', [
        'getSecretQuestions'
      ]
    )
  },
  computed: {
    ...mapGetters('sqareg', [
      'secretQuestions'
    ]),
    ...mapGetters('cvsstepup', [
      'urlOnMount'
     ])
  },
  watch: {
    secretQuestions: function(newVal) {
		  if(newVal !== null) {
        this.$router.push({ path: 'vbg-csqa/reg' })
      }
    }
  }
}
</script>
<style scoped>
</style>
