<template>
    <div>
        <BlockUI v-if="submitLoader" :url="url"></BlockUI>
        <vpsHeader></vpsHeader>
        <div v-if="fuAlerts.length > 0">
            <div class="row" v-for="j in fuAlerts" :key="j">
                <div class="col-sm-12">
                    <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
                        <div class="vbg-ff-msg-container">
                            <div class="messageWrapper" style="display: flex;">
                                <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                                    <div class="messageImage" :class="messageImage(j)"></div>
                                </div>
                                <p class="messageContentUn col-xs-11" v-if="j.type == 'list'" v-html="j.msg"
                                    style="padding:0px;margin:0px">{{j.msg}}</p>
                                <p class="messageContentUn col-xs-11"
                                    v-else-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px"
                                    v-html="j.msg">{{j.msg}}</p>
                                <p class="messageContentUn col-xs-11" v-else
                                    style="padding:0px;margin:0px;color:#ffffff" v-html="j.msg">{{j.msg}}</p>
                                <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                                    <img class="closeMessageImg"
                                        v-if="j.type == 'list' || j.type == 'error' || j.type == 'warning'"
                                        src="@/common/assets/styles/corp/images/close_black.png"
                                        @click="deleteAlert(j)" />
                                    <img class="closeMessageImg" v-else
                                        src="@/common/assets/styles/corp/images/clear_white.png"
                                        @click="deleteAlert(j)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="suspend-error-container">
            <div class="wrapper-container">
                <div class="container-left-vps">
                    <h2 class="vps-header-pos">Forgot my</h2>
                    <h2 class="vps-header-pos">User ID</h2>
                    <p class="vps-sidehead-pos">User ID will be sent to your Email address or</p>
                    <p class="vps-sidehead-pos">Mobile Number</p>
                </div>
                <div class="container-right-vps">
                    <p class="vps-radio-header">Select a User ID you are</p>
                    <p class="vps-radio-header">trying to retrieve</p>
                    <div class="vps-radio-container">
                        <label class="vps-label" v-for="user in userList" :key="user.userid">
                            <input type="radio" :value="user.userid" v-model="selectedUserId" />
                            <span>{{ user.useridMasked }}</span>
                        </label>
                        <button type="submit" v-on:click="submitUserName()"
                            class="btn vbg-ff-btn-continue spacing05 vps-cont-btn">
                            Continue
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <vpsFooter></vpsFooter>
    </div>
</template>

<script>
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: "VPSForgotUserIdSelect",
    components: {
        vpsHeader,
        vpsFooter
    },
    data() {
        return {
            selectedUserId: "",
            userList: [],
            url: iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/circle-loaderWHITE_48x48.gif',
        };
    },
    mounted() {
        this.fuAlerts.splice(0, 1);
        this.formatUsernames();
    },
    methods: {
        ...mapActions('cvsstepup', [
            'vpsTriggerForgotuserNameEmail'
        ]
        ),
        formatUsernames() {
            if (this.vpsUsersData) {
                let rawData = this.vpsUsersData;
                let keyValuePairs = Object.entries(rawData);
                this.userList = []
                keyValuePairs.forEach(([key, value]) => {
                    let maskedUserNameValue = this.maskUsername(String(value))
                    let eachData = {
                        guid: key,
                        userid: value,
                        useridMasked: maskedUserNameValue
                    }
                    this.userList.push(eachData);
                })
            }
        },
        maskUsername(username) {
            let firstLetter = username.charAt(0);
            let lastletter = username.charAt(username.length - 1);
            let maskedpart = 'x'.repeat(username.length - 2);
            return firstLetter + maskedpart + lastletter;
        },
        submitUserName() {
            this.vpsTriggerForgotuserNameEmail(this.selectedUserId);
        },
        getMsgIcon(index) {
            return "@/common/assets/styles/corp/images/" + index.type + ".png";
        },
        messageImage(index) {
            if (index.type == 'error' || index.type == 'warning' || index.type == 'list') {
                return "newErrorMessageImage"
            } else {
                return index.type + "MessageImage";
            }
        },
        getCloseIcon() {
            return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
        },
        deleteAlert(alert) {
            this.fuAlerts.splice(this.fuAlerts.indexOf(alert), 1)
        },
        getAlertType(index) {
            return index.type
        }
    },
    computed: {
        ...mapGetters('cvsstepup', [
            'vpsUsersData',
            'fuAlerts',
            'submitLoader'
        ]),
        fuAlerts: {
            get: function () {
                return this.$store.getters['cvsstepup/fuAlerts']
            },
            set: function (newVal) {
                this.$store.commit('cvsstepup/setFuAlert', newVal)
            }
        }
    },
    watch: {
        fuAlerts: function (newVal) {
            if (newVal) {
                window.scrollTo(0, 0);
            }
        },
    }
};
</script>
<style scoped>
.wrapper-container {
    overflow: hidden;
}

.vps-label {
    display: block;
    text-align: left;
}

.vps-radio-container {
    margin-top: 10px;
}

.wrapper-container div {
    /* min-height: 200px; */
    padding: 10px;
}

.container-left-vps {
    float: left;
    margin-right: 90px;
    /* width:auto; */
}

.container-right-vps {
    overflow: hidden;
    margin: 10px;
}

.vps-cont-btn {
    float: left;
    margin-top: 40px;
}

@media screen and (max-width: 800px) {
    .container-left-vps {
        float: none;
        margin-right: 0;
        width: auto;
    }

    .container-right-vps {
        margin-left: 30px;
    }
}

input[type="radio"] {
    cursor: pointer;
    padding: 0;
    margin: 0 12px 0 0;
    min-width: 20px;
    width: 20px;
    height: 20px;
    top: 0px;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    background-color: #FFFFFF;
    border: 2px solid #000000;
}

input[type="radio"]:checked {
    border: 5px solid #000000;
}

.suspend-error-container {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 280px;
    word-break: break-word;
}

.vps-header-pos {
    font-size: 32px;
    margin-left: 30px;
    text-align: left;
}

.vps-sidehead-pos {
    font-size: 14px;
    margin-left: 30px;
    text-align: left;
}

.vps-radio-header {
    font-size: 16px;
    font-weight: bolder;
    text-align: left;
}
</style>