<template>
  <div>
    <div class="tittle">
      <h2>{{ mainTitle }}</h2>
    </div>
    <div class="sub-tittle">
      <p>{{ description }}</p>
    </div>
    <div class="buttonm btn">
      <button @click="resetPassword()">
        {{ buttonVal }}
      </button>
    </div>
    <div class="link-text bottom_underline">
      <p @click="backToLogin()">Log in with a different User ID</p>
    </div>
  </div>
</template>
  <script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import translations from "@/common/assets/json/messages1.json";
export default {
  name: "NewResetPassword",
  data() {
    return {
      whichRealm: true,
      url: "",
      loaderUrl:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      mainTitle:
        translations.en.message["reset_security_account_locked_tittle"],
      description:
        translations.en.message["reset_security_account_locked_description"],
      buttonVal: translations.en.message["forgot_pd_reset_link"],
    };
  },
  components: {},
  mixins: [commonMixin],
  mounted() {
    this.setLoader(false);
    console.log("query..", this.$route, translations);
    if (this.$route.params && this.$route.params.lockType == "resetPassword") {
      this.mainTitle =
        translations.en.message["reset_security_account_locked_tittle"];
      this.description =
        translations.en.message["reset_security_account_locked_description"];
      this.buttonVal = translations.en.message["forgot_pd_reset_link"];
    } else if (
      this.$route?.params?.lockType == "unlockAccount" &&
      iamvar_loginAssistant
    ) {
      this.mainTitle =
        translations.en.message["reset_security_account_locked_sqa_tittle"];
      this.description = translations.en.message["account_locked_description"];
      this.buttonVal = translations.en.message["unlock_account_button"];
    } else if (
      this.$route.params &&
      this.$route.params.lockType == "resetSecretAnswer"
    ) {
      this.mainTitle =
        translations.en.message["reset_security_account_locked_sqa_tittle"];
      this.description =
        translations.en.message[
          "reset_security_account_locked_sqa_description"
        ];
      this.buttonVal = translations.en.message["reset_secret_answer"];
    }
    this.$store.dispatch("login/updateGotoUrl", this.getGotoUrl());

    var globalNav = document.createElement("script");
    globalNav.setAttribute(
      "src",
      "https://www.verizon.com/business/ngnav/smb.js"
    );
    globalNav.setAttribute("async", "async");
    document.head.appendChild(globalNav);

    var f = document.createElement("script");
    f.setAttribute(
      "src",
      iamvar_vbm_login_redirect +
        "?realm=vzw&service=WlnOneVerizonChain&fromVZT=Y"
    );
    f.setAttribute("async", "async");
    document.body.appendChild(f);

    if (this.urlOnMount != null || this.urlOnMount != undefined) {
      const hostUrl = this.urlOnMount;
      if (hostUrl.includes("/account/business/login/mobile")) {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      let iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }
  },
  methods: {
    ...mapActions("login", ["mybizzForget", "updateForgotUsername"]),
    ...mapActions("cvsstepup", ["updateFlowType", "initializeStepup"]),
    ...mapMutations("cvsstepup", ["setLoader"]),
    ...mapMutations("login", ["setLoginAssist"]),
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (
        match != null &&
        match.length > 2 &&
        typeof match[2] === "string" &&
        match[2].length > 0
      ) {
        return match[2];
      } else {
        return null;
      }
    },
    resetPassword() {
      if (
        this.$route.params &&
        this.$route.params.lockType == "resetSecretAnswer" &&
        iamvar_vcgvbgMigration
      ) {
        window.location.href = this.sanitizeUrl(
          iamvar_vbm_fp_redirect + "&fa=true"
        );
      } else {
        this.url = window.location.href;
        //console.log("url is:", this.url)
        const clientId = this.getParameterByNameURL("clientId", this.url);
        if (clientId) {
          let fiosURLFP = "";
          let gotoURLFP = this.getParameterByName("goto");
          if (gotoURLFP) {
            // Added goto url to fios url
            fiosURLFP = `${iamvar_fios_fp_url}?goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(
              gotoURLFP
            )}&clientId=vbm`;
          } else {
            fiosURLFP = iamvar_fios_fp_url + "?clientId=vbm";
          }
          window.location.href = fiosURLFP;
        } else {
          if (
            (this.getLineOfBusiness == 2 ||
              this.getLineOfBusiness == 3 ||
              this.getLineOfBusiness == 5) &&
            iamvar_vcgvbgMigration
          ) {
            //this.mybizzForget(false)
            window.location.href = this.sanitizeUrl(iamvar_vbm_fp_redirect);
          } else if (
            iamvar_loginAssistant &&
            this.$route.params.lockType == "unlockAccount" &&
            (this.getLineOfBusiness == 0 || this.getLineOfBusiness == 1)
          ) {
            this.setLoader(true);
            this.updateFlowType("login_assistant");
            this.setLoginAssist(true);
            this.initializeStepup();
          } else {
            this.$router.push({ path: "/login/recovery/forgotpassword" });
            // this.$router.push({ name: "PwdLessOtpDelivery" });
          }
        }
      }
    },
    backToLogin() {
      this.updateForgotUsername(this.getLockedUserName);
      // redirect user back to login page
      this.redirectBackToLogin(this.urlOnMount, this.gotoUrl);
    },
  },
  computed: {
    ...mapGetters("login", [
      "urlOnMount",
      "getLockedUserName",
      "gotoUrl",
      "getLineOfBusiness",
    ]),
    ...mapGetters("cvsstepup", ["currentStep", "submitLoader"]),
  },
  watch: {
    currentStep: function (newVal) {
      if (newVal === "FLOW_INITIALIZED") {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessOtpDelivery",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: "PwdLessOtpDelivery" });
        }
      }
    },
  },
};
</script>
  <style scoped>
.vbg-ff-content-container {
  box-sizing: border-box;
  min-width: 464px;
  max-width: 464px;
  border: 1px solid #979797;
  margin: 56px auto 366px;
  padding: 64px 40px;
  border-radius: 4px;
}
.tittle {
  width: 100%;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 12px;
}
.sub-tittle {
  width: 100%;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.buttonm {
  width: 99%;
  margin: 64px 0px 16px;
  font-size: 16px !important;
  height: 44px;
  background-color: #000;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
}
.btn:focus,
.btn:hover {
  color: #fff;
  text-decoration: none;
}
.link-text {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  cursor: pointer;
  width: 208px;
  /*text-decoration: underline;*/
}
.bottom_underline {
  text-decoration: none !important;
  border-bottom: 1px solid #000;
  line-height: 1;
  width: 208px;
}
@media (max-width: 1024px) {
  .vbg-ff-main-container {
    padding: 24px 16px 64px;
  }
  .vbg-ff-content-container {
    box-sizing: border-box;
    min-width: 240px;
    max-width: 328px;
    margin: auto;
    padding: 48px 24px;
    border: 1px solid #979797;
  }
  .tittle {
    width: 100%;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .sub-tittle {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
  .link-text {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    width: 150px !important;
  }
  .bottom_underline {
    width: 150px !important;
  }
  .buttonm {
    margin: 48px 0px 16px;
  }
}
#tsd-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
  
  