export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export const setCookie = (cookieName, value) => {
  const domainName = window.location.hostname;
  document.cookie = `${(cookieName)}=${(value)};path=/;domain=.${domainName}`;
};

export const setMaskedEmail = (inputEmail) => {
var inputEmailValue = inputEmail.toLowerCase();
var encodingMail = inputEmailValue.split("@");
var first = "";
for(var i = 0; i < encodingMail[0].length; i++){
  if(i != 0 && i != encodingMail[0].length - 1){
      first += '*';
  }else{
      first += encodingMail[0][i];
  }
}

var maskedEmail = first +"@"+ encodingMail[1];
return maskedEmail;
};