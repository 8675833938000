<template>
  <div class="combine-root-container">
    <div class="header-container">
      <Header></Header>
    </div>
    <div v-if="getCombinenowAlerts.length > 0">
      <div class="row" style="margin:unset;" v-for="j in getCombinenowAlerts" :key="j">
        <div
          class="col-sm-12 newMessagesInfo"
          v-bind:class="getTypeOfAlert(j)"
          style="padding:0px;"
        >
          <div class="newMessagesInfo" v-bind:class="getTypeOfAlert(j)">
            <div class="messageWrapper customMessageWrapper">
              <div class="messageIcon" style="padding: 0px; margin: 0px">
                <div class="messageImage" :class="messageImage(j)"></div>
              </div>
              <p
                class="messageContent col-xs-10 combinenow-message"
                style="padding:0px;margin:0px;"
                :style="j.type === 'combineNowsuccess' ? 'color: #ffffff': 'color: #000000'"
                v-html="j.msg"
              ></p>
              <div class="supportblock" v-if="getSupportCheck">
                <a :href="gotoSupportPage">
                  <button class="supportbutton" >
                    <span class="buttontext">Go to support page</span>
                  </button>
                </a>
              </div>
              <div class="closeMessage col-xs-1" style="padding:0px;margin:0px">
                <img
                  class="closeMessageImg"
                  :class="getCloseIconStyle()"
                  :src="getCloseIcon()"
                  @click="deleteAlert(j)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <combinenow-component v-if="combinenowValue == 'combinenow'"></combinenow-component>
    <combinenow-error-component v-if="combinenowValue == 'combinenowError'"></combinenow-error-component>
    <combine-now-success-component v-if="combinenowValue == 'combinenowSuccess'"></combine-now-success-component>
    <div class="footerCss">
      <Footer />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "@/vbgauth/components/CombinenowHeader";
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import Footer from "@/common/components/Footer";
import CombinenowComponent from "./CombinenowComponent.vue";
import CombinenowErrorComponent from "./CombinenowErrorComponent.vue";
import CombineNowSuccessComponent from "./CombineNowSuccessComponent.vue";
import ClearWhite from "@/common/assets/styles/corp/images/clear_white.png";
import CloseBlack from "@/common/assets/styles/corp/images/close_black.png";

export default {
  name: "CombinenowRootComponent",
  components: {
    Header,
    Footer,
    CombinenowComponent,
    CombinenowErrorComponent,
    CombineNowSuccessComponent
  },
  props: [],
  created() {},
  mounted() {
    console.log(
      "this.getters here getCombinenowAlerts ..",
      this.getCombinenowAlerts
    );
  },
  data() {
    return {
      gotoSupportPage : iamvar_GotoSupport,
    };
  },
  methods: {
    deleteAlert(alert) {
      this.getCombinenowAlerts.splice(
        this.getCombinenowAlerts.indexOf(alert),
        1
      );
    },
    getCloseIconStyle() {
      const whiteIconStyle = 'whiteClsicon'
      const blackIconStyle = (this.getSupportCheck) ? 'errorbancloseicon' : 'closeicon'
      return this.combinenowValue == "combinenowSuccess" ? whiteIconStyle : blackIconStyle;
    },
    getCloseIcon() {
      // return  (
      //   iamvar_cdnUrl +
      //   iamvar_imgSourceContext +
      //   "/business/login/includes/img/close.7e170800.svg"
      // )
      return this.combinenowValue == "combinenowSuccess"
        ? ClearWhite
        : CloseBlack;
    },
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png'
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      return index.type + "MessageImage";
    },
    getTypeOfAlert(index) {
      return index.type;
    }
  },
  computed: {
    ...mapGetters("cvsstepup", ["submitLoader"]),
    ...mapGetters("login", ["getCombinenowAlerts", "combinenowValue","getCombinenowErrorCode"]),

      getSupportCheck(){
        return ((this.getCombinenowErrorCode == "ACCOUNT_NEWLY_LOGIN_LOCKED" || this.getCombinenowErrorCode == "ACCOUNT_LOGIN_LOCKED" || this.getCombinenowErrorCode == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") && (this.combinenowValue != "combinenowError")) ? true : false
      }
  },
  watch: {
      
  }
};
</script>
<style>
.whiteClsicon{
  cursor: pointer; 
  margin-top: -14px;
}
.closeicon,
.errorbancloseicon{
  cursor: pointer;
  margin-top: -10px;
  width: 13px; 
  height: 13px;
}
.combine-root-container {
  font-family: "VzNHGeDS-reg11";
}
.combinenow-heading {
  font-family: "VzNHGeDS-bold11";
  color: #000000;
}
.supportblock{
  Width:239px;
  Height:28px;
  position: absolute;
  margin-left: 620px;
}
.supportbutton{
  Width:157px;
  Height:28px;
  border-radius:14px;
  border: solid #000000;
}
.buttontext{
  height: 16px;
  width: 56px;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  top:-1px;
  line-height: 16px;
  text-align: center;
}
h1, h2, h3, h4, h5, h6 {
  line-height: normal !important;
}

@media(max-width: 320px) {
  .errorbancloseicon{
    margin-top: -205px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 426px) {
  .errorbancloseicon{
    margin-top: -125px;
  }
}
@media only screen and (min-width: 426px) and (max-width: 1023px) {
  .errorbancloseicon{
    margin-top: -90px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1023px) {
  .supportblock {
      position: relative;
      margin-left: 30px;
      margin-top: 15px;
      float: left;
    }
}
@media (min-width: 600px) {
  .customMessageWrapper {
    margin-left: 80px;
    margin-right: 80px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 540px) {
  .customMessageWrapper {
    margin-left: -5px;
  }
  .combinenow-heading {
    height: 48px;
    /*width: 288px;*/
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    /*line-height: 24px;*/
  }
  .combinenow-h3 {
    font-size: 20px;
    /*line-height: 20px;*/
  }
  .combinenow-p {
    height: 80px;
    /*width: 288px;*/
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
}
</style>
<style scoped>
.footerCss div {
  width: 100% !important;
}
@media (min-width: 600px) {
  /* .footerCss {
    position: relative;
    top: 1000px;
  } */
}
@media (max-width: 600px) {
  .combinenow-message b {
    font-family: "VzNHGeDS-bold11";
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
  .footerCss div {
    width: 100% !important;
    margin-top: 60px !important;
  }
  .closeMessageImg {
    position: static;
  }
}
</style>
