<template>
    <v-row>
        <p class="px-0">{{ $t("message.login_otp_page_directions") }}
            <span>
                <button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;" v-tooltip="content3"></button>
            </span>
        </p>
        <v-form>
            <v-row class="padleft15 martop10">
              <label class="labeltiny">{{ $t("message.login_otp_device_dropdown_label") }}</label>
            </v-row>
            <div class="form-group padleft15 col-xs-4">
              <div class="row dropdown dropdown-toggle" v-bind:class="{ open: showDropdown }" aria-haspopup="true" aria-expanded="false" @click="toggleDropdown()">
                <div class="col-xs-9" style="padding-right:0px">
                  <a id="dropdownMenu1" aria-haspopup="true" role="button" >
                    <span>
                      <span>{{ selectedOtpDevice.otpType }}</span>
                    </span>
                    <span class="padleft10"> {{ selectedOtpDevice.displayString }}</span>
                    </a>
                </div>
                <a class="col-xs-1 pull-right dropdown-cheveron" style="padding:0px;"></a>
                <ul class="dropdown-menu col-xs-12 px-0" aria-labelledby="dropdownMenu1">
                  <li v-for="device in devices" :key="device.id" role="presentation" v-bind:class="{ chosen: (device.guid === selectedOtpDevice.guid)}">
                    <a role="menuitem" @click="deviceOptionSelected(device)">
                      <span>
                        <span>{{ device.otpType }}</span>
                      </span>
                      <span class="padleft10"></span> {{device.displayString}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <v-row class="padleft15 col-xs-12 padding-top-large padding-bottom-large">
                <button type="button" class="btn btn_primary_black" :disabled="otpSent || selectedOtpDevice.guid == null" @click="sendOTP()">{{$t("message.login_otp_page_send_passcode")}}</button>
            </v-row>
            <SwitchToSQAComponent></SwitchToSQAComponent>
        </v-form>
    </v-row>
</template>
<script>
import translations from '@/common/assets/json/messages1.json'
import { mapGetters, mapActions } from 'vuex'
import SwitchToSQAComponent from '@/vbgauth/components/core/SwitchToSQAComponent'
export default {
  name: 'AuthOtpDeliveryComponent',
  components: {
    SwitchToSQAComponent
  },
  data () {
    return {
      showDropdown: false,
      showResendOtpLink: false,
      content3: translations.en.message['login_otp_tooltip']
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    deviceOptionSelected(selDevice) {
      this.updateSelectedDevice(selDevice)
    },
    sendOTP() {
        // if valid
        let self = this
        this.sendOtpToDevice(this.selectedOtpDevice.guid)
        self.showResendOtpLink = true
    },
    deviceOptionsNotAvailable() {
      this.noAccessToOTP = true
    },
    ...mapActions('authstepup', [
        'sendOtpToDevice',
        'updateSelectedDevice',
        'redirectToOpenam'
    ])
  },
  computed: {
      ...mapGetters('authstepup', [
      'devices',
      'sqaEnabled'
    ]),
    otpSent: {
      get: function () {
        return this.$store.getters['authstepup/otpSent']
      },
      set: function (newVal) {
        this.$store.dispatch('authstepup/updateOtpSent', newVal)
      }
    },
    noAccessToOTP: {
      get: function() {
        return this.$store.getters['authstepup/noAccessToOTP']
      },
      set: function(newVal) {
        this.$store.dispatch('authstepup/updateNoAccessToOTP', newVal)
      }
    },
    selectedOtpDevice: {
      get: function () {
        return this.$store.getters['authstepup/selectedOtpDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('authstepup/updateSelectedDevice', newVal)
      }
    }
  },
  watch: {
    otpSent: function(newVal) {
      if(newVal) {
        // this.$router.push({ path: 'send' })
      }
    },
    devices: function(newVal) {
      console.log('Devices updated')
    }
  }
}
</script>
<style scoped>
h1 {
    margin: 4.6rem 0 2.50rem !important;
    font-size: 32px !important;
    font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", "Arial", "Helvetica", "sans-serif !important"
}
.v-row {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}
.alert-warning {
	background-color: #ffbc3d;
	background-size: 35px 29px;
	color: #000;
	border-color: #ffbc3d;
}
.alert-info {
	background-color: #08c;
	background-size: 35px 29px;
	color: #fff;
	border-color: #08c;
}
h2 {
  font-size: 20px;
  margin: 30px 0 20px;
}

h1, h2, h3, h4 {
  font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 100%;
}
.close {
    float: right !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
    opacity: .2 !important;
    margin-top: -2px !important;
}
.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.vz-input-field:focus {
  border: 1px solid black !important;
}
</style>
