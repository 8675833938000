<template>
  	<v-row class="container">
        <v-row>
          	<v-row class="col-xs-12">
            	<h1>Security Profile Registration</h1>
          	</v-row>
          	<v-row class="col-xs-12">
				<label class="has-error">*</label><p class="labeltiny">Indicates required field.</p>
          	</v-row>
        </v-row>
		<v-row class="col-xs-12" v-if="!formValid">
			<v-row class="alert alert-danger">
				<div><span><h6>Please correct the highlighted fields</h6></span></div>
			</v-row>
		</v-row>
	    <v-row class="col-xs-12">
		  	<h3 class="text-left col-xs-12">Complete all Secret Questions/Answers</h3>
		  	<h6 class="text-left col-xs-12 marbottom20">
				Answers are not case sensitive, must be from 3 – 40 characters, and can only consist of letters (A-Z), numbers (0-9), spaces, and periods.<br>
				<b>Note: </b>Please complete all Secret Questions/Answers and click Continue prior to closing this page to avoid Sign-In issues going forward
		  	</h6><br>
		</v-row>     
		<v-row class="col-xs-12">
			<v-form class="col-xs-12" autocomplete="off">
					<v-row class="col-xs-12" v-if="secretQuestions !== null" v-for="(set, index) in secretQuestions" :key=index>
						<v-row class="col-xs-12 col-sm-10">
							<label><h4 class="col-xs-12"><span class="has-error">*</span> Select Secret Question {{index+1}}:</h4></label>   
						</v-row>
						<v-row class="col-sm-2"></v-row>
						<v-row class="col-xs-12 col-sm-10">
							<select class="form-control" v-bind:class="dynamicErrorClass[index].question" :id="index+1" aria-label="index+1" v-model="selectedQuestions[index]" required>
								<option value="" selected="selected">-- choose a new question --</option>
								<option v-for="(q, j) in set" :key=j :value="q">{{q.question}}</option>
							</select>
						</v-row>
						<v-row class="col-sm-2"></v-row>
						<v-row class="col-xs-12 col-sm-10"> 
							<label>
								<h4 class="col-xs-12"><span class="has-error">*</span> Your Answer</h4>
							</label>
						</v-row>
						<v-row class="col-sm-2"></v-row>
						<v-row class="col-xs-12 col-sm-10">
				      		<input name="answer" type="text" autocomplete="off" v-model="selectedQuestions[index].answer" class="form-control" v-bind:class="dynamicErrorClass[index].answer" required>
				   		</v-row>					
					</v-row>
					<br>
					<v-row class="col-xs-12">
						<v-row class="col-xs-12 col-sm-10"></v-row>
						<v-row class="col-sm-2">
							<button type="button" @click="submitSQA" class="btn btn-primary btn-sm">Continue</button>
						</v-row>
                    </v-row>
				
		    </v-form>
		</v-row>
  	</v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
// import '@/common/assets/styles/bootstrap-theme-idm_2018.css'
import 'bootstrap/dist/css/bootstrap.min.css'
export default {
  name: 'AMSqaRegComponent',
  components: {
  },
  data () {
    return {
		formValid: true
    }
  },
  mounted () {
  },
  methods: {
	...mapActions('sqareg', [
		'submitSecretQAnswers'
      ]
    ),
	submitSQA () {
		if(this.validated()) {
			this.submitSecretQAnswers(this.selectedQuestions)
		}
	},
	validated () {
		this.formValid = true
		for(var i = 0; i < this.selectedQuestions.length; i++) {
			if(this.selectedQuestions[i]=="") {
				this.dynamicErrorClass[i].question = 'invalid'
				this.dynamicErrorClass[i].answer = 'invalid'
				this.formValid = false
			} else {
				if (this.selectedQuestions[i].answer == "" || this.selectedQuestions[i].answer == null) {
					this.formValid = false
					this.dynamicErrorClass[i].answer = 'invalid'
				} else {
					this.dynamicErrorClass[i].answer = ''
				} 
				if (this.selectedQuestions[i].question == "" || this.selectedQuestions[i].question == null) {
					this.formValid = false
					this.dynamicErrorClass[i].question = 'invalid'
				} else {
					this.dynamicErrorClass[i].question = ''
				}
			}
		}
		return this.formValid
	} 
  },
  computed: {
	  ...mapGetters('sqareg', [
      'secretQuestions',
	  'dynamicErrorClass'
    ]),
	selectedQuestions: {
      get: function () {
        return this.$store.getters['sqareg/selectedQuestions']
      },
      set: function (newVal) {
      }
    }
  },
  watch: {
	secretQuestions: function(newVal) {
      if(newVal) {
      }
    },
	selectedQuestions: function(newVal) {
      if(newVal) {
      }
    }
  }
}
</script>

<style scoped>
h4 {
    font-size: 16px !important;
    margin: 20px 0 10px !important;
}
h1, h2, h3, h4 {
    font-family: "NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", Arial, Helvetica, sans-serif !important;
    font-weight: normal !important;
    line-height: 100% !important;
}
.col-xs-12, .col-sm-10, .col-sm-2 {
	padding-top: 0px !important;
}
.form-control:focus, input:focus, select:focus, textarea:focus {
	border: 1px solid black !important;
}
.btn-sm, .btn-group-sm>.btn {
	font-size: 14px !important;
    height: 36px !important;
    padding: 0px 16px !important;
    min-width: 95px !important;
}
.btn {
	border: medium none !important;
    border-radius: 0 !important;
    cursor: pointer !important;
    display: inline-block !important;
    font-family: NeueHaasGroteskDisplayBold, Arial !important;
    font-weight: 400 !important;
    line-height: 1.42857 !important;
    margin-bottom: 0 !important;
    text-align: center !important;
    vertical-align: middle !important;
    white-space: nowrap !important;
}
.invalid {
	border: 1px solid red !important;
}

.alert {
	padding: 20px;
    color: #4b4b4b;
    position: relative;
    border-radius: 0px;
    font-weight: 700;
}
.alert-danger {
	background: #ffcfd2 url(~@/common/assets/styles/corp/images/vz_message_error.png) 10px 22px no-repeat;
    padding-left: 50px !important;
    color: #5b2437;
	border-color: #ebccd1;
}
</style>
