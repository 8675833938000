<template>
    <div>
      <BlockUI v-if="loading" :url="loaderGIF"></BlockUI>
      <form @submit.prevent="formSubmitHandler()">
        <div>
          <div>
            <div for="firstname" class="new_pwdless_input_label">First name</div>
            <input
              class="new_pwdless_input_field"
              type="text"
              id="firstname"
              placeholder="First name"
              @input="firstnameHandler()"
              v-model="firstName"
              name="firstname"
            />
          </div>
          <div>
            <div for="lastname" class="new_pwdless_input_label">Last name</div>
            <input
              class="new_pwdless_input_field"
              type="text"
              id="lastname"
              placeholder="Last name"
              @input="lastnameHandler()"
              v-model="lastName"
              name="lastname"
            />
          </div>
        </div>
        <NewAMForgotUserIDAcc
          v-if="userIdFlow === 'COMPLEX'"
          @changeAccountNumber="changeAccountNumber($event)"
          @changeMtn="changeMtn($event)"
          :accountIsValid="accountIsValid"
          :mtnIsValid="mtnIsValid"
        ></NewAMForgotUserIDAcc>
        <div class="pwd_less_btn_container">
          <button
            class="vbg-button mt-8"
            id="username-continue-btn"
            name="username-continue-btn"
            :class="isDisabled() || loading ? 'disabled' : ''"
            :disabled="isDisabled() || loading"
            type="submit"
          >
            Continue
          </button>
          <!-- <button
            id="username-cancel-btn"
            name="username-cancel-btn"
            class="vbg-button vbg-button-cancel float-right"
            @click="cancelUsrRecovery()"
            type="button"
          >Cancel</button> -->
        </div>
      </form>
    </div>
  </template>
  <script>
  import NewAMForgotUserIDAcc from "./NewAMForgotUserIDAcc.vue";
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import { eventTracking } from "../../../../../public/adobeTagging";
  import commonMixin from "@/common/mixin/commonMixins";
  
  export default {
    name: "NewAMForgotUserIDInfo",
    data() {
      return {
        firstName: "",
        lastName: "",
        mtn: "",
        account: "",
        loading: false,
        accountIsValid: false,
        mtnIsValid: false,
        loaderGIF: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
        //userIdFlow: 'COMPLEX'
      };
    },
    mixins: [commonMixin],
    components: { NewAMForgotUserIDAcc },
    mounted() {
      this.changeContextBiocatch('forgot_username_userinfo_page')
      if (window.location.href.includes("business")) {
        this.readConfig("vbg_config");
      } else if (window.location.href.includes("consumer")) {
        this.readConfig("vcg_config");
      }
      if (this.urlOnMount != null || this.urlOnMount != undefined) {
        const hostUrl = this.urlOnMount;
        if (hostUrl.includes("/account/business/login/mobile")) {
          this.whichRealm = false;
        }
      }
      if (iamvar_realm == "MOBILE") {
        let returnUrl = this.urlOnMount;
        this.updateUrlOnMount(returnUrl);
      }
      if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
        let iamvar_appCookie = getCookie("X-VZ-C_APP");
        if (iamvar_appCookie == "MVB") {
          this.whichRealm = false;
        }
      }
    },
  
    methods: {
      ...mapActions("cvsstepup", [
        "updateUserDetailsForStepup",
        "initializeStepup",
        "updateFlowType",
      ]),
      ...mapActions("forgotflow", ["readConfig"]),
      ...mapActions("login", ["updateUrlOnMount"]),
    ...mapMutations("login", ["setCommonErrors"]),
      isDisabled() {
        if (this.userIdFlow === "SIMPLE" && this.firstName && this.lastName) {
          return false;
        } else if (this.userIdFlow === "COMPLEX") {
          if (this.firstName && this.lastName) {
            // mobile number format is 999-999-9999 hence checking 12 digits
            // in api mtn will be 9999999999 without '-'
            if (
              (this.mtn && this.mtn.length === 12) ||
              (this.account &&
                this.account.length >= 5 &&
                this.account.length <= 19)
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      changeAccountNumber(account) {
        this.account = account ? account : "";
      },
      changeMtn(mtn) {
        this.mtn = mtn ? mtn : "";
      },
      async formSubmitHandler() {
        this.setCommonErrors();
        this.loading = true;
        const payload = {
          firstname: this.firstName.trim(),
          lastname: this.lastName.trim(),
        };
        if (this.userIdFlow === "COMPLEX") {
          if (this.account) {
            payload.account = this.account;
          }
          if (this.mtn) {
            payload.mtn = this.mtn.replace("-", "");
          }
        }
        this.updateUserDetailsForStepup(payload);
        this.updateFlowType("forgot_username");
        eventTracking("Username Recovery Request User Details Submitted", "");
        try {
          await this.initializeStepup();
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      cancelUsrRecovery() {
        this.$store.dispatch("cvsstepup/resetState");
        this.$store.dispatch("forgotflow/resetState");
        var returnUrl = this.getHostName(window.location.href);
  
        if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
          let iamvar_appCookie = getCookie("X-VZ-C_APP");
          if (iamvar_appCookie == "MVB") {
            window.location.href = iamvar_mvbLaunchUrl;
          }
        } else if (!this.urlOnMount) {
          window.location.href =
          this.sanitizeUrl("https://" + returnUrl + "/account/business/login/unifiedlogin");
        } else {
          window.location.href = this.urlOnMount;
        }
  
        // checking if user agent is vema then redirect user back to mobile login page
        if(this.userAgent && this.userAgent.toLowerCase() === 'vema') {
          window.location.href = iamvar_vemaLaunchUrl;
        }
      },
      getHostName(url) {
        var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
        if (
          match != null &&
          match.length > 2 &&
          typeof match[2] === "string" &&
          match[2].length > 0
        ) {
          return match[2];
        } else {
          return null;
        }
      },
      getOAuthClass(className = "oauth-flow") {
        return className;
      },
      firstnameHandler() {
        //let x = this.firstName.replace(/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/gi, "");
        //this.firstName = x.trim();
      },
      lastnameHandler() {
        //let x = this.lastName.replace(/^[a-zA-Z\s'._-]/gi, "");
        //this.lastName = x.trim();
      }
    },
    computed: {
      ...mapGetters("cvsstepup", [
        "userIdFlow",
        "currentStep",
        "cannotRecoverUsername",
        "urlOnMount",
        "fuAlerts",
      ]),
      ...mapGetters("login", [ "userAgent" ]),
    },
    watch: {
      mtn: function () {
        if (this.mtn && this.mtn.length === 12) {
          this.mtnIsValid = true;
        } else {
          this.mtnIsValid = false;
        }
      },
      account: function () {
        if (
          this.account &&
          this.account.length >= 5 &&
          this.account.length <= 19
        ) {
          this.accountIsValid = true;
        } else {
          this.accountIsValid = false;
        }
      },
      currentStep: function (newVal) {
        if (newVal === "FLOW_INITIALIZED" && !this.cannotRecoverUsername) {
          this.$router.push({ name: "FFStepupRootComponent" });
        }
      },
      fuAlerts: function (newVal) {
        if (newVal) {
          window.scrollTo(0, 0);
        }
      },
    },
  };
  </script>
  <style scoped>
  .vbg-container {
    font-family: VzNHGeDS-reg11;
    padding-top: 40px;
    /*padding-left: 57px;*/
    max-width: 642px;
  }
  .vbg-heading-container {
    margin-bottom: 10px;
  }
  .vbg-heading {
    max-height: 32px;
    max-width: 206px;
    color: #000000;
    font-family: VzNHGeDS-bold11;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 32px;
  }
  .vbg-paragraph-container {
    margin-bottom: 30px;
  }
  .vbg-paragraph {
    max-height: 16px;
    max-width: 218px;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
  }
  .vbg-form-group {
    display: flex;
  }
  .vbg-input-group {
    display: flex;
    flex-direction: column;
  }
  .vbg-label {
    max-height: 64px;
    font-size: 12px;
    color: #000000;
  }
  .vbg-input:focus { border: 1px solid #000000; }
  .vbg-input {
    box-sizing: border-box;
    height: 44px;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000000;
    background-color: #ffffff;
    padding: 12px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
  .vbg-button {
    height: 44px;
    width: 140px;
    border-radius: 22px;
    background-color: #000000;
    color: #ffffff;
    font-family: VzNHGeDS-bold11;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
    padding: 12px 34.5px 12px 34.5px;
    margin-right: 12px;
  }
  .vbg-button:focus {
    outline: 1px dashed #000;
    outline-offset: 2px;
  }
  .vbg-button-cancel {
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #000000;
    margin-right: 0px !important;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .mr-40 {
    margin-right: 40px;
  }
  .mt-60 {
    margin-top: 60px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .width-301 {
    width: 301px;
  }
  .disabled {
    background-color: #d8dada;
  }
  .vbg-button {
    width: 118px;
    padding: 0px;
    margin-right: 0px;
  }
  @media (max-width: 425px) {
    
  }
  @media (max-width: 1024px) {
    .vbg-form-group {
      flex-direction: column;
    }
    .vbg-container {
      width: 100%;
      max-width: 100%;
    }
    .vbg-input-group {
      margin-right: 0;
      margin-bottom: 32px;
      width: 100% !important;
    }
    .vbg-input-group:last-child {
      margin-bottom: 0px !important;
    }
    .vbg-button-container {
      margin-top: 48px !important;
      display: flex;
      justify-content: space-between;
    }
    .vbg-button {
      width: 48%;
    }
  }
  @media (max-width: 768px) {
    .vbg-button {
      width: 100% !important;
    }
    .vbg-button-container {
      flex-direction: column-reverse;
    }
    .vbg-button-cancel {
      margin-bottom: 12px;
    }
  }
  @media (min-width: 1025px) {
    .vbg-input {
      width: 301px !important;
    }
    .vbg-button-cancel {
      width: 140px !important;
    }
  }
  </style>
  