<template>
  <div>
    <Header style="position: relative" />
    <div class="grid-container">
      <div class="column left">
        <form @submit.prevent="submitForm" class="form">
          <label class="form-label">User ID</label>
          <br />
          <input
            type="text"
            class="input-f input_mr input_border"
            v-model="userID"
          />
          <br />
          <!-- <div>Error message</div> -->
          <button
            type="submit"
            class="btn primary_black continue_btn"
            :disabled="!userID.length"
          >
            Continue
          </button>
        </form>
      </div>
      <div class="column right">
        <BlockUI v-if="submitLoader" :url="url"></BlockUI>
        <pre
          v-else-if="responseData"
          :style="{ 'border-color': status ? 'green' : 'red', width: '100%' }"
        >
            <code>
{{ responseData }}
            </code>
        </pre>
        <div
          class="copy-icon"
          :class="!copied ? 'icon-color' : ''"
          @click="copyToClipboard"
          v-if="status && responseData"
        >
          <span v-if="copied">Copied Successfully </span>
          <span v-else>Copy</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/common/components/Header.vue";
export default {
  name: "LdapTool",
  components: { Header },
  data() {
    return {
      userID: "",
      submitLoader: false,
      status: true,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      copied: false,
      responseData: null,
    };
  },
  methods: {
    async submitForm() {
      console.log("test");
      this.submitLoader = true;
      try {
        let endUrl = `${b2bServicesGetUserDetails}am-services/adaptive/user/details/byUid/${this.userID}`;
        let headers = {
          Authorization: "Basic dmVjOnZlYw==",
          "Content-Type": "application/json",
        };
        const response = await fetch(endUrl, { headers });
        console.log("response", response);
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`);
        }

        const json = await response.json();
        console.log(json);
        this.status = true;
        this.responseData = { ...json };
        this.submitLoader = false;
      } catch (error) {
        console.error(error.message);
        this.responseData = error;
        this.submitLoader = this.status = false;
      }
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(JSON.stringify(this.responseData))
        .then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
          // alert("Json copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy json: ", err);
        });
    },
  },
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.column {
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left {
  /* background-color: #ddd; */
  height: calc(100vh - 150px);
}
.right {
  background-color: #f1f1f1;
  position: relative;
}
.form {
  width: 100%;
  border: 1px solid #d8dada;
  padding: 30px;
}
.btn {
  width: 100%;
  margin-top: 10px;
}
.copy-icon {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
.copy-icon:hover {
  background-color: #45a049;
}
.icon-color {
  background-color: #000;
}
.icon-color:hover {
  background-color: #292828;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones & tabs */
  .grid-container {
    grid-template-columns: 1fr !important;
  }
  .column {
    padding: 20px;
  }
  .left {
    height: auto;
  }
  .copy-icon {
    top: 30px;
    right: 30px;
  }
}
</style>