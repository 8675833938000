<template>
  <div class="new_pwdless_form_container">
    <div class="new_pwdless_box_container">
      <div class="new_pwdless_box">
        <div class="new_pwdless_content">
          <BlockUI
            v-if="submitLoader || getTransactionLoader"
            :url="url"
          ></BlockUI>
          <ErrorMessage
            :message_details="getCommonErrors[0]"
            v-if="getCommonErrors.length"
          />
          <h1 class="new_pwdless_main_heading pwdles_main_heading">
            {{ $t("message.new_pwdless_login_heading") }}
          </h1>
          <div class="new_pwdless_sub_heading pwdles_sub_heading">
            {{ $t("message.new_pwdless_login_sub_heading") }}
          </div>
          <form id="loginBiz" @submit.prevent="submitLoginForm">
            <div class="new_pwdless_input_label">
              {{ $t("message.new_pwdless_input_label") }}
            </div>
            <div>
              <input
                id="pwdUserID"
                name="pwd_userid"
                class="new_pwdless_input_field"
                autofocus
                type="text"
                required
                v-model="formData[0].input[0].value"
                maxlength="60"
              />
              <div
                class="pwdLoginTextError error_spacing"
                v-if="this.pwdInlineError != ''"
                id="inlineError"
              >
                <p class="inline_err">{{ this.pwdInlineError }}</p>
              </div>
            </div>
            <div class="new_pwdless_remember_me">
              <div class="new_pwdless_checkbox_content">
                <input
                  name="remember_me"
                  class="new_pwdless_checkbox dark_bg_checkbox"
                  type="checkbox"
                  id="ch"
                  v-model="rememberMe"
                />
                <span for="ch" class="new_pwdless_remember_me_text">{{
                  $t("message.new_pwdless_remember_me_text")
                }}</span>
              </div>
              <div
                class="new_pwdless_forgot_text pwd-forgot-link"
                id="forgot_userid_link"
                @click="forgotPopup('forgotUserId')"
                name="forgot_userid_link"
              >
                <a class="new_pwdless_forgot_text_link" tabindex="0">
                  {{ $t("message.new_pwdless_forgot_text_link") }}
                </a>
              </div>
            </div>
            <div class="new_pwdless_btn_section">
              <button
                class="new_pwdless_submit_btn"
                type="submit"
                id="submitUserID"
                name="submitUserID"
                :class="continueDisabled ? 'disabled' : ''"
                :disabled="continueDisabled"
                tabindex="0"
              >
                {{ $t("message.new_pwdless_submit_btn") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="new_pwdless_suggestions_section">
      <span class="new_pwdless_need_access_link">
        <label>{{ $t("message.new_pwdless_need_access_link") }}</label></span
      >
      <span class="new_pwdless_need_reg_link">
        <a :href="supportUrl" tabindex="0"
          >{{ $t("message.new_pwdless_need_reg_link") }}
          <img
            src="@/common/assets/images/RightCaretIcon.png"
            alt="right_caret_icon"
          />
        </a>
      </span>
    </div>
    <div class="">
      <div class="new_pwdless_forgot_text new_pwdless_bottom_link">
        <a :href="resendWelcomeEmail" class="new_pwdless_forgot_text_link_bottom" tabindex="0">{{
          $t("message.new_pwdless_forgot_text_resend")
        }}</a>
      </div>
      <div class="new_pwdless_forgot_text new_pwdless_bottom_link">
        <a 
          @click="forgotPopup('payWithoutLogin')"
          class="new_pwdless_forgot_text_link_bottom" tabindex="0"
          >{{ $t("message.new_pwdless_forgot_text_pay") }}</a
        >
      </div>
      <div class="new_pwdless_forgot_text new_pwdless_bottom_link">
        <a :href="quick_login" class="new_pwdless_forgot_text_link_bottom" tabindex="0">{{
          $t("message.new_pwdless_forgot_text_task")
        }}</a>
      </div>
      <div class="new_pwdless_forgot_text new_pwdless_bottom_link">
        <a :href="Mgmt_portal_login" class="new_pwdless_forgot_text_link_bottom" tabindex="0">{{
          $t("message.new_pwdless_forgot_text_manage")
        }}</a>
      </div>
      <div class="new_pwdless_forgot_text new_pwdless_bottom_link">
        <a :href="vcg_personal_login" class="new_pwdless_forgot_text_link_bottom" tabindex="0">{{
          $t("message.new_pwdless_forgot_text_account")
        }}</a>
      </div>
    </div>
    <div>
      <div id="noAccessToOtpModal" class="modal fade">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" :class="checkModal" :style="gethelp">
            <div class="close-icon-container">
              <a data-dismiss="modal" @click="closeCombinenow()">
                <img
                  src="@/common/assets/styles/corp/images/close_black.png"
                  style="height: 20px; width: 20px"
                />
              </a>
            </div>
            <ConfirmSecurityDetailsOrDoLaterPopUp v-if="displayPopupClass" />
            <pop-up-component v-else></pop-up-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopUpComponent from "@/vbgauth/components/hybridExp/popups/PopUpComponent";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
import $ from "jquery";
import translations from "@/common/assets/json/messages1.json";
import ConfirmSecurityDetailsOrDoLaterPopUp from "@/vbgauth/components/hybridExp/popups/ConfirmSecurityDetailsOrDoLaterPopUp";
import { iamvar_vcgMultipleAccount } from "../../../../../public/adobeTagging";
import ErrorMessage from "@/vbgauth/components/VbgLoginNew/common/ErrorMessage.vue";
import CommonErrorMixin from "../common/common-error-mixin";
export default {
  name: "PwdLessLogin",

  components: {
    PopUpComponent,
    ConfirmSecurityDetailsOrDoLaterPopUp,
    ErrorMessage,
  },

  mixins: [commonMixin, CommonErrorMixin],

  data() {
    return {
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      userId: "",
      userPassword: "",
      rememberMe: false,
      canSubmit: false,
      captchaOn: true,
      SignUpUrl: "",
      vec2Registration: vec2_ent_portal_base_url,
      vcg_personal_login: iamvar_vcg_login,
      wireline_login: iamvar_fios_login,
      quick_login: iamvar_Quick_links,
      Mgmt_portal_login: iamvar_Mgmt_Portals,
      resendWelcomeEmail: iamvar_resendWelcomeEmail,
      show1: false,
      displayPopup: false,
      downlinkSpeed: null,
      isSlowConnection: false,
    };
  },

  async created() {
    // setPageDetails('Login:landing');
    this.getLoginCaptchaEnabledEnvVar();
    this.checkForCaptchaOffConditions();
  },

  mounted() {
    this.updateFlowType('auth')
    if (localStorage.getItem("clientId")) {
      localStorage.removeItem("clientId");
    }
    // this.$store.dispatch('login/updateUnifiedLoginAlerts', [] , {root:true})
    window.onpopstate = (event) => {
      //  this.$store.dispatch('login/updateUnifiedLoginAlerts', [] , {root:true})
      // this.formData[0].input[0].value = "";
      //browser back button disabled
      window.history.forward();
    };
    if (
      iamvar_pwdLessFlowType == "basic" &&
      this.getPasswordSuccess != "success"
    ) {
      this.$store.dispatch("login/updateUnifiedLoginAlerts", [], {
        root: true,
      });
    }
    // get gotourl from mixins
    this.$store.dispatch("login/updateGotoUrl", this.getGotoUrl());
    this.$store.dispatch("login/updateEncodedGotoUrl", this.getGotoUrl());
    this.removeExistingVBGCCookie();
    this.getSessionInfo();
    this.updateUrlOnMount(window.location.href);
    this.resetState();

    // call rememberMe function only if uid query parameter is not present
    // let params = new URLSearchParams(window.location.search);
    if (!this.getParameterByName("uid")) {
      this.callRememberMe();
    }
    this.processQueryParameters();
    if (
      this.getParameter("appGroup") === "TSD" &&
      this.getParameter("client_id") === "mdp_agent"
    ) {
      this.SignUpUrl = iamvar_mdpSignUpUrl;
    } else if (
      this.getParameter("appGroup") === "TSD" &&
      this.getParameter("client_id") === "tsd_agent"
    ) {
      this.SignUpUrl = iamvar_thingSpaceSignUpUrl;
    } else if (
      this.getParameter("appGroup") === "TSM" ||
      this.getParameter("client_id") === "tss_agent_gz"
    ) {
      this.SignUpUrl = iamvar_ccSignUpUrl;
    } else {
      this.SignUpUrl = iamvar_thingSpaceSignUpUrl;
    }
    if (
      this.getParameterByName("success") == "true" ||
      this.getParameterByName("success") == true
    ) {
      var alertMsg = translations.en.message["forgot_username_display"];
      this.setAlertTypeUpdate("success");
      var msg = [
        {
          msg: alertMsg.replace("$0", this.getParameterByName("uid")),
          type: "success",
        },
      ];
      this.updateUnifiedLoginAlerts(msg);
    }
    window.localStorage.setItem("InitialRoute", window.location.href);
    setTimeout(() => {
      this.csidBiocatch();
      this.changeContextBiocatch("pwdless_login_user_id_page");
    }, 1000);
    this.updateDownLinkSpeed();
    if('connection' in navigator) {
      navigator.connection.addEventListener('change', this.updateDownLinkSpeed)
    }
  },

  methods: {
    ...mapMutations("login", ["setNavHistory", "setRedirectToStepupPage"]),
    ...mapActions("login", [
      "submitPwdAMLoginForm",
      "callRememberMe",
      "removeExistingVBGCCookie",
      "getSessionInfo",
      "updateUrlOnMount",
      "resetState",
      "updateCaptchaToken",
      "updateFederalPortalIndicator",
      "UpdatePopupValue",
      "smbFormSubmit",
      "selectAccount",
      "sendCombinenowValue",
      "updateMergeStatus",
      "updateUnifiedLoginAlerts",
      "setAlertTypeUpdate",
    ]),
    ...mapActions("cvsstepup", [
      "initializeStepup",
      "updateUserIdFlow",
      "updateUsernameForStepup",
      "updateFlowType",
    ]),

    submitLoginForm: function (recaptchaToken) {
      this.clearAllErrors();
      let payload = {
        formData: this.formData,
        rememberUser: this.rememberMe,
      };

      // update state with new captcha token
      if (this.captchaOn) this.updateCaptchaToken(recaptchaToken);
      this.$store.dispatch("login/updateUnifiedLoginAlerts", [], {
        root: true,
      });
      this.submitPwdAMLoginForm(payload);
      this.updateUsernameForStepup(this.formData[0].input[0].value.trim());

      // might not be needed since submit button is disabled when form is empty
      /*if(this.$refs.loginForm.validate()) {
          this.submitAMLoginForm(payload);
      }*/

      // TODO: implement valid/invalid logic. For right now, redirect to OTP regardless of result
      // this.$router.push({ path: '/vbg-tsd-login/otp' });
      // this.forgotPopup('combinenowPopupVal');
    },

    messageImage(index) {
      if (
        index.type == "error" ||
        index.type == "warning" ||
        index.type == "list"
      ) {
        return "newErrorMessageImage";
      } else if (index.type == "success") {
        return "newSuccessMessageImage";
      } else if (index.type == "information") {
        return "newInfoMessageImage";
      } else {
        return index.type + "MessageImage";
      }
    },
    // need to rewrite again in root component
    deleteAlert(alert) {
      this.getUnifiedLoginAlerts.splice(
        this.getUnifiedLoginAlerts.indexOf(alert),
        1
      );
    },

    getParameter(url) {
      let decodeUrl = decodeURIComponent(document.location);
      url = url.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + url + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(decodeUrl);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    processQueryParameters() {
      // let params = new URLSearchParams(window.location.search);

      if (this.getParameterByName("uid")) {
        this.formData[0].input[0].value = this.getParameterByName("uid");
      }
      if (this.getParameterByName("loginAssistantUid")) {
        this.formData[0].input[0].value = atob(
          this.getParameterByName("loginAssistantUid")
        );
      }
      if (this.getParameterByName("rememberMe")) {
        if (this.getParameterByName("rememberMe") == "true")
          this.rememberMe = true;
      }
    },

    getLoginCaptchaEnabledEnvVar() {
      this.captchaOn = iamvar_enableLoginCaptcha;
    },

    checkForCaptchaOffConditions() {
      // turn off captcha when instance is related to a federal portal
      let gotoParam = this.getParameterByName("goto");

      // if goto has 'oauth', get redirect_uri and check if redirect_uri has any of the federalPortalKeywords
      if (gotoParam != null && gotoParam.includes("oauth")) {
        // decode goto in order to easily access redirect_uri parameter
        let decodedGoto = decodeURIComponent(gotoParam);
        // let redirectUriParam = this.getParameterByName('redirect_uri');
        let decodedParameters = new URLSearchParams(decodedGoto);
        let redirectUriParam = decodedParameters.get("redirect_uri");

        if (
          iamvar_federalPortalKeyWords.some((str) =>
            redirectUriParam.includes(str)
          )
        ) {
          this.captchaOn = false;
          this.updateFederalPortalIndicator(true);
        }
      } else {
        // check if goto has any of the federalPortalKeywords
        if (
          iamvar_federalPortalKeyWords.some(
            (str) => gotoParam && gotoParam.includes(str)
          )
        ) {
          this.captchaOn = false;
          this.updateFederalPortalIndicator(true);
        }
      }
    },

    forgotPopup(value) {
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
      this.UpdatePopupValue(value);
      var eventPayload = {};
      var eventName = null;
      if (value == "forgotUserId" || value == "forgotUserPassword") {
        eventName =
          value == "forgotUserId" ? "forgot_user_id" : "forgot_password";
        eventPayload = {
          accounts: {
            1: "My Business Wireless",
            2: "Verizon Enterprise Center",
            3: "My Business Wireline Fios, phone, internet & TV",
          },
        };
        eventTracking(eventName, eventPayload);
      } else if (value == "payWithoutLogin") {
        eventName = "pay_without_logging_in";
        eventPayload = {
          accounts: {
            1: "My Business Wireless",
            2: "My Business Wireline Fios, phone, internet & TV",
            3: "Verizon Enterprise Center",
          },
        };
        eventTracking(eventName, eventPayload);
      }
    },
    closeCombinenow() {
      if (this.popupValue == "combinenowPopupVal") {
        this.updateMergeStatus("closePopup");
      } else if (this.popupValue == "sdarPopup") {
        this.$store.dispatch("login/UpdatePopupValue", null);
        this.$store.dispatch("login/updateDisplayPopupClass", false);
      }
    },
  },

  beforeDestroy() {
    this.formData[0].input[0].value = "";
  },

  computed: {
    ...mapGetters("login", [
      "authError",
      "submitLoader",
      "iamvar_enableCaptcha",
      "redirectToOptionsPage",
      "redirectToPasswordPage",
      "redirectToSecProfilePage",
      "iamvar_idmUrl",
      "loginCaptchaKey",
      "getAlertType",
      "popupValue",
      "getInterstitialPage",
      "getUserSelectedAccount",
      "getUnifiedErrorCode",
      "rememberMeTrue",
      "combinenowValue",
      "remembermeUserName",
      "getUnifiedLoginAlerts",
      "redirectToStepupPage",
      "getPasswordSuccess",
      "encodedGotoUrl",
      "gotoUrl",
      "displayPopupClass",
      "getCommonErrors",
    ]),

    ...mapGetters("cvsstepup", ["currentStep"]),

    ...mapGetters("forgotflow", ["usrName"]),

    ...mapGetters("cvsstepup", { getTransactionLoader: "submitLoader" }),

    isFormComplete() {
      return this.formData[0].input[0].value.length !== 0;
    },

    supportUrl() {
      let isGoToPresent = window.location.href.split("goto=");
      if (isGoToPresent.length > 1) {
        let registration_url = this.vec2Registration.split("#");
        return (
          registration_url[0] +
          "?goto=" +
          isGoToPresent[1] +
          "#" +
          registration_url[1]
        );
      }
      return this.vec2Registration;
    },

    pwdInlineError: {
      get: function () {
        return this.$store.getters["login/pwdInlineError"];
      },
      set: function (newVal) {
        this.$store.commit("login/setPwdInlineError", newVal);
      },
    },

    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },

    checkModal() {
      if (
        this.popupValue == "forgotUserId" ||
        this.popupValue == "forgotUserPassword"
      ) {
        return "modal-content-forgot";
      } else if (
        this.popupValue == "interstitial" &&
        this.getUnifiedErrorCode.includes("MYBIZ") &&
        this.getUnifiedErrorCode.includes("VEC")
      ) {
        return "modal-content-hybrid";
      } else if (
        this.popupValue == "interstitial" &&
        (this.getUnifiedErrorCode.includes("MYBIZ") ||
          this.getUnifiedErrorCode.includes("VEC"))
      ) {
        return "modal-content-int";
      } else if (this.popupValue == "payWithoutLogin") {
        return "modal-content-pwl";
      } else if (this.popupValue == "combinenowPopupVal") {
        return "modal-content-cn";
      } else if (this.popupValue == "gethelp") {
        return "modal-content-gethelp";
      } else if (this.popupValue == "sdarPopup") {
        return "sdar_popup";
      } else {
        return "";
      }
      // return [{ 'modal-content-pwl': this.popupValue == 'payWithoutLogin' ? true : false }];
    },

    gethelp() {
      return this.popupValue == "gethelp" ? "overflow: auto" : "";
    },

    getHelpCheck() {
      return this.getUnifiedErrorCode == "ACCOUNT_LOCK_PREVENTION" ||
        this.getUnifiedErrorCode == "ACCOUNT_LOCKED" ||
        this.getUnifiedErrorCode == "ACCOUNT_PREVENTION" ||
        this.getUnifiedErrorCode == "ACCOUNT_LOGIN_LOCKED" ||
        this.getUnifiedErrorCode ==
          "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH"
        ? true
        : false;
    },
    continueDisabled() {
      return !this.formData[0].input[0].value;
    },
  },

  watch: {
    displayPopupClass: function (newval) {
      if (newval) {
        this.forgotPopup("sdarPopup");
      }
    },
    remembermeUserName: function (newval) {
      if (newval != "" || newval != null) {
        this.formData[0].input[0].value = newval;
      }
    },
    usrName: function (newVal) {
      if (newVal != undefined || newVal != null) {
        this.formData[0].input[0].value = newVal;
      }
    },
    redirectToStepupPage: function (newVal) {
      if (newVal) {
        this.initializeStepup();
        console.log("called initializeStepup from newpwdlesslogin")
        this.setRedirectToStepupPage(false)
      }
    },
    getUnifiedLoginAlerts: function (newVal) {
      if (this.getUnifiedLoginAlerts.length > 0) {
        window.scrollTo(0, 0);
      }
    },
    show1: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.show1 = false;
        }, 10000);
      }
    },
    redirectToOptionsPage: function (newVal) {
      if (newVal) {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessOption",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: "PwdLessOption" });
        }
      }
    },
    redirectToPasswordPage: function (newVal) {
      if (newVal) {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessPassword",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: "PwdLessPassword" });
        }
      }
    },
    rememberMeTrue: function (newVal) {
      if (newVal == "setToTrue") {
        this.rememberMe = true;
      }
    },
    redirectToSecProfilePage: function (newVal) {
      if (newVal) {
        window.location.href = newVal;
      }
    },
    currentStep: function (newVal) {
      if (newVal === "FLOW_INITIALIZED") {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessOtpDelivery",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: "PwdLessOtpDelivery" });
        }
      }
    },
    getInterstitialPage: function (newVal) {
      if (newVal) {
        // if from 2nd or 5th auth call X-VZ-MULTIPLE-ACCOUNT is coming that means there are 2 accounts VBMC(VEC) and VBMS(FIOS)
        if (newVal == iamvar_vcgMultipleAccount) {
          this.forgotPopup(iamvar_vcgMultipleAccount);
        } else {
          // if new value is true then it means it's has conflict between any of combination between mybiz/vec/fios
          this.forgotPopup("interstitial");
        }
        this.$store.commit("login/setInterstitialPage", false);
      }
    },
    getUserSelectedAccount: function (selectedAccount) {
      let payload = "";
      $("#noAccessToOtpModal").modal("hide");
      if (selectedAccount && this.popupValue == "interstitial") {
        payload = {
          formData: this.formData,
          rememberUser: this.rememberMe,
          additionalAuth: "VBG_LOGIN",
        };
        if (this.getUnifiedErrorCode.includes("INVALID")) {
          if (
            selectedAccount == "wireline" &&
            this.getUnifiedErrorCode.includes("INVALID")
          ) {
            // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          } else if (
            selectedAccount == "wireless" &&
            this.getUnifiedErrorCode.includes("INVALID")
          ) {
            // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          } else if (
            selectedAccount == "fios" &&
            this.getUnifiedErrorCode.includes("INVALID")
          ) {
            payload["additionalAuth"] = "INVALID_SMB_LOGIN";
            // to do - "authenticate" call with additional header parameter as "INVALID_SMB_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          }
        } else {
          if (
            selectedAccount == "fios" &&
            this.getUnifiedErrorCode.includes("VALID")
          ) {
            this.smbFormSubmit({
              username: this.formData[0].input[0].value,
              password: this.formData[1].input[0].value,
              rememberUser: this.rememberMe,
            });
          } else if (
            selectedAccount == "wireline" &&
            this.getUnifiedErrorCode.includes("VALID")
          ) {
            // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          } else if (
            selectedAccount == "wireless" &&
            this.getUnifiedErrorCode.includes("VALID")
          ) {
            // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          }
        }
      } else if (
        (selectedAccount == "wireless" || selectedAccount == "wireline") &&
        this.popupValue == "forgotUserId"
      ) {
        this.$router.push({ path: "/login/recovery/forgotusername" });
        this.updateUserIdFlow("BASIC");
      } else if (
        (selectedAccount == "wireless" || selectedAccount == "wireline") &&
        this.popupValue == "forgotUserPassword"
      ) {
        this.$router.push({ path: "/login/recovery/forgotpassword" });
      } else if (
        selectedAccount == "fios" &&
        this.popupValue == "forgotUserPassword"
      ) {
        let fiosURLFP = "";
        let gotoURLFP = this.getParameterByName("goto");
        if (gotoURLFP) {
          // Added goto url to fios url
          fiosURLFP = `${iamvar_fios_fp_url}?goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(
            gotoURLFP
          )}&clientId=vbm`;
        } else {
          fiosURLFP = iamvar_fios_fp_url + "?clientId=vbm";
        }
        window.location.href = fiosURLFP;
      } else if (
        selectedAccount == "fios" &&
        this.popupValue == "forgotUserId"
      ) {
        let fiosURLFU = "";
        let gotoURLFU = this.getParameterByName("goto");
        if (gotoURLFU) {
          // Added goto url to fios url
          fiosURLFU = `${iamvar_fios_fu_url}&goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(
            gotoURLFU
          )}`;
        } else {
          fiosURLFU = iamvar_fios_fu_url;
        }
        window.location.href = fiosURLFU;
      } else if (
        selectedAccount == "wireless" &&
        this.popupValue == "payWithoutLogin"
      ) {
        window.location.href =
          iamvar_mbt_base_url +
          "/mbt/manageservice/payments.go/#/billpay/onetimepayment";
      } else if (
        selectedAccount == "fios" &&
        this.popupValue == "payWithoutLogin"
      ) {
        window.location.href = this.wireline_login;
      } else if (
        selectedAccount == "wireline" &&
        this.popupValue == "payWithoutLogin"
      ) {
        window.location.href = iamvar_vec_quickpay;
      } else if (
        (selectedAccount == "vbmc" || selectedAccount == "vbms") &&
        this.popupValue == iamvar_vcgMultipleAccount
      ) {
        payload = {
          formData: this.formData,
          rememberUser: this.rememberMe,
          additionalVcgAuth: selectedAccount == "vbmc" ? "VBMC" : "VBMS",
        };
        this.submitPwdAMPasswordForm(payload);
      }

      // to reset initial value
      this.selectAccount("null");
    },
    popupValue: function (newVal) {
      if (newVal == "combinenowPopupVal") {
        this.forgotPopup(newVal);
      }
    },
    downlinkSpeed: function(newVal) {
      if(newVal < 1) {
        // this.isSlowConnection = true
        var infoMsg = translations.en.message["slow_internet_msg"]
        this.setUnifiedLoginAlerts([{ type: 'information', msg: infoMsg }])
      }
    },
  },
};
</script>
<style scoped>
.pwdles_main_heading {
  margin-bottom: 12px;
  margin-top: 32px;
}
.pwdles_sub_heading {
  margin-bottom: 32px;
}
.error_spacing {
  height: auto !important;
  font-family: "VzNHGeTX-reg11" !important;
  color: #000 !important;
}
.pwdLoginTextError {
  height: auto !important;
  font-size: 12px;
  line-height: 16px;
  color: #000 !important;
  margin-bottom: 12px;
}
.pwd-forgot-link {
  cursor: pointer;
}
.a {
  text-decoration: none !important;
}
#noAccessToOtpModal {
  z-index: 105000000;
}
@media only screen and (max-width: 1023px) {
  .pwdles_main_heading{
    margin-bottom: 12px;
    margin-top: 0;
}
  .pwdles_sub_heading{
    margin-bottom: 24px;
  }
  .new_pwdless_btn_section{
    margin-top: 32px;
  }
}
</style>
