<template>
  <v-layout>
    <v-container>
      <Header></Header>
      <UserRegMainComponent></UserRegMainComponent>
      <Footer></Footer>
    </v-container>
  </v-layout>
</template>
<script>
import Footer from '@/vbgauth/components/Footer'
import Header from '@/vbgauth/components/Header'
import UserRegMainComponent from '@/vbgauth/components/userRegistrationFlow/UserRegMainComponent'
export default {
  name: 'UserRegRootComponent',
  components: {
    Header,
    Footer,
    UserRegMainComponent
  },
  data () {
    return {
    }
  }
}
</script>
<style scoped>
</style>
