import axios from 'axios'
import {iamvar_serverUrl} from '../../../public/const.js'

export const profilerest = axios.create({
 
  baseURL: iamvar_serverUrl+'ifgservices/vbg/iamservices/api/',
  withCredentials: true
})


export default profilerest