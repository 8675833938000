<template>
  <div class="main-container">
    <div class="card">
      <div class="card-header">
        {{ tab.tabName }}
      </div>
      <div class="card-content">
        <ul class="list-group">
          <li class="list-group-item">
            <a target="_blank" href="/jsoneditor"></a>
            Json Editor &amp; Formatter

            <i class="fa fa-external-link-square"></i>
          </li>
          <li class="list-group-item">
            <a target="_blank" href="http://vectrack.verizon.com">Vectrack</a>
            &nbsp;&nbsp;
            <a target="_blank" href="http://onejira.verizon.com"> OneJira</a>
            <i class="fa fa-external-link-square"></i>
          </li>
          <li class="list-group-item">
            <a target="_blank" href="/jsoneditor"></a>
            Json Editor &amp; Formatter

            <i class="fa fa-external-link-square"></i>
          </li>
          <li class="list-group-item">
            <a target="_blank" href="http://vectrack.verizon.com">Vectrack</a>
            &nbsp;&nbsp;
            <a target="_blank" href="http://onejira.verizon.com"> OneJira</a>
            <i class="fa fa-external-link-square"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VbgQuickLinks",
  props: ["tab"],
};
</script>
<style scoped>
.main-container {
  margin: 0 35px;
}
.card {
  border: none;
  box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}
.card-header {
  background-color: #2196f3;
  border-color: #2196f3;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.card-content {
  background: white;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 20px;
  /* padding: 10px; */
}

.list-group-item {
  display: flex;
}
.list-group-item:first-child {
    border-radius: unset !important; 
     /* border-top-right-radius: 4px; */
}
.list-group {
  margin-bottom: 0px !important;
}
@media only screen and (max-width: 768px) {
  .main-container {
    padding: 7px 0px;
    margin: 0 20px;
  }
}
</style>