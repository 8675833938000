<template>
  <div class="stepsCss">
    <div
      :class="[{ active: step.active }, { rspdisabled: step.disabled }]"
      class="stepsCss-main"
      v-for="step in stepsContent"
      :key="step.title"
    >
        <div class="stepsCss-text">
          <p> {{step.title}} </p>
        </div>
        <div class="stepsCss-border"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";

export default {
  name: "ResetSecurityProfileSteps",
  components: {
  },
  computed: {
    ...mapGetters('login',['tokenExpired']),
    ...mapGetters('profile',['stepsContent']),
  },
  data() {},
  mixins: [commonMixin],
  mounted() {
    
  },
  methods: {
    ...mapActions('login',["updateSetTokenExpired","updateUrlOnMount"])
  },
};
</script>
<style scoped>
    .stepsCss {
        display: flex;
        column-gap: 10px;
    }
    .stepsCss-main {
        width: 180px;
    }
    .stepsCss-text h1 {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        margin-bottom: 3px;
    }
    .stepsCss-border {
        height: 1px;
        width: 180px;
        border: 2px solid #D52B1E;
    }
    .stepsCss-text {
      color: #000;
    }
    /* media query for mobile screens and tablet screens */
    @media (max-width: 1024px) {
      .stepsCss {
        column-gap: 7px;
      }
      .stepsCss-main {
        width: 100%;
      }
      .stepsCss-text h1 {
        width: 100%;
      }
      .stepsCss-border {
        height: 1px;
        width: 100%;
      }
      .vbg-rsp-input-field {
        width: 100%;
      }
    }
    .stepsCss-main.active>.stepsCss-text {font-weight: bold; color: #000;}
    .stepsCss-main.rspdisabled>.stepsCss-text {color: #D8DADA;}
    .stepsCss-main.rspdisabled>.stepsCss-border {border: 2px solid #D8DADA;}
</style>
