<template>
  <v-wait for="sqa_fetch_to_complete">
    <template slot="waiting">
      <LoaderComponent></LoaderComponent>
    </template>
    <BlockUI v-if="submitLoader" :url="url"></BlockUI>
    <!-- <div v-if="sqaAlerts.length > 0">
      <div class="row" v-for="j in sqaAlerts" :key="j.msg">
        <div class="col-sm-12">
          <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
            <div class="vbg-ff-msg-container">
              <div class="messageWrapper" style="display: flex;">
                <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                  <div class="messageImage" :class="messageImage(j)"></div>
                </div>
                <p class="messageContentUn col-xs-11" v-if="j.type=='list'" v-html="j.msg" style="padding:0px;margin:0px">{{j.msg}}</p>
                <p class="messageContentUn col-xs-11" v-else-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px" v-html="j.msg">{{j.msg}}</p>
                <p class="messageContentUn col-xs-11" v-else style="padding:0px;margin:0px;color:#ffffff" v-html="j.msg">{{j.msg}}</p>
                <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                  <img class="closeMessageImg" v-if="j.type=='list' || j.type == 'error' || j.type == 'warning'" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                  <img class="closeMessageImg" v-else src="@/common/assets/styles/corp/images/clear_white.png" @click="deleteAlert(j)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      <!--<div v-if="this.whichRealm" class="col-xs-0 col-md-1"></div>-->
      <div>
        <!-- <div class="vbg-ff-title-container">
          <h1 class="vz-ff-tittleFont" v-if="sqaPageMessages.title">{{ sqaPageMessages.title }}</h1>
        </div> -->
        <div v-if="sqaPageMessages.desc" class="martop12">
          <p class="px-0" v-if="!fingerprintSaved">
            <b style="color: #000;">{{ sqaPageMessages.desc }}</b>
            <span v-if="!fingerprintSaved && sqaPageMessages.desc">
              <button class="bx-tooltip marright0" type="button" style="position: relative;" v-tooltip="content"></button>
            </span>
          </p>
        </div>
        <div>
          <p class="vz-ff-subtittleFont">{{ sqaPageMessages.subtitle }}</p>
          <!-- <p class="px-0">{{ sqaPageMessages.instrn }}</p> -->
        </div>
        <div class="vbg-ff-form-container">
          <form @submit.prevent.enter="validateAnswer(true)">
            <div class="labeltiny">
              <!-- <label>Secret question</label> -->
              <h5>{{selectedQuestion.question}}</h5>
            </div>
            <div class="vbg-ff-input-container">
              <label class="vbg-ff-input-label padleft0 " :class="hideOtpValidateComponent ? '' : 'vbg-rsp-disabled-label'">Your answer</label>
              <div class="vbg-icon-input-group">
                <input 
                :type="isShow ? 'text' : 'password'"
                class="vbg-sqa-input-field"
                v-bind:class="[hideOtpValidateComponent ? '':'vbg-rsp-disabled-label border-bottom-disable',currentStep === 'SQA_INVALIDATED' ? 'vbg-input-warning' : '', (sqaValidated &&hideOtpValidateComponent) ? 'vbg-input-sucess' : '']"
                v-model="selectedQuestion.answer"
                :disabled="isDisabled"
                ref="sqaRef"
                :tabindex="devices.length + 4"
                id="sqa"
                name="sqa"
                >
                <div class="vbg-show-icon" :class="hideOtpValidateComponent ? '' : 'vbg-rsp-disabled-label'">
                  <span v-if="!isShow && selectedQuestion.answer" @click="isShow = !isShow">Show</span>
                  <span v-if="isShow && selectedQuestion.answer"  @click="isShow = !isShow">Hide</span>
                </div>
                <div class="vbg-rsp-warning-icon" style="right: 62px; top: 13px;" v-if="currentStep == 'SQA_INVALIDATED' && selectedQuestion.answer"><img :src="warningIcon" /></div>
                <div class="vbg-success-icon" style="right: 62px; top: 13px;" v-if="sqaValidated &&hideOtpValidateComponent"><img style="height: 8px; width: 8px;" :src="checkIcon" /></div>
              </div>
              <div v-if="currentStep == 'SQA_INVALIDATED'" class='vbg-ff-input-label'  style="margin-bottom: 0px !important;">
                <span>{{ $t("message.sqa_incorret_msg") }}</span> 
              </div>
            </div>
            <div class="submit-btn" :class="hideOtpValidateComponent ? '' : 'vbg-rsp-disabled-label'"> 
              <button type="submit" class="btn primary_black marright20" :disabled="continueDisabled" :tabindex="devices.length + 5" v-if="flowType == 'auth'" @click="validateAnswer(true)">{{ $t("message.otp_verify") }}{{ $t("message.login_register_device") }}</button>
              <button type="submit" class="btn vbg-ff-btn-continue vbg-ff-btn-width140" :tabindex="devices.length + 5" :class="isDisabledClass()" :disabled="continueDisabled" v-if="flowType != 'auth'" @click="validateAnswer(false)" name="sqa-continue-btn" id="sqa-continue-btn">{{ $t("message.otp_verify") }}</button>
            </div>
            <div v-if="!disableSqaFields && selectedQuestion.answer && flowType == 'auth'" class="padleft0 margin-bottom-large padding-bottom-small col-xs-12" :class="getOAuthClass('oauth-flow-pr0')">
              <a class="vz-a" @click="validateAnswer(false)">{{ $t("message.otp_verify") }}{{ $t("message.login_dont_register_device") }}</a>
              <span>
                <button class="bx-tooltip marright0" type="button" style="position: relative;" v-tooltip="content3"></button>
              </span>
            </div>
            <div v-if="displayDiffLoginOption">
              <a class="vz-a" @click="redirectToLogin()"> Sign in as a different user </a>
            </div>
          </form>
        </div>
      </div>
      <!--<div v-if="this.whichRealm" class="col-xs-0 col-md-5"></div>-->

  </v-wait>
</template>
<script>

import LoaderComponent from '@/vbgauth/components/LoaderComponent'
import { mapGetters, mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import translations from '@/common/assets/json/messages1.json'
import {setPageDetails} from '../../../../../public/adobeTagging'
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import commonMixin from "@/common/mixin/commonMixins";
import warningIcon from '@/common/assets/styles/corp/images/warning.png'
import checkIcon from '@/common/assets/styles/corp/images/checkmark.png'
export default {
  name: 'VPSFFSqaComponent',
  components: {
    LoaderComponent,
  },
  async created() {
    setPageDetails('Forgot Password:SAQ');
  },
  mixins: [commonMixin],
  data () {
    return {
      hideSubmit: false,
      content: translations.en.message['login_device_not_recognized_tooltip'],
      //url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      content3: translations.en.message['login_verify_and_dont_register_tooltip'],
      sqaValidationInputClass: '',
      sessionCookie: '',
      challengeMethod: 'sqa',
      displayDiffLoginOption: false,
      whichRealm: true,
      isShow: false,
      warningIcon: warningIcon,
      checkIcon: checkIcon,
    }
  },
  async mounted () {
    this.changeContextBiocatch('forgot_password_sqa_page')
    if((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if(hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          this.whichRealm = false;
        }
    }

    // check if /verify is present on url
    var currentUrl = window.location.pathname
    if(currentUrl.includes('/verify/')) {
      // update flowtype to POST_AUTH_FLOW
      this.updateFlowType('POST_AUTH_FLOW')
      // pass challengeMethod as part of the payload
      this.initializeStepup({ challengeMethod: 'SQA' })
    } else { // no '/verify/' present
      console.log('getting user questions on pre-auth stepup')
      try{
        if(!this.selectedQuestion.question){
          await this.getUserQuestions()
        }
      } catch(e) { console.log('Error') } finally {
        // this will run and set focus to
        // input field when user land on this page
        this.$nextTick(() => {
          this.$refs.sqaRef.focus()
        })
      }
    }
    
    if(this.$route.query.continueURL) {
      this.continueUrl = this.$route.query.continueURL
    }

    // If coming from ODI, display IDM Message. Option for "login as different user" which redirects to login
    if(this.urlOnMount && this.urlOnMount.includes('appview=odi')) {
      this.displayDiffLoginOption = true
    }
  },
  methods: {
    ...mapActions('cvsstepup', ['validateSqa','cancelVerification', 'initializeStepup', 'updateFlowType', 'updateTriggerSendOTP', 'updateSQAValidated']),
    ...mapActions('login', ['redirectToOpenam', 'urlOnMount']),
    ...mapWaitingActions('cvsstepup', {
        getUserQuestions: 'sqa_fetch_to_complete'
    }),
    deleteAlert(alert) {
      this.sqaAlerts.splice(this.sqaAlerts.indexOf(alert),1)
    },
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png'
      return "@/common/assets/styles/corp/images/"+index.type+".png"
    },
    messageImage(index) {
      if(index.type == 'error' || index.type == 'warning' || index.type == 'list') {
        return "newErrorMessageImage"
      } else {
        return index.type+"MessageImage";
      }
    },
    getCloseIcon() {
      return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
    },
    getAlertType (index) {
      //when error occurs take the user to the top screen where the orange banner is shown
      window.scrollTo(0,0);
      return index.type
    },
    async validateAnswer (saveFingerprint) {
      if (this.disableSqaFields || !this.selectedQuestion.answer) {
      // the continue button is disabled and should not be clickable
      } else {
        if(!this.sqaValidated) {
        let self = this
        var sqaValidateParams = {
          "sqa": self.selectedQuestion,
          "saveFingerprint": saveFingerprint,
          "sessionCookie": self.sessionCookie,
          "challengeMethod": self.challengeMethod
        }
        await this.validateSqa(sqaValidateParams, this.sessionCookie, this.challengeMethod)
        // this.updateTriggerSendOTP(true)
        } else {
          this.updateTriggerSendOTP(true)
        }
      }
    },
    switchToOTP() {
      this.$router.push({path: 'vpsotp'})
    },
    redirectToLogin() {
      window.location.href = this.urlOnMount
    },
    getOAuthClass(className = 'oauth-flow', subClass = '') {
      return className + ' ' + subClass;
    },
    isDisabledClass() {
      if(this.disableSqaFields || !this.selectedQuestion.answer || this.submitLoader || !this.hideOtpValidateComponent) {
        return ' vbg-ff-disabled'
      } else {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters('cvsstepup', [
      'fingerprintSaved',
      'submitLoader',
      'isPwReset',
      'dynamicErrorClasses',
      'userQuestions',
      'selectedQuestion',
      'sqaSubmitLinkClass',
      'flowInitialized',
      'disableSqaFields',
      'sqaPageMessages',
      'currentStep',
      'flowType',
      'stepupReason',
      'otpEnabled',
      'urlOnMount',
      'sqaValidated',
      'hideOtpValidateComponent',
      'devices'
    ]),
    vzigInstanceCreated: {
      get: function() {
        return this.$store.getters['cvsstepup/vzigInstance']
      }
    },
    sqaAlerts: {
      get: function() {
        return this.$store.getters['cvsstepup/sqaAlerts']
      },
      set: function (newVal) {
        this.$store.commit('cvsstepup/setSQAAlert', newVal)
      }
    },
    continueUrl: {
      get: function() {
        return this.$store.getters['cvsstepup/continueUrl']
      },
      set: function(newVal) {
        this.$store.commit('cvsstepup/setContinueUrl', newVal)
      }
    },
    continueDisabled() {
      return this.disableSqaFields || !this.selectedQuestion.answer || this.submitLoader 
    },
    isDisabled() {
      return this.disableSqaFields || !this.hideOtpValidateComponent
    }
  },
  watch: {
    currentStep: function(newVal) {
      if(newVal === 'TOKEN_EXPIRED') {
        // this.cancelVerification()
        // window.location.href = this.urlOnMount
      } else if(newVal === 'SQA_VALIDATED') {
        if(this.continueUrl) {
          window.location.href = this.continueUrl
        // } else if(this.flowType === 'forgot_password') {
          // this.cancelVerification()
          // this.initializeStepup({ challengeMethod: 'OTP' })
        } else if(this.flowType === 'auth') {
          this.redirectToOpenam()
        }
      // } else if(this.flowType === 'forgot_password' && newVal === 'FLOW_INITIALIZED') {
        // this.$router.push({ name: 'FFOtpComponent'}).catch(err => {throw err})
      }
    },
    vzigInstanceCreated: function(newVal) {
      // if(newVal != null) {
      //   if(!this.selectedQuestion.question){
      //   console.log('calling getuserquestions from watch')
      //   this.getUserQuestions()
      //   }
      // }
    },
    isShow: function(newVal) {
      if(newVal) {
        setTimeout(()=> {
            this.isShow = false
        },10000)
      }
    },
    'selectedQuestion.answer': function() {
      if(this.sqaValidated) {
        this.updateSQAValidated(false)
      }
    }
  }
}
</script>
<style scoped>
.vbg-ff-title-container{
  margin-bottom: 40px !important;
}
.vbg-ff-input-container{
  margin-top: 20px !important;
  /* margin-bottom: 60px !important; */
  display: flex;
  flex-direction: column;
}
.vbg-sqa-input-field:focus {
  border: 1px solid black !important;
  box-shadow: none !important;
}
.vbg-sqa-input-field{
  width: 100% !important;
  height: 44px;
  font-size: 16px;
}
.submit-btn{
  margin-top: 60px;
}
.labeltiny h5 {
  color: #000000 !important;
  font-family: "VzNHGeDS-bold11",Arial,Helvetica,sans-serif;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 16px !important;
}
.messageContentUn {
  color: #000000;
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  line-height: 20px;
}
.div {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}
input {
  padding: 11px 0 11px 10px;
    border-radius: 0px;
    font-size: 14px;
    color: #000;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000;
    box-shadow: none;
    transition: border .4s;
    height: 36px;
}
.vz-ff-tittleFont{
  font-size: 32px !important;
  font-family: "VzNHGeDS-bold11",Arial,Helvetica,sans-serif;
}
.vz-ff-subtittleFont{
  color: #000000;
  letter-spacing: 0;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 20px;
  font-family: VzNHGeDS-bold11;
}
.vbg-ff-form-container{
  margin-top: 20px !important;
}
.vbg-icon-input-group{
  width: 596px !important;
  position: relative;
}
.vbg-show-icon {
  height: 20px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  text-decoration: underline;
  cursor: pointer;
  letter-spacing: 0.5px;
  /* text-decoration: none !important; */
  /* border-bottom: 1px solid #000000 !important; */
  color: #000000 !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.vbg-input-warning {
  border: 1px solid #ED7000 !important;
  border-bottom: 4px solid #ED7000 !important;
}
.vbg-input-warning:focus {
  border: 1px solid #ED7000 !important;
  border-bottom: 4px solid #ED7000 !important;
}
.vbg-input-sucess {
  border-bottom: 4px solid #008330 !important;
}
.vbg-input-sucess:focus {
  border-bottom: 4px solid #008330 !important;
}
.vbg-ff-btn-width140 {
  width: 151px;
  height: 44px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: center;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  /* padding: 12px; */
  font-size: 16px;
}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
  margin-top: 8px;
}
.vbg-success-icon {
  height: 18px;
  width: 18px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 11px;
  right: 50px;
}
input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
/* for Oauth flows */
@media (min-width: 280px) and (max-width: 1024px) {
  .oauth-flow-mt12 {
    margin-top: 12px !important;
  }
  .oauth-flow {
    padding-right: 0 !important;
    margin-top: 12px !important;
  }
  .oauth-flow v-form.padleft0.col-xs-12 {
    padding-right: 0 !important;
  }
  .oauth-flow-pr0 {
    padding-right: 0 !important;
  }
  .oauth-flow-w-100 {
    width: 100% !important;
  }
  .oauth-flow-jcaptch {
    width: 100%;
    margin-bottom: 10px;
  }
  .oauth-flow-continue-btn {
    width: 100%;
    margin-bottom: 12px;
  }
  .oauth-flow-cancel-btn {
    width: 100%;
    margin-left: 0 !important;
  }
  .oauth-flow-pt16 {
    padding-top: 16px !important;
  }
  .oauth-d-flex {
    display: flex;
  }
  .oauth-flex1 {
    flex: 1;
  }
  .oauth-flow-pr0 button {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .oauth-flow-pr0 button {
    width: 47%;
  }
}
@media (max-width: 1024px) {
  .col-xs-12.col-md-6 {
    max-width: 100% !important;
    width: 100% !important;
  }
  .vbg-ff-title-container{
    margin-bottom: 32px !important;
  }
  .vbg-ff-input-container{
    margin-top: 24px !important;
    margin-bottom: 48px !important;
  }
  .vbg-icon-input-group{
    width: 100% !important;
  }
  .submit-btn{
    margin-top: 48px;
  }
}
@media (max-width: 425px) {
 .messageContentUn {
    width: 232px;
  }
  .vz-ff-tittleFont{
    font-size: 24px !important;
  }
  .vz-ff-subtittleFont{
    font-size: 16px !important;
  }
  .vbg-ff-form-container{
    margin-top: 32px !important;
  }
}
button:focus {
  outline: 1px dashed #000 !important;
  outline-offset: 2px !important;
}
.border-bottom-disable {
  border-bottom: 1px solid #d8dada;
}
</style>
