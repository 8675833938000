<template>
  <div class="clear forgot-flow-footer">
    <div id="vz-gf20"></div>
  </div>
</template>

<script>
export default {
  name: "vpsFooter",
  components: {},
  data() {
    return {
      componentName: "/",
    };
  },
  beforeMount() {
    // eslint-disable-next-line no-undef
    gnavdl = {
      bu: "smb",
      appid: "vpslimited",
    };
  },
  mounted() {
    var footer = document.createElement("script");
    footer.setAttribute(
      "src",
      "https://www.verizon.com/etc/designs/vzwcom/gnav20/smb.js"
    );
    footer.setAttribute("async", "async");
    document.head.appendChild(footer);
    this.componentName = this.$route.name;
  },
};
</script>
<style scoped>
.clear {
  clear: both;
}

.footerCss {
  width: 100% !important;
  margin: 0px;
  border-radius: 0px;
}

.footer-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.links-container ul li {
  display: inline-block;
  margin-left: 30px;
}

.img-container {
  padding-left: 60px;
  width: 35%;
}

.links-container a {
  color: #747676;
}
@media only screen and (min-height: 550px) and (min-width: 768px) {
  /*.forgot-flow-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }*/
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .footerCss {
    position: static;
  }
  .footer-container {
    flex-direction: column;
  }
  .img-container {
    padding: 12px 16px 20px;
    width: 100%;
    height: 56px;
  }
  .links-container {
    width: 100%;
    padding: 0 16px 60px;
  }
  .links-container ul li {
    display: block;
    width: 100%;
    float: none;
    padding: 0 8px 8px 0;
    margin: 0px;
  }
  .links-container ul li a {
    font-size: 12px !important;
    line-height: 14.4px;
    padding: 12px 0;
    display: block;
  }
  .copyright-container {
    margin-top: 32px !important;
  }
}
</style>
