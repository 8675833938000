<template>
    <div style="overflow: hidden">
        <div class="profile_layout">
            <BlockUI v-if="submitLoader" :url="url"></BlockUI>
            <form @submit.prevent="onSubmit()" class="profile_container">
                <h1 class="profile_container_heading">Security Profile registration</h1>
                <div class="mtn_container">
                    <input type="radio" class="vz-custom-radio" checked />
                    <label for="radio1"></label>
                    <!-- <p class="mtn_number_text">Text to **** **** 0573</p> -->
                    <p class="mtn_number_text">Text to {{ maskedPhoneNumber }}</p>
                </div>
                <div class="input_section btn_container">
                    <input type="submit" class="submit_btn" tabindex="0" />
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import commonMixin from "@/common/mixin/commonMixins";
import Header from "@/vbgauth/components/LegalHeader";
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import { mapGetters, mapActions } from 'vuex'


export default {
    name: "SecurityProfileRequest",
    
    mounted() {
        // window.scrollTo(0,0);
        // this.getPassswordBlackList("create");
        // this.readConfig('vbg_config')
        // this.changeContextBiocatch('forgot_password_reset_password_page')
        this.updateMaskedPhoneNumber();
    },
    data() {
        return {
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      maskedPhoneNumber:""
        }
    },
    computed: {
        ...mapGetters('login', ["getVcgMtn"]),
         ...mapGetters('profile', ["uuID", "submitLoader"]),
           
        // areFirstTwoFieldvalid(){
        //     return (this.validPwd && !this.pwdNotMatched);
        // },


    },
    methods: {
        ...mapActions('profile', [
            'requestProfileSecurityPin'

        ]),
        onSubmit(){
        let payload = {
             "uuID":this.uuID,
            "otpType":"SMS",
            "countryCode":"1",
            "mtn": this.getVcgMtn,
      };
      
      if (this.selectedOtpType == "text") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "sms";
        }
      this.requestProfileSecurityPin(payload);
    },
    maskMtn(phoneNum) {
        if(typeof phoneNum === 'number'){
            phoneNum = phoneNum.toString();
        }
            let visiblePart = phoneNum.slice(-4);
            let maskedPart = 'x'.repeat(phoneNum.length - 4);
            return maskedPart + visiblePart;
    },
    updateMaskedPhoneNumber(){
        this.maskedPhoneNumber = this.maskMtn(this.getVcgMtn);
    },
    },
    components: {
        Header,
        Footer,
    },
    ForgotFlowFootermixins: [commonMixin],
};
</script>
<style scoped>
body {
    color: #000000 !important;
}

.footer-margin-top {
    margin-top: 64px;
}

.profile_layout {
    display: flex;
    justify-content: center;
}

.profile_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    width: 50%;
}

.profile_container_heading {
    margin: 0;
    font-size: 32px;
    color: #000000;
    line-height: 36px;
    letter-spacing: normal;
    font-family: 'VzNHGeDS-bold11';
    margin-bottom: 12px;
}

.input_section {
    margin-bottom: 16px;
    position: relative;
}

.security_radio_input {
    height: 20px;
    width: 20px;
    border: 4px solid #000000;
}

.btn_container {
    text-align: center;
}

.submit_btn {
    background-color: #000000;
    color: #FFFFFF;
    font-family: 'VzNHGeDS-bold11';
    padding: 12px 24px;
    letter-spacing: 0.5px;
    border-radius: 25px;
    width: 100%;
    margin-top: 4px;
    font-size: 16px;
}

.submit_btn:focus {
    outline: 1px solid #000000;
}

.mtn_container {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.mtn_number_text{
    margin-left: 4px;
}

@media only screen and (max-width: 1023px) {
    .footer-margin-top {
        margin-top: 48px;
    }

    .profile_container {
        width: 100%;
    }
    .profile_container_heading {
        font-size: 24px;
        line-height: 28px;
    }


}
.vz-custom-radio {
    display: none;
    /* height: 18px;
    width: 18px;
    border: 1px solid black;
    position: relative;
    border-radius: 100%;
    bottom: 2px;
    display: block;
    background: #000; */
}
.vz-custom-radio + label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding-left: 25px;
    line-height: 18px;
    margin-bottom: 16px;

}
.vz-custom-radio + label:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 4px solid #000000;
    background-color: #FFFFFF;
    border-radius: 50%
}
/* .vz-custom-radio:checked + label:after{
    content: "";
    position: absolute;
    left: 6;
    top: 6;
    width: 8px;
    height: 8px;
    border: 3px solid #000000;
    background-color: #000000;
    border-radius: 50%
}
.vz-custom-radio + label:after{
    content: "";
    position: absolute;
    left: 6;
    top: 6;
    width: 8px;
    height: 8px;
    border: 3px solid #000000;
    background-color: #000000;
    border-radius: 50%
} */
</style>