<template>
    <v-wait for="request_to_complete" class="full-height">
        <template slot="waiting">
            <LoaderComponent></LoaderComponent>
        </template>
        <BlockUI v-if="submitLoader" :url="url"></BlockUI>
        <div class="vz-forgot-flow">
          <div v-if="otpAlerts.length > 0">
            <div class="row" v-for="j in otpAlerts" :key="j">
              <div class="col-sm-12">
                <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
                  <div class="vbg-ff-msg-container">
                    <div class="messageWrapper" style="display: flex;">
                      <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                        <div class="messageImage" :class="messageImage(j)"></div>
                      </div>
                      <p class="messageContentUn col-xs-11" v-if="j.type=='list'" v-html="j.msg" style="padding:0px;margin:0px">{{j.msg}}</p>
                      <p class="messageContentUn col-xs-11" v-else-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px" v-html="j.msg">{{j.msg}}</p>
                      <p class="messageContentUn col-xs-11" v-else style="padding:0px;margin:0px;color:#ffffff" v-html="j.msg">{{j.msg}}</p>
                      <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                        <img class="closeMessageImg" v-if="j.type=='list' || j.type == 'error' || j.type == 'warning'" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                        <img class="closeMessageImg1" v-else src="@/common/assets/styles/corp/images/clear_white.png" @click="deleteAlert(j)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="wirelessAlerts.length > 0">
              <div class="row" v-for="j in wirelessAlerts" :key="j">
                <div class="col-sm-12">
                  <div class="wnMessagesInfo" v-bind:class="getAlertType(j)">
                    <div class="vbg-container-fluid">
                    <div class="messageWrapper" style="display: flex;">
                      <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                        <div class="messageImage" :class="messageImage(j)"></div>
                      </div>
                      <p class="messageContentUn col-xs-11" v-if="j.type=='list'" v-html="j.msg" style="padding:0px;margin:0px">{{j.msg}}</p>
                      <p class="messageContentUn col-xs-11" v-else-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px" v-html="j.msg">{{j.msg}}</p>
                      <p class="messageContent col-xs-11" v-else style="padding:0px;margin:0px">{{j.msg}}</p>
                      <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                        <img class="closeMessageImg" v-if="j.type=='list' || j.type == 'error' || j.type == 'warning'" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                        <img class="closeMessageImg" v-else src="@/common/assets/styles/corp/images/close_wh.png" @click="deleteAlert(j)" />
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="vbg-ff-main-container">
            <div>
              <h1 v-if="otpPageMessages.title" style="color: #000;">{{ otpPageMessages.title }}</h1>
            </div>
            <div v-if="showDescription">
              <p class="px-0" v-if="!fingerprintSaved">
                <b style="color: #000;">{{ otpPageMessages.desc }}</b>
                <span v-if="otpPageMessages.desc">
                  <!--<button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;" v-tooltip="content"></button>-->
                </span>
              </p>
            </div>
            <FFOtpDeliveryComponent></FFOtpDeliveryComponent>
            <FFSqaComponent v-if="ffSQAEnabled && selectedOtpDevice.deviceType != 'select' && flowType == 'forgot_password'"/>
            <FFOtpValidateComponent v-if="!hideValidateComponent"></FFOtpValidateComponent>
          </div>
        </div>
    </v-wait>
</template>
<script>
import SwitchChallengeMethod from '@/vbgauth/components/core/SwitchChallengeMethod'
import LoaderComponent from '@/vbgauth/components/LoaderComponent'
import FFOtpDeliveryComponent from '@/vbgauth/components/ffstepup/FFOtpDeliveryComponent'
import FFOtpValidateComponent from '@/vbgauth/components/ffstepup/FFOtpValidateComponent'
import FFSqaComponent from '@/vbgauth/components/ffstepup/FFSqaComponent'
import { mapGetters, mapActions } from 'vuex'
import translations from '@/common/assets/json/messages1.json'
import { mapWaitingActions } from 'vue-wait'
import '@/common/assets/styles/tooltip.css' 
import {setPageDetails} from '../../../../public/adobeTagging'
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import commonMixin from '@/common/mixin/commonMixins'

export default {
  name: 'FFOtpComponent',
  components: {
    LoaderComponent,
    FFOtpDeliveryComponent,
    FFOtpValidateComponent,
    SwitchChallengeMethod,
    FFSqaComponent
  }, 
  mixins: [commonMixin],
  async created() {
        setPageDetails('login:OTP');
      },
  data () {
    return {
        content: translations.en.message['login_device_not_recognized_no_sqa_tooltip'],
        //url : iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
        //url : loadingImage,
        url : iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
        continueUrl: null,
        sessionCookie: '',
        challengeMethod: 'otp',
        hideValidateComponent: true,
        showDescription: true,
        whichRealm: true
    }
  },
  beforeLeave: (to, from, next) => {
        if(to.name === 'FFStepupRootComponent') {
            if(this.continueUrl) next({ name: 'SimpleLoginComponent' , query: { goto: this.continueUrl }}) 
            else next({ name: 'SimpleLoginComponent' , query: { goto: this.gotoUrl }})
          }
    else next()
      },
  mounted () {
    this.assignShowDescription()
    setGotoUrlInClientLib(this.gotoUrl)
    if((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if(hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('iamvar_realm') !== -1) {
      let iamvar_realmCookie = getCookie('iamvar_realm')
      if(iamvar_realmCookie == 'MOBILE') {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      let iamvar_appCookie = getCookie('X-VZ-C_APP')
      if(iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }
    

    // check if /verify is present on url
    var currentUrl = window.location.pathname
    if(currentUrl.includes('/verify/')) {
      // update flow type to POST_AUTH_FLOW
      this.updateFlowType('POST_AUTH_FLOW')
      // pass challengeMethod as part of the payload
      this.initializeStepup({ challengeMethod: 'OTP' })
    } else { // no '/verify/' present
      console.log('getting otp devices on pre-auth stepup')
      console.log('If no device options will redirect to initialRoute')
      this.getOtpDevices()
    }
    if (iamvar_oneSessionPerLoginEnable !== true) {
      if(this.$route.query.continueURL) {
        this.continueUrl = this.$route.query.continueURL
      }
    } else {
      if(this.$route.query.continueUrl) {
        this.continueUrl = this.$route.query.continueUrl
      } 
    }   
    // this.getOtpDevices()
    this.updateUrlOnMount(window.location.href);
    this.displayPageTimeoutWarning(false);
      },
  methods: {
      getMsgIcon(index) {
        // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
        return "@/common/assets/styles/corp/images/"+index.type+".png";
      },
      messageImage(index) {
        if(index.type == 'error' || index.type == 'warning' || index.type == 'list') {
          return "newErrorMessageImage"
        } else {
          return index.type+"MessageImage";
        }
      },
      getCloseIcon() {
        return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
      },
      getAlertType (index) {
        //when error occurs take the user to the top screen where the orange banner is shown
        window.scrollTo(0,0);
        return index.type
      }, 
      deleteAlert(alert) {
        this.otpAlerts.splice(this.otpAlerts.indexOf(alert),1)
        this.wirelessAlerts.splice(this.wirelessAlerts.indexOf(alert),1)
      },  
      ...mapActions('cvsstepup', [
                'initializeStepup',
                'updateFlowType',
                'sendCookie',
                'updateUrlOnMount',
                'displayPageTimeoutWarning'
            ]
        ),
      ...mapActions('login', ['redirectToOpenam']),
      ...mapWaitingActions('cvsstepup', {
        getOtpDevices: 'request_to_complete'
      }),
      assignShowDescription() {
        if(this.flowType == 'forgot_username' || this.flowType == 'forgot_password' || this.flowType == 'auth') {
          this.showDescription = false;
        }
      }
  },
  watch: {
    otpSent: function(newVal) {
      if(newVal) {
        console.log('Otp has been sent')
        console.log('goto validate component')
      }
    },
    fingerprintSaved: function(newVal) {
      if(newVal) {
        if(this.flowType === 'auth') {
          this.redirectToOpenam()
        } else if(this.currentStep === 'OTP_VALIDATED' && this.continueUrl !== null) {
          // window.location.href = this.continueUrl
          
          // append needed query parameter if continuing to profile flow
          let sanitizedUrl = this.sanitizeUrl(this.continueUrl);
          if(this.continueUrl.includes('/profile/postauth')) {
            // split the whole url after the question mark; if something is present after the question mark, parameters have been found
            // append query parameter as & if parameters are found, or append it with a '?' otherwise
            let qParameterCheck = this.continueUrl.split('?')

            if(qParameterCheck[1] !== '') { // check split after '?'
              // append new parameter using '&'
              window.location.href = sanitizedUrl != null ? this.continueUrl : this.sanitizeUrl(this.urlOnMount) + '&stepupVerification=true'
            } else {
              // append new parameter using '?'
              window.location.href = sanitizedUrl != null ? this.continueUrl : this.sanitizeUrl(this.urlOnMount) + '?stepupVerification=true'
            }
          }
          else 
            window.location.href = sanitizedUrl != null ? this.continueUrl : this.sanitizeUrl(this.urlOnMount);

        }
      }
    },
    vzigInstanceCreated: function(newVal) {
      if(newVal != null && this.flowType != 'forgot_password') {
        console.log('calling getotpdevices from watch')
        this.getOtpDevices()
      }
    },
    currentStep: function(newVal) {
      if(newVal === 'TOKEN_EXPIRED') {
        // window.location.href = this.urlOnMount
      }
    },
    hideOtpValidateComponent: function(newVal) {
      this.hideValidateComponent = newVal
    }
  },
  computed: {
      ...mapGetters('cvsstepup', [
      'fingerprintSaved',
      'sqaEnabled',
      'submitLoader',
      'otpPageMessages',
      'currentStep',
      'flowType',
      'urlOnMount',
      'hideOtpValidateComponent',
      'ffSQAEnabled',
      'selectedOtpDevice'
    ]),
    ...mapGetters('login', [ 'gotoUrl' ]),
    otpSent: {
      get: function () {
        return this.$store.getters['cvsstepup/otpSent']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateOtpSent', newVal)
      }
    },
    vzigInstanceCreated: {
      get: function() {
        return this.$store.getters['cvsstepup/vzigInstance']
      }
    },
    otpAlerts: {
      get: function() {
        return this.$store.getters['cvsstepup/otpAlerts']
      },
      set: function(newVal) {
        this.$store.commit('cvsstepup/setOTPAlert', newVal)
      }
    },
    wirelessAlerts: {
      get: function() {
        return this.$store.getters['cvsstepup/wirelessAlerts']
      },
      set: function(newVal) {
        this.$store.commit('cvsstepup/setWirelessAlerts', newVal)
      }
    },
    continueUrl: {
      get: function() {
        return this.$store.getters['cvsstepup/continueUrl']
      },
      set: function(newVal) {
        this.$store.commit('cvsstepup/setContinueUrl', newVal)
      }
    }
  }
}
</script>
<style scoped>
/*h1 {
    margin: 4.6rem 0 2.50rem !important;
    font-size: 32px !important;
    font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", "Arial", "Helvetica", "sans-serif !important"
}*/

.div {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}
.alert-warning {
	background-color: #ffbc3d;
	background-size: 35px 29px;
	color: #000;
	border-color: #ffbc3d;
}
.alert-info {
	background-color: #08c;
	background-size: 35px 29px;
	color: #fff;
	border-color: #08c;
}

.close {
    float: right !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
    opacity: .2 !important;
    margin-top: -2px !important;
}
.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.vz-input-field:focus {
  border: 1px solid black !important;
}
.messageContentUn {
  color: #000000;
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  line-height: 20px;
}
.stepup-container {
  min-height: 95vh;
}
.vbg-ff-main-container {
  padding: 40px 20px !important;
}
.closeMessageImg {
  height: 13.33px;
  width: 13.33px;
}
@media (max-width: 767px) {
  .stepup-container {
    min-height: 85vh;
  }
}
@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
  .closeMessageImg {
    position: static !important;
    margin-left: 4px;
  }
}
@media (max-width: 1024px) {
  .col-xs-12.col-md-6 {
    max-width: 100% !important;
    width: 100% !important;
  }
  .md-none {
    display: none !important;
  }
  .stepup-container {
    padding-top: 32px !important;
  }
  h1 {
    font-size: 24px !important;
  }
  .vbg-ff-main-container {
    padding: 32px 16px !important;
  }
}
@media (min-width: 1025px) {
  .stepup-container {
    padding-top: 40px !important;
  }
}
@media (max-width: 425px) {
  .messageContent, .messageContentUn {
    width: 232px;
  }
  .messagesInfo {
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
  }
}
@media (min-width: 1272px) {
  .vbg-container-fluid {
    max-width: 1272px !important;
    margin: 0 auto;
    padding: 0 20px;
  }
}
</style>
