<template>
  <div class="createpwd_container">
    <div class="vbg-rsp-main-container">
      <BlockUI v-if="submitLoader" :url="url"></BlockUI>
      <div class="vbg-rsp-title-container">
        <h1 class="vbg-rsp-title-h1">{{ $t("message.sqa_page_title") }}</h1>
      </div>
      <div>
        <p class="vbg-rsp-des">
          {{ $t("message.sqa_page_des")}}
        </p>
      </div>
      <div class="vbg-rsp-form-container">
        <form @submit.prevent="onSubmit()"> 
          <label class="vbg-ff-input-label" style="line-height: 16px;" v-if="challengeQuestionText == 'Select'">Secret question</label>
          <label class="vbg-ff-input-label" style="line-height: 16px;" v-else >Your secret question</label>
          <div ref="dropdownRef"
            class="row dropdown dropdown-toggle" 
            id="drpdn" v-bind:class="{ open: showDropdown }" 
            aria-haspopup="true" aria-expanded="false" 
            @click="toggleDropdown()" 
            @keyup.enter="toggleDropdown()" 
            tabindex="1"
          >
            <div class="text-flex1">
              <a id="dropdownMenu1" aria-haspopup="true" role="button"> 
                <span>{{challengeQuestionText}}</span>
              </a>
            </div>
            <img class="vbg-rsp-dropdown-cheveron" :src="downCaret" />
            <ul class="dropdown-menu col-xs-12 px-0" aria-labelledby="dropdownMenu1"> 
              <li 
                v-for="(question, index) in getVcgSecretQuestions" 
                :key="question.id" role="presentation" :tabindex="index+1" 
                v-bind:class="{ chosen: (question.question === challengeQuestionText), 'last-item': (index === getVcgSecretQuestions.length - 1)}"
                @keyup.enter="questionSelected(question, 'enter')"
                @keyup="listkeyupHandler($event, index)"
                @click="questionSelected(question, 'click')"
                @keydown.tab="handleTabKey"
                ref="userSelectQuestion"
              >
                <a role="menuitem" @click="selectQuestion(question)">
                  <span>
                    <span class="sqaText">{{question.question}}</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="vbg-ff-input-container"> 
            <label class="vbg-ff-input-label padleft0 ">Answer</label>
            <div class="vbg-icon-input-group"> 
              <input 
                class="vbg-rsp-input-field"    
                :class="(!isValidText && userAnswer) ? 'vbg-rsp-input-warning' : ''"        
                v-model="userAnswer" 
                maxlength="40"
                @input="getbuttondisable"
                ref="submitButtonRef"
                :tabindex="getVcgSecretQuestions.length+1"
                :type="isShow ? 'text' : 'password'" 
                id="rsp-sqa-input"
                name="rsp-sqa-input"
                :disabled="!challengeQuestion"
              />
              <div class="vbg-show-icon">
                <span v-if="!isShow && userAnswer" class="vbg-rsp-link" @click="isShow = !isShow">Show</span>
                <span v-if="isShow && userAnswer" class="vbg-rsp-link" @click="isShow = !isShow">Hide</span>
              </div>
              <div class="vbg-rsp-warning-icon" style="right: 62px; top: 11px;" v-if="!isValidText && userAnswer">
                <img :src="warningIcon" />
              </div>
            </div>
            <div v-if="!isValidText && userAnswer">
              <p class="vbg-rsp-warning-msg" style=" margin-top: 8px; height: auto; " v-html="getWarningMessage" />
            </div>
          </div>
          <!-- <div class="subtext"> 
              {{ $t("message.sqa_page_subtext")}}
          </div> -->
      <div class="input_section" v-if="getLineOfBusiness == 2 || getLineOfBusiness == 5">
          <label class="Createpwd_input_label"
            >Mobile Telephone Number(Optional)</label
          >
          <div>
            <input
              type="text"
              id="text"
              name="text"
              v-model="text"
              ref="text"
              @input="acceptMobileNumber('text')"
              class="createpwd_input"
            />
          </div>
        </div> 
          <div class="vbg-sp-btn-continue"> 
            <input
            :disabled="disableButton"
            :tabindex="getVcgSecretQuestions.length+4"
            class="btn vbg-ff-btn-continue vbg-ff-btn-width140 spacing05"
            :class="disableButton ? 'vbg-ff-disabled' : ''"
            ref="continueButton"
            type="submit"
            id="rsp-sqa-continue-btn"
            name="rsp-sqa-continue-btn"
            value="Continue"
            />
          </div>
        </form>
      </div>

    </div>

  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import downCaret_blk from "@/common/assets/styles/corp/images/down-caret_blk.png";
import warningIcon from '@/common/assets/styles/corp/images/warning.png'
import popupComponent from "@/common/components/TermsPopupComponent"
import commonMixin from "@/common/mixin/commonMixins";


export default {
  name: "Createsqa",
  components: { popupComponent },
  mixins: [commonMixin],
  mounted() {
    this.changeContextBiocatch('rsp_choose_sqa_page')
    this.updateVcgSecretQuestions()
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      var iamvar_appCookie = getCookie('X-VZ-C_APP')
      if(iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }
    // Rendering the component based on uuID . So it will prevent the route to render in other tab without ID without id.
    if(this.uuID === "") {
      this.showContent = false;
    }
    this.$refs.dropdownRef.focus()
    document.addEventListener('click', (e) => {
      if(this.showDropdown && e.target.closest("#drpdn") === null) {
        if(iamvar_appCookie == 'MVB') {
            this.showDropdown = true;
          }
          else {
            this.showDropdown=false
          }
      }
    })
    
  },
  data() {
    return {
      status: false,
      whichRealm: true,
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      challengeQuestion: "",
      challengeQuestionText: "Select",
      showDropdown: false,
      userAnswer: '',
      invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/,
      isShow: false,
      // disableButton: true,
      showContent: true,
      downCaret: downCaret_blk,
      warningIcon: warningIcon,
      isValidText: false,
      mobileNumber: "",
      secretQuestion: "",
      text: ""
    };
  },

  methods: {
    ...mapActions("profile", ["displayLoginIntercept", "saveSQA", "updateVcgSecretQuestions","updateVcgSecurityProfile", "updateChallengeQuestion","updateSecretAnswer"]),
    ...mapActions("login", [ "updateUnlockMyAccountAlerts", "updateVcgProfileView", "updateVcgMtn" ]),
    onSubmit() {
      let lineOfBusiness = this.getLineOfBusiness;
      let payload = {
        requestType: "INCOMPLETE_PROFILE",
        sqaID: this.challengeQuestion,
        sqaPwd: this.userAnswer,
        mtn: this.text.replace(/-/g, ""),
      };
      this.updateChallengeQuestion(this.challengeQuestion)
      this.updateSecretAnswer(this.userAnswer)
      if((lineOfBusiness == 2 || lineOfBusiness == 5 ) && this.text !==""){
        this.updateVcgProfileView('resetCsqaRequestPin')
        this.updateVcgMtn(this.text.replace(/-/g, ""))
        this.$router.push({ name: "VcgProfileUpdateRootComponent" });
      } else {
        eventTracking('Set SQA Initited','');
        this.updateVcgSecurityProfile(payload);
      }
    },
    
    selectQuestion(selQuestion) {
      if (selQuestion != null) {
        this.challengeQuestion = selQuestion.id;
        this.challengeQuestionText = selQuestion.question;
      }
    },
    listkeyupHandler(e, index) {
      // logic to move up and down in device list with up & down arrow
      const questionList = this.$refs.userSelectQuestion;
      switch(e.key) {
        case 'ArrowUp':
          if (index >= 1) {
            console.log(index)
            questionList[index-1].focus();
          } 
          if (index < 1) {
            questionList[questionList.length-1].focus();
          }
        break;
        case 'ArrowDown':
          if (index < questionList.length - 1) {
            questionList[index+1].focus();
          } else {
            questionList[0].focus();
          }
        break;
        default:
        break;
      }      
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    messageImage(index) {
      if(index.type == 'error' || index.type == 'warning' || index.type == 'list') {
        return "newErrorMessageImage"
      } else {
        return index.type+"MessageImage";
      }
    },
    questionSelected( selectedQuestion, selectionType ) {
      if(selectionType === 'enter') {
        // move focus to button if user 
        // click enter after selecting an option from dropdown

        this.challengeQuestion = selectedQuestion.id;
        this.challengeQuestionText = selectedQuestion.question
        this.$nextTick(() => this.$refs.submitButtonRef.focus())
      } else {
        // dont move focus to button if user select from mouse
        this.$refs.dropdownRef.focus()
      }
    },
    handleTabKey(event) {
      const dropdownList = this.$refs.userSelectQuestion;
      
      //Checking whether the pressed key is tab and the focused element is the last element to close the dropdown
      if(event.key === 'Tab' && document.activeElement.classList.contains('last-item')) {
        this.toggleDropdown()
      }
    },
    tcModal() {
      $("#termModal").modal({
          backdrop: "static",
        });
         $('#tc-modal').prop('checked', false);
    },
    enable(status) {
      if (status ) {
        this.status = true;
      } else {
        this.status = false;
      }
    },
    toggleCheckBox( selectiontype ) {
      if(selectiontype === 'enter') {
        this.$nextTick(() => this.$refs.continueButton.focus())
      } else{
        this.$refs.checkboxRef.focus()
      }
      this.status = !this.status;
      
    },
    getbuttondisable() {
      this.isValidText = false
      if(this.userAnswer.length >= 3 && !(this.invalidCharRegex.test(this.userAnswer)) && this.challengeQuestionText != "Select" ) {
        this.isValidText = true
      }
    },
    acceptMobileNumber(payload) {
      let x = this[payload]
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let phoneNum = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

      this[payload] = phoneNum;
    },
  },

  computed: {
    ...mapGetters('login',[
      'getUnifiedLoginAlerts',
      'redirectToSecProfilePage',
      // 'submitLoader',
      'getLineOfBusiness'
    ]),
    ...mapGetters("profile", [
      'getVcgSecretQuestions','uuID',"gotoSQA","gotoOtp","enableTC","migContent","gotoOtpOvw","firstName", "lastName","submitLoader",
    ]),
    formData: {
      get: function () {
          return this.$store.getters['login/formData'];
      },
      set: function (newVal) {
          this.$store.dispatch('login/updateFormData', newVal);
      }
    },
    // disableButton() {
    //   return (this.userAnswer.length >= 3 && !(this.invalidCharRegex.test(this.userAnswer)) && this.challengeQuestionText != "Select") ? false : true
    // },
    getWarningMessage() {
      if(this.userAnswer.length < 3){
        return 'Your answer must be 3-40 characters.'
      } else if (this.invalidCharRegex.test(this.userAnswer)) {
        return 'Only letters, numbers, spaces, and periods are allowed.'
      } else if (!this.userAnswer) {
        return 'Please enter a valid secret answer.'
      }
      return ''
    },
    disableButton() {
      //return (this.challengeAnswer.length >= 3 && !(this.invalidCharRegex.test(this.challengeAnswer)) && this.challengeQuestion != "Select" && this.enabletc ? this.status : "") ? false : true
      
      if(this.enableTC) {
        if(this.userAnswer.length >= 3 && !(this.invalidCharRegex.test(this.userAnswer)) && this.challengeQuestionText != "Select" && this.status) {
          return false
        }
      } else {
        if(this.userAnswer.length >= 3 && !(this.invalidCharRegex.test(this.userAnswer)) && this.challengeQuestionText != "Select" ) {
          return false
        }
      }

      return true
    },
    // isQuestionSelected() {
    //   return this.challengeQuestion;
    // },
  },

  watch: {
    isShow: function(newVal) {
      if(newVal) {
        setTimeout(()=> {
            this.isShow = false
        },10000)
      }
    },
    gotoSQA: function (newVal) {
      if (newVal) {
        this.$router.push({ path: 'setsqa' })
      }
    },
    gotoOtp: function (newVal) {
      if (newVal) {
        this.$router.push({ path: 'addotpdevice' })
      }
    },
    gotoOtpOvw: function (newVal) {
      if (newVal) {
        this.updateGotoComponent('resetSecurityProfileOTPSummary');
      }
    },
  }
}
</script>

<style scoped>
#dropdownMenu1 span {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
}
.messageContentUn {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.dropdown {
  width: 596px !important;
  min-height: 44px !important;
  padding: 7px 14.6px 7px 12px !important;
  height: 44px !important;
  font-size: 16px;
}
.dropdown-arrow {
  /* background-size: 14px;
  background-position-x: 95%;
  background-position-y: 8px;
  width: 40px;
  height: 22px;
  padding: 0px;
  text-decoration: none;
  border-bottom: none; */
  position: absolute; 
  right: 0px;
  bottom: 11px;
  width: 32.33px;
}
.dropdown-menu {
  box-shadow: none !important;
}
.dropdown-menu li {
  border-bottom: 1px solid #CDCCCB;
}
.sqaText {
  white-space: normal;
}
.vbg-ff-input-container{
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  display: flex;
  flex-direction: column;
}
.vbg-icon-input-group{
  width: 596px !important;
  position: relative;
}
.vbg-show-icon {
  height: 20px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 12px;
  /* text-decoration: underline; */
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 16px !important;
}
.vbg-rsp-input-field {
  width: 596px !important;
}
.subtext {
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 12px !important;
  color: #000;
  font-family: 'VzNHGeTX-reg11' !important;
}
.dropdown-menu li.chosen {
  border-left: 4px solid #D52B1E;
}
.text-flex1 { 
  flex: 1;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.dropdown-menu > li > a {
  color: #000 !important;
  text-decoration: none !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
}
.vbg-rsp-dropdown-cheveron {
  margin-right: 0px !important;
}
.check_box_border[type="checkbox"]:checked{
  accent-color: #000 !important;
}
.modal-dialog {
  margin: 0px !important;
  max-width: 739px;
  width: 100% !important;
  height: -webkit-fill-available !important;
}
.close-icon-container {
  padding: 0px;
  position: absolute;
  right: 34px;
  top: 34px;
  height: 20px;
  z-index: 1;
}
.modal-content {
  height: 100% !important;
  overflow: scroll !important;
  width: 100% !important;
  padding: 0px !important;
}
.btn-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.5);
  padding: 16px 16px 48px 40px !important;
}
input[type="checkbox"] { 
  position: relative;
  left: 0em;
  height: 20px;
  width: 20px;
}
input[type="checkbox"] + label {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-family: VzNHGeDS-reg11;
  margin-bottom: 0px;
}
.modal-open .modal {
  display: flex !important;
  align-items: end;
  justify-content: end;
  padding: 0px !important;
}
#ch {
  margin: 0px 10px 0px 0px !important;
}
.vbg-rsp-des {
  max-width: 371px;
  line-height: 16px;
  color: #000000 !important;
}
.vbg-ff-btn-width140 {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}
.vbg-rsp-warning-msg {
  font-family: 'VzNHGeTX-reg11' !important;
}
@media (max-width: 1024px) { 
  .vbg-icon-input-group{
    width: 100% !important;
  }
  .dropdown {
    width: 100% !important;
  }
  .vbg-rsp-input-field {
    width: 100% !important;
  }
  .dropdown {
    min-height: 44px !important;
    height: auto !important;
  }
  .dropdown-menu li.chosen > a {
    color: #000 !important;
    padding-left: 12px !important;
  }
  .dropdown-menu li.chosen {
    border-left: none !important;
  }
  .dropdown-menu > li > a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    margin-left: 0px;
    padding-left: 12px !important;
  }
}
@media (max-width: 768px) { 
  .modal-content { padding: 0px !important; }
  .close-icon-container { 
    right: 14px;
    top: 62px;
    z-index: 1;
  }
  .btn-block {
    display: flex;
    /* -ms-flex-direction: column-reverse; */
    flex-direction: column-reverse;
    padding: 16px 16px 48px !important;
  }
}
@media (max-width: 425px) {
  .messageContent,
  .messageContentUn {
    width: 232px;
  }
}
@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
}
@media (min-width: 280px) {
  .closeMessageImg {
    position: static !important;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.row.dropdown.dropdown-toggle {
  -ms-flex-align: center;
  align-items: center;
}
.input_section {
  margin-bottom: 16px;
  position: relative;
}
.Createpwd_input_label {
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  font-family: "VzNHGeTX-reg11";
}
.createpwd_input {
  padding: 12px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  width: 100%;
  border: 1px solid #cccccc;
  border-bottom: 1px solid #000;
  color: #000000;
}
.createpwd_input:focus {
  border: 1px solid #000;
}
.show_hide {
  position: absolute;
  top: 38px;
  right: 12px;
  border-bottom: 1px solid #000000;
}
.show_hide:focus {
  outline: 1px solid #000000;
}
.createpwd_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  /* width: 50%; */
}
@media only screen and (max-width: 1023px) {
  .createpwd_container {
    width: 100%;
  }
}
</style>