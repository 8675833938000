<template>
    <div>
        <vpsHeader></vpsHeader>
        <div class="suspend-error-container">
            <div>
              <img :src="require('@/common/assets/styles/corp/images/fail_M@3x.png')" />
            </div>
            <h2 class="err-head-pos">Your Registration attempt is unsuccessful</h2>
            <h2>There was an issue with the link.</h2>
            <h2>Please contact your Administrator and have them resend a new link.</h2>
        </div>
        <vpsFooter></vpsFooter>
    </div>
</template>

<script>
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
export default {
    name: "VPSRegistrationUnSuccessful",
    components: {
        vpsHeader,
        vpsFooter
    },
    data() {
        return {
            
        };
    },
    mounted() {

    },
};
</script>
<style scoped>
.suspend-error-container {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 280px;
    word-break: break-word;
}

.err-head-pos {
    margin-top: 20px;
    margin-bottom: 30px;
}
</style>