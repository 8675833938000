<style scoped>
.return_div {
    padding-left: 18px;
}

.return_caret {
    padding-right: 6px;
}

.resend-field-link {
    width: auto;
    height: 20px;
    font-size: 16px;
    margin-top: 16px;
}

.return_to_signIn {
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    font-feature-settings: 'clig' off, 'liga' off;
    /* [VDS] DeskTab/🖥 Body LG Bold */
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.5px;
}

.return_to_signIn a:focus {
    color: #000;
}
.modal-dialog {
  width: 560px;
  height: 308px;
  margin: 0 auto !important;
  position: relative;
  top: 33%;
}
.resendemail_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px !important;
}
.resendemail_main_heading{
    text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    font-size: 48px;
    /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
    font-family: 'VzNHGeDS-bold11';
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
}
.resendemail_sub_heading{
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 16px;
    /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
    font-family: 'VzNHGeDS-reg11';
}
.resendemail_mail_id{
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 32px;
    /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
    font-family: 'VzNHGeDS-bold11';
}
.resendemail_recieve_link{
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif; */
    font-family: 'VzNHGeTX-reg11';
    margin-bottom: 16px;
}
.resendemail_resend_link{
    text-align: center;
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    font-size: 16px;
    /* font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif; */
    font-family: 'VzNHGeTX-reg11';
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
}
.resendemail_troubleshoot_link{
    text-align: center;
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    font-size: 16px;
    /* font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif; */
    font-family: 'VzNHGeTX-reg11';
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 16px;
}
.resendemail_help_link, .resendemail_help_link:hover {
    text-decoration: underline;
}
.resendemail_help_link:focus{
    color: #000;
}
.popup_heading{
    font-family: 'VzNHGeDS-bold11';
    font-size: 24px;
    margin-bottom: 40px;
    text-align: center
}
.popup_call_btn{
    width: 200px !important;
    text-align: center !important;
    font-family: 'VzNHGeDS-bold11' !important;
    padding: 12px 24px !important;
    margin-left: 12px !important;
    font-size: 16px !important;
    letter-spacing: 0.5px !important;
    border: 2px solid #000 !important;
    background-color: transparent !important;
    color: #000 !important;
}
.popup_email_btn{
    width: 200px !important;
    text-align: center !important;
    padding: 12px 24px !important;
    font-family: 'VzNHGeDS-bold11' !important;
    letter-spacing: 0.5px !important;
    font-size: 16px !important;
}
.popup_btns{
    text-align: center;
}
.popup_box{
    width: 560px !important;
    height: auto !important;
    border-radius: 8px !important;
}
@media screen and (max-width: 600px){
    .popup_box{
        margin: 16px !important;
    }
    .popup_call_btn{
        margin-left: 0px !important;
        margin-top: 12px !important;
    }
    .modal-dialog.modal-dialog-centered{
        height: auto !important
    }
    .resendemail_sub_heading, .resendemail_recieve_link, .resendemail_mail_id{
        font-size: 20px !important;
    }
}
@media screen and (max-width: 767px){
  .resendemail_parent{
    align-items: flex-start !important;
  }
  .resendemail_troubleshoot_link{
    text-align: left;
  }
  .resendemail_main_heading, .resendemail_sub_heading {
    text-align: left;
  }
  .resendemail_main_heading{
    font-size: 40px;
  }
  .resendemail_sub_heading, .resendemail_recieve_link{
    font-size: 20px;
  }
}
</style>

<template>
    <div>
    <div id="vz-gh20"></div>
    <!-- <div class="return_div" tabindex="0">
            <span class="return_caret">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Left Caret Icon">
                        <path id="Vector"
                            d="M7.83301 11.5L2.33301 6L7.83301 0.5L9.2219 1.88889L5.16634 5.94444L9.2219 10L7.83301 11.5Z"
                            fill="black" />
                    </g>
                </svg>
            </span>
            <span class="return_to_signIn">
                <a href="#" @click="redirectToSign" tabindex="0">{{ $t("message.return_to_signin_link") }}</a>
            </span>
        </div> -->
        <div class="resendemail_parent">
        <h1 class="resendemail_main_heading">{{ $t("message.email_token_main_heading") }}</h1>
        <div class="resendemail_sub_heading">{{ $t("message.email_token_sub_heading") }}</div>
        <div class="resendemail_mail_id">{{this.vsbdrMaskedMailId}}</div>
        <div class="resendemail_recieve_link">{{ $t("message.email_token_didnt_recieve_email") }}</div>
        <span class="resendemail_resend_link">
                <a href="#" @click="onSubmit" tabindex="0">{{ $t("message.email_token_resend_link") }}</a>
        </span>
        <div class="resendemail_troubleshoot_link">{{ $t("message.email_token_troubleshoot") }}</div>
        <div style="margin-bottom: 30px;" class="resend-field-link">
        <a @click="openHelpModal();focusTrap();" href="#" class="resend-field-otr resendemail_help_link">{{ $t("message.email_token_need_help_heading") }}</a>
    </div>
    <div id="needHelpModal" class="modal fade">
      <div class="modal-dialog modal-dialog-centered" role="document">
       <div class="modal-content modal-content-container popup_box">
        <div class="modal-container">
        <div class="close-icon-container">
            <a data-dismiss="modal" @click="closeModal()" @keydown.enter="closeModal()" tabindex="0" id="closeModalIcon" aria-label="modalCloseIcon">
                <img src="@/common/assets/styles/corp/images/close_black.png" style="height:20px; width: 20px;" />
            </a>
            <div>            
        </div>            
        </div>
        <h2 class="popup_heading" tabindex="0">{{ $t("message.forgot_pd_need_help_heading") }}</h2>
            <div class="popup_btns">
            <a
                class="btn primary_black popup_email_btn"
                type="submit"
                name="Email Button"
                tabindex="0"
                target="_blank"
                href='mailto:digitalready@verizon.com'
            >
                {{ $t("message.forgot_pd_email_button") }}
            </a>
            <a
                class="btn primary_black popup_call_btn"
                type="submit"
                name="Call Button"
                id="callClick"
                tabindex="0"
                href='tel:8009164351'
            >
                {{ $t("message.forgot_pd_call_button") }}
        </a>
        </div>
        </div>
        </div>
      </div>
    </div>
</div>
  <div id="vz-gf20" style = "clear:both"></div>
</div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default ({
    name: 'login',
    data() {
        return {
            selectedLanguage: this.$i18n.locale,
            supportedLanguages: [
                { code: 'en', name: 'English' },
                { code: 'es', name: 'Español' },
            ]
        }
    },
    methods: {
        ...mapActions("login", [
            "updateVsbdrError",
            "updateForgotPasswordMail"
        ]),
         getHostName (url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                return match[2];
            }
            else {
                return null;
            }
        },
        redirectToSign() {
            window.location.href = "https://"+ this.getHostName(window.location.href) + '/citizen/login/';
        },
        openHelpModal() {
            $("#needHelpModal").modal({
                backdrop: "static"
            });
        },
        focusTrap() {
          var firstTabble = $("#closeModalIcon");
          var lastTabble = $("#callClick");
          firstTabble.focus();
          lastTabble.on('keydown', function (e) {
              if ((e.which === 9 && !e.shiftkey)) {
                  e.preventDefault();
                  firstTabble.focus();
              }
          });
          firstTabble.on('keydown', function (e) {
              if ((e.which === 9 && e.shiftkey)) {
                  e.preventDefault();
                  lastTabble.focus();
              }
          });
        },
        closeModal() {
            $("#needHelpModal").modal('hide')
        },
        onSubmit() {
            const payLoad = {
                secProfileEmail: this.vsbdrMailId
            }
            this.updateForgotPasswordMail(payLoad);
        }
    },
    computed: {
        ...mapGetters('login', [
            "vsbdrMailId",
            "vsbdrMaskedMailId"
        ]),
     },
    watch: {
  }, 
  created: function () {
    const searchParams = new URLSearchParams(window.location.search);
    const storedLanguage = localStorage.getItem('lng');
    const browserLanguage = navigator.language.substring(0, 2);;
    if (storedLanguage) {
        this.$i18n.locale = storedLanguage;
        this.selectedLanguage = storedLanguage;
    } else if (this.supportedLanguages.some(lang => lang.code === browserLanguage)) {
        this.$i18n.locale = browserLanguage;
        this.selectedLanguage = browserLanguage;
    } 
  },
  mounted () {
    document.documentElement.lang = this.$i18n.locale;
     (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window, document, 'script', 'dataLayer', 'GTM-KJFSV78');
    if(document.getElementById('closeModalIcon')){
      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          this.closeModal();
        }
      })
    }
    setTimeout(() => {
        const langSwitchLink = document.querySelector('.gnav20-lang-link');
         if(langSwitchLink) {
          document.addEventListener('click', (e)=> {
          const langValue = e.target.getAttribute('data-lang');     
          if(langValue) {
            if(langValue === 'English' || langValue === 'Inglés') {
                localStorage.setItem("lng","en");
            } else {
                localStorage.setItem("lng","es");
            }
          }
          })
          var locale = this.$i18n.locale;
          const variationValue = locale == 'es' ? 'prospect-es' : 'prospect';
          var globalNavdl = {
            "bu": "corporate",
            "appid": "vsbdr",
            "variation": variationValue,
            "options":{"hide-item":["search"]}
          }
          window.gnavdl = globalNavdl;
         }
       }, 1500);
       var globalNav = document.createElement('script')
       globalNav.setAttribute('src', 'https://www.verizon.com/etc/designs/vzwcom/gnav20/corporate.js')
       globalNav.setAttribute('async', 'async');
       document.head.appendChild(globalNav);
  }
})
</script>
