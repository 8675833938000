<template> 
    <div style="overflow: hidden; font-family:'VzNHGeDS-reg11'">
      <div id="vz-gh20"></div>
      <div>
        <VPSPwdLessLogin></VPSPwdLessLogin>
      </div>
      <div id="vz-gf20"></div>
    </div>
    </template>
    
    <script>
    import $ from "jquery";
    import { mapActions, mapGetters } from 'vuex';
    import {setPageDetails} from '../../../../../public/adobeTagging';
    import ClearWhite from "@/common/assets/styles/corp/images/clear_white.png";
    import VPSPwdLessLogin from "./VPSPwdLessLogin";
    import commonMixin from '@/common/mixin/commonMixins';
    
    export default {
      name: "VPSPwdLessLoginRootComponent",
      components: { VPSPwdLessLogin },
      mixins: [commonMixin],
      data() {
        return {
          meth: "pass",
          showClickHereMsg: false,
          idmRedirect: '',
          pwdLoginEnable: false
        };
      },
      computed:{
        ...mapGetters('login',[
            "getUnifiedLoginAlerts","getUnifiedErrorCode"
        ]),
        
      },
      watch : {
        
      },
      async created() {
           setPageDetails('UnifiedvpsLoginMain:landing');
      },
      mounted(){
        var globalNav = document.createElement('script')
          globalNav.setAttribute('src', 'https://www.verizon.com/etc/designs/vzwcom/gnav20/smb.js')
          globalNav.setAttribute('async', 'async');
          document.head.appendChild(globalNav)

          // f.setAttribute('id',"login-form");
          // f.setAttribute('name',"loginForm");
    
          var rky = window.location.search.match(/Reg_Message=([^\&]+)/) || [0, 0];
          if (rky && rky[1]) {
            // document.getElementById("regMsgP").innerHTML = regMsg[rky[1]] || "";
            // document.getElementById("regMsgS").checked = true;
            this.updateSetUnifiedLoginAlerts(rky[1].toLowerCase())
          }
      },
      methods: {
        ...mapActions('login',[
          'setCookieForBlueBanner','UpdatePopupValue','updateSetUnifiedLoginAlerts'
        ]),
        getCloseIcon(){
          // return  (
          //   iamvar_cdnUrl +
          //   iamvar_imgSourceContext +
          //   "/business/login/includes/img/close.7e170800.svg"
          // )
          //return this.getUnifiedLoginAlerts[0].type == "success"
          // ? CloseBlack : ClearWhite;
          return ClearWhite;
        },
        bannerContainer() {
          return (this.getUnifiedLoginAlerts[0].type == 'success' || this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")) ? 'bannerMargin' : ''
        },
        deleteAlert(alert) {
          this.getUnifiedLoginAlerts.splice(this.getUnifiedLoginAlerts.indexOf(alert),1)
        },
        getMsgIcon(index) {
          // return iamvar_cdnUrl+'account/business/login/includes/img/info.png'
          return "@/common/assets/styles/corp/images/"+index.type+".png"
        },
        messageImage(index) {
          return index.type+"MessageImage"
        },
        getTypeOfAlert (index) {
              return index.type
        },
        gethpop(value){
          this.UpdatePopupValue(value);
          $("#noAccessToOtpModal").modal({
            backdrop: "static",
          });
        }
      },
      async created() {
        gnavdl = {
          "bu": "smb",
          "appid" : "vps"
        }
            setPageDetails('unifiedvpsLogin:landing');
            this.setCookieForBlueBanner()
           
      },
    };
    </script>
    <style>
    
    </style>
    