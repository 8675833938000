<template>
  <div class="new_notification_component" :class="setColorClass">
    <div class="new_notification_content">
      <div class="new_notification_text">
        <div class="new_notification_icon">
          <img :src="setIconURL" :alt="setAltName" />
        </div>
        <div>
          <!-- <div
            class="new_notification_heading"
            v-if="message_details && message_details.title"
          >
            {{ message_details.title }}
          </div>
          <div
            class="new_notification_sub_heading"
            v-if="message_details && message_details.description"
          >
            {{ message_details.description }}
          </div> -->
          <div class="new_error_msg">
            <div v-html="formattedMessage"></div>
          </div>
        </div>
      </div>
      <div
        class="new_notification_close_icon"
        @click="clearMessage()"
        v-if="showCrossIcon"
      >
        <img
          src="@/common/assets/styles/corp/images/close_black.png"
          alt="close_image"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ErrorMessage",
  props: ["message_details"],
  computed: {
    ...mapGetters("login", ["getNavHistory"]),
    showCrossIcon() {
      return (
        this.message_details.type == "success" ||
        this.message_details.type == "information"
      );
    },
    setColorClass() {
      return this.message_details.type == "success"
        ? "success_color"
        : this.message_details.type == "information"
        ? "info_color"
        : "error_color";
    },
    setIconURL() {
      return this.message_details.type == "success"
        ? require("@/common/assets/images/tick.png")
        : this.message_details.type == "information"
        ? require("@/common/assets/images/info.png")
        : require("@/common/assets/images/Notification Icon.png");
    },
    setAltName() {
      return this.message_details.type == "success"
        ? "Tick_mark_image"
        : this.message_details.type == "information"
        ? "Info_image"
        : "Error_image";
    },
    formattedMessage(){
      let normalizedMessage = this.message_details.msg.replace(/<br\s*\/?>/gi, '<br>');
      console.log(normalizedMessage, "normalzedMessage----------------------")
      if(normalizedMessage.includes('<br>')){
        const[boldText, normalText]= normalizedMessage.split('<br>');
        return `
        <b class="bold-text">${boldText}</b>
        <br>
        <span class="normal-text">
          ${normalText}
          </span>
        `
      }else{
        return `
        <span class="bold-text">
          ${normalizedMessage}
          </span>
        `
      }
    }
  },
  methods: {
    ...mapMutations("login", ["setCommonErrors"]),
    clearMessage() {
      this.setCommonErrors();
    },
  },
};
</script>
<style scoped>
.new_notification_close_icon {
  height: 13px;
  width: 10px;
  display: flex;
  cursor: pointer;
}
.normal-text{
  font-weight: 400;
  font-family: 'VzNHGeTX-reg11';
  font-size: 12px !important;
  line-height: 16px;
  color: #000000;
}
.bold-text{
  font-weight: 700;
  font-family: 'VzNHGeTX-reg11';
  font-size: 12px !important;
  line-height: 16px;
  color: #000000;

}
</style>