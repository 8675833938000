<template>
    <div id="tsd-container">
        <div style="padding: 20px 20px 20px 20px;">
            <div class="clearfix padding-top-large padding-bottom-xlarge header">
                <div class="col">
                    <h2 id="sign-in-title" class="log-in" style="height: 32px; font-size: 32px;">Log in</h2>
                    <p style=" margin-bottom: 0px; height: 24px; color: #000000; font-size: 20px; letter-spacing: 0; line-height: 24px;">to your business account</p>
                </div>  
            </div>    
        </div>
        
        <div class="login-container">
            <BlockUI v-if="submitLoader" :url="url"></BlockUI>
            
            <!-- <span class="vz-error-msg" id="errorMsgVZW" v-if="authError" style="margin-right: 50%;">{{authError.errorMessage}}</span> -->
            <div class="messagesInfo" v-bind:class="getAlertType" v-if="authError">
                <p v-if="getAlertType === 'success'" v-html="authError.errorMessage" />
                <p v-else-if="authError.errorCode === 'B360_ENROLLED_PENDING_REGISTRATION' || authError.errorCode === 'PROSPECT_ENROLLED_PENDING_REGISTRATION' || authError.errorCode === 'ACCOUNT_ALREADY_UNLOCKED' || authError.errorCode === 'ACCOUNT_UNLOCK_ALREADY_REQUESTED'" v-html="authError.errorMessage"></p>
                <p v-else> {{ authError.errorMessage }} 
                    <a v-bind:href="this.idmRedirect" v-if="this.showClickHereMsg" style="color: white; text-decoration: underline;"> click here </a> 
                </p> 
            </div>

            <div class="messagesInfo" v-bind:class="getAlertType" v-if="!authError && cvsAuthAlert">
                <p v-html="cvsAuthAlert.errorMessage"></p>
            </div>
            <br />

            <form @submit.prevent="submit" ref="loginForm">
                <div class="loginContainer">
                    <div class="user-id">
                        <label for style="font-size: 14px;">User ID</label>
                        <input class="userInput" type="text" v-model="formData[0].input[0].value"/>
                        <p class="forgot-link" @click=" FrUsername(); mybizzForget(true);">
                            Forgot user ID?
                        </p>
                    </div>
                    <div class="user-id pwd">
                        <label for style="font-size: 14px;">Password</label>
                        <input class="userInput" :type="isShow ? 'text' : 'password'" v-model="formData[1].input[0].value"/>
                        <span class="show_pwd" v-if="!isShow && formData[1].input[0].value" @click="isShow = !isShow">Show</span>
                        <span class="show_pwd" v-if="isShow && formData[1].input[0].value"  @click="isShow = !isShow">Hide</span>
                        <p class="forgot-link" @click=" FrPassword(); mybizzForget(true);">
                            Forgot password?
                        </p>
                    </div>
                    <div v-if="this.gotoPage" style="display: flex; align-items: center;">
                        <p>
                          <input class="tc" type="checkbox" id="ch" v-model="rememberMe"/>
                          <label for="ch">Remember my user ID</label>
                        </p>
                    </div>
                    <div v-if="this.rememberMeCheckbox">
                        <label :class="classes" class="vz-cutom-checkbox tc" for="privacyPolicy">
                            By continuing you agree to the
                            <a
                            name="privacyPolicy"
                            :href="this.vzwPrivacyPolice"
                            class="black-ink-link"
                            > Verizon Privacy Policy </a> 
                        </label>
                    </div>
                    <div v-if="!this.rememberMeCheckbox">
                        <p>By Continuing you agree to the</p> 
                        <a class="black-ink-link" :href="this.vzwPrivacyPolice"> Verizon Privacy Policy, </a>
                        <a class="black-ink-link" :href="this.vzwPrivacyNotice"> Verizon Privacy Notice </a>
                        <a style="text-decoration: none; cursor: default;"> and </a>
                        <a class="black-ink-link" :href="this.vzwCaliforniaPrivacyNotice"> California Privacy Notice </a> 
                        <br />
                    </div>
                    <div>
                        <button
                        class="btn primary_black" 
                        style="width: 100%; margin: 35px 0px 24px;font-size: 16px !important; font-family: 'VzNHGeDS-bold11';"
                        value="Sign In"
                        :disabled="!isFormComplete"
                        @click="submitMobileLoginForm()"
                        type="submit"
                        >
                            Log in
                        </button>
                    </div>
                    <p v-if="!this.disableprelogin">
                        <label>Need access? 
                            <a :href="registerLinkHref()" style="text-decoration:none; white-space: pre;"><b style="font-family:VzNHGeDS-bold11">Register for an account</b>&nbsp;<img
                            style="width: 10px; height: 14px"
                            :src="caret"
                        /></a></label>
                    </p>
                    <p v-if="this.rememberMeCheckbox" class="vz-login-link" style="margin-bottom: 16px">
                        <a :href="resendWelcomeEmail">Resend Welcome email</a>
                    </p>

                </div>
            </form>
            <br />
        </div>
        <div v-if="this.quickLinks" id="wrapperLinks">
            <div class="wrapper-links-first-table">
                <h3 v-if="mvbContent.quickLinksLbl" class="quick-links">{{mvbContent.quickLinksLbl}}</h3>
                <table id="show" class="tableStyle">
                    <tr v-for="item in mvbContent.quickLinks" id="show" class="quickLinks">
                        <a class="page-link" :href="item.pageLink">{{item.title}}</a>
                    </tr>
                </table>
                <p v-if="mvbContent.generalLinks" id="generalLinksClass" class="old-general-links"  @click="generalLinksClicked()">General</p>
            </div>
            <div class="wrapper-links-general-table">
                <table v-if="seen" id="hide" class="tableStyle">
                    <tr v-for="block in mvbContent.generalLinks" v-if="seen" id="hide" class="geLinks">
                        <a class="page-link" :href="block.pageLink">{{block.title}}</a>
                    </tr>
                </table>
            </div>
        </div> 
    </div>
</template>

<script>
import Header from '@/common/components/Header';
import Footer from '@/common/components/Footer';
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import commonMixin from '@/common/mixin/commonMixins';
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
import { mapGetters, mapActions } from 'vuex';
import {setPageDetails} from '../../../../public/adobeTagging';
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";



export default {
    name: 'MobileLoginComponent',
    components: { Header, Footer, InvisibleRecaptcha },
    mixins: [commonMixin],
    data() {
        return {
            caret: caret_blk,
            //url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
            //url : loadingImage,
            url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
            userId: '',
            userPassword: '',
            rememberMe: false,
            canSubmit: false,
            captchaOn: true,
            SignUpUrl:'',
            showClickHereMsg: false,
            idmRedirect: '',
            displaySignUpFalse: '',
            gotoPage: true,
            jCaptchaFlag: false,
            captchaText: null,
            realm: 'MOBILE',
            rememberMeCheckbox: true,
            seen:false,
            quickLinks: false,
            disableprelogin: false,
            vzwPrivacyPolice: 'https://www.verizon.com/about/privacy/full-privacy-policy',
            vzwPrivacyNotice: 'https://www.verizon.com/business/support/vec/onlinehelp/TermsAndConditions/GDPR-Privacy-Notice.html',
            vzwCaliforniaPrivacyNotice: 'https://www.verizon.com/about/privacy/full-privacy-policy?scrollto=index-1#acc-item-55',
            resendWelcomeEmail: iamvar_resendWelcomeEmail,
            isShow: false
        }
    },
    async created() {
        setPageDetails('Login:landing');
        this.getLoginCaptchaEnabledEnvVar();
        this.checkForCaptchaOffConditions();
    },
    mounted() {
        let msg = '{"actionType":"clearHistory","screenName":"oauthLogin"} ';
        if (window.webkit != undefined) { // iOS
            if (window.webkit.messageHandlers.mfAppInterface != undefined) {
            window.webkit.messageHandlers.mfAppInterface.postMessage(msg);
            }
        }
        if (window.mfAppInterface != undefined) { // Android
            window.mfAppInterface.postMessage(msg);
        }
        if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
            let iamvar_appCookie = getCookie('X-VZ-C_APP')
            if(iamvar_appCookie == 'MVB') {
                iamvar_mvbLaunchUrl = window.location.href;
            }
        }
        // when user lands on /mobile url then mvb team may send this cookie for savefingerprint
        if(document.cookie.indexOf('X-VZ-DFP') !== -1) {
            let saveFingerprint = getCookie('X-VZ-DFP')
            if(saveFingerprint) {
                //X-VZ-C_APP this cookie contains client id mvb or vema
                this.updateSetDeviceInfo(saveFingerprint)
            }
        }
       
        // get gotourl from mixins
        this.$store.dispatch('login/updateGotoUrl', this.getGotoUrl());
        this.$store.dispatch('login/updateEncodedGotoUrl', this.getGotoUrl());
        const hostUrl = window.location.href
        if(hostUrl.includes('/account/business/odasignin')) {
            this.gotoPage = false;
        }
        else if(hostUrl.includes('/account/business/ilogin')) {
            this.jCaptchaFlag = true;
        }
        else if (hostUrl.includes('/account/business/login/mobile')) {
            if(hostUrl.includes('disableprelogin=true') || hostUrl.includes('disableprelogin%3Dtrue')) {
                this.disableprelogin = true;
                this.quickLinks = false;
            }
            else if(hostUrl.includes('client_id=mvb') || hostUrl.includes('client_id%3Dmvb')) {
                this.quickLinks = true;
                this.getQuickLinksForMobile();
                this.updateUserAgent('MVB');
            }
            else if(hostUrl.includes('client_id=vema') || hostUrl.includes('client_id%3Dvema')) {
                this.rememberMeCheckbox = false;
                this.quickLinks = true;
                this.getQuickLinksForVemaMobile();
                this.updateUserAgent('VEMA');
            }
        }
        //this.getQuickLinksForMobile();
        this.removeExistingVBGCCookie();
        this.getSessionInfo();
        this.updateUrlOnMount(window.location.href);
        this.resetState();  
        

        // call rememberMe function only if uid query parameter is not present
        // let params = new URLSearchParams(window.location.search);
        if(!this.getParameterByName('uid')) {
            //this.findPushUserIdCookie();
            this.findRememberMeCookie();
        }
        
        this.processQueryParameters();
        // console.log('idm url in tsd ', iamvar_idmUrl);             
        if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='mdp_agent'){
            this.SignUpUrl=iamvar_mdpSignUpUrl         
        }
        else if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='tsd_agent'){
            this.SignUpUrl=iamvar_thingSpaceSignUpUrl
        }
        else if(this.getParameter('appGroup')==='TSM' || this.getParameter('client_id')==='tss_agent_gz'){
             this.SignUpUrl=iamvar_ccSignUpUrl
        }
        else{
            this.displaySignUpFalse = 'vz-error'
        }
        if(this.getAlertType == 'success' || this.cvsAuthAlert) {
            this.updateRemoveMessage(true)
        }
        window.localStorage.setItem("InitialRoute", window.location.href);
    },
    beforeDestroy(){
        this.formData[0].input[0].value = "";
        this.formData[1].input[0].value = "";
  },
    methods: {
        generalLinksClicked() {
            this.seen = !this.seen;
            if (this.seen) {
                document.getElementById('generalLinksClass').className = 'new-general-links';
            }
            else {
                document.getElementById('generalLinksClass').className = 'old-general-links';
            }
        },
        ...mapActions('login', [
                'submitAMMobileLoginForm',
                'callRememberMe',
                'removeExistingVBGCCookie',
                'getSessionInfo',
                'updateUrlOnMount',
                'resetState',
                'updateCaptchaToken',
                'updateFederalPortalIndicator',
                'findRememberMeCookie',
                'findPushUserIdCookie',
                'getQuickLinksForMobile',
                'updateUserAgent',
                'getQuickLinksForVemaMobile',
                'mybizzForget'
            ]
        ),
        ...mapActions('cvsstepup', [ 
            'initializeStepup',
            'updateUserIdFlow',
            'updateCvsAuthAlert',
            'updateSetDeviceInfo',
            'updateRemoveMessage'
            //'updateRealmForStepup'
         ]),
        submitMobileLoginForm: function (recaptchaToken) {
            this.updateCvsAuthAlert(null)
            let payload = {
                formData: this.formData,
                rememberUser: this.rememberMe,
                captchaAuth: this.captchaText,
                additionalAuth:'VBG_LOGIN'
            };
            //this.updateRealmForStepup(this.realm);
            iamvar_realm = this.realm;
            // update state with new captcha token
            if(this.captchaOn)
                this.updateCaptchaToken(recaptchaToken);

            this.submitAMMobileLoginForm(payload);
            
            // might not be needed since submit button is disabled when form is empty
            /*if(this.$refs.loginForm.validate()) {
                this.submitAMLoginForm(payload);
            }*/

            // TODO: implement valid/invalid logic. For right now, redirect to OTP regardless of result
            // this.$router.push({ path: '/vbg-tsd-login/otp' });
        },
        registerLinkHref() {
            const hostUrl = window.location.href
            if(hostUrl.includes('client_id=mvb') || hostUrl.includes('client_id%3Dmvb')) {
                return "mvb://mvbmobile/registration/spoc/mbt/spocreg.register";
            }
            else if(hostUrl.includes('client_id=vema') || hostUrl.includes('client_id%3Dvema')) {
                return iamvar_vemaRegistrationLink;
            }    
        },
        getParameter(url) {
            let decodeUrl=decodeURIComponent(document.location)
            url = url.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + url + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(decodeUrl);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        processQueryParameters() {
           // let params = new URLSearchParams(window.location.search);

            if(this.getParameterByName('uid')) {
                this.formData[0].input[0].value = this.getParameterByName('uid');
            }
            if(this.getParameterByName('rememberMe')) {
                if(this.getParameterByName('rememberMe') == 'true')
                    this.rememberMe = true;
            }
        },
        getLoginCaptchaEnabledEnvVar() {
            console.log('(Env based) captcha is on = ', iamvar_enableLoginCaptcha);
            this.captchaOn = iamvar_enableLoginCaptcha;
        },
        checkForCaptchaOffConditions() {
            // turn off captcha when instance is related to a federal portal
            let gotoParam = this.getParameterByName('goto');

            // if goto has 'oauth', get redirect_uri and check if redirect_uri has any of the federalPortalKeywords
            if(gotoParam != null && gotoParam.includes('oauth')) {

                // decode goto in order to easily access redirect_uri parameter
                let decodedGoto = decodeURIComponent(gotoParam);
                // let redirectUriParam = this.getParameterByName('redirect_uri');
                let decodedParameters = new URLSearchParams(decodedGoto);
                let redirectUriParam = decodedParameters.get('redirect_uri');

                if(iamvar_federalPortalKeyWords.some(str => redirectUriParam.includes(str))) {
                    this.captchaOn = false;
                    this.updateFederalPortalIndicator(true);
                }
            } else { // check if goto has any of the federalPortalKeywords
                //if(iamvar_federalPortalKeyWords.some(str => gotoParam.includes(str))) {
                //    this.captchaOn = false;
                //    this.updateFederalPortalIndicator(true);
                //}
            }
        },
        addRedirectUrlToErrorBanner() {
            /* if the current authError (banner showed on screen) is for the user being redirected to IDM 
               (errorCode = REDIRECT_TO_IDM_REQUIRED) we need to add text that says "click here", 
               with an href of the IDM url
            */

           // TODO: confirm if 'redirected' is a good key word to identify this error message
           let errorMsg = this.$store.getters['login/authError'];
           if(errorMsg.errorMessage.includes('redirected')) {
               this.showClickHereMsg = true;
           }

        },
        getHostName(url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                return match[2];
            }
            else {
                return null;
            }
        },
        getCaptchaImgUrl() {
            let captchaImgHref = this.getHostName(window.location.href);
            return "https://" + captchaImgHref + "/ifgservices/vbg/captcha";
        },
        refreshCaptchaImgUrl() {
            let captchaImgHref = this.getHostName(window.location.href);
            document.getElementById("captchaImg").src = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha?cb=" + Math.random());
        },
        FrUsername() {
            this.$router.push({ name: 'AMForgotUsrnmComponent' });
            this.updateUserIdFlow('BASIC')
        },
        FrPassword() {
            this.$router.push({ name: 'AMForgotPwdComponent' });
        }
    },
    computed: {
        ...mapGetters('login', [
            'authError',
            'submitLoader',
            'iamvar_enableCaptcha',
            'redirectToStepupPage',
            'redirectToSecProfilePage',
            'iamvar_idmUrl',
            'loginCaptchaKey',
            'getAlertType',
            'idmRedirectUrl',
            'rememberMeTrue',
            'remembermeUserName'
        ]),
        ...mapGetters('cvsstepup', [ 'currentStep', 'cvsAuthAlert' ]),
        ...mapGetters('forgotflow',[ "usrName" ]),
        isFormComplete() {
            let captchaComplete;
            if(this.jCaptchaFlag) {
                if (this.captchaText != null) {
                    captchaComplete = true;
                }
                else {
                    captchaComplete = false;
                }
            }
            else {
                captchaComplete = true;
            }
            // return this.userId && this.userPassword;
            return this.formData[0].input[0].value.length !== 0 &&
                   this.formData[1].input[0].value.length !== 0 &&
                   captchaComplete;
        },
        formData: {
            get: function () {
                return this.$store.getters['login/formData'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateFormData', newVal);
            }
        },
        mvbContent: {
            get: function () {
                return this.$store.getters['login/mvbContent'];
            }
        },
        gotoUrl: {
            get: function () {
                return this.$store.getters['login/gotoUrl'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateGotoUrl', newVal);
                this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
            }
        },
    },
    watch: {
        redirectToStepupPage: function(newVal) {
            if(newVal) {
                this.initializeStepup();
            }
        },
        redirectToSecProfilePage: function(newVal) {
            if(newVal) {
                window.location.href = newVal;
            }
        },
        rememberMeTrue: function(newVal) {
            if(newVal == 'setToTrue') {
                this.rememberMe = true
            }
        },
        currentStep: function(newVal) {
            if(newVal === 'FLOW_INITIALIZED') {
                if(this.gotoUrl != null) {
                    this.$router.push({ name: 'FFStepupRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
                } else {
                    this.$router.push({ name: 'FFStepupRootComponent' });
                }
            }
        },
        idmRedirectUrl: function(newVal) {
            if(newVal)
                this.idmRedirect = iamvar_mvbLaunchUrl;
        },
        authError: function(newVal) {
            if(newVal) {
                this.addRedirectUrlToErrorBanner();
                window.scrollTo(0,0);
                if(this.jCaptchaFlag) {
                    this.refreshCaptchaImgUrl();
                    this.captchaText = null;
                }
            }
        },
        isShow: function(newVal) {
            if(newVal) {
                setTimeout(()=> {
                    this.isShow = false
                },10000)
            }
        },
        usrName: function(newVal) {
            if(newVal != undefined || newVal != null) {
                console.log("username=",newVal)
                this.formData[0].input[0].value = newVal
            }
        },
        remembermeUserName: function(newval) {
          if(newval != "" || newval != null) {
            console.log("remembermeUserName=", newval)
            this.formData[0].input[0].value = newval
          }
        },
        cvsAuthAlert: function(newVal) {
            if(this.getAlertType == 'success' || newVal) {
                this.updateRemoveMessage(true)
            }
        }
    }
}

</script>

<style scoped>
#tsd-container { 
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 600px;
}
.loginContainer{
    width: 279px;
}
.user-id{
    width: 100% !important;
    margin-bottom: 12px;
}
.forgot-link {
    height: 20px;
    color: #000000;
    margin-top: 14px;
    /* font-family: "Verizon NHG DS"; */
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    float: right;
    cursor: pointer;
    text-decoration: underline;
}
#wrapperLinks .wrapper-links-first-table {
    margin: 25px 10px 0px 20px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
}
#wrapperLinks .wrapper-links-general-table .tableStyle .geLinks .page-link{
    padding-right: 35px;
    padding-left: 20px;
}
.header {
    border-bottom: 5px solid #0f0f0f !important;
}

.login-container
{
    margin: 25px 10px 0px 20px;
    padding-right: 15px;
}
input[type=text], input[type=password]
{
    border-color: #d8dada #d8dada #000000 #d8dada;
    border-radius: 0px;
    color: #000000;
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    border: 1px solid #d8dada;
    background-color: #ffffff;
    background-image: none;
    height: 42px;
    width: 279px;
    box-sizing: border-box;
    border-bottom: 1px solid #000;
}
#sign-in-title
{
    height: 31px;
    width: 100%;
    color: #000000;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 30.4px;
    font-weight: bold !important;
}
.form-label
{
    height: 12px;
    color: #747676;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: 400 !important;
}
input[type=submit]
{
    width: auto;
    min-width:120px;
    background-color: black;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}
.anchor-button{
    width: auto;
    min-width:120px;
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 14px 35px;
    margin: 8px 0;
    border-radius: 30px;
    cursor: pointer;
    text-decoration: none;
}
input[type=submit]:disabled
{
    width: auto;
    min-width: 120px;
    background-color: #d4d6d9;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: auto;
}

.black-ink-link
{
    width: 170px;
    color: #000000 !important;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16.8px;
}
.register-box
{
    float: right;
    background-color: dimgrey;
    margin-right: 15%;
}
.register-box span
{
    font-size: 10px;
}
.footer-banner {
    padding: 20px 50px 0px 50px;
    
}
#captcha-submit {
    width: auto;
    min-width:120px;
    background-color: black;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}
#captcha-submit:disabled {
    width: auto;
    min-width: 120px;
    background-color: #d4d6d9;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: auto;
}
#register-div {
    display: inline-block;
    margin-top: 20px;
}
.vz-error {
  display: none;
}

.refreshCaptcha{
  border: none;
  background: white;
  text-decoration: underline;
}
.quickLinks{
    border-bottom: 1px solid #e9e9e9;
    font-size: 13px;
    font-weight: 600;
    width: 279px;
    padding: 10px 0 10px 0;
    text-decoration: none;
}
ul.links {
    list-style: none;
}
.geLinks{
    list-style: none;
    background-color:#f6f6f6;
    border-bottom: 1px solid #efebeb;
    font-size: 12px;
    width: 75%;
    padding: 10px 0 10px 0;
}

.page-link {
    text-decoration: none;
    vertical-align: bottom;
    margin: 5px 0px 0px 0px;
    display: inline-block;
    width: 100%;
    padding: 12px 10px 0px 0px;
}

.page-link::after {
    position: relative;
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 0.1em solid #080707;
    border-top: 0.1em solid #080707;
    transform: rotate(45deg);
    margin: 10px 0px 15px 0px;
    text-decoration: none;
    float: right;
}

.quick-links {
    border-bottom: 2px solid #080707;
    width: 75%;
    text-decoration: none;   
    padding-bottom: 10px;
}

.tableStyle {
    width: 75%;
    text-decoration: none;
}

.old-general-links {
    background:url('~@/common/assets/styles/corp/images/Plus.png') no-repeat;
    background-position: right 27% center;
    text-decoration: none;
    width: 100%;
    margin: 20px 0 20px 0;
    float: right;
    font-size: 13px;
    font-weight: 600;
    background-size: 12px 12px;
}

.new-general-links {
    background:url('~@/common/assets/styles/corp/images/Minus.png') no-repeat;
    background-position: right 27% center;
    text-decoration: none;
    width: 100%;
    margin: 20px 0 20px 0;
    float: right;
    font-size: 13px;
    font-weight: 600;
    background-size: 12px;
}
.regSubmitBtn {
    font-family: "Verizon NHG TX" !important;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
}

.remember-me-text {
    padding: 0 5px 0 5px; margin-bottom: 0;
}
.remember-me-checkbox
{
    /* padding: 5px; */
    background-color: aqua;
    position: static !important;
    margin: 0 !important;
}
@media  (min-width: 550px) and (max-width: 1024px) {
  .userInput, .pswdInput{
    list-style: none;
    background-color:#f6f6f6;
    border-bottom: 1px solid #efebeb;
    font-size: 12px;
    width:100% !important;
    padding: 10px 0 10px 0;
    }
    .regSignInBtn{
        margin: 0 auto;
        min-width:150px;
    }
     .regSubmitBtn{
        text-align: center;
    }
    .regSubmitBtn{
        text-align:center;
    }
    .quick-links{
        padding-bottom: 10px;
        width: 100% !important;
    }
    .quickLinks{
        width:100% !important;
    }
    .geLinks{
        width:100% !important;
    }
    .tableStyle {
        width: 100% !important;
    }
    #tsd-container{
        width: 100% !important;
    }
    .loginContainer{
        width: 100% !important;
    }
    .old-general-links{
        background-position: right 0.6% center;
    }
    .new-general-links{
        background-position: right 0.6% center;
    }
    .regSubmitBtn {
        display: flex;
    }
    .regSubmitBtn a {
        flex: 0.7;
    }
    .regSubmitBtn input {
        flex: 1;
    }
}
@media  (min-width: 280px) and ( max-width: 550px){
    .userInput, .pswdInput{
        width: 100% !important;
    }
    .regSubmitBtn{
        text-align:center;
    }
    .quick-links{
        padding-bottom: 10px;
        width: 100% !important;
    }
    .quickLinks{
        width:100% !important;
    }
    .geLinks{
        width:100% !important;
    }
    .tableStyle {
        width: 100% !important;
    }
     #tsd-container{
        width: 100% !important;
    }
    .loginContainer{
        width: 100% !important;
    }
    .new-general-links{
        background-position: right 2% center;
    }
    .old-general-links{
        background-position: right 2% center;
    }
    .regSubmitBtn {
        display: flex;
    }
    .regSubmitBtn a {
        flex: 0.7;
    }
    .regSubmitBtn span {
        padding-left: 5px;
        padding-right: 5px;
    }
    .regSubmitBtn input {
        flex: 1;
    }
}
input[type="checkbox"].tc {
    outline: none !important;
}
.user-id.pwd{
    position: relative;
}

.show_pwd{
  position: absolute;
  right: 12px;
  bottom: 45px;
  text-decoration: underline;
  cursor: pointer;
  height: 20px;
  color: #000000;
  font-family: "Verizon NHG eDS";
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
</style>
