<template>
  <v-layout>
    <template slot="waiting">
      <LoaderComponent></LoaderComponent>
    </template>
    <v-container> 
      <router-view></router-view>
    </v-container>
  </v-layout>
</template>
<script>
//import Footer from '@/components/Footer'
//import Header from '@/components/Header'
import LoaderComponent from '@/vbgauth/components/LoaderComponent'
import { playrest } from '@/vbgauth/util/playrest-axios.js'
// import { mapWaitingActions } from 'vue-wait'
// import { mapGetters } from 'vuex'
export default {
  name: 'AuthStepupRootComponent',
  components: {
    LoaderComponent
  },
  data () {
    return {
      flowInitialized: 'NOT_INITIALIZED',
      stepupReason: ''
    }
  },
  async created () {
    let self = this
    this.$wait.start('initialization_to_complete')
    // /api/aa/v1/login
    // http://localhost:9002/initialize
    return playrest.get('/api/aa/v1/login').then(function (response) {
          self.flowInitialized = response.data.STATUS
          self.stepupReason = response.data.STEPUPREASON
        }).catch((error) => {
          // commit('setStateInitialized', false)
          throw error
        }).finally(() => {
        this.$wait.end('initialization_to_complete')
      })
  },
  watch: {
    flowInitialized: function(newVal) {
      this.$store.dispatch('authstepup/updateFlowInitialized', newVal)
      if (newVal == 'OTP_INITIALIZED') {
        this.$router.push({ name: 'AuthOtpComponent'}).catch(err => {throw err})
      } else if (newVal == 'SQA_INITIALIZED') {
        this.$router.push({ name: 'AuthSqaComponent'}).catch(err => {throw err})
      } else if (newVal == 'ALLOW_INITIALIZED') {
        if(this.stepupReason === 'DEVICE_NOT_REGISTERED') {
          this.$router.push({ name: 'AuthOtpComponent'}).catch(err => {throw err})
        } else if(this.stepupReason === 'RISK') {
          this.$router.push({ name: 'AuthOtpComponent'}).catch(err => {throw err})
        } else {
          this.$store.dispatch('authstepup/redirectToOpenam')
        }
      } else {
        // console.log('FLOW NOT INITIALIZED YET')
      }
    },
    stepupReason: function() {
      this.$store.dispatch('authstepup/updateStepupReason', this.stepupReason)
    }
  }
}
</script>
<style scoped>
</style>
