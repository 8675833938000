<template>
  <div class="sdar_main_container">
    <h2 class="sdar_main_title">Welcome to My Business</h2>
    <p class="sdar_sub_title">
      We need some additional information to complete your security details and
      keep your account safe.
    </p>
    <div class="btn_container">
      <button @click="$emit('completeSecurityProfile')" class="sdar_reg_btn">
        Complete my security details
      </button>
      <button
        v-if="sdarUserType === 'PEND_SDAR_2'"
        @click="$emit('doitLater')"
        class="sdar_sign_btn"
      >
        I’ll do it later
      </button>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";

export default {
  name: "ConfirmSecurityDetailsOrDoLaterPopUp",
  mixins: [commonMixin],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters("profile", ["sdarUserType"]),
  },
};
</script>
  
  <style>
.sdar_main_title {
  font-size: 32px;
  line-height: 34px;
  color: #000000;
  font-family: "VzNHGeDS-bold11";
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 27px;
}
.sdar_sub_title {
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  font-family: "VzNHGeDS-reg11";
  letter-spacing: normal;
  text-align: left;
  /*margin-bottom: 180px;*/
}
.sdar_reg_btn {
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  background-color: #000000;
  font-family: "VzNHGeDS-bold11";
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 18px;
  width: 100%;
  padding: 16px 52.5px;
  border-radius: 26px;
  margin-right: 12px;
}
.sdar_sign_btn {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  background-color: #ffffff;
  font-family: "VzNHGeDS-bold11";
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 18px;
  width: 100%;
  padding: 16px 52.5px;
  border-radius: 26px;
  border: 1px solid #000000;
}
@media only screen and (max-width: 1023px) {
  /*padding: 32px 16px;*/
  /* .sdar_main_title {
      margin-bottom: 35.84px;
    } */
  .sdar_sub_title {
    /*margin-bottom: 100px;*/
  }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .sdar_popup > .sdar_main_title {
    margin-top: 24px !important;
  }
}

.sdar_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sdar_sub_title {
  flex: 1;
}
</style>
  