<template>
  <div class="vbg-ff-root-container">
    <Header v-if="this.whichRealm"></Header>
    <div class="vbg-ff-content-container">
      <div v-if="fuAlerts.length > 0">
        <div class="row" v-for="j in fuAlerts" :key="j">
          <div class="col-sm-12">
            <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
              <div class="vbg-ff-msg-container">
                <div class="messageWrapper" style="display: flex">
                  <div
                    class="messageIcon"
                    style="padding: 0px; margin-right: 12px"
                  >
                    <div class="messageImage" :class="messageImage(j)"></div>
                  </div>
                  <p
                    class="messageContentUn col-xs-11"
                    v-if="j.type == 'list'"
                    v-html="j.msg"
                    style="padding: 0px; margin: 0px"
                  >
                    {{ j.msg }}
                  </p>
                  <p
                    class="messageContentUn col-xs-11"
                    v-else-if="j.type == 'error' || j.type == 'warning'"
                    style="padding: 0px; margin: 0px"
                    v-html="j.msg"
                  >
                    {{ j.msg }}
                  </p>
                  <p
                    class="messageContentUn col-xs-11"
                    v-else
                    style="padding: 0px; margin: 0px; color: #ffffff"
                    v-html="j.msg"
                  >
                    {{ j.msg }}
                  </p>
                  <div
                    class="closeMessage col-xs-1"
                    style="padding: 0px; margin-left: auto"
                  >
                    <img
                      class="closeMessageImg"
                      v-if="
                        j.type == 'list' ||
                        j.type == 'error' ||
                        j.type == 'warning'
                      "
                      src="@/common/assets/styles/corp/images/close_black.png"
                      @click="deleteAlert(j)"
                    />
                    <img
                      class="closeMessageImg"
                      v-else
                      src="@/common/assets/styles/corp/images/clear_white.png"
                      @click="deleteAlert(j)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div v-if="this.whichRealm" class="col-xs-0 col-md-1 md-none"></div>-->
      <div class="vbg-ff-main-container">
        <div class="vbg-ff-title-container">
          <h1>{{ $t("message.forgot_username_general_title") }}</h1>
        </div>
        <div v-if="userIdFlow == 'SIMPLE' || userIdFlow == 'COMPLEX'" class="">
          <p>{{ $t("message.forgot_username_additional_info") }}</p>
        </div>
        <div
          v-if="!this.whichRealm && this.userVerified"
          class="blackbar martop20"
        ></div>
        <div v-if="userVerified"><br /><br /><br /></div>
        <!-- <div v-else class="blackbar martop20"></div> -->
        <!-- <div>
				  <v-spacer></v-spacer><p v-if="!userVerified" class="ptiny">{{ $t("message.all_fields_required") }}</p>
			  </div> -->
        <ForgotUsernameUserDetailsComponent
          v-if="userIdFlow == 'BASIC'"
        ></ForgotUsernameUserDetailsComponent>
        <AMForgotUserIDInfo
          v-if="userIdFlow == 'SIMPLE' || userIdFlow == 'COMPLEX'"
        ></AMForgotUserIDInfo>
        <SignInButtonComponent v-if="userVerified"></SignInButtonComponent>
      </div>
      <!--<div v-if="this.whichRealm" class="col-xs-0 col-md-5"></div>-->
    </div>
    <Footer v-if="this.whichRealm"></Footer>
  </div>
</template>
<script>
import Header from "@/vbgauth/components/LegalHeader";
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import translations from "@/common/assets/json/messages1.json";
import ForgotUsernameUserDetailsComponent from "@/vbgauth/components/core/ForgotUsernameUserDetailsComponent";
import SignInButtonComponent from "@/vbgauth/components/core/SignInButtonComponent";
import AMForgotUserIDInfo from "@/vbgauth/components/amForgotFlow/Enhanced/AMForgotUserIDInfo";
import { mapGetters, mapActions } from "vuex";
import { setPageDetails } from "../../../../public/adobeTagging";
export default {
  name: "AMForgotUsrnmComponent",
  components: {
    Footer,
    Header,
    ForgotUsernameUserDetailsComponent,
    SignInButtonComponent,
    AMForgotUserIDInfo,
  },
  data() {
    return {
      whichRealm: true,
    };
  },
  async created() {
    setPageDetails("Forgot Username:Credentials");
  },
  mounted() {
    this.fuAlerts.splice(0, 1);
    let appUrl = this.$route.query.appUrl;
    let gotoUrl = this.$route.query.goto;

    if (this.urlOnMount != null || this.urlOnMount != undefined) {
      const hostUrl = this.urlOnMount;
      if (hostUrl.includes("/account/business/login/mobile")) {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      var iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }

    if (appUrl != undefined) {
      if (gotoUrl != undefined) {
        // encode gotoUrl before appending it to urlOnMount
        gotoUrl = encodeURIComponent(gotoUrl);
        this.$store.dispatch(
          "cvsstepup/updateUrlOnMount",
          appUrl + "?goto=" + gotoUrl
        );
      } else this.$store.dispatch("cvsstepup/updateUrlOnMount", appUrl);
    }

    if (gotoUrl != undefined) {
      this.$store.dispatch("cvsstepup/updateGotoUrl");
    }
    window.onpopstate = function () {
      window.history.forward();
    };

    // old code
    // if (this.urlOnMount != null || this.urlOnMount != undefined) {
    //   const hostUrl = this.urlOnMount;
    //   if (hostUrl.includes("/account/business/login/mobile")) {
    //     this.whichRealm = false;
    //   }
    // }
    // if (document.cookie.indexOf("iamvar_realm") !== -1) {
    //   var iamvar_realmCookie = getCookie("iamvar_realm");
    //   if (iamvar_realmCookie == "MOBILE") {
    //     this.whichRealm = false;
    //   }
    // }
    // if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
    //   let iamvar_appCookie = getCookie("X-VZ-C_APP");
    //   if (iamvar_appCookie == "MVB") {
    //     this.whichRealm = false;
    //   }
    // }
    // //In FFOtpcompoment - Route redirecting when user refresh the page
    // window.localStorage.setItem("InitialRoute", window.location.href);
  },
  beforeDestroy() {
    if (this.whichRealm == false && this.userVerified) {
      window.location.href = iamvar_mvbLaunchUrl;
    }
  },
  methods: {
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      if (
        index.type == "error" ||
        index.type == "warning" ||
        index.type == "list"
      ) {
        return "newErrorMessageImage";
      } else {
        return index.type + "MessageImage";
      }
    },
    getCloseIcon() {
      return (
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/img/close_white.png"
      );
    },
    deleteAlert(alert) {
      this.fuAlerts.splice(this.fuAlerts.indexOf(alert), 1);
    },
    getAlertType(index) {
      return index.type;
    },
  },
  computed: {
    ...mapGetters("forgotflow", ["userVerified"]),
    ...mapGetters("cvsstepup", ["urlOnMount", "userIdFlow","flowType"]),
    fuAlerts: {
      get: function () {
        return this.$store.getters["cvsstepup/fuAlerts"];
      },
      set: function (newVal) {
        this.$store.commit("cvsstepup/setFuAlert", newVal);
      },
    },
  },
  watch: {
    /* userVerified: function(newVal) {
      if(newVal) {
        this.$router.push({ name: 'AMForgotUsrnmResultComponent'}).catch(err => {throw err})
      }
    }*/
  },
};
</script>
<style scoped>
.forgot-flow-container {
  min-height: 95vh;
}
@media (max-width: 767px) {
  .forgot-flow-container {
    min-height: 80vh;
  }
}
#tsd-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* old code */
.messageContentUn {
  color: #000000;
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  line-height: 20px;
}
@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
}
@media (max-width: 425px) {
  .messageContent,
  .messageContentUn {
    width: 232px;
  }
}
@media (max-width: 1024px) {
  .col-xs-12.col-md-6 {
    max-width: 100% !important;
    width: 100% !important;
  }
  .md-none {
    display: none;
  }
  h1 {
    font-size: 24px !important;
  }
}
</style>
