<template>
  <div class="container2">
    <div v-if="!cardData.flag" class="loader"></div>
    <div v-else class="align-center">
      <span v-if="cardData?.status" class="icon-size">&#9989;</span>
      <span v-else class="icon-size">&#10060;</span>
      <span style="margin-right: 5px">{{ cardData?.name }}</span>
      <b>Build Info ({{ cardData.buildinfo }})</b>
      <!-- {{ buildInfo }} -->
    </div>
  </div>
</template>
<script>
import commonMixin from "./mixins/common-mixin.js";
export default {
  props: ["cardContent"],
  data() {
    return {
      cardData: { ...this.cardContent, status: false, buildinfo: null },
    };
  },
  mixins: [commonMixin],
  computed: {},
  async mounted() {
    console.log("test", this.cardData);
    await this.fetchData();
  },
  methods: {},
};
</script>
<style  scoped>
.icon-size {
  font-size: 20px !important;
  margin-right: 5px;
}
.container2 {
  margin: 5px;
  display: flex;
}
.align-center {
  display: flex;
  align-items: center;
  /* flex: none; */
}
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid red;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>