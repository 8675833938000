<template>
    <div>
      <div class="vbg-subtitle-container">
        <h3 class="vbg-subtitle">{{otpPageMessages.subtitle}}</h3>
        <!--<buton class="bx-tooltip marright0" type="button" style="float: left;" v-tooltip="idmTooltip"></buton>-->
        <!-- <button
          v-if="!vbgFlow"
          class="vbg-tooltip marright0"
          type="button"
          :data-text="idmTooltipVerify"
          tabindex="1"
        ></button>
        <button
          v-else
          class="vbg-tooltip marright0"
          type="button"
          :data-text="idmTooltip"
          tabindex="1"
        ></button> -->
      </div>
        <!--<p v-if="flowType == 'forgot_username' || flowType == 'forgot_password'" style="clear: both;">{{ otpPageMessages.instrn }}</p>-->
        <!-- <p v-if="otpPageMessages.idm_instrn && flowType != 'auth'" style="font-size: 12px;clear: both;">*{{ otpPageMessages.idm_instrn }}</p> -->
        <!--<p v-if="vbgFlow && flowType == 'auth'" style="font-size: 12px; clear: both;">Select a one-time passcode delivery method.</p>
        <p v-if="!vbgFlow && flowType == 'auth'" style="font-size: 12px; clear: both;">Select how you would like to verify your account.</p>-->
        <p v-if="vbgFlow" style="clear: both; color: #000000 !important;">We’ll send a one-time passcode so you can verify your identity.</p>
        <p v-if="!vbgFlow" style="clear: both; color: #000000 !important;">We’ll send a verification link so you can verify your identity.</p>
        <v-form>
            <div class="padleft0 dropdown-mar-top" :class="getOAuthClass('oauth-flow-pt16')">
              <label class="labeltiny" :class="flowType == 'forgot_password' ? hideOtpValidateComponent ? '' : 'vbg-rsp-disabled-label' : ''">{{ $t("message.login_otp_device_dropdown_label") }}</label>
            </div>
            <div
              id="drpdn"
              style="margin-bottom: 0px;" 
              class="form-group"
            >
              <div ref="dropdownRef" class="row dropdown dropdown-toggle" v-bind:class="[{ open: showDropdown  },flowType == 'forgot_password' ? hideOtpValidateComponent ? '' : 'vbg-rsp-disabled-select' : '',]" aria-haspopup="true" aria-expanded="false" @click="toggleDropdown()" @keyup.enter="toggleDropdown()" tabindex="2">
                <div class="col-xs-9" style="padding-right:0px; margin-top: 4px;" :class="getOAuthClass('padleft0 oauth-flex1')" >
                  <a id="dropdownMenu1" aria-haspopup="true" role="button" :class="flowType == 'forgot_password' ? hideOtpValidateComponent ? '' : 'vbg-rsp-disabled-label' : ''">
                    <span class="padleft10"> {{ selectedOtpDevice.label }}<span v-if="flowType == 'forgot_password' && selectedOtpDevice.vzCarrier && (selectedOtpDevice.deviceType == 'SMARTPHONE')"> (Recommended)</span></span>
                    </a>
                </div>
                <a class="col-xs-1 pull-right dropdown-cheveron" style="padding:0px; margin-top: 3px;"></a>
                <ul ref="dropdownListRef" class="dropdown-menu col-xs-12 px-0" aria-labelledby="dropdownMenu1">
                  <li 
                    v-for="(device, index) in devices" 
                    :key="device.deviceID" 
                    role="presentation" 
                    v-bind:class="{ 
                      chosen: (device.deviceID === selectedOtpDevice.deviceID)
                    }" 
                    :id="device.deviceID" :tabindex="index+3" 
                    @keyup.enter="deviceOptionSelected(device, 'enter')"
                    @keyup="deviceListkeyupHandler($event, index)"
                    @click="deviceOptionSelected(device, 'click')"
                    ref="selectDeviceList"
                  >
                    <a role="menuitem">
                      <span class="padleft10"></span> {{device.label}}<span v-if="flowType == 'forgot_password' && device.vzCarrier && (device.deviceType == 'SMARTPHONE')"> (Recommended)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group padleft0 col-xs-12" v-if="isShowWirelessNumber" :class="getOAuthClass('oauth-flow-pr0')">
              <div class="padleft0 martop10 col-xs-12">
                <h4 style="float:left;">Wireless Number</h4>
                <span>
                    <button class="bx-tooltip marright0" type="button" style="position: relative;" v-tooltip="content4"></button>
                </span>
              </div>
              <div class="padleft0 col-xs-4" :class="getOAuthClass('oauth-flow-pr0 oauth-flow-w-100 oauth-flow-mt12')">
                <input id="otpCode" name="otpCode" v-model="wirelessMobileNumber" @input="enableSendOTP()" :disabled="mobDisable" type="text" class="form-control vz-input-field" autocomplete="off" v-bind:class="userValidationInputClass">
              </div>
              <div v-if="!isMobilenumberValid">
                <div class="padleft0 martop10 col-xs-12">
                  <p class="pmicro">{{ $t("message.us_number_not_correct") }}</p>
                </div>
              </div>
            </div>
            <div class="verifyAndLinkContainer">
              <button type="button" ref="submitButtonRef" v-if="vbgFlow && flowType !='forgot_password'" class="btn primary_black vbg-static-otp-btn" :class="isDisabled()" @click="sendOTP()" :disabled="disabledAttr"
              :tabindex="devices.length + 1" id="otp_send" name="otp_send">{{$t("message.login_otp_page_send_passcode")}}</button>
              <button type="button" ref="submitButtonRef" v-if="!vbgFlow && flowType !='auth' && flowType !='forgot_password' && !this.displayAllToggle" class="btn primary_black verifyContainer" :class="isDisabled()" @click="sendOTP(false)" :disabled="disabledAttr" :tabindex="devices.length + 1"
              >Verify</button>
              <button type="button" ref="submitButtonRef" v-if="!vbgFlow && flowType !='auth' && flowType !='forgot_password' && this.displayAllToggle" class="btn primary_black verifyContainer" :class="isDisabled()" @click="sendOTP(false)" :disabled="disabledAttr" :tabindex="devices.length + 1"
              >Continue</button>
              <button type="button" ref="submitButtonRef" v-if="!vbgFlow && flowType =='auth' && whichRealm == false" class="btn primary_black verifyContainer" :class="isDisabled()" @click="sendOTP(false)" :disabled="disabledAttr" :tabindex="devices.length + 1">Verify</button>
              <button type="button" ref="submitButtonRef" v-if="!vbgFlow && flowType == 'auth' && whichRealm == true && ((this.selectedOtpDevice.deviceType == 'EMAIL') || (this.selectedOtpDevice.deviceType == 'SMARTPHONE'))" class="btn primary_black verifyRemContainer" :class="isDisabled()" @click="sendOTP(true)" :disabled="disabledAttr" :tabindex="devices.length + 1" id="tokenized_verifynRem_button">{{$t("message.otp_verify")}}{{$t("message.login_register_device")}}</button>
              <button type="button" v-if="!vbgFlow && flowType == 'auth' && whichRealm == true && ((this.selectedOtpDevice.deviceType == 'EMAIL') || (this.selectedOtpDevice.deviceType == 'SMARTPHONE'))" class="btn secondary_white verifyDontRemContainer" :class="isDisabled()" @click="sendOTP(false)" :disabled="disabledAttr" :tabindex="devices.length + 2" id="tokenized_verifynDrem_button">{{$t("message.otp_verify")}}{{$t("message.login_dont_register_device")}}</button>
              <button type="button" ref="submitButtonRef" v-if="!vbgFlow && flowType =='auth' && whichRealm == true && ((this.selectedOtpDevice.deviceType == 'BASIC') || (this.selectedOtpDevice.deviceType == 'IVR'))" class="btn primary_black verifyContainer" :class="isDisabled()" @click="sendOTP(false)" :disabled="disabledAttr" :tabindex="devices.length + 1" id="tokenized_continue_button"
              >Continue</button>
              <button type="button" ref="submitButtonRef" v-if="flowType =='forgot_password' && !ffSQAEnabled" class="btn primary_black verifyContainer" :class="isDisabled()" @click="sendOTP(false)" :disabled="disabledAttr" :tabindex="devices.length + 1"
              >Verify</button>
            </div>
            <div v-if="((!this.vbgFlow && displayVerificationLink)) && !userDeniedOTP && flowType != 'forgot_password'" class="margin-bottom-medium padright0 padleft0 linkContainer">
              <a class="vz-a" @click="dontHaveOTPAccess()" tabindex="10" id="tokenized_not_received" name="tokenized_not_received">
                Did not receive your verification link?
              </a>
            </div>
            <div>
              <div id="pageTimeoutModal" class="modal fade">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <a data-dismiss="modal" class="col-xs-1 pull-right close-icon-popup" style="padding: 0px;"></a>
                    <h3 class="martop0 marbottom20">Session timeout warning</h3>
                    <div>
                      <p>You have been inactive for a while.</p>
                      <p>For your security, we will automatically log you off</p>
                      <p>in 5 minutes unless you click "Stay active" below.</p>
                    </div>
                    <div>
                    <button type="submit" data-dismiss="modal" class="btn primary_black marright20 martop50" @click="stayActive()" id="stepup_active">
                      <span>Stay active</span>
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div id="noAccessToOtpModal" class="modal fade">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <!-- <div v-if="this.cantrcvLink"> -->          
                  <!-- </div> -->
                  <!-- <div v-if="this.checkVerifyPopUp"> -->
                  <div class="modal-content modal-content-container">
                    <div class="modal-container">
                      <div class="close-icon-container" tabindex="1">
                        <a data-dismiss="modal" @click="closeModal()">
                          <img src="@/common/assets/styles/corp/images/close_black.png" style="height:20px; width: 20px;" />
                        </a>
                      </div>
                      <div class="window-content mar-bottom50">
                        <div v-if="this.checkVerifyPopUp">
                          <h2 v-if="verificationSent" class="modal-content-title">Your verification link was sent.</h2>
                          <h2 v-else class="modal-content-title">Your verification link was resent.</h2>
                          <p v-if="selectedOtpDevice.deviceType == 'EMAIL'" class="modal-content-description">Check your email and use your verification link to continue logging in to your account. <br> 
                          </p>
                          <p v-else class="modal-content-description">Check your text message and use the verification link to continue logging into your account. <br> 
                          </p>
                          <p v-if="selectedOtpDevice.deviceType == 'EMAIL'" class="modal-content-description">This verification link will expire in {{ this.aaEmailTimeout }} minutes.</p>
                          <p v-else class="modal-content-description">This verification link will expire in {{ this.aaSmsTimeout }} minutes.</p>
                        </div>
                        <div v-if="this.cantrcvLink">
                          <h2 v-if="((flowType == 'forgot_username' || flowType == 'auth') && !this.vbgFlow)" style="height: auto;" class="martop0 marbottom20 modal-content-title">Did not receive your verification link?</h2>
                          <h2 v-else class="martop0 marbottom20 modal-content-title">Can't receive a one time passcode?</h2>
                          <p class="modal-content-description">
                            Please try another delivery method, contact your company administrator, or 
                            <a href="https://www.verizon.com/business/support/" style="text-decoration: underline; cursor: pointer;" class="vbg-ff-focus" target="_blank" tabindex="2">visit our support page.</a>
                          </p>
                        </div>
                      </div>

                      <div class="modal-loader" v-if="this.checkVerifyPopUp">
                        <CustomLoaderComponent></CustomLoaderComponent>
                      </div>
                      
                      <div class="btn-container" v-if="this.checkVerifyPopUp">
                        <button type="submit" class="button-primary-large-copy btn secondary_white vbg-rel-bottom-40" tabindex="2" style="cursor: pointer; font-size: 16px; letter-spacing: 0.5px;" @click="resendPasscode()" id="tokenized_resend_button">
                        Resend verification link
                        </button>
                        <div class="margin-bottom-medium padright0 padleft0 col-xs-12 text-link vbg-rel-bottom-40">
                          <a class="vz-a link-text" @click="dontHaveOTPAccess()" tabindex="3" id="tokenized_modal_not_received">
                            I didn’t receive a verification link
                          </a>
                        </div>
                      </div>

                      <div class="modal-btn-container martop100" v-if="this.cantrcvLink">
                        <button type="submit" data-dismiss="modal" id="otp_modal_close" @click="closeModal()" class="btn primary_black marright20 martop50 oauth-close-button bttn-container vbg-rel-bottom-40 spacing05" tabindex="3">
                          <span>Close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>

        </v-form>
    </div>
</template>
<script>
import '@/common/assets/styles/tooltip.css'
import translations from '@/common/assets/json/messages1.json'
import { mapGetters, mapActions } from 'vuex'
import {setPageDetails,eventTracking,errorSet} from '../../../../../public/adobeTagging';
import CustomLoaderComponent from '@/vbgauth/components/CustomLoaderComponent.vue';
import commonMixin from '@/common/mixin/commonMixins'

export default {
  name: 'VPSFFOtpDeliveryComponent',
  components: {
    CustomLoaderComponent,
  },
  mounted() {
    // focus dropdown when landing on SQA page
    this.$refs.dropdownRef.focus()
    if((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if(hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          this.whichRealm = false;
        }
    }
    if(this.wirelessMob) {
      this.wirelessMobileNumber = this.wirelessMob
    }
    /* Event Listener For Closing Select Box */
    document.addEventListener('click', (e) => {
      if(this.showDropdown && e.target.closest("#drpdn") === null) {
        this.showDropdown = false
      }
    })
    this.setTimeOutVar()
    this.vbgFlow ? this.changeContextBiocatch('step_up_page_otp_delivery') : this.changeContextBiocatch('step_up_page_tokenized_flow')
    this.updateSelectedDevice(this.defaultSelectedDevice) 
  },
  updated() {
    if(this.showDropdown && this.$refs.selectDeviceList.length > 1) {
      this.$refs.selectDeviceList[1].focus()
    }
  },
  beforeDestroy() {
    $('.modal-backdrop').remove()
  },
  mixins: [commonMixin],
  data () {
    return {
      showDropdown: false,
      showResendOtpLink: false,
      checkVerifyPopUp: false,
      //cantrcvLink: false,
     // mobDisable: false,
     // isShowWirelessNumber: false,
      wirelessMobileNumber: null,
      userValidationInputClass: '',
      content3: translations.en.message['login_otp_tooltip'],
      idmTooltip: translations.en.message['idm_otp_help_icon_msg'],
      idmTooltipVerify: translations.en.message['idm_otp_help_icon_msg_verify'],
      content4: translations.en.message['wireless_no_help_icon_msg'],
      url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/circle-loaderWHITE_48x48.gif',
      whichRealm: true, 
      displayVerificationLink: false,
      //reSendVerification: true,
      aaEmailTimeout: 15,
      aaSmsTimeout: 10,
      displayAllToggle: iamvar_aaTokLinkDisplayAllToggle
    }
  },
  methods: {
    deviceListkeyupHandler(e, index) {
      // logic to move up and down in device list with up & down arrow
      const deviceList = this.$refs.selectDeviceList;
      switch(e.key) {
        case 'ArrowUp':
          if (index > 1) {
            console.log(index)
            deviceList[index-1].focus();
          } 
          if (index <= 1) {
            deviceList[deviceList.length-1].focus();
          }
        break;
        case 'ArrowDown':
          if (index < deviceList.length - 1) {
            deviceList[index+1].focus();
          } else {
            deviceList[1].focus();
          }
        break;
        default:
        break;
      }      
    },
    async resendPasscode() {
      eventTracking('One Time Passcode Resend Request Initiated','');
      let self = this
      self.otpSent = false
      self.showResendOtpLink = true
      this.resendPasscodeToSelectedDevice = true
      //this.resendVerificationToSelectedDevice = true
      this.resendOtpToDevice(this.selectedOtpDevice) 
      if(this.aaThrottled == 'true') {
        this.aaEmailTimeout = 15;
        this.aaSmsTimeout = 10;
      } else if (this.aaThrottled == 'false') {
        this.aaEmailTimeout = 15;
        this.aaSmsTimeout = 10;
      }
      //this.reSendVerification = false;
      this.updateVerificationSent(false);
    },
    toggleDropdown() {
      if(this.flowType == 'forgot_password' && !this.hideOtpValidateComponent) {
        //it should not open the dropdown
      } else {
      this.showDropdown = !this.showDropdown
      }
    },
    deviceOptionSelected(selDevice, selectionType) {
      if(selDevice.label == 'Enter Wireless Number') {
       // this.isShowWirelessNumber = true;
        this.$store.dispatch('cvsstepup/showWirelessNumber',true)
      } else {
       // this.isShowWirelessNumber = false;
        this.$store.dispatch('cvsstepup/showWirelessNumber',false)
      }
      this.updateSelectedDevice(selDevice)
      if(selectionType === 'enter') {
        // move focus to button if user 
        // click enter after selecting an option from dropdown
        this.$nextTick(() => this.$refs.submitButtonRef.focus())
      } else {
        // dont move focus to button if user select from mouse
        this.$refs.dropdownRef.focus()
      }
    },
    isDisabled() {
      if(this.otpSent || this.selectedOtpDevice.deviceID == null || this.selectedOtpDevice.deviceID == 'select-label') {
       // this.mobDisable = true
        return "inactive";
      }
    },
    stayActive() {
      this.pageTimeout = false
    },
    sendOTP(saveFingerprint) {
      if((this.flowType != 'forgot_password' && this.otpSent) || this.selectedOtpDevice.deviceID == null || this.selectedOtpDevice.deviceID == 'select-label') {
        //the send OTP/ Verify button is disabled and should not be clickable
      } else {
        eventTracking('One Time Passcode Request Initiated','');
          // if valid
          if(this.wirelessMobileNumber) {
            this.userValidationErrorMethod();
          }
          let self = this
          if(saveFingerprint != null || saveFingerprint != undefined) {
            this.$store.dispatch('cvsstepup/updateSetUserDeviceRegistration', saveFingerprint)
          }
          if(this.aaThrottled == 'true') {
            this.aaEmailTimeout = 15;
            this.aaSmsTimeout = 10;
          } else if (this.aaThrottled == 'false') {
            this.aaEmailTimeout = 15;
            this.aaSmsTimeout = 10;
          }
          this.sendOtpToDevice(this.selectedOtpDevice.deviceID)
          self.showResendOtpLink = true
            
          if (!this.vbgFlow && (this.flowType == 'forgot_password' || this.flowType==='forgot_username' || this.flowType == 'auth')) {
            if ((this.selectedOtpDevice.deviceType == 'EMAIL') || (this.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
              this.checkVerifyPopUp = true;
              this.updatecantrcvLink(false);
              $("#noAccessToOtpModal").modal({
                backdrop: "static"
              });
              // this.$store.dispatch('forgotflow/forgotPwdSqaTransactionId',this.forgotPwdSqaTransactionId)
              // console.log("@@@ forgotPwdSqaTransactionId ",this.forgotPwdSqaTransactionId)
              this.displayVerificationLink = false
            } 
            this.$store.dispatch('forgotflow/updateVbgFlow', false)
        }
      } 
    },
    enableSendOTP() {
      this.otpSent = false
    },
    dontHaveOTPAccess() {
      this.updatecantrcvLink(true);
      this.checkVerifyPopUp = false;
      $("#noAccessToOtpModal").modal({
          backdrop: "static"
      });
      this.displayVerificationLink = false
      if(!this.vbgFlow && (this.flowType == 'forgot_password' || this.flowType==='forgot_username' || this.flowType == 'auth')) {
        this.otpSent = true
      }
    },
    userValidationErrorMethod() {
      var phoneno = /^\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/g;
      this.wirelessMobileNumber = this.wirelessMobileNumber ? this.wirelessMobileNumber.replace(/ +/g, "") : null; // removing all whitespaces
      // removing +1 from the US mobile number
      if(this.wirelessMobileNumber && this.wirelessMobileNumber.startsWith('+1')) {
        this.wirelessMobileNumber = this.wirelessMobileNumber.slice(2);
      }
      // checking the US mobile number is valid or not and returning the value in format (+1) (xxx) xxx-xxxx
      if(this.wirelessMobileNumber && this.wirelessMobileNumber.match(phoneno)) {
        this.wirelessMobileNumber = this.wirelessMobileNumber.replace(phoneno, '$1-$2-$3');
       // this.userValidationError = false;
        this.$store.dispatch('cvsstepup/mobilenumberValidate',true)
        this.$store.dispatch('cvsstepup/sendMDN',this.wirelessMobileNumber)
        this.userValidationInputClass = '';  
      //  this.$store.commit('cvsstepup/setHideOtpValidateComponent', false); 
        } else {
          console.log("Not a valid Phone Number");
        //  this.$store.commit('cvsstepup/setHideOtpValidateComponent', true);
         // this.userValidationError = true;
          this.$store.dispatch('cvsstepup/mobilenumberValidate',false)
          this.$store.dispatch('cvsstepup/sendMDN',null)
          this.userValidationInputClass = 'vz-error';
        }
    },
    ...mapActions('cvsstepup', [
        'sendOtpToDevice',
        'updateSelectedDevice',
        'resendOtpToDevice',
        'updateVerificationSent',
        'updatecantrcvLink',
        'updateOTPAlert',
        'updateTriggerSendOTP'
    ]),
    ...mapActions('forgotflow', [
                'updateUserVerified',
                'setFFTransactionId',
                'setFFUsername',
                'getUsername',
                'updateVbgFlow',
                'setIsSmbUser'
            ]
    ),
    getOAuthClass(className = 'oauth-flow', subClass) {
      return className + ' ' + subClass;
    },
    closeModal() {
      if(!this.vbgFlow) {
        this.updatecantrcvLink(false);
        this.checkVerifyPopUp = false;
        this.displayVerificationLink = true;
        this.updateVerificationSent(true);
        this.updateSelectedDevice(this.defaultSelectedDevice)
      }
      this.updateOTPAlert([])
      $("#noAccessToOtpModal").modal('hide')
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      } else {
        return null;
      }
    },
    setTimeOutVar() {
      if(this.aaThrottled == 'true') {
        this.aaEmailTimeout = 15;
        this.aaSmsTimeout = 10;
      } else if (this.aaThrottled == 'false') {
        this.aaEmailTimeout = 15;
        this.aaSmsTimeout = 10;
      }
    }
  },
  computed: {
    ...mapGetters('cvsstepup', [
      'devices',
      'stepupReason',
      'otpPageMessages',
      'transactionId',
      'username',
      'flowType',
      'email',
      'sqaEnabled',
      'pageTimeout',
      'isMobilenumberValid',
      'isShowWirelessNumber',
      'wirelessMob',
      'vbgFlow',
      'currentStep',
      'forgotPwdSqaTransactionId',
      'isSmbUser',
      'verificationSent',
      'cantrcvLink',
      'userDeniedOTP',
      'defaultSelectedDevice',
      'aaThrottled',
      'ffSQAEnabled',
      'triggerSendOTP',
      'hideOtpValidateComponent'
    ]),
    ...mapGetters('login', [
      'urlOnMount', 'gotoUrl'
     ]),
    otpSent: {
      get: function () {
        return this.$store.getters['cvsstepup/otpSent']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateOtpSent', newVal)
      }
    },
    resendPasscodeToSelectedDevice: {
      get:function() {
        return this.$store.getters['cvsstepup/resendPasscodeToSelectedDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateResendPasscodeToSelectedDevice', newVal)
      }
    },
    resendVerificationToSelectedDevice: {
      get:function() {
        return this.$store.getters['cvsstepup/resendVerificationToSelectedDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateResendVerificationToSelectedDevice', newVal)
      }
    },
    selectedOtpDevice: {
      get: function () {
        return this.$store.getters['cvsstepup/selectedOtpDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateSelectedDevice', newVal)
      }
    },
    pageTimeout: {
      get: function() {
        return this.$store.getters['cvsstepup/pageTimeout']
      },
      set: function(newVal) {
        this.$store.dispatch('cvsstepup/displayPageTimeoutWarning', newVal)
      }
    },
    mobDisable() {
      return this.otpSent || this.selectedOtpDevice.deviceID == null || this.selectedOtpDevice.deviceID == 'select-label' ? true : false;
    },
    disabledAttr() {
      return (this.otpSent || this.selectedOtpDevice.deviceID == null || this.selectedOtpDevice.deviceID == 'select-label' ? true: false);
    },
  },
  watch: {
    triggerSendOTP: function(newVal) {
      if(newVal) {
        if(this.flowType == 'forgot_password' && this.ffSQAEnabled) {
          this.sendOTP(false);
          this.updateTriggerSendOTP(false)
        }
      }
    },
    otpSent: function(newVal) {
      if(newVal) {
        // this.$router.push({ path: 'send' })
      }
    },
    pageTimeout: function(newVal) {
      if(newVal) {
         $("#pageTimeoutModal").modal({
          backdrop: "static"
        });
      }
    },

    currentStep: function(newVal) {
      console.log("@@@@ FFOtpDeliveryComponenet :: currentStep")
      if(!this.vbgFlow && newVal === 'OTP_VALIDATED') {
        $("#noAccessToOtpModal").modal('hide')
        this.updateUserVerified(true)
        this.setFFTransactionId(this.transactionId)
        this.setIsSmbUser(this.isSmbUser)
        if(this.flowType === 'forgot_password') {
          this.setFFUsername(this.username)
          this.$router.push({ name: 'VPSForgotPwdFormComponent'})
        } else if(this.flowType==='forgot_username') {
          this.getUsername(this.email)
          // redirect user back to login page
          this.redirectBackToLogin(this.urlOnMount, this.gotoUrl);
        }
      } else if(!this.vbgFlow && newVal==='OTP_INVALIDATED' && (this.flowType==='forgot_password'|| this.flowType==='forgot_username' || this.flowType == 'auth')) {
        console.log("@@@FFotpComponent ",newVal)
        $("#noAccessToOtpModal").modal('hide')
        this.displayVerificationLink = true
      } else if(!this.vbgFlow && (newVal==='IFG_TOKEN_EXPIRED' || newVal==='IFG_TOKEN_PROCESS_ERROR' || newVal==='IFG_TOKEN_INVALID_REQUEST_ERROR') && (this.flowType==='forgot_password'|| this.flowType==='forgot_username'|| this.flowType == 'auth')) {
        console.log("@@@FFotpComponent ",newVal)
        $("#noAccessToOtpModal").modal('hide')
        //this.displayVerificationLink = true
        this.displayVerificationLink = false
      }
		},

    resendVerificationToSelectedDevice: function(newVal) {
      if(newVal) {
        if (!this.vbgFlow && (this.flowType == 'forgot_password' || this.flowType==='forgot_username' || this.flowType == 'auth')) {
          if ((this.selectedOtpDevice.deviceType == 'EMAIL') || (this.selectedOtpDevice.deviceType == 'SMARTPHONE')) {
            this.checkVerifyPopUp = true;
            this.updatecantrcvLink(false);
            $("#noAccessToOtpModal").modal({
              backdrop: "static"
            });
            // this.$store.dispatch('forgotflow/forgotPwdSqaTransactionId',this.forgotPwdSqaTransactionId)
            // console.log("@@@ forgotPwdSqaTransactionId ",this.forgotPwdSqaTransactionId)
            this.displayVerificationLink = false
            this.resendVerificationToSelectedDevice = false
          }
          this.$store.dispatch('forgotflow/updateVbgFlow', false)
        }
      }
    }
    // userValidationError: function(newVal) {
    //   if(newVal) {
    //     this.userValidationInputClass = 'vz-error'
    //   }
    // },
  }
}
</script>
<style scoped>

.div {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}
.alert-warning {
	background-color: #ffbc3d;
	background-size: 35px 29px;
	color: #000;
	border-color: #ffbc3d;
}
.alert-info {
	background-color: #08c;
	background-size: 35px 29px;
	color: #fff;
	border-color: #08c;
}
h2 {
  font-size: 20px;
  margin: 30px 0 20px;
}

li {
  list-style-type: disc;
}

.close {
    float: right !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
    opacity: .2 !important;
    margin-top: -2px !important;
}
.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.vz-input-field:focus {
  border: 1px solid black !important;
}
input {
  padding: 11px 0 11px 10px;
    border-radius: 0px;
    font-size: 14px;
    color: #000;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000;
    box-shadow: none;
    transition: border .4s;
    height: 36px;
}
.vz-error {
  border: #ed7000 1px solid;
  border-bottom: #ed7000 4px solid;
}

.modal-loader {
  margin: auto;
  height: 23.33px;
  width: 23.33px;
  margin-top: 70px;
}
.close-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px;
}
.close-icon-container:focus {
  outline: 1px dashed #000;
  outline-offset: -12;
}
.modal-button {
  margin-top: 37.34px;
  height: 44px;
  width: 188px;
}
.modal-content-container {
  position: relative;
  Width: 640px;
  Height: 431px !important;
  margin: 0 auto;
  padding: 48px !important;
}
.modal-content-title {
  height: auto;
  width: 100%;
  font-size: 32px;
  line-height: 32px;
  margin-top: 0px !important;
}
.modal-content-description {
  margin-top: 24px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.dropdown{height: 44px !important;}
/* @media (max-width: 400px) {
  .modal-button {
    width: 200px;
  }
} */
.dropdown-mar-top {
  padding-top: 30px;
}
@media (max-width: 550px) {
  .modal-loader {
    display: flex;
    justify-content: center;
    width: 100% !important;
    margin-left: 0px;
  }
  .modal-loader div {
    margin-left: -32px;
  }
}

/*@media(max-width: 425px) {
  .modal-content-container {
    width: 100%;
    height: 101%;
    padding: 0px !important;
  }
  .close-icon-container {
    height: 48px;
    width: 48px;
    margin-left: 270px;
  }
  .modal-content-title {
    height: 48px;
    width: auto !important;
    max-width: 288px;
    font-size: 24px;
    line-height: 24px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .modal-content-description {
    height: 60px;
    width: auto !important;
    max-width: 288px;
    margin-left: 10px;
    margin-top: 24px;
  }
  .modal-button-container {display: flex; justify-content: center;}
  .modal-button {
    height: 44px;
    width: 288px;
    position: absolute;
    bottom: 24px;
    margin-left: 46px;
  }
}*/
.oauth-flex1 { flex: 1; }
.remove-hover:hover {
  color: #000 !important;
  border-bottom: 1px solid #000 !important;
}
.remove-hover:focus { 
  color: #000 !important;
  border-bottom: 1px solid #000 !important;
}
.verifyRemContainer {
  margin-right: 12px;
}
@media (min-width: 280px) and (max-width: 1024px) {
  .verifyRemContainer {
    width: 100%;
    margin-bottom: 12px;
  }
  .verifyDontRemContainer {
    width: 100%;
    margin-left: 0 !important;
    /*margin-bottom: 12px;*/
  }
  .oauth-flow-mt12 {
    margin-top: 12px !important;
  }
  .oauth-flow {
    padding-right: 0 !important;
    margin-top: 32px !important;
  }
  .oauth-flow v-form.padleft0.col-xs-12 {
    padding-right: 0 !important;
  }
  .oauth-flow-pr0 {
    padding-right: 0 !important;
  }
  .oauth-flow-w-100 {
    width: 100% !important;
  }
  .oauth-flow-jcaptch {
    width: 100%;
    margin-bottom: 10px;
  }
  .oauth-flow-continue-btn {
    width: 100%;
    margin-bottom: 12px;
  }
  .oauth-flow-cancel-btn {
    width: 100%;
    margin-left: 0 !important;
  }
  .oauth-flow-pt16 {
    padding-top: 32px !important;
  }
  .oauth-d-flex {
    display: flex;
  }
  .oauth-flex1 {
    flex: 1;
  }
  .oauth-flow-pr0 button {
    width: 100%;
  }
  .oauth-close-button {
    width: 100%;
  }
  .linkContainer {
    margin-top: 15px;
  }
  .vbg-h3 {
    font-size: 16px !important;
  }
  .form-group {
    margin-bottom: 46px !important;
  }
  .padding-top-large {
    padding-top: 0px !important;
  }
  h1 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  /*.vbg-otp-container {
    margin-top: 32px;
  }*/
  .vbg-subtitle-container{
    margin-top: 32px;
    margin-bottom: 8px !important;
  }
  /*.vbg-tooltip {
    display: none;
  }*/
  .verifyAndLinkContainer button {
    width: 100%;
  }
  .dropdown-mar-top {
    padding-top: 32px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .verifyRemContainer {
    width: 47% !important;
    margin-bottom: 0 !important;
    /*float: right;*/
  }
  .verifyDontRemContainer {
    width: 47% !important;
    margin-left: 0 !important;
    float: right;
    margin-right: 0 !important;
  }
  .oauth-flow-continue-btn {
    width: 47%;
  }
  .oauth-flow-cancel-btn {
    width: 47%;
  }
  .oauth-flow-pr0 button {
    width: 47%;
  }
  .modal-content {
    height: auto;
  }
  .verifyAndLinkContainer {
    display: flex;
    justify-content: space-between;
  }
  .verifyAndLinkContainer button {
    width: 47%;
  }
}
@media (min-width: 768px) {
  .verifyRemContainer {
    margin-bottom: 0px !important;
  }
  /*.verifyAndLinkContainer {
    display: block;
  }*/
  .verifyContainer {
    width: 25%;
  }
  .linkContainer {
    width: 75%;
    margin-top: 15px;
    margin-left: 0px;
  }
}
.window-content {
  height: 96px;
  width: 90%;
}
.btn-container{
  margin-top: 94px;
  height: 44px;
  width: 100%;
}
.container {
  box-sizing: border-box;
  height: 44px;
  width: 252px;
  border: 1px solid #000000;
  border-radius: 22px;
  display: initial;
}
.button-primary-large-copy {
  width: 252px;
}
.button-text {
  height: 20px;
  width: 183px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
  top: auto;
}
.text-link{
  margin-top: -35px; 
  position: absolute;
  width: 273px;
  left: 322px;
}
.link-text {
  height: 20px;
  width: 270px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.martop100 {margin-top: 105px;}
.bttn-container {
  height: 44px;
  width: 190px;
  border-radius: 22px;
  background-color: #000000;
}
.modal-open {
  padding-right: unset !important;
}
@media(max-width: 640px) {
  .modal-content-container {
    width: 100%;
    height: 100% !important;
    padding: 0px !important;
  }
  .modal-container{
    height: 360px;
    padding: 69px 0px 0px 16px;
  }
  .close-icon-container {
    height: 48px;
    width: 48px;
    position: relative;
    float: right;
  }
  .window-content{
    height: 142px;
    width: 90% !important;
  }
  .modal-content-title {
    height: 58px !important;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;
  }
  .modal-content-description {
    margin-top: 24px;
  }
  .modal-loader {
    margin-top: 62px;
  }
  .btn-container{
    margin-top: 60px;
    height: 92px;
    width: 100%;
    padding-right: 16px;
  }
  .button-primary-large-copy{
    width: 100%;
  }
  .text-link{
    position: inherit;
    margin: auto;
    margin-top: 32px;
    width: 100%;
    display: flow-root;
    text-align: start;
  }
  .modal-btn-container{
    padding-right: 16px;
  }
  .martop100 {margin-top: 0px;}
  .bttn-container {
    width: 100%;
  }
  #noAccessToOtpModal {
    padding-right: 0px !important;
  }
  .close-icon-container {
    top: -12px;
  }
}
@media(max-width: 280px) {
  .modal-content-description{
    max-width: 260px
  }
  .modal-content-title{
    height: auto !important;
  }
  .mar-bottom50 {
    margin-bottom: 60px;
  }
}

/* button align on popup desktop/tablet */
@media(min-width: 641px) {
  .vbg-rel-bottom-40 {
    position: absolute;
    bottom: 40px !important;
  }
}
.remove-hover:hover {
  color: #000000 !important;
}
@media (min-width: 1025px) {
  .dropdown {
    width: 318px;
    height: 44px !important;
  }
  .verifyContainer {
    width: 151px !important;
  }
  .vbg-static-otp-btn {
    width: 260px !important;
  }
  .padding-top-large {
    padding-top: 60px !important;
  }
  .oauth-flow {
    margin-top: 40px !important;
  }
  .vbg-subtitle-container {
    margin-top: 40px;
    margin-bottom: 5px !important;
  }
  .form-group {
    margin-bottom: 60px !important;
  }
}
.labeltiny {
  font-size: 12px !important;
  color: #000000 !important;
  font-family: 'VzNHGeTX-reg11' !important;
}
.vbg-subtitle-container {
  display: flex;
  align-items: center;
  color: #000000 !important;
}

#select-label {
  display: none;
}
.dropdown-menu {
  box-shadow: none !important;
}
/*.dropdown-menu li.chosen {
  border-left: 4px solid #000000 !important;
}*/

.dropdown:focus {
  border: 1px solid #000 !important;
}
.dropdown[class~="open"],
.dropdown:active {
  border: 1px solid #d4d4d4 !important;
}
.dropdown-menu li {
  border-bottom: 1px solid #CDCCCB;
}
.dropdown li a {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-decoration: none;
}
.padleft10 {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.modal-backdrop.in {
  opacity: .8 !important;
}

button:focus {
  outline: 1px dashed #000 !important;
  outline-offset: 2px !important;
}
a.vz-a:focus  {
  outline: 1px dashed #000;
  outline-offset: 2px;
}
.vbg-ff-focus:focus {
  outline: 1px dashed #000;
  outline-offset: 1px;
}
.modal-dialog {
  width: 100%;
}
.vbg-rsp-disabled-label {
  color: #d8dada !important;
  cursor: not-allowed;
}
.border-bottom-disable {
  border-bottom: 1px solid #d8dada;
}
@media screen and (max-width: 540px){
  .modal-dialog.modal-dialog-centered{
      height: 100% !important
  }
  .modal-dialog {
    top: 0% !important;
  }
}
@media (max-width: 1024px) { 
  .dropdown-menu > li > a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    margin-left: 0px;
    padding-left: 12px !important;
  }
   .dropdown-menu li.chosen > a {
    color: #000 !important;
  }
}
</style>
