<template>
    <div>
        <div id="vz-gh20" v-if="this.whichRealm"></div>
        <div class="vbg-ff-main-container">
            <div class="vbg-ff-content-container">
                    <div class="tittle">
                        <h2>{{ mainTitle }}</h2>
                    </div>
                    <div class="sub-tittle">
                        <p>{{ description }}</p>
                    </div>
                    <div class="buttonm btn">
                        <button @click="resetPassword()">
                            {{ buttonVal }}
                        </button>
                    </div>
                    <div class="link-text bottom_underline">
                        <p @click="backToLogin()">Log in with a different User ID</p>
                    </div>
            </div>
        </div>
        <div id="vz-gf20" v-if="this.whichRealm"></div>
    </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import translations from "@/common/assets/json/messages1.json";
export default {
  name: 'ResetPassword',
  data () {
    return {
      whichRealm: true,
      url: "",
      mainTitle: translations.en.message['reset_security_account_locked_tittle'],
      description: translations.en.message['reset_security_account_locked_description'],
      buttonVal: translations.en.message['forgot_pd_reset_link']
    }
  },
  components: {},
  mixins: [commonMixin],beforeMount() {
    // eslint-disable-next-line no-undef
    gnavdl = {
      bu: "smb",
      appid: "vps",
    };
  },
  mounted () {
    console.log('query..',this.$route, translations)
    if(this.$route.params && this.$route.params.lockType == 'resetPassword') {
      this.mainTitle = translations.en.message['reset_security_account_locked_tittle']
      this.description = translations.en.message['reset_security_account_locked_description']
      this.buttonVal = translations.en.message['forgot_pd_reset_link']
    } else if(this.$route.params && this.$route.params.lockType == 'resetSecretAnswer') {
      this.mainTitle = translations.en.message['reset_security_account_locked_sqa_tittle']
      this.description = translations.en.message['reset_security_account_locked_sqa_description']
      this.buttonVal = translations.en.message['reset_secret_answer']
    }
    var globalNav = document.createElement('script')
    globalNav.setAttribute('src', 'https://www.verizon.com/etc/designs/vzwcom/gnav20/smb.js')
    globalNav.setAttribute('async', 'async');
    document.head.appendChild(globalNav);
  },
  methods: {
    ...mapActions("login", [
      "mybizzForget",
      "updateForgotUsername"
    ]),
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      } else {
        return null;
      }
    },
    resetPassword() {
        this.$router.push({ path: "/login/recovery/vpsforgotpassword" });
    },
    backToLogin() {
      this.updateForgotUsername(this.getLockedUserName);
      const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
      if(gotoURL) {
        this.$router.push({ path: '/login/vpsunifiedlogin', query: {goto: gotoURL}})
      } else {
        this.$router.push({ path: '/login/vpsunifiedlogin' })
      }
    }
  },
  computed: {
    ...mapGetters('login', [ 'urlOnMount', 'getLockedUserName' ]),
  },
  watch: {
  }
}
</script>
<style scoped>
.vbg-ff-content-container {
  box-sizing: border-box;
  min-width: 464px;
  max-width: 464px;
  border: 1px solid #979797;
  margin: 56px auto 366px;
  padding: 64px 40px;
  border-radius: 4px;
}
.tittle {
  width: 100%;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 12px;

}
.sub-tittle {
  width: 100%;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.buttonm {
  width: 99%;
  margin: 64px 0px 16px;
  font-size: 16px !important;
  height: 44px;
  background-color: #000;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
}
.btn:focus, .btn:hover {
  color: #fff;
  text-decoration: none;
}
.link-text {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  cursor: pointer;
  width: 208px
  /*text-decoration: underline;*/
}
.bottom_underline {
  text-decoration: none !important;
  border-bottom: 1px solid #000;
  line-height: 1;
  width: 208px;
}
@media (max-width: 1024px) {
  .vbg-ff-main-container {
    padding: 24px 16px 64px;
  }
  .vbg-ff-content-container {
    box-sizing: border-box;
    min-width: 240px;
    max-width: 328px;
    margin: auto;
    padding: 48px 24px;
    border: 1px solid #979797;
  }
  .tittle {
    width: 100%;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .sub-tittle { 
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
  .link-text {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    width: 150px !important;
  }
  .bottom_underline {
    width: 150px !important;
  }
  .buttonm {
    margin: 48px 0px 16px;
  }
}
#tsd-container { 
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

</style>

