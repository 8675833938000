<style scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  text-align: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20px;
}

.forgotFlow {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'VzNHGeDS-reg11' !important;
}
.forgotFlow>a:focus{
  color: #000000;
}

.helpDocs {
  padding-top: 1.0rem !important;
}

.padding-login {
  padding-left: 1px;
}

.showbtn-div {
  position: relative;
}

.showBtn {
  position: absolute;
  float: right;
  top: 0;
  right: 10px;
  right: 12px;
  margin-top: 9px;
  text-decoration: underline;
  cursor: pointer;
  height: 20px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.input-f {
  box-sizing: border-box;
  height: 44px;
  width: 100%;
  border: 1px solid #d8dada;
  background-color: #ffffff;
  margin-bottom: 14px;
  border-bottom: #000000 1px solid !important;
  padding: 6px 12px;
  font-size: 16px;
}

input.tab {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  display: none;
  background: transparent;
  visibility: hidden;
}

input.tab {
  display: inline-block;
  position: absolute;
  margin-left: -6px;
  height: 40px;
  width: 120px;
  cursor: pointer;
}

.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(165, 163, 163, 0.7);
  z-index: 99999999;
  background-image: url('../../../common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

/* input.tab+label { display:none; } */
input.tab+label {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  vertical-align: bottom;
  padding: 6px 24px 15px 0;
  margin: 0;
  border-bottom: solid 1px #ccc;
  /* font-size: 12px !important; */
  width: 100%;
  text-align: center;
  box-shadow: none;
}

@media (min-width:1025px) {
  input.tab+label {
    font-size: 16px !important;

  }

}

input.tab+label:not(:hover) {
  color: #585858;
}

input#useSignin+label {
  padding-left: 24px;
  padding-right: 0;
}

input.tab:checked+label {
  border-bottom: solid 4px #d52b1e;
  padding: 6px 0 12px !important;
  color: #000;
  font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
}

.primary_black {
  display: flex;
  margin: 24px auto;
  font-size: 16px;
}
.primary_black_enabled{
background-color: #000;
display: flex;
  margin: 24px auto;
  font-size: 16px;
  color:#fff;
}
.primary_black_enabled:hover{
background-color: #000;
display: flex;
  margin: 24px auto;
  font-size: 16px;
  color:#fff;
}
.primary_black_disabled{
background-color: #d8dada;
display: flex;
  margin: 24px auto;
  font-size: 16px;
  color:#fff;
  font-family: 'VzNHGeDS-bold11' !important;
  line-height: 20px !important;
}
.primary_black_disabled:hover{
background-color: #d8dada;
display: flex;
  margin: 24px auto;
  font-size: 16px;
  color:#fff;
}

.form-control{
  box-shadow: none !important;
}


.ErrorPanel {
  background: rgb(255, 236, 224);
  padding: 20px;
  margin-top: 24px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 32px;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 31px !important;
  font-weight: normal !important;
  opacity: initial !important;

}

.signinbtn {
  text-align: center;
}

.main_container {
  margin-top: 32px;
}

.form-group {
  margin-bottom: 0;
}

.loginmar20 {
  margin: 0 auto;
  width: 92%;
  margin-top: 30px;

  .Reg {
    width: 50%
  }
}

@media (min-width:1025px) {
  .loginmar20 {
    width: 31%;
  }
}

.welcometxt {
  font-size: 32px;
  font-weight: bold;
  /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
  font-family: 'VzNHGeDS-bold11' !important;
  color: #000;
}

.vz-input-field,
.input-f {
  border: 1px solid #000;
  border-radius: 5px !important;
  height: 40px;
  font-family: 'VzNHGeDS-reg11' !important;
}

.welsub {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.75rem;
  /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
  font-family: 'VzNHGeDS-reg11' !important;
  color: rgb(0, 0, 0);
  margin: 0px;
  text-decoration: none;
  margin-top: 20px;
}

.labeltiny {
  color: #000 !important;
  font-size: 12px;
  /* font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif; */
  font-family: 'VzNHGeTX-reg11' !important;

}

.errortxt {
  padding-top: 5px;
  display: block;
  font-size: 12px;
  /* font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif; */
  font-family: 'VzNHGeTX-reg11' !important;

}

.password {
  margin-top: 24px;
}

.themes {
  background-color: rgb(255, 236, 224);
  border: 0.0625rem solid rgb(185, 83, 25);
}

.username {
  position: relative;
}

.hidden {
  display: none
}

.validmail {
  background-color: transparent;

}

.warningsymbol,
.validsymbol {
  width: 18px;
  display: block;
  position: absolute;
  right: 10px;
  top: 32px
}


.validsymbol {
  top: 16px;
  right: 5px;
}

@media (min-width:1025px) {
  input.tab+label {
    font-size: 16 !important;
  }

  .welcometxt {
    font-size: 32px !important;
    text-align: center;
  }

  .welsub {
    font-size: 20px;
    text-align: center !important;
  }
}

.container_signin {
  /* border-top: 2px solid #ebecec; */
  /* width: 80%; */
  /* margin: 0 auto; */
  min-height: 500px;
  display: flex;
  justify-content: center;
  padding: 0px 16px;
}

.login_page {
  width: 384px;
  margin: 0 auto;
  padding-top: 32px;
}

.login_sub {
  width: 100%;
}

@media (max-width:767px) {
  /* .container_signin {
    width: 95%;
  } */
  .fbqHCo{
  font-size: 12px;
  line-height: 16px;
}
  .welcometxt {
    font-size: 32px !important;
  }
.welsub {
  font-size: 20px;
}
  .login_page {
    width: 100%;
  }
}


.toast-para {
  padding: 0px 60px;
  font-size: 16px;
  line-height: 18px;
  font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
  letter-spacing: 0.03125rem;
  margin: 0px;
  text-decoration: none;
}

.reset-btn-div {
  padding-left: 60px;
  padding-top: 16px;
}

.toast-btn {
  padding: 4px 14px;
  background-color: transparent;
  border: 1px solid rgb(0, 0, 0) !important;
  color: rgb(0, 0, 0);
  border-radius: 2rem;
  line-height: 20px;
  font-weight: 700;
}

.toast-btn:hover {
  border: 5px solid rgb(0, 0, 0);
}

.toast-symbol {
  width: 16px;
}

.toast-container {
  background-color: rgb(255, 236, 224);
  padding: 1.25rem;
  border-radius: 0.25rem;
}

.toast-heading {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
  letter-spacing: 0.03125rem;
  color: rgb(0, 0, 0);
  margin: 0px;
  text-decoration: none;
}

@media (max-width:767px) {
  .reset-btn-div {
    padding: 10px 24px;
  }
  .toast-para {
    font-size: 13px;
    padding: 4px 20px;
    font-weight: 540;
    color: #000;
  }

  .toast-heading-span {
    font-weight: 780;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .toast-heading {
    font-size: 12px;
    font-weight: 750;
    line-height: 20px;
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
    letter-spacing: 0.03125rem;
    color: rgb(0, 0, 0);
    color: #000;
    margin: 0px;
    text-decoration: none;
  }
}

.register_link {
  border: 1px solid black;
  padding: 8px 16px;
  border-radius: 51px;
  color: #000;
  background-color: rgb(255, 236, 224);
  font-family: 'VzNHGeDS-bold11' !important;
}
.navbar{
  background-color: #000;
  border-radius: 0px !important;
  padding-bottom: 22px;
}
.header-container{
  margin-top: 0px !important;
  width: 100% !important;
  padding-left: 22px;
}
.toast-link {
  margin-top:15px;
}
.pass_err{
color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
}
.warn_sign_pass{
  width: 18px;
  display: block;
  position: absolute;
  right: 60px;
  top: 10px
}
.toaster:focus{
  outline: 1px solid #000;
}
.err_pass_input {
  background-color: rgb(255, 236, 224);
  border: 0.0625rem solid rgb(185, 83, 25);
  border-bottom: 0.0625rem solid rgb(185, 83, 25) !important;
}
.reg_link {
    width: 50%;
    display: inline-block;
    &:focus{
    outline: 1px solid black;
    }
}
.reg {
  margin-bottom: 0;
  font-family: 'VzNHGeDS-bold11' !important;
}
.introtext {
  margin-top: 30px;
}
.back_link{
    /* float: left; */
    margin-left: 40px;
    /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
  font-family: 'VzNHGeDS-bold11' !important;
    font-weight: bold;
    /* margin-top: 32px !important; */
}
.link_back{
    text-decoration: none;
    color: #000000 !important;

}
.tab_link_text{
  font-family: 'VzNHGeDS-bold11' !important;
font-size: 16px !important;
}
.welcometxt {
    font-size: 32px !important;
    line-height: 36px;
  }
  .welsub{
    line-height: 24px !important;
  }
@media (min-width:768px) {
.welcometxt, .welsub{
  text-align: center;
}
}
</style>

<template>
  <div>
    <div id="vz-gh20"></div>
        <div>
        <div>
            <!-- <div class="full-height">
            <div class="clearfix padding-top-large padding-bottom-xlarge padding-left-small margin-left-xlarge  padding-top-logo">
                <div class="col-xs-12 col-sm-4">
                <img src="../common/images/Check.svg" class="hlogo" />
                </div>
            </div>
            </div> -->        
            
        <!-- <div class = "back_link">
              <a class = "link_back" href="#" @click="$router.back()">
              <span aria-hidden="true">&#60;</span>
              {{$t('message.sign_in_page_back_link')}}
            </a>
        </div> -->

            <div v-if="getInfo || submitLoader" class="cover-spin"> </div>
            <div v-else class="container_signin">
            <div class="container-to-your-business-acc ">
                <div v-if="meth == 'signin'">
                <button type="submit" v-if="meth == 'reg'" @click="onRegister" />
                <div class="containers">
                    <div class="login_page">
                    <div class="login_sub">
                        <div class="login_heading">
                        <div tabindex="0" class = "reg_link"> 
                        <input type="radio" v-model="meth" class="tab" name="meth" value="reg" @change="onTabChange($event)"
                            id="useReg" />
                        <label class="Reg tab_link_text" for="useReg">{{$t('message.sign_in_page_signin_btn_register_link')}}</label>
                        </div>
                        <div tabindex ="0" class = "reg_link"> 
                        <input type="radio" v-model="meth" class="tab" name="meth" value="signin"
                            @change="onTabChange($event)" id="useSignin" />
                        <label class="reg tab_link_text" for="useSignin">{{$t('message.sign_in_page_signin_btn')}}</label>
                        </div>
                        </div>
                        <div v-if="getAuthError"
                        :style="{ padding: '20px 15px', margin: '40px 0px', display: 'block'}" class="toast-container"
                        id="toast-active" tabindex = '0'>
                        <div class="toast-heading" :style="{ display: 'flex', justifyContent:'space-between', alignItems:'flex-start'}">
                            <div class="toast-symbol" :style="{display:'flex', alignItems:'center'}">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 21.6 21.6">
                                <path
                                    d="M10.80213,19.80122a2.39567,2.39567,0,0,1-1.705-.707L2.50743,12.50444a2.41244,2.41244,0,0,1,0-3.40913L9.09808,2.50555a2.4159,2.4159,0,0,1,3.40908-.001l6.58967,6.59073a2.41244,2.41244,0,0,1,0,3.40913L12.50716,19.0942A2.394,2.394,0,0,1,10.80213,19.80122Zm-7.4998-9.911a1.289,1.289,0,0,0,0,1.81931L9.893,18.29929a1.31476,1.31476,0,0,0,1.81928,0l6.58967-6.58976a1.289,1.289,0,0,0,0-1.81931L11.71226,3.30047a1.29076,1.29076,0,0,0-1.81928,0ZM9.95,15.05h1.7V13.367H9.95Zm0-6.00953.561,2.635h.56952l.56951-2.635V6.55H9.95Z">
                                </path>
                                </svg>
                            </div>
                            <div class="toast-heading" :style="{ paddingLeft: '5px', display:'flex', alignItems:'flex-start', flexDirection:'column', width:'100%', justifyContent:'flex-start' }">
                            {{getAuthError.errorMessage}}
                            <div class="toast-link" :style="{ marignTop: '30px', display: 'inline-block' }">
                            <button v-if="getAccountDisabled" class="register_link" tabindex="0"
                                @click="onResetPassword">{{$t('message.sign_in_page_resetpwd_link')}}</button>
                            <!--<button class="register_link" id="registerButton" @click="onRegister">Register</button>-->
                            <!--<a v-bind:href="'https://accessmgrqa3.verizonwireless.com/accessmanager/public/c/reg/guest/start?clientId=vag'" class="register_link">Register</a>-->
                            </div>
                            </div>
                            <div @click="handleToaster" :style="{ cursor: 'pointer', display:'flex', alignItems:'center' }" class="toast-symbol">
                            <svg tabindex = '0' aria-label="ErrorMessageCloseicon" class="toaster" id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.6 21.6" version="1.1" x="0px" y="0px">
                                <path
                                d="M12.2,10.8l6.8,6.8L17.6,19l-6.8-6.8L4,19l-1.4-1.4l6.8-6.8L2.6,4L4,2.6l6.8,6.8l6.8-6.8L19,4L12.2,10.8z">
                                </path>
                            </svg>
                            </div>
                        </div>
                        <div>
                        </div>
                        </div>

                        <div class="introtext">
                        <h1 class="welcometxt">{{$t('message.sign_in_page_main_heading')}}</h1>
                        <p class=" welsub">
                        {{$t('message.sign_in_page_sub_heading')}}
                        </p>
                        </div>
                        <div class="main_container">
                        <div id="dynamic-div">
                            <div class="form-group margin-top-medium">
                            <div class="username">
                                <label class="labeltiny" for ="enterUserName">{{$t('message.sign_in_page_enter_email_field')}}</label>
                                <input type="text" class="form-control vz-input-field"
                                :class="emailError ? 'validmail' : ' themes '" v-on:change="onUserNameSubmit"
                                id="enterUserName" v-model="form.username" @input="isEmailValid" tabindex ="0"/>
                                <svg :class="emailError ? 'hidden' : 'warningsymbol'" id="Layer_3" data-name="Layer 1"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.6 21.6">
                                <path
                                    d="M10.80213,19.80122a2.39567,2.39567,0,0,1-1.705-.707L2.50743,12.50444a2.41244,2.41244,0,0,1,0-3.40913L9.09808,2.50555a2.4159,2.4159,0,0,1,3.40908-.001l6.58967,6.59073a2.41244,2.41244,0,0,1,0,3.40913L12.50716,19.0942A2.394,2.394,0,0,1,10.80213,19.80122Zm-7.4998-9.911a1.289,1.289,0,0,0,0,1.81931L9.893,18.29929a1.31476,1.31476,0,0,0,1.81928,0l6.58967-6.58976a1.289,1.289,0,0,0,0-1.81931L11.71226,3.30047a1.29076,1.29076,0,0,0-1.81928,0ZM9.95,15.05h1.7V13.367H9.95Zm0-6.00953.561,2.635h.56952l.56951-2.635V6.55H9.95Z">
                                </path>
                                </svg>
                                <div id="validatedSymbol" class="hidden">
                                <svg :class="emailError ? 'validsymbol' : 'hidden'" id="LayerValid" data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.6 21.6">
                                    <path
                                    d="M10.80009,19.80015a9,9,0,1,1,9-9A9.01054,9.01054,0,0,1,10.80009,19.80015Zm0-16.87512a7.87512,7.87512,0,1,0,7.87512,7.87512A7.88411,7.88411,0,0,0,10.80009,2.925ZM14.75,8.63964,13.95474,7.85,9.62367,12.17072,7.64331,10.20631,6.85,10.9979,9.62562,13.75Z">
                                    </path>
                                </svg>
                                </div>
                                <div id="Errortext">
                                <a v-if="emailErrortxt" class="errortxt" id="errortxt" style="color: #000;" aria-described ="Errortext" role="alert">{{$t('message.sign_in_page_enter_valid_email')}}</a>
                            </div>
                            </div>
                            <label class="labeltiny password" for ="enterPassword">{{$t('message.sign_in_page_enter_pwd')}}</label>
                            <div class="showbtn-div" >
                                <input class="input-f" :type="show1 ? 'text' : 'password'" v-on:change="onPasswordSubmit"
                                id="enterPassword" required v-model="form.password" :class="input-f" tabindex ="0"/>
                                
                                    <a class="showBtn" v-if="!show1 && form.password" @click="show1 = !show1" tabindex ="0">{{$t('message.sign_in_page_show_link')}}</a>
                                <a class="showBtn" v-if="show1 && form.password" @click="show1 = !show1" tabindex ="0">{{$t('message.sign_in_page_hide_link')}}</a>
                                <!-- <a class="showBtn" v-if="!show1 && form.password" @click="show1 = !show1" tabindex ="6">{{show1 ? 'Hide' : 'Show'}}</a> -->
                                
                            </div>
                            </div>
                        </div>
                        <div class="padleft0 col-xs-12 margin-bottom-large signinbtn padding-top-large">
                            <button type="submit" :class="isFormComplete ? 'btn primary_black_enabled  ' : 'btn primary_black_disabled'" id="submitButton" @click="onSubmit"
                            :disabled="!isFormComplete || getAccountDisabled" tabindex ="0">{{$t('message.sign_in_page_signin_btn')}}</button>
                            <button class="hidden" id="registerButton" @click="onRegister" >{{$t('message.sign_in_page_signin_btn_register_link')}}</button>
                            <a @click="onResetPassword" class="forgotFlow" tabindex ="0"><u>{{$t('message.sign_in_page_forgot_pass_link')}}</u></a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    <div id="vz-gf20" style = "clear:both"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default ({
  name: 'login',
  data() {
    return {
      meth: "signin",
      show1: false,
      emailError: true,
      emailErrortxt: false,
      form: {
        username: "",
        password: "",
      },
      forgotFlowUrl: '/account/business/login/forgotPasswordLanding',
      regUrl : regUrl,
      selectedLanguage: this.$i18n.locale,
      supportedLanguages: [
        { code: 'en', name: 'English' },
        { code: 'es', name: 'Español' },
      ]
    }
  },
  methods: {
    ...mapActions("valogin", [
      "getSessionInfo",
      "getcallbacks",
      "onLogin",
      "updateGotoUrl",
      "updateAuthError",
      "updateGotoSessionUrl"
    ]),
    apiCallbacks() {
      this.getSessionInfo();
      // this.getcallbacks();
      this.submitLoader();
      // this.onLogin();
    },
    handleToaster() {
      this.updateAuthError(false);
    },
    onTabChange(event) {
      this.meth = event.target.value;
      if (event.target.value == 'reg') {
        event.preventDefault();
        document.getElementById("registerButton").click();
      }
    },
    onResetPassword() {
        this.$router.push({ path: 'forgotPasswordLanding' })
    },
    isEmailValid() {
      if ((/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.username))) {
        this.emailError = true;
        this.emailErrortxt = false;
        document.getElementById("enterUserName").style.backgroundColor = 'rgb(220, 245, 230)'
        document.getElementById("enterUserName").style.border = '1px solid #008331'
        document.getElementById("validatedSymbol").className = "validsymbol"

      } else {
        this.emailError = false
        this.emailErrortxt = true;
        document.getElementById("Layer_3").style.display="block";
        document.getElementById("enterUserName").style.backgroundColor = 'rgb(255, 236, 224)'
      }
    },
    onPasswordSubmit() {
      var passSubmit = document.getElementById("enterPassword");
      passSubmit.addEventListener("keyup", function (e) {
        if (e.code === "Enter") {
          e.preventDefault();
          document.getElementById("enterUserName").style.backgroundColor = 'transparent'
          document.getElementById("validatedSymbol").className = "hidden"
          document.getElementById("submitButton").click();
        }
      });
    },
    onUserNameSubmit() {
      var passSubmit = document.getElementById("enterUserName")
      passSubmit.addEventListener("keyup", function (e) {
        if (e.code === "Enter") {
          e.preventDefault();
          document.getElementById("enterUserName").style.backgroundColor = 'transparent'
          document.getElementById("validatedSymbol").className = "hidden"
          document.getElementById("submitButton").click();
        }
      });
    },
    onSubmit() {
      document.getElementById("enterUserName").style.backgroundColor = 'transparent'
      document.getElementById("validatedSymbol").className = "hidden"
      this.form.username = this.form.username.trim();
      this.getcallbacks(this.form);
    },
    onRegister() {
      var linkerParam
      var tracker
      // Gets the default tracker and returns the linker param (the _ga parameter)
      window.ga(function () {
        tracker = window.ga.getAll()[0];
        linkerParam = tracker.get('linkerParam');
      });
      if (linkerParam == undefined) {
        window.location.href = regUrl
      } else {
        var urlAppend = regUrl + "&" + linkerParam
        window.location.href = urlAppend
      }
    },
    updatetheurl(goto) {
      this.updateGotoUrl(goto);
    },
    getParameterByNameURL(name, url) {
      const finalUrl = !url ? window.location.href : url;
      const formattedName = name.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${formattedName}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(finalUrl);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
  },
  computed: {
    ...mapGetters("valogin", [
      "submitLoader",
      //"getterCallbacks",
      "getOtpCallbacks",
      "getAuthError",
      "getErrorCallbacks",
      "getAccountLock",
      "getInfo",
      "getTimer",
      "getAccountDisabled"
    ]),
    isFormComplete() {
      return this.form.username.length !== 0 && this.form.password.length !== 0 && this.emailError
    },
  },
  watch: {
    getAuthError: function(newVal) {
      if(newVal) {
        setTimeout(() => {
         if(document.getElementById("toast-active")){
          document.getElementById("toast-active").focus();
        }
        });
      }
    }
  },
  created: function () {
  const storedLanguage = localStorage.getItem('lng');
  const browserLanguage = navigator.language.substring(0, 2);
  const searchParams = new URLSearchParams(window.location.search);
  const decodedSearchParams = decodeURIComponent(searchParams);
  const localeValue = searchParams.get('ciamLang') ? searchParams.get('ciamLang') : this.getParameterByNameURL('ciamLang',decodedSearchParams);
  if (storedLanguage) {
    this.$i18n.locale = storedLanguage;
    this.selectedLanguage = storedLanguage;
  } else if(!storedLanguage && localeValue) {
    this.$i18n.locale = localeValue;
    this.selectedLanguage = localeValue;
    localStorage.setItem("lng",localeValue);
  } else if (this.supportedLanguages.some(lang => lang.code === browserLanguage)) {
    this.$i18n.locale = browserLanguage;
    this.selectedLanguage = browserLanguage;
  }
 
    setTimeout(() => {
      this.apiCallbacks()
    }, 1600);
  },
  mounted() {
    //HardCoding for Loading these files
    var body = document.getElementsByTagName('body')[0];
    var script1 = document.createElement('script');
    var script2 = document.createElement('script');
    script1.src = "https://www.google-analytics.com/plugins/ua/linkid.js";
    script2.src = "https://www.google-analytics.com/analytics.js";
    body.appendChild(script1);
    body.appendChild(script2);

    //GTM dataLayer
    var dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'environment': env_envVAR,
      'channel': 'va',
      'partner': 'verizon',
    });
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
      dataLayer.push({
        "event": "page_view",
        "detailed_event": "Page Load Completed"
      })
    } 
    }
    if (document.readyState == "loading" || document.readyState == "interactive") {
      dataLayer.push({
      "event": "page_load_started",
      "detailed_event": "Page Load Started",
        "event_data": {
            "profile_status": "",
            "topics": ""
        },
        "page_data": {
            "country": "US",
            "language": this.$i18n.locale,
            "page_location": window.location.href,
            "page_referrer": pageReferrer,
            "page_title": "Verizon Small Business Account Login"
        },
        "user_data": {
            "user_id": "",
            "user_login_state": "logged out"
        }
    });
    }
    document.documentElement.lang = this.$i18n.locale;
      setTimeout(() => {
        const langSwitchLink = document.querySelector('.gnav20-lang-link');
         if(langSwitchLink) {
          document.addEventListener('click', (e)=> {
          const langValue = e.target.getAttribute('data-lang');
          if(langValue) {
            if(langValue === 'English' || langValue === 'Inglés') {
                localStorage.setItem("lng","en");
            } else {
                localStorage.setItem("lng","es");
            }
          }
          })
          var locale = this.$i18n.locale;
          var variationValue = locale == 'es' ? 'prospect-es' : 'prospect';
          var globalNavdl = {
            "bu": "corporate",
            "appid": "vsbdr",
            "variation": variationValue,
            "options":{"hide-item":["search"]}
          }
          window.gnavdl = globalNavdl;
         }
       }, 1500);  
    //GTM script
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KJFSV78');
    //Capturing goto url from sign in page
    //Google Analytics Tracking
    let goto = '';
    let qparams = new URLSearchParams(window.location.search);
    if (qparams.has('goto')) {
        goto = qparams.get('goto');
    } else {
        goto = relayState;
    }
    this.updateGotoSessionUrl(goto)
    var linkerParam
    var tracker
    // Gets the default tracker and returns the linker param (the _ga parameter)
    var self = this;
    setTimeout(() => {
           
      window.ga(function () {
        tracker = window.ga.getAll()[0];
        if (tracker != undefined) {
          linkerParam = tracker.get('linkerParam');
        }
        // console.log(linkerParam)
        if (tracker == undefined || tracker == null || tracker === '' || linkerParam == undefined ||
          linkerParam == null || linkerParam === '') {
          self.updatetheurl(goto);
        } else {
          let encodedRelayState = encodeURIComponent(linkerParam)
          goto = goto + "?linkerParam=" + encodedRelayState
          self.updatetheurl(goto)
        }
      });
    }, 1500);
    var globalNav = document.createElement('script')
    globalNav.setAttribute('src', 'https://www.verizon.com/etc/designs/vzwcom/gnav20/corporate.js')
    globalNav.setAttribute('async', 'async');
    document.head.appendChild(globalNav)
  }
})
</script>
