<template>
    <div>
        <Header></Header>
        <div class="options_container">
            <div class="opt-container-1">
                <BlockUI v-if="submitLoader" :url="url"></BlockUI>
                <div class="pwdPushRError" id="errorIAMPush" style="display:none" aria-hidden="true">
                    <img class="warningImage" :src="warningImage" />
                    <p class="pushTextError" id="IAMerrorMessage"></p>
                    <img class="closeImage" :src="closeImage" @click="deleteAlert()" />        
                </div>
                <div class="opt-container-1-1">
                    <h3 class="opt-header-1">How do you want to log in?</h3>
                    <div>
                        <p class="opt-p-1">User ID</p>
                        <div style="display: flex;">
                            <p class="opt-username">{{ this.pwdUsername }}</p>
                            <button class="opt-button-1" id="changeUserID" name="changeUserID" @click="gotoPwdLessLogin()">Change</button>
                        </div>
                    </div>
                    <div class="opt-optionsTable">
                        <table>
                            <tr v-for="item in pwdAuthOptions" v-if="item.available">
                                <hr class="opt-horizontal-1" />
                                <div v-if="!item.disable" id="loginMethodEnabled" class="arrow_block" @click="redirectToMethod(item.pagePath)">
                                    <div class="arrow_content">
                                        <a class="opt-title">{{ item.title.replace('phone or email', 'email') }}</a>
                                        <a Class="opt-sub-title">{{ item.subTitle.replace('phone number or email address', 'email address') }}</a>
                                    </div>
                                    <div>
                                        <img src="@/common/assets/styles//corp/images/right-arrow.png"
                                            style="height:20px; width: 20px; cursor: pointer;" />
                                    </div>
                                </div>
                                <div v-if="item.disable" id="loginMethodDisabled" style="opacity: 0.5;" class="arrow_block">
                                    <div class="arrow_content">
                                        <a class="opt-title pwdLess-opt-disabled">{{ item.title.replace('phone or email', 'email') }}</a>
                                        <a Class="opt-sub-title pwdLess-opt-disabled">{{ item.subTitle.replace('phone number or email address', 'email address') }}</a>
                                    </div>
                                    <div>
                                        <img src="@/common/assets/styles//corp/images/right-arrow.png"
                                            style="height:20px; width: 20px;" />
                                    </div>
                                </div>
                            </tr>
                            <!-- <hr class="opt-horizontal-1" /> -->
                        </table>
                    </div>
                </div>
            </div>
            <div class="opt-container-2">
                <div class="opt-default-promo1">
                    <p class="opt-default-promo">Manage your Verizon Partner Solutions transactions online anytime, anywhere.</p>
                </div>
            </div>
        </div>
        <Footer class="footer-margin-top"></Footer>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import Header from '@/vbgauth/components/vpsHeader'
import Footer from '@/vbgauth/components/vpsFooter'
export default {
  name: 'VPSPwdLessOption',
  components: {
    Header,
    Footer,
  },
  mixins: [commonMixin],
  data() {
    return {
        url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
        warningImage : iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/warning.png',
        closeImage: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/close_black.png',
        //pwdUsername: getCookie("pwdUserName"),
    }
  },
  mounted() {
    // setGotoUrlInClientLib(this.getParameterByName('goto'))
    // this.gotoUrl = this.$route.query.goto;
    // if (this.gotoUrl != null) {
    //     // determine if there is a goto with a hashtag
    //     let hashValue = window.location.hash.substr(1);
    //     if (hashValue != "") {
    //       let hashInUrl = '#' + window.location.hash.substr(1);
    //       if (this.gotoUrl != null) {
    //         this.gotoUrl += hashInUrl;
    //         this.$store.dispatch('login/updateGotoUrl', this.gotoUrl);
    //       }
    //     }
    // }
    this.resetState();
    if (iamvar_pwdlessVetLock == true) {
        this.pwdAuthOptions[1].disable = true
        if(document.getElementById("IAMerrorMessage")) {
            document.getElementById('errorIAMPush').style = 'display: flex;'
            document.getElementById("IAMerrorMessage").innerHTML = "<b>You have no remaining verification attempts with phone or email</b><p>Please wait 24 hours to access your account. Use another way to login."
        }
    }
    if (this.pwdUsername == null) {
        this.$router.push({ name: 'VPSPwdLessLoginRootComponent'})
    }
    document.addEventListener("click", (e) => {
      if (e.target.id == "resendN") {
        this.resendNotification()
      }
    });

    this.changeContextBiocatch('pwdless_login_options_page')
  },
  methods: {
    ...mapActions('cvsstepup', [
        'updateOTPAlwaysOn',
        'initializeStepup'
      ]
    ),
    ...mapActions('login', [
      'submitPwdAMPasswordForm',
      'submitPwdLessPushLogin',
      'resetState',
      'updateUrlOnMount' // updates urlOnMount on both cvsstepup and login modules
    ]),
    resendNotification() {
        this.submitPwdLessPushLogin({userName:this.pwdUsername});
    },
    gotoPwdLessLogin() {
   
      if(this.gotoUrl != null) {
          this.$router.push({ name: 'VPSPwdLessLoginRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
      } else {
          this.$router.push({ name: 'VPSPwdLessLoginRootComponent' });
      }
    },
    redirectToMethod(path) {
        if (path == 'PwdLessVerify') {
            this.formData[0].input[0].value = this.pwdUsername
            this.formData[1].input[0].value = ''
            let payload = {
                formData: this.formData,
            };
        
            this.submitPwdAMPasswordForm(payload);
        } else if (path == 'PwdLessPush') {
            this.submitPwdLessPushLogin({userName:this.pwdUsername});
        } else if (path == 'PwdLessPassword') {
            this.$router.push({ name: 'VPSPwdLessPassword' , query: { goto: this.gotoUrl } });
        }
    },
    deleteAlert() {
        document.getElementById('errorIAMPush').setAttribute('style', 'display:none');
    }
  },
  computed: {
    ...mapGetters('cvsstepup', [
      'flowInitialized',
      'stepupReason',
      'urlOnMount',
      'otpAlwaysOn',
      'dnrStatus',
      'currentStep'
    ]),
    ...mapGetters('login', [ 'gotoUrl', 'pwdUsername', 'redirectToStepupPage', 'submitLoader', 'pwdAuthOptions', 'redirectToSecProfilePage' ]),
    formData: {
        get: function () {
            return this.$store.getters['login/formData'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/updateFormData', newVal);
        }
    },
    pwdUsername: {
        get: function () {
            return this.$store.getters['login/pwdUsername'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/setPwdUsername', newVal);
        }
    },
    pwdAuthOptions : {
        get: function () {
            return this.$store.getters['login/pwdAuthOptions'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/pwdAuthOptions', newVal);
        }
    },
    gotoUrl: {
        get: function () {
            return this.$store.getters['login/gotoUrl'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/updateGotoUrl', newVal);
            this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
        }
    },
  },
  watch: {
    pwdUsername: function (newVal) {
      if(newVal) {
        this.formData[0].input[0].value = newVal
      }
    },
    pwdAuthOptions: function (newVal) {
        if(newVal) {
            this.pwdAuthOptions = newVal
        }
    },
    redirectToStepupPage: function(newVal) {
        if(newVal) {
            this.initializeStepup();
        }
    },
    currentStep: function(newVal) {
      if(newVal === 'FLOW_INITIALIZED') {
        if(this.gotoUrl != null) {
          this.$router.push({ name: 'VPSPwdLessOtpDelivery', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: 'VPSPwdLessOtpDelivery' });
        }
      }
    },
    redirectToSecProfilePage: function(newVal) {
      if(newVal) {
        window.location.href = newVal;
      }
    },
  }
}  
</script>

<style scoped>
.opt-container-1 {
    width: 464px;
    /* height: 581px; */
    border: solid;
    border-color: #D8DADA;
    border-width: 1px;
    margin-bottom: 198px;
    /* margin-left: 290px; */
    border-radius: 4px;
}
.pwdPushRError {
    display: block;
    background-color: #FFECE0;
    height: auto;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.warningImage {
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 36px;
    width: 24px;
    height: 24px;
}
.pushTextError {
    margin-top: 16px;
    margin-right: 8px;
    margin-bottom: 16px;
    margin-left: 8px;
    height: auto;
    font-size: 12px;
    line-height: 16px;
    flex: 1;
    font-family: 'VzNHGeTX-reg11';
    color: #000000 !important;
    flex: 1 !important;
}
.closeImage {
    margin-top: 16px;
    margin-right: 16px;
    margin-bottom: 36px;
    float: right;
    width: 11.7px;
    height: 11.7px;
}
.opt-container-1-1 {
/* height: 485px; */
    margin-top: 48px;
    margin-left: 40px;
    margin-right: 40px;
}
.opt-header-1 {
    /* width: 382px; */
    height: 36px;
    font-family: 'VzNHGeDS-bold11';
    font-size: 32px;
    margin-bottom: 32px;
}
.opt-p-1 {
    width: 298px;
  /*  height: 20px; */
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
}
.opt-username {
    max-width: 80%;
    height: 24px;
    font-family: 'VzNHGeDS-bold11';
    font-size: 20px;
    margin-top: 4px;
    word-break: break-all;
}
.opt-button-1 {
    width: 57px;
    height: 20px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    margin-top: 4px;
    margin-left: 12px;
    /*text-decoration: underline;*/
    text-decoration: none !important;
    border-bottom: 1px solid #000;
}
.opt-optionsTable {
    width: 384px;
    height: auto;
    margin-right: 40px;
    margin-top: 24px;
    margin-bottom: 48px;
}
.opt-title {
    width: 298px;
    height: 28px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 24px;
    margin-bottom: 8px;
    display: block;
    text-decoration: none;
}
.opt-sub-title {
    width: 298px;
    height: auto;
    font-family: 'VzNHGeTX-reg11' !important;
    font-size: 14px;
    line-height: 19px;
    display: block;
    text-decoration: none;
    letter-spacing: 0.5px;
}
.opt-horizontal-1 {
    width: 384px;
    height: 1px;
    fill: solid;
    color: #D8DADA;
    margin-bottom: 24px;
    margin-top: 24px;
    margin-right: 40px;
}
.opt-container-2 {
    margin-left: 172px;
    margin-bottom: 20px;
}
.opt-default-promo1 {
    background: #F6F6F6;
    height: 650px;
    width: 464px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.opt-default-promo {
    height: 128px;
    width: 253.78px;
    color: #A7A7A7;
    font-family: VzNHGeDS-bold11;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
}
.footer-margin-top {
    margin-top: 64px;
}
.pwdLess-opt-disabled {
    cursor: default !important;
}
@media only screen and (min-width: 280px) and (max-width: 1200px) {
    .opt-default-promo {
        display: none;
    }

    .opt-default-promo1 {
        display: none;
    }
}

/* responsive styles starts */
.options_container {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    /* margin-left: 18px; */
}

@media only screen and (min-width: 280px) and (max-width: 1200px) {
.opt-container-1,
.opt-container-2 {
  margin-left: 0 !important;
}
}

.arrow_block {
    display: flex;
    justify-content: space-between;
    max-width: 384px;
}

@media only screen and (max-width: 1200px) {
    .options_container {
        display: flex;
        justify-content: center;
        margin-top: 48px;
    }

    .opt-container-2 {
        display: none !important;
    }

    .opt-container-1 {
        /* max-width: 464px !important; */
        height: auto !important;
    }

}

@media only screen and (max-width: 768px) {
    .opt-header-1 {
        font-size: 32px !important;
        max-width: 100% !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 500px) {
    .opt-optionsTable {
        max-width: 320px !important;
        margin-top: 18px !important;
    }
    .opt-container-1 {
margin-bottom: 0 !important;
    }

    .opt-container-1-1 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .opt-container-1 {
        /* border: none !important; */
        max-width: 300px !important;
    }

    /* .arrow_content {
        width: 298px;
    } */

    .opt-horizontal-1 {
        width: 100% !important;
        margin-right: 0 !important;
    }

    .opt-header-1 {
        font-size: 24px !important;
        /* max-width: 100% !important; */
        height: auto !important;
        padding-bottom: 30px !important;
    }
    .opt-sub-title{
        width: 264px !important;
        letter-spacing: 0.5px;
    }
    .opt-title {
        font-size: 20px !important;
        width: 260px !important;

    }
    .options_container {
    margin-top: 0px !important;
}
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
    .opt-container-1 {
        max-width: 300px !important;
        border:none !important;
        
    }
    .opt-horizontal-1 {
        width: 100% !important;
        margin-right: 0 !important;
    }

}
@media only screen and (max-width: 1023px) {
    .container {
    max-width: 100%;
}
.opt-title {
    width: 298px;
    /* height: 28px; */
    font-family: 'VzNHGeDS-reg11';
    font-size: 20px !important;
    margin-bottom: 8px;
    display: block;
    text-decoration: none;
    line-height: 24px !important;
    letter-spacing: normal;
}
.opt-sub-title {
    /* width: 298px; */
    height: auto;
    font-family: 'VzNHGeTX-reg11' !important;
    font-size: 12px !important;
    line-height: 16px;
    display: block;
    text-decoration: none;
    letter-spacing: normal !important;
}
.footer-margin-top{
    margin-top: 0px !important;
}
    .opt-container-1{
        border: none !important;
        max-width: 100% !important;
        width: 100% !important;
        margin-bottom: 24px !important;
    }
    .opt-username{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #000000;
    }
    .opt-button-1{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #000000;
    }
    .options_container{
        margin-top: 32px !important;
    }
    .opt-container-1-1{
        margin: 0px !important;
        padding-left: 16px;
        padding-right: 16px;
    }
    .opt-optionsTable{
        width: 100% !important;
        margin-right: 0px !important;
        max-width: 100% !important;
    }
    table{
        width: 100% !important;
    }
    .opt-horizontal-1{
        width: 100% !important;
    }
    .arrow_block{
        max-width: 100% !important;
    }
    .opt-header-1{
        padding-bottom: 24px !important;
        margin-bottom: 0px !important;
        font-size: 24px !important;
        line-height: 28px !important;
    }
    .opt-p-1{
        font-size: 12px !important;
    line-height: 16px !important;
    color: #000000 !important;
    }
    .opt-optionsTable {
    margin-top: 24px !important;
}
}
/* responsive styles ends */
</style>