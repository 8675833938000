<template>
    <v-wait for="sqa_fetch_to_complete">
      <template slot="waiting">
        <LoaderComponent></LoaderComponent>
      </template>
      <BlockUI v-if="submitLoader" :url="url"></BlockUI>
      <Header v-if="this.whichRealm"></Header>
      <v-row class="vz-forgot-flow">
        <v-col xs="0" sm="0" md="2"></v-col>
        <v-col cols="12" xs="12" sm="12" md="8" class="padleft15 padright15">
          <v-row>
            <h1 v-if="stepupPageTitle">{{ stepupPageTitle }}</h1>
          </v-row>
          <v-row v-if="alerts.length > 0" class="marright5 marleft5">
            <v-row class="row alert" v-bind:class="getAlertType(j)" v-for="j in alerts" :key="j">{{j.msg}}</v-row>
          </v-row>
          <v-row v-if="stepupPageTitle" class="blackbar martop0"></v-row>
          <v-row>
            <p v-if="!fingerprintSaved">
              <b style="color: #000;" v-if="!fingerprintSaved && stepupPageDesc">{{ stepupPageDesc }}</b>
              <span v-if="!fingerprintSaved && stepupPageDesc">
                <button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;" v-tooltip="content"></button>
              </span><br/>
			        <span>{{ $t("message.login_sqa_page_title") }}</span><br/>
              <a class="vz-a" v-if="otpEnabled" @click="$event.preventDefault();switchToOTP();" href="#">{{$t("message.login_otp_unable_use_sqa")}}</a>
			      </p>
            <p>{{ $t("message.login_sqa_page_directions") }}</p>
          </v-row>
          <v-row>
            <v-form class="padleft15 col-xs-12">
                <v-row class="form-group margin-top-medium padright0 padleft0 col-xs-12 col-sm-7 col-md-5">
                    <label class="labeltiny">{{selectedQuestion.question}}</label>
                    <input 
                    type="password" 
                    class="form-control vz-input-field"
                    v-bind:class="sqaValidationInputClass"
                    v-model="selectedQuestion.answer"
                    :disabled="disableSqaFields"
                    >
                </v-row>
                <v-row class="padleft0 col-xs-12"></v-row>
                <v-row class="padleft15 col-xs-12 margin-top-large margin-bottom-large padding-top-large"> 
                    <button type="button" class="btn btn_primary_black" :disabled="disableSqaFields || !selectedQuestion.answer" v-if="fingerprintSaved" @click="validateAnswer(false)">{{ $t("message.otp_verify") }}</button>
                    <button type="button" class="btn btn_primary_black marright20" :disabled="disableSqaFields || !selectedQuestion.answer" v-if="!fingerprintSaved" @click="validateAnswer(true)">{{ $t("message.otp_verify") }}{{ $t("message.login_register_device") }}</button>
                </v-row>
                <v-row v-if="!fingerprintSaved && !disableSqaFields && selectedQuestion.answer" class="padleft0 margin-bottom-large padding-bottom-small col-xs-12">
                    <a class="vz-a" @click="validateAnswer(false)">{{ $t("message.otp_verify") }}{{ $t("message.login_dont_register_device") }}</a>
                    <span>
                      <button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;" v-tooltip="content3"></button>
                  </span>
                </v-row>
            </v-form>
        </v-row>
      </v-col>
      <v-col xs="0" sm="0" md="2"></v-col>
      </v-row>
      <Footer v-if="this.whichRealm"></Footer>
</v-wait>
</template>
<script>
import Footer from '@/vbgauth/components/Footer'
import Header from '@/vbgauth/components/Header'
import LoaderComponent from '@/vbgauth/components/LoaderComponent'
import { mapGetters, mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import translations from '@/common/assets/json/messages1.json'
import loadingImage from '@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif'
//import SwitchToOTPComponent from '@/components/core/SwitchToOTPComponent'
export default {
  name: 'AuthSqaComponent',
  components: {
    LoaderComponent,
    Footer,
    Header
  },
  data () {
    return {
      hideSubmit: false,
      content: translations.en.message['login_device_not_recognized_tooltip'],
      url: loadingImage,
      content3: translations.en.message['login_verify_and_dont_register_tooltip'],
      sqaValidationInputClass: '',
      whichRealm: true
    }
  },
  mounted () {
    this.getUserQuestions();
    if((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount;
      if(hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('iamvar_realm') !== -1) {
    	var iamvar_realmCookie = getCookie('iamvar_realm')
    	if(iamvar_realmCookie == 'MOBILE') {
     		this.whichRealm = false;
   		}
   	}
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          this.whichRealm = false;
        }
    }	
  },
  methods: {
    ...mapActions('authstepup', ['validateSqa','redirectToOpenam']),
    ...mapWaitingActions('authstepup', {
        getUserQuestions: 'sqa_fetch_to_complete'
    }),
    getAlertType (index) {
          return 'alert-' + index.type
    },
    validateAnswer (saveFingerprint) {
      let self = this
      var sqaValidateParams = {
        "sqa": self.selectedQuestion,
        "saveFingerprint": saveFingerprint
      }
      this.validateSqa(sqaValidateParams)
    },
    switchToOTP() {
      this.$router.push({path: 'otp'})
    }
  },
  computed: {
    ...mapGetters('authstepup', [
      'fingerprintSaved',
      'submitLoader',
      'isPwReset',
      'alerts',
      'dynamicErrorClasses',
      'userQuestions',
      'selectedQuestion',
      'sqaSubmitLinkClass',
      'flowInitialized',
      'disableSqaFields',
      'stepupPageTitle',
      'stepupPageDesc',
      'otpEnabled',
      'sqaValidationError'
    ]),
    ...mapGetters('cvsstepup', [
      'urlOnMount'
     ])
  },
  watch: {
    fingerprintSaved: function(newVal) {
      if(newVal) {
        this.redirectToOpenam()
      }
    },
    sqaValidationError: function(newVal) {
      if(newVal) {
        this.sqaValidationInputClass = "vz-error"
        this.selectedQuestion.answer = ''
      }
    }
  }
}
</script>
<style scoped>
/*h1 {
    margin: 4.6rem 0 2.50rem !important;
    font-size: 32px !important;
    font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", "Arial", "Helvetica", "sans-serif !important"
}*/
h1 {
    height: 31px;
    width: 332px;
    color: #000;
    font-family: NeueHaasGroteskDisplay!important;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 30.4px;
    font-weight: 700!important;
}
.v-row {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}
.vz-input-field:focus {
  border: 1px solid black !important;
}
input {
  padding: 11px 0 11px 10px;
    border-radius: 0px;
    font-size: 14px;
    color: #000;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000;
    box-shadow: none;
    transition: border .4s;
    height: 36px;
}
.vz-error {
  border: #ed7000 1px solid;
  border-bottom: #ed7000 4px solid;
}
</style>
