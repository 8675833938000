<template>
  <v-row>
    <v-row class="col-xs-12"><p>To validate your identity, please enter the full phone number that ends in ***-***-8455 below. Then select either Text me or Call me to receive a One Time Passcode at that number. The Text me option is for wireless numbers only.</p></v-row>
    <v-form class="col-xs-12">
      <v-row class="from-group padright0 padleft0 col-xs-12 col-sm-4 margin-top-medium">
        <label class="labeltiny" for="phoneno">Phone number*</label>
        <input id="phoneno" name="phoneno" v-model="phoneno" type="text" autocomplete="off">
      </v-row>
      <v-row class="from-group padright0 padleft0 col-xs-12 margin-top-medium">
        <input type="radio" id="one" value="text" v-model="deliveryMethod" class="vz-custom-radio">
        <label class="margin-left-small" for="one">Text me</label>
        <span class="margin-left-medium margin-right-small"></span>
        <input type="radio" id="two" value="call" v-model="deliveryMethod" class="vz-custom-radio">
        <label class="margin-left-small" for="two">Call me</label>
        <p class="pmicro">Our text messages are delivered with a reference code of 900070005000 to devices on the Verizon network and 11000011904 to those that are not. You will not be charged for the minutes or text if Verizon is your service provider. Charges may apply for non-Verizon devices.</p>
      </v-row>
      <v-row class="padleft0 col-xs-12 margin-top-large margin-bottom-large padding-top-small">  
        <button type="button" class="btn btn_primary_black" :disabled="!phoneno" @click="sendOtp()">Send One Time Passcode</button>
      </v-row>
    </v-form>
    <AuthOtpValidateComponent></AuthOtpValidateComponent>
  </v-row>
</template>
<script>
import AuthOtpValidateComponent from '@/vbgauth/components/authstepup/AuthOtpValidateComponent'
export default {
  name: 'UserRegValidateComponent',
  components: {
    AuthOtpValidateComponent
  },
  data () {
    return {
      phoneno: '',
      deliveryMethod: null
    }
  }
}
</script>
<style scoped>
input {
  padding: 11px 0 11px 10px;
    border-radius: 0px;
    font-size: 14px;
    color: #000;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000;
    box-shadow: none;
    transition: border .4s;
    height: 36px;
}
.vz-custom-radio {
    height: 18px;
    width: 18px;
    border: 1px solid black;
    position: relative;
    border-radius: 100%;
    bottom: 2px;
    display: block;
    background: #000;
}
</style>
