<template>
    <v-container class="vz-container">
    <v-row no-gutters class="vz-am-footer">
      <v-col
        style="width: 25%; float:left; margin:0 0 42px 0; padding:0 42px 0 0; box-sizing:border-box;"
        v-for="(list, n) in footerConfig.lists"
        :key="n"
        cols="12"
        sm="4"
      >
    <v-list flat>
      <v-subheader>{{ list.name }}</v-subheader>
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in list.listItems"
          :key="i"
          :href="item.href"
          :ripple="false"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
      </v-col>
    </v-row>
    <v-row no-gutters class="vz-am-footer vz-am-logo-bar">
      <v-img :src="require('@/common/assets/styles/corp/images/vzlogo-white.png')" class="fLogo"></v-img>
      <v-row class="vz-footerNav">
          <v-col style="padding: 5px 0px;flex: 0 0 14.6666666667%;" v-for="(link, j) in footerConfig.links" :key="j" cols="12" sm="6" md="2">
            <a class="vz-footer-link" :href="link.href">
              {{ link.name }}
            </a>
          </v-col><span style="padding: 5px 0px">© 2020 Verizon</span>
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
import config from '@/common/assets/json/login_page_config.json'
export default {
  name: 'AMFooter',
  components: {
  },
  data () {
    return {
        footerConfig:  config.footer
    }
  }
}
</script>
<style scoped>
.vz-container {
  width: 100% !important;
  padding: 0px !important;
  background: #000 !important; 
  margin-top: 24px !important;
}
.vz-text-link {
  color:#fff !important; 
  text-decoration:none  !important;
  font-size: 14px !important;
  padding: 0px !important;
}
.v-subheader {
  color:#fff !important; 
  border-bottom: solid 1px #fff !important; 
  padding-bottom: 6px !important; 
  font-size: 16px !important;
  font-family: NeueHaasGroteskDisplay,Arial,Sans-Serif !important;
}
.v-list-item__title {
  color: #fff !important;
  font-size: 14px !important;
}
.theme--light.v-list {
  background: #000 !important;
}
.v-list-item__content {
  padding: 0px !important;
}
.v-list-item {
  min-height: 24px !important;
}
.vz-am-footer {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 24px;
  box-sizing: border-box;
  clear: left;
}
.vz-am-logo-bar {
  border-top: solid 1px #333;
  padding-top: 12px;
}
.fLogo {
  display: block;
  float: left;
  width: 103px;
  height: 22px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: left center;
}
.v-responsive {
  flex: none;
}
.vz-footerNav {
  color: #747676;
  text-align: center;
  /* padding-right: 103px;*/
  font-size: 14px;
}
.vz-footer-link {
  font-size: 14px !important;
  padding: 2px 0px !important;
  margin: 10px 0 0 12px;
  text-decoration: none !important;
  border-bottom: 1px solid transparent !important;
  cursor: pointer !important;
  color: #747676 !important;
}
.vz-footer-link:hover {
  border-bottom: 1px solid #747676 !important;
}
.info{
  color: #999;
  opacity: 0.25;
  float: right;
  font-size: 10px;
  margin-top: -12px;
}
</style>
