<template>
  <div class="vbg-ff-root-container">
    <Header v-if="this.whichRealm"></Header>
    <div class="vbg-ff-content-container">
      <router-view></router-view>
    </div>
    <Footer v-if="this.whichRealm"></Footer>
  </div>
</template>
<script>
import Header from "@/vbgauth/components/LegalHeader";
import Footer from "@/vbgauth/components/ForgotFlowFooter";
// import Footer from '@/common/components/Footer';
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AMForgotInfoRootComponent",
  components: {
    Footer,
    Header,
  },
  mounted() {
    let appUrl = this.$route.query.appUrl;
    let gotoUrl = this.$route.query.goto;

    if (this.urlOnMount != null || this.urlOnMount != undefined) {
      const hostUrl = this.urlOnMount;
      if (hostUrl.includes("/account/business/login/mobile")) {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      var iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }

    if (appUrl != undefined) {
      if (gotoUrl != undefined) {
        // encode gotoUrl before appending it to urlOnMount
        gotoUrl = encodeURIComponent(gotoUrl);
        this.$store.dispatch(
          "cvsstepup/updateUrlOnMount",
          appUrl + "?goto=" + gotoUrl
        );
      } else this.$store.dispatch("cvsstepup/updateUrlOnMount", appUrl);
    }

    if (gotoUrl != undefined) {
      this.$store.dispatch("cvsstepup/updateGotoUrl");
    }
    window.onpopstate = function () {
      window.history.forward();
    };
  },
  data() {
    return {
      whichRealm: true,
    };
  },
  methods: {
    // ...mapActions('cvsstepup' [
    //   'updateUrlOnMount'
    // ]),
    // ...mapActions('login', [
    //   'updateGotoUrl'
    // ])
  },
  computed: {
    ...mapGetters("cvsstepup", ["urlOnMount", "flowType"]),
  },
  watch: {},
  // beforeDestroy() {
  //   if(this.flowType === 'auth') {
  //     window.onpopstate = function() {
  //       window.location.href="https://www.google.com/"
  //     }
  //   }
  // }
};
</script>
<style scoped>
.forgot-flow-container {
  min-height: 95vh;
}
@media (max-width: 767px) {
  .forgot-flow-container {
    min-height: 80vh;
  }
}
#tsd-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
