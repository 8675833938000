<template>
  <div class="padright0 padleft0 col-xs-12">
    <div class="col-md-5 col-xs-12 padleft0 form-group">
      <div class="vz_radio">
        <!-- :checked not supported in IE11. Checked and v-model working same here
        <input type="radio" name="accountType" value="otp" :checked="challengeMethod ==='otp'" v-model="method" v-on:change="switchMethod($event)">  -->
        <input type="radio" name="accountType" value="otp" v-model="method" v-on:change="switchMethod($event)"> 
        <span> Use One Time Passcode</span>
      </div>
    </div>
    <div class="col-md-5 col-xs-12 padleft0 form-group">
      <div class="vz_radio">
        <!-- :checked not supported in IE11. Checked and v-model working same here
        <input type="radio" name="accountType" value="sqa" :checked="challengeMethod ==='sqa'" v-model="method" v-on:change="switchMethod($event)">  -->
        <input type="radio" name="accountType" value="sqa" v-model="method" v-on:change="switchMethod($event)"> 
        <span> Use Secret Question</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SwitchChallengeMethod',
  components: {
  },
  props: [
    'challengeMethod'
  ],
  created() {
    console.log(this.challengeMethod)
  }, 
  data () {
    return {
      method: this.challengeMethod
    }
  },
  methods: {
    switchMethod(event) {
      if(event.target.value ==='otp')
        this.$router.push({ name: 'FFOtpComponent'})
      else 
        this.$router.push({ name: 'FFSqaComponent'})
    }
  },
  computed: {
    ...mapGetters('cvsstepup', [
      'fingerprintSaved',
      'sqaEnabled',
      'noSqa'
    ])
  },
  watch: {
    sqaEnabled: function(newVal) {
      if(!newVal) {
        //this.linkClass = 'vz-a-disabled'
      }
    }
  }
}
</script>
<style scoped>
.div {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
</style>
