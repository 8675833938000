<template>
  <!-- <div class="login-container">
      <BlockUI v-if="submitLoader" :url="url"></BlockUI>

      <form @submit.prevent="submit" ref="loginForm">
        <div class="loginContainer">
          <div class="user-id">
            <label for style="font-size: 14px">User ID</label>
            <input
              class="userInput"
              type="text"
              v-model="formData[0].input[0].value"
            />
            <p
              class="forgot-link"
              @click="
                FrUsername();
                mybizzForget(true);
              "
            >
              Forgot user ID?
            </p>
          </div>
          <div class="user-id pwd">
            <label for style="font-size: 14px">Password</label>
            <input
              class="userInput"
              :type="isShow ? 'text' : 'password'"
              v-model="formData[1].input[0].value"
            />
            <span
              class="show_pwd"
              v-if="!isShow && formData[1].input[0].value"
              @click="isShow = !isShow"
              >Show</span
            >
            <span
              class="show_pwd"
              v-if="isShow && formData[1].input[0].value"
              @click="isShow = !isShow"
              >Hide</span
            >
            <p
              class="forgot-link"
              @click="
                FrPassword();
                mybizzForget(true);
              "
            >
              Forgot password?
            </p>
          </div>
          <div v-if="this.gotoPage" style="display: flex; align-items: center">
            <p>
              <input class="tc" type="checkbox" id="ch" v-model="rememberMe" />
              <label for="ch">Remember my user ID</label>
            </p>
          </div>
          <div v-if="this.rememberMeCheckbox">
            <label
              :class="classes"
              class="vz-cutom-checkbox tc"
              for="privacyPolicy"
            >
              By continuing you agree to the
              <a
                name="privacyPolicy"
                :href="this.vzwPrivacyPolice"
                class="black-ink-link"
              >
                Verizon Privacy Policy
              </a>
            </label>
          </div>
          <div v-if="!this.rememberMeCheckbox">
            <p>By Continuing you agree to the</p>
            <a class="black-ink-link" :href="this.vzwPrivacyPolice">
              Verizon Privacy Policy,
            </a>
            <a class="black-ink-link" :href="this.vzwPrivacyNotice">
              Verizon Privacy Notice
            </a>
            <a style="text-decoration: none; cursor: default"> and </a>
            <a class="black-ink-link" :href="this.vzwCaliforniaPrivacyNotice">
              California Privacy Notice
            </a>
            <br />
          </div>
          <div>
            <button
              class="btn primary_black"
              style="
                width: 100%;
                margin: 35px 0px 24px;
                font-size: 16px !important;
                font-family: 'VzNHGeDS-bold11';
              "
              value="Sign In"
              :disabled="!isFormComplete"
              @click="submitMobileLoginForm()"
              type="submit"
            >
              Log in
            </button>
          </div>
          <p v-if="!this.disableprelogin">
            <label
              >Need access?
              <a
                :href="registerLinkHref()"
                style="text-decoration: none; white-space: pre"
                ><b style="font-family: VzNHGeDS-bold11"
                  >Register for an account</b
                >&nbsp;<img style="width: 10px; height: 14px" :src="caret" /></a
            ></label>
          </p>
          <p
            v-if="this.rememberMeCheckbox"
            class="vz-login-link"
            style="margin-bottom: 16px"
          >
            <a :href="resendWelcomeEmail">Resend Welcome email</a>
          </p>
        </div>
      </form>
      <br />
    </div> -->
  <div class="mobile_component_mvb">
    <div class="new_pwdless_form_container">
      <div class="new_pwdless_box_container">
        <div class="new_pwdless_box">
          <div class="new_pwdless_content">
            <BlockUI v-if="submitLoader" :url="url"></BlockUI>
            <ErrorMessage
              :message_details="getCommonErrors[0]"
              v-if="getCommonErrors.length"
            />
            <h1 class="new_pwdless_main_heading pwdles_main_heading">Log in</h1>
            <div class="new_pwdless_sub_heading pwdles_sub_heading">
              to your Verizon business account
            </div>
            <form @submit.prevent="submitMobileLoginForm" ref="loginForm">
              <div class="new_pwdless_input_label">User ID</div>
              <div>
                <input
                  type="text"
                  class="new_pwdless_input_field"
                  v-model="formData[0].input[0].value"
                />
              </div>
              <div class="new_pwdless_remember_me">
                <div class="new_pwdless_checkbox_content">
                  <input
                    type="checkbox"
                    class="new_pwdless_checkbox dark_bg_checkbox"
                    v-model="rememberMe"
                  />
                  <span class="new_pwdless_remember_me_text">
                    Remember User ID
                  </span>
                </div>
                <div
                  class="new_pwdless_forgot_text"
                  @click.prevent.stop="
                    FrUsername();
                    mybizzForget(true);
                  "
                >
                  <a class="new_pwdless_forgot_text_link">
                    I forgot my User ID
                  </a>
                </div>
              </div>
              <div
                class="new_pwdless_checkbox_content"
                style="margin-top: 10px"
                v-if="getBiometricEnable"
              >
                <input
                  type="checkbox"
                  class="new_pwdless_checkbox dark_bg_checkbox"
                  v-model="biometricEnableCheck"
                />
                <span class="new_pwdless_remember_me_text">
                  {{faceIdBiometricCheck}}
                </span>
              </div>
              <div class="new_pwdless_btn_section">
                <button
                  class="new_pwdless_submit_btn"
                  type="submit"
                  id="submitUserID"
                  name="submitUserID"
                  :class="continueDisabled ? 'disabled' : ''"
                  :disabled="continueDisabled"
                >
                  {{ $t("message.new_pwdless_submit_btn") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="new_pwdless_suggestions_section">
      <span class="new_pwdless_need_access_link">
        <label>{{ $t("message.new_pwdless_need_access_link") }}</label></span
      >
      <span class="new_pwdless_need_reg_link">
        <a :href="registerLinkHref()"
          >{{ $t("message.new_pwdless_need_reg_link") }}
          <img
            src="@/common/assets/images/RightCaretIcon.png"
            alt="right_caret_icon"
          />
        </a>
      </span>
    </div>
    
    <div class="new_pwdless_forgot_text new_pwdless_bottom_link">
        <a :href="resendWelcomeEmail" class="new_pwdless_forgot_text_link">
         Resend welcome email
        </a>
      </div>
    </div>
    <div class="s" v-if="JSON.stringify(mvbContent) !== '{}'">
      <div class="">
        <h3
          style="
            border-bottom: 2px solid #080707;
            padding-bottom: 10px;
            margin-bottom: 24px;
          "
        >
          {{ mvbContent.quickLinksLbl }}
        </h3>
        <div
          class="new_pwdless_forgot_text new_pwdless_bottom_link"
          v-for="item in mvbContent.quickLinks"
          :key="item"
        >
          <a class="new_pwdless_forgot_text_link" :href="item.pageLink">{{
            item.title
          }}</a>
        </div>

        <h3
          v-if="mvbContent.generalLinks"
          id="generalLinksClass"
          class="old-general-links"
          @click="generalLinksClicked()"
        >
          General
        </h3>
      </div>
      <div v-if="seen">
        <div
          class="new_pwdless_forgot_text new_pwdless_bottom_link"
          v-for="block in mvbContent.generalLinks"
          :key="block"
        >
          <a class="new_pwdless_forgot_text_link" :href="block.pageLink">{{
            block.title
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import commonMixin from "@/common/mixin/commonMixins";
import CommonErrorMixin from "../common/common-error-mixin";
import InvisibleRecaptcha from "vue-invisible-recaptcha";
import { mapGetters, mapActions } from "vuex";
import {
  setPageDetails,
  eventTracking,
} from "../../../../../public/adobeTagging";
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import ErrorMessage from "@/vbgauth/components/VbgLoginNew/common/ErrorMessage.vue";
export default {
  name: "MobilePwdLessLoginComponent",
  components: {
    InvisibleRecaptcha,
    ErrorMessage,
  },
  mixins: [commonMixin, CommonErrorMixin],
  data() {
    return {
      caret: caret_blk,
      //url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      userId: "",
      userPassword: "",
      rememberMe: false,
      canSubmit: false,
      captchaOn: true,
      SignUpUrl: "",
      showClickHereMsg: false,
      idmRedirect: "",
      displaySignUpFalse: "",
      gotoPage: true,
      jCaptchaFlag: false,
      captchaText: null,
      realm: "MOBILE",
      rememberMeCheckbox: true,
      seen: false,
      quickLinks: false,
      disableprelogin: false,
      vzwPrivacyPolice:
        "https://www.verizon.com/about/privacy/full-privacy-policy",
      vzwPrivacyNotice:
        "https://www.verizon.com/business/support/vec/onlinehelp/TermsAndConditions/GDPR-Privacy-Notice.html",
      vzwCaliforniaPrivacyNotice:
        "https://www.verizon.com/about/privacy/full-privacy-policy?scrollto=index-1#acc-item-55",
      resendWelcomeEmail: iamvar_resendWelcomeEmail,
      vec2Registration: vec2_ent_portal_base_url,
      isShow: false,
      biometricEnableCheck: null,
      getBiometricEnable: false,
      faceIdBiometricCheck: 'Enable Biometrics'
    };
  },
  async created() {
    setPageDetails("Login:landing");
    this.getLoginCaptchaEnabledEnvVar();
    this.checkForCaptchaOffConditions();
  },
  mounted() {
    console.log("fhsdhf");
    let msg = '{"actionType":"clearHistory","screenName":"oauthLogin"} ';
    if (window.webkit != undefined) {
      // iOS
      if (window.webkit.messageHandlers.mfAppInterface != undefined) {
        window.webkit.messageHandlers.mfAppInterface.postMessage(msg);
      }
    }
    if (window.mfAppInterface != undefined) {
      // Android
      window.mfAppInterface.postMessage(msg);
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        iamvar_mvbLaunchUrl = window.location.href;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_CLIENT") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_CLIENT");
      if (iamvar_appCookie == "IOS") {
        this.faceIdBiometricCheck = 'Enable Face ID';
      } else {
        this.faceIdBiometricCheck = 'Enable Biometrics';
      }
    }
    // when user lands on /mobile url then mvb team may send this cookie for savefingerprint
    if (document.cookie.indexOf("X-VZ-DFP") !== -1) {
      let saveFingerprint = getCookie("X-VZ-DFP");
      if (saveFingerprint) {
        //X-VZ-C_APP this cookie contains client id mvb or vema
        this.updateSetDeviceInfo(saveFingerprint);
      }
    }

    // get gotourl from mixins
    this.$store.dispatch("login/updateGotoUrl", this.getGotoUrl());
    this.$store.dispatch("login/updateEncodedGotoUrl", this.getGotoUrl());
    const hostUrl = window.location.href;
    if (hostUrl.includes("/account/business/odasignin")) {
      this.gotoPage = false;
    } else if (hostUrl.includes("/account/business/ilogin")) {
      this.jCaptchaFlag = true;
    } else if (hostUrl.includes("/account/business/login/mobile")) {
      if (
        hostUrl.includes("disableprelogin=true") ||
        hostUrl.includes("disableprelogin%3Dtrue")
      ) {
        this.disableprelogin = true;
        this.quickLinks = false;
      } else if (
        hostUrl.includes("client_id=mvb") ||
        hostUrl.includes("client_id%3Dmvb")
      ) {
        this.quickLinks = true;
        this.getQuickLinksForMobile();
        this.updateUserAgent("MVB");
      } else if (
        hostUrl.includes("client_id=vema") ||
        hostUrl.includes("client_id%3Dvema")
      ) {
        this.rememberMeCheckbox = false;
        this.quickLinks = true;
        this.getQuickLinksForVemaMobile();
        this.updateUserAgent("VEMA");
      }
    }
    //this.getQuickLinksForMobile();
    this.removeExistingVBGCCookie();
    this.getSessionInfo();
    this.updateUrlOnMount(window.location.href);
    this.resetState();

    // call rememberMe function only if uid query parameter is not present
    // let params = new URLSearchParams(window.location.search);
    if (!this.getParameterByName("uid")) {
      //this.findPushUserIdCookie();
      this.findRememberMeCookie();
    }

    this.processQueryParameters();
    // console.log('idm url in tsd ', iamvar_idmUrl);
    if (
      this.getParameter("appGroup") === "TSD" &&
      this.getParameter("client_id") === "mdp_agent"
    ) {
      this.SignUpUrl = iamvar_mdpSignUpUrl;
    } else if (
      this.getParameter("appGroup") === "TSD" &&
      this.getParameter("client_id") === "tsd_agent"
    ) {
      this.SignUpUrl = iamvar_thingSpaceSignUpUrl;
    } else if (
      this.getParameter("appGroup") === "TSM" ||
      this.getParameter("client_id") === "tss_agent_gz"
    ) {
      this.SignUpUrl = iamvar_ccSignUpUrl;
    } else {
      this.displaySignUpFalse = "vz-error";
    }
    if (this.getAlertType == "success" || this.cvsAuthAlert) {
      this.updateRemoveMessage(true);
    }
    window.localStorage.setItem("InitialRoute", window.location.href);
    if (
      document.cookie.indexOf("vbgbioc") !== -1 &&
      getCookie("vbgbioc") == "Y"
    ) {
      this.getBiometricEnable = true;
    } else {
      this.getBiometricEnable = false;
    }
  },
  beforeDestroy() {
    this.formData[0].input[0].value = "";
    this.formData[1].input[0].value = "";
  },
  supportUrl(){
    let isGoToPresent = window.location.href.split('goto=');
    if(isGoToPresent.length>1){
      let registration_url = this.vec2Registration.split('#')
      return registration_url[0]+'?goto='+isGoToPresent[1]+'#'+registration_url[1]
    }
    return this.vec2Registration;
  },
  methods: {
    ...mapActions("login", [
      "submitPwdAMMobileLoginForm",
      "callRememberMe",
      "removeExistingVBGCCookie",
      "getSessionInfo",
      "updateUrlOnMount",
      "resetState",
      "updateCaptchaToken",
      "updateFederalPortalIndicator",
      "findRememberMeCookie",
      "findPushUserIdCookie",
      "getQuickLinksForMobile",
      "updateUserAgent",
      "getQuickLinksForVemaMobile",
      "mybizzForget",
      "UpdatePopupValue",
      "updateDisplayPopupClass",
    ]),
    ...mapActions("cvsstepup", [
      "initializeStepup",
      "updateUserIdFlow",
      "updateCvsAuthAlert",
      "updateSetDeviceInfo",
      "updateRemoveMessage",
      "updateUsernameForStepup",
      //'updateRealmForStepup'
    ]),   
    generalLinksClicked() {
      this.seen = !this.seen;
      if (this.seen) {
        document.getElementById("generalLinksClass").className =
          "new-general-links";
      } else {
        document.getElementById("generalLinksClass").className =
          "old-general-links";
      }
    },
    submitMobileLoginForm: function (recaptchaToken) {
      this.clearAllErrors();
      this.updateCvsAuthAlert(null);
      let payload = {
        formData: this.formData,
        rememberUser: this.rememberMe,
        captchaAuth: this.captchaText,
        additionalAuth: "VBG_LOGIN",
        biometricEnableCheck: this.getBiometricEnable
          ? this.biometricEnableCheck
            ? "Y"
            : "N"
          : null,
      };
      //this.updateRealmForStepup(this.realm);
      iamvar_realm = this.realm;
      // update state with new captcha token
      if (this.captchaOn) this.updateCaptchaToken(recaptchaToken);

      this.submitPwdAMMobileLoginForm(payload);
      this.updateUsernameForStepup(this.formData[0].input[0].value.trim());
      // might not be needed since submit button is disabled when form is empty
      /*if(this.$refs.loginForm.validate()) {
                this.submitAMLoginForm(payload);
            }*/

      // TODO: implement valid/invalid logic. For right now, redirect to OTP regardless of result
      // this.$router.push({ path: '/vbg-tsd-login/otp' });
    },
    registerLinkHref() {
      const hostUrl = window.location.href;
      if (
        hostUrl.includes("client_id=mvb") ||
        hostUrl.includes("client_id%3Dmvb")
      ) {
        return "mvb://mvbmobile/registration/spoc/mbt/spocreg.register";
      } else if (
        hostUrl.includes("client_id=vema") ||
        hostUrl.includes("client_id%3Dvema")
      ) {
        return iamvar_vemaRegistrationLink;
      } else {
        return this.vec2Registration
      }
    },
    getParameter(url) {
      let decodeUrl = decodeURIComponent(document.location);
      url = url.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + url + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(decodeUrl);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    processQueryParameters() {
      // let params = new URLSearchParams(window.location.search);

      if (this.getParameterByName("uid")) {
        this.formData[0].input[0].value = this.getParameterByName("uid");
      }
      if (this.getParameterByName("rememberMe")) {
        if (this.getParameterByName("rememberMe") == "true")
          this.rememberMe = true;
      }
    },
    getLoginCaptchaEnabledEnvVar() {
      console.log("(Env based) captcha is on = ", iamvar_enableLoginCaptcha);
      this.captchaOn = iamvar_enableLoginCaptcha;
    },
    checkForCaptchaOffConditions() {
      // turn off captcha when instance is related to a federal portal
      let gotoParam = this.getParameterByName("goto");

      // if goto has 'oauth', get redirect_uri and check if redirect_uri has any of the federalPortalKeywords
      if (gotoParam != null && gotoParam.includes("oauth")) {
        // decode goto in order to easily access redirect_uri parameter
        let decodedGoto = decodeURIComponent(gotoParam);
        // let redirectUriParam = this.getParameterByName('redirect_uri');
        let decodedParameters = new URLSearchParams(decodedGoto);
        let redirectUriParam = decodedParameters.get("redirect_uri");

        if (
          iamvar_federalPortalKeyWords.some((str) =>
            redirectUriParam.includes(str)
          )
        ) {
          this.captchaOn = false;
          this.updateFederalPortalIndicator(true);
        }
      } else {
        // check if goto has any of the federalPortalKeywords
        //if(iamvar_federalPortalKeyWords.some(str => gotoParam.includes(str))) {
        //    this.captchaOn = false;
        //    this.updateFederalPortalIndicator(true);
        //}
      }
    },
    addRedirectUrlToErrorBanner() {
      /* if the current authError (banner showed on screen) is for the user being redirected to IDM 
               (errorCode = REDIRECT_TO_IDM_REQUIRED) we need to add text that says "click here", 
               with an href of the IDM url
            */

      // TODO: confirm if 'redirected' is a good key word to identify this error message
      let errorMsg = this.$store.getters["login/authError"];
      if (errorMsg.errorMessage.includes("redirected")) {
        this.showClickHereMsg = true;
      }
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (
        match != null &&
        match.length > 2 &&
        typeof match[2] === "string" &&
        match[2].length > 0
      ) {
        return match[2];
      } else {
        return null;
      }
    },
    getCaptchaImgUrl() {
      let captchaImgHref = this.getHostName(window.location.href);
      return "https://" + captchaImgHref + "/ifgservices/vbg/captcha";
    },
    refreshCaptchaImgUrl() {
      let captchaImgHref = this.getHostName(window.location.href);
      document.getElementById("captchaImg").src = this.sanitizeUrl(
        "https://" +
          captchaImgHref +
          "/ifgservices/vbg/captcha?cb=" +
          Math.random()
      );
    },
    FrUsername() {
      this.$router.push({ path: "/login/recovery/forgotusername" });
      this.updateUserIdFlow("BASIC");
    },
    FrPassword() {
      this.$router.push({ name: "AMForgotPwdComponent" });
    },
  },
  computed: {
    ...mapGetters("login", [
      "popupValue",
      "authError",
      "submitLoader",
      "iamvar_enableCaptcha",
      "redirectToOptionsPage",
      "redirectToPasswordPage",
      "redirectToStepupPage",
      "redirectToSecProfilePage",
      "iamvar_idmUrl",
      "loginCaptchaKey",
      "getAlertType",
      "idmRedirectUrl",
      "rememberMeTrue",
      "remembermeUserName",
      "getUnifiedLoginAlerts",
      "gotoUrl",
      "getUnifiedErrorCode",
      "getBiometricEnable",
      "displayPopupClass",
      "getCommonErrors",
    ]),
    ...mapGetters("cvsstepup", ["currentStep", "cvsAuthAlert"]),
    ...mapGetters("forgotflow", ["usrName"]),
    continueDisabled() {
      return !this.formData[0].input[0].value;
    },
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
    mvbContent: {
      get: function () {
        return this.$store.getters["login/mvbContent"];
      },
    },
    gotoUrl: {
      get: function () {
        return this.$store.getters["login/gotoUrl"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateGotoUrl", newVal);
        this.$store.dispatch("login/updateEncodedGotoUrl", newVal);
      },
    },
  },
  watch: {
    redirectToStepupPage: function (newVal) {
      if (newVal) {
        this.initializeStepup();
      }
    },
    redirectToSecProfilePage: function (newVal) {
      if (newVal) {
        window.location.href = newVal;
      }
    },
    rememberMeTrue: function (newVal) {
      if (newVal == "setToTrue") {
        this.rememberMe = true;
      }
    },
    currentStep: function (newVal) {
      if (newVal === "FLOW_INITIALIZED") {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessOtpDelivery",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: "PwdLessOtpDelivery" });
        }
      }
    },
    idmRedirectUrl: function (newVal) {
      if (newVal) this.idmRedirect = iamvar_mvbLaunchUrl;
    },
    authError: function (newVal) {
      if (newVal) {
        this.addRedirectUrlToErrorBanner();
        window.scrollTo(0, 0);
        if (this.jCaptchaFlag) {
          this.refreshCaptchaImgUrl();
          this.captchaText = null;
        }
      }
    },
    getUnifiedLoginAlerts: function (newVal) {
      if (this.getUnifiedLoginAlerts.length > 0) {
        window.scrollTo(0, 0);
      }
    },
    isShow: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.isShow = false;
        }, 10000);
      }
    },
    usrName: function (newVal) {
      if (newVal != undefined || newVal != null) {
        console.log("username=", newVal);
        this.formData[0].input[0].value = newVal;
      }
    },
    remembermeUserName: function (newval) {
      if (newval != "" || newval != null) {
        console.log("remembermeUserName=", newval);
        this.formData[0].input[0].value = newval;
      }
    },
    cvsAuthAlert: function (newVal) {
      if (this.getAlertType == "success" || newVal) {
        this.updateRemoveMessage(true);
      }
    },
    redirectToOptionsPage: function (newVal) {
      if (newVal) {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessOption",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: "PwdLessOption" });
        }
      }
    },
    redirectToPasswordPage: function (newVal) {
      if (newVal) {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessPassword",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: "PwdLessPassword" });
        }
      }
    },
  },
};
</script>

<style scoped>
.pwdles_main_heading {
  margin-bottom: 12px;
}
.pwdles_sub_heading {
  margin-bottom: 32px;
}
.old-general-links {
  background: url("~@/common/assets/styles/corp/images/Plus.png") no-repeat;
  background-position: right 27% center;
  margin: 20px 0 20px 0;
  font-weight: 600;
  background-size: 12px 12px;
  cursor: pointer;
}

.new-general-links {
  background: url("~@/common/assets/styles/corp/images/Minus.png") no-repeat;
  background-position: right 27% center;
  margin: 20px 0 20px 0;
  font-weight: 600;
  background-size: 12px;
  cursor: pointer;
}
.new_pwdless_need_access_link{
  margin-right: 4px;
}
.new_pwdless_suggestions_section{
  margin-bottom: 16px;
}
.new_pwdless_forgot_text_link {
    border-bottom: 1px solid #000000;
    padding-bottom: 1px;
    font-size: 16px;
}
@media only screen and (max-width: 1023px) {
 .mobile_component_mvb{
    width: 100%;
  }
}
</style>

