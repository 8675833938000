<template>
    <div>
      <div class="mb-24">
        <div class="vbg-radio-group" tabindex="1">
          <label for="accountno" class="vbg-radio-label mb-0">
            <input
              class="vbg-radio"
              type="radio"
              id="accountno"
              name="accountno"
              value="acc"
              v-model="accounttype"
            />
            <p class="vbg-radio-text">Account number</p>
            <span class="vbg-radio-button">
              <span class="vbg-radio-button-checked"></span>
            </span>
          </label>
        </div>
        <div class="vbg-radio-group-2">
          <label for="mtnno" class="vbg-radio-label mb-0">
            <input
              class="vbg-radio"
              type="radio"
              id="mtnno"
              name="mtnno"
              value="mno"
              v-model="accounttype"
            />
            <p class="vbg-radio-text">
              Mobile number (for My Business Wireless users only)
            </p>
            <!--<label for="mtnno" class="vbg-radio-label mb-0 mobile"
              >Verizon business mobile number</label
            >-->
            <span class="vbg-radio-button">
              <span class="vbg-radio-button-checked"></span>
            </span>
          </label>
        </div>
      </div>
      <div v-if="accounttype === 'acc'">
        <!--<div class="mb-40">
          <p class="vbg-account-text">
            My Business Wireless: The account number is a 14 or 15-digit number<br />
            Verizon Enterprise Center: Only numbers or combination of letters and
            numbers 8-18 characters long
          </p>
          <p></p>
        </div>-->
  
        <div class="vbg-input-group">
          <div for="account" class="new_pwdless_input_label">Account number</div>
          <div class="vbg-icon-input-group">
            <input
              :class="accountIsValid? 'green_input': ''"
              class="new_pwdless_input_field"
              type="text"
              name="account"
              id="account"
              placeholder="Account number"
              v-model="account"
              minlength="5"
              maxlength="19"
              required
              @input="acceptAccountNumber()"
            />
            <div class="vbg-success-icon" v-if="accountIsValid"><img :src="CheckIcon" /></div>
          </div>
        </div>
      </div>
      <div v-if="accounttype === 'mno'">
        <div class="vbg-input-group">
          <div for="mtn" class="new_pwdless_input_label"
            >10 digit Verizon business mobile number</div
          >
          <div class="vbg-icon-input-group">
            <input
              :class="mtnIsValid? 'green_input': ''"
              class="new_pwdless_input_field"
              type="text"
              id="mtn"
              name="mtn"
              placeholder="Mobile number"
              v-model="mtn"
              maxlength="12"
              minlength="12"
              required
              @input="acceptMobileNumber()"
            />
            <div class="vbg-success-icon" v-if="mtnIsValid"><img :src="CheckIcon" /></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import CheckIcon from '@/common/assets/styles/corp/images/checkmark.png'
  import commonMixin from "@/common/mixin/commonMixins";
  export default {
    name: "NewAMForgotUserIDAcc",
    data() {
      return {
        accounttype: "acc",
        account: "",
        mtn: "",
        CheckIcon
      };
    },
    components: {},
    mixins: [commonMixin],
    props: ['accountIsValid', 'mtnIsValid'],
    mounted() { this.changeContextBiocatch('forgot_username_account_page') },
    watch: {
      accounttype() {
        this.$emit("changeMtn", "");
        this.$emit("changeAccountNumber", "");
        this.mtn = "";
        this.account = "";
      },
    },
    methods: {
      acceptAccountNumber() {
        let x = this.account.replace(/[^a-z0-9-]/gi, "");
        let accInputArr = x.split('-')
        let accInputStr = '';
  
        // allow hyphen only one time
        if (accInputArr.length > 0) {
          accInputStr = accInputArr[0];
          // only allow - 
          // when first string length is greater then 9 
          if(accInputArr[0].length >= 9 && accInputArr.length > 1) {
            accInputStr += `-${accInputArr.slice(1).join('')}`
          }
        }
        this.account = accInputStr;
        this.$emit("changeAccountNumber", this.account);
      },
      acceptMobileNumber() {
        let x = this.mtn.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.mtn = !x[2] ? x[1] : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        this.$emit("changeMtn", this.mtn);
      },
    },
  };
  </script>
  <style scoped>
  .vbg-input-group {
    display: flex;
    flex-direction: column;
  }
  .vbg-label {
    max-height: 64px;
    font-size: 12px;
    color: #000000;
  }
  .vbg-input {
    box-sizing: border-box;
    height: 44px;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000000;
    background-color: #ffffff;
    padding: 12px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
  .vbg-input:focus { border: 1px solid #000000; }
  .vbg-radio-div:focus {
    outline: 1px dashed #000;
    outline-offset: 2px;
  }
  .vbg-radio-button {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: white;
    top: 0;
  }
  /*.vbg-radio:checked ~ .vbg-radio-button:hover {
    height: 22px;
    width: 22px;
  }*/
  .vbg-radio:checked ~ .vbg-radio-button .vbg-radio-button-checked {
    width: 12px;
    height: 12px;
    background-color: #000;
    display: block;
    border-radius: 50%;
    z-index: 3;
  }
  .vbg-radio:focus ~ .vbg-radio-button {
    outline: 1px dashed #000;
    outline-offset: 2px;
  }
  .vbg-radio {
    margin-left: 4px;
    margin-top: 1px;
    margin-right: 12px;
    position: absolute;
    top: 3px;
  }
  .vbg-radio-text {
    margin-left: 30px;
  }
  /*.vbg-radio {
    -moz-appearance: initial;  for Firefox Browsers
    visibility: hidden;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: none;
    margin-right: 12px;
    margin-top: 0 !important;
  }*/
  /*.vbg-radio:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 100%;
  }*/
  
  /*.vbg-radio:checked:before {
    background: #000000;
    visibility: visible;
  }*/
  /*.vbg-radio:after {
    content: "";
    display: block;
    position: relative;
    top: -20px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border: 1px solid #000000;
    visibility: visible;
  }*/
  /*.vbg-radio:checked:after {
    content: "";
    display: block;
    position: relative;
    top: -20px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border: 1px solid #000000;
    visibility: visible;
  }*/
  /*.vbg-radio:before:focus {
    outline: 1px dashed #000;
    outline-offset: 2px;
  }*/
  
  .mb-0 {
    margin-bottom: 0;
  }
  .mr-40 {
    margin-right: 40px;
  }
  .mt-40 {
    margin-top: 40px;
  }
  .mt-60 {
    margin-top: 60px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .width-301 {
    width: 301px;
  }
  .mb-22 {
    margin-bottom: 22px;
  }
  .mt-22 {
    margin-top: 22px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .vbg-radio-group {
    position: relative;
    height: 44px;
    width: 174px;
    display: flex;
    align-items: center;
  }
  .vbg-radio-group-2 {
    position: relative;
    height: 44px;
    display: flex;
    align-items: center;
  }
  .vbg-radio-group-container {
    display: flex;
  }
  .vbg-account-text {
    height: 40px;
    width: 716px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
  .vbg-radio-label {
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    position: relative;
  }
  .mobile {
    display: none;
  }
  .vbg-input-success {
      border-bottom: 4px solid #008330 !important;
  }
  .vbg-success-icon {
    height: 16px;
    width: 16px;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 13px;
  }
  .vbg-success-icon img { 
    height: 8px;
    width: 8px;
  }
  .vbg-icon-input-group { position: relative; }
  .vbg-icon-input-group input { width: 100%; }
  @media (max-width: 1024px) {
    .vbg-radio-group-container {
      flex-direction: column;
    }
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
    .vbg-input-group {
      width: 100% !important;
    }
    .vbg-account-text {
      width: 100% !important;
      height: auto !important;
    }
  }
  @media (min-width: 1025px) {
    .vbg-input {
      width: 301px !important;
    }
  }
  </style>
  