import { render, staticRenderFns } from "./VbgTabs.vue?vue&type=template&id=c3ea0294&scoped=true"
import script from "./VbgTabs.vue?vue&type=script&lang=js"
export * from "./VbgTabs.vue?vue&type=script&lang=js"
import style0 from "./VbgTabs.vue?vue&type=style&index=0&id=c3ea0294&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3ea0294",
  null
  
)

export default component.exports