export default {
  data() {
    return {
      cardData: { ...this.card, status: false, buildinfo: null },
    };
  },
  methods: {
    // Find the element which contains buildnumber
    async findElementContainingText({ element, text }) {
      if (element.textContent.includes(text)) return element.textContent;

      for (let child of element.children) {
        const foundElement = await this.findElementContainingText({
          element: child,
          text,
        });
        if (foundElement) return foundElement;
      }
    },

    // Find the build Number by extracting from element
    findBuildNumber(element, checkURL, UIBuild, VbgHealthCheckByServers) {
      console.log(element, checkURL);
      // Regex for extracting Build Number from element
      let match = UIBuild
        ? element.match(/!--\s*(.*?)\s*-->/gm)
        : element?.match(/BuildNumber\s*:\s*(\d+)\s*/gm);
      // Login & B2B services
      if (match && checkURL)
        return UIBuild
          ? match[0].split(" ")[1].split("-")[0].split(".").at(-1)
          : match[0].split(":").at(-1).trim();
      // Extracting Build no from text for tds & sdc servers
      else if (match && !checkURL && VbgHealthCheckByServers)
        return match[0].split(" ").at(-1).split("\n")[0];
      // AM, IG, IAM, AA services
      else if (!checkURL) return element.split("-")[0].split(".").at(-1);
      // server is busy or down
      else {
        console.log("BuildNumber not found");
        return null;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async fetchData(tabName) {
      try {
        this.cardData.flag = false;
        const response = await fetch(this.cardData.url);
        // console.log("response", response);
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`);
        }
        const contentType = await response.headers.get("content-type");
        // console.log("contentType", contentType);
        let checkURL =
          response.url.includes("login") ||
          response.url.includes("b2bbuildinfo");
        const UIBuild = response.url.includes("login");
        const text = await response.text();
        const VbgHealthCheckByServers =
          contentType?.includes("text/html") &&
          tabName === "Prodcution Blue/Green Serve Status";
        // console.log("checkUrl", checkURL);
        // if (response.url.includes("login")) this.getBuildInfo(response.url);
        // Response getting as html document for login & B2B Services
        if ((contentType == null && checkURL) || VbgHealthCheckByServers) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, "text/html");
          console.log("final_doc", doc);
          const element = await this.findElementContainingText({
            element: doc.body,
            text: "BuildNumber",
          });
          if (element || UIBuild)
            this.cardData.buildinfo = this.findBuildNumber(
              UIBuild ? text : element,
              checkURL,
              UIBuild,
              VbgHealthCheckByServers
            );
          else console.log("No element found");
        } else {
          // Response getting as JSON for AM, IG, IAM, AA services
          const data = JSON.parse(text);
          // console.log("data", data);
          // AM & IG services
          if (data?.appType == "AM" || data?.appType == "IG") {
            this.cardData.buildinfo = this.findBuildNumber(
              data["git.build.version"],
              checkURL
            );
          }
          // AA Services
          else if (data?.["aaservices.version"]) {
            this.cardData.buildinfo = this.findBuildNumber(
              data["aaservices.version"],
              checkURL
            );
          }
          // IAM Services
          else if (data?.["iamservices.version"]) {
            this.cardData.buildinfo = this.findBuildNumber(
              data["iamservices.version"],
              checkURL
            );
          }
        }
        console.log("buildinfo", response.url, this.cardData.buildinfo);
        this.cardData.flag = true;
        this.cardData.status = true;
      } catch (error) {
        console.error(error.message);
        this.cardData.status = false;
        this.cardData.flag = true;
      }
    },
  },
};
