<template>
  <button @click="returnToLogin()" type="button" class="btn primary_black">
    Return to Log in
  </button>
</template>
<script>
import { mapGetters } from "vuex";
import commonMixin from '@/common/mixin/commonMixins';
export default {
  name: "SignInButtonComponent",
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      whichRealm: true,
    };
  },
  mounted() {
    if (this.urlOnMount != null || this.urlOnMount != undefined) {
      const hostUrl = this.urlOnMount;
      if (hostUrl.includes("/account/business/login/mobile")) {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      var iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }
  },
  methods: {
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (
        match != null &&
        match.length > 2 &&
        typeof match[2] === "string" &&
        match[2].length > 0
      ) {
        return match[2];
      } else {
        return null;
      }
    },
    returnToLogin() {
      var returnUrl = this.getHostName(window.location.href);
      if (!this.urlOnMount) {
        window.location.href =
        this.sanitizeUrl("https://" + returnUrl + "/account/business/login/unifiedlogin");
      } else {
        window.location.href = this.urlOnMount;
      }
    },
  },
  computed: {
    ...mapGetters("cvsstepup", ["urlOnMount"]),
  },
  watch: {},
};
</script>
<style scoped>
.btn {
  border-radius: 36px !important;
}
@media (min-width: 280px) and (max-width: 1024px) {
  .primary_black {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .primary_black {
    width: 47% !important;
  }
}
</style>
