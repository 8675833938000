<template>
    <div id="tsd-container">
        <div>
            <Header />
        </div>
        
        <div v-if="this.loginType === 'simpleLogin'">
            <SimpleLoginComponent />
        </div>
        <div v-if="this.loginType === 'odi'">
            <ODIComponent />
        </div>
        <div v-if="this.loginType === 'calnet'">
            <CalnetLoginComponent/>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/common/components/Header';
import Footer from '@/common/components/Footer';
import SimpleLoginComponent from './SimpleLoginComponent';
import ODIComponent from './ODIComponent';
import CalnetLoginComponent from './CalnetLoginComponent';
import commonMixin from '@/common/mixin/commonMixins';
import { mapMutations } from 'vuex'

export default {
    name: 'LoginRootComponent',
    components: { Header, Footer, SimpleLoginComponent, ODIComponent, CalnetLoginComponent},
    mixins: [commonMixin],
    data() {
        return {
            loginType: 'simpleLogin'
        }
    },
    created() {
        // determine if queryParam for ODI is present
        // URLSearchParams not supported in IE
       // const params = new URLSearchParams(window.location.search);
        if(this.getParameterByName('appview')) {
            console.log('params has appview');
            console.log('appview value= ', this.getParameterByName('appview'));

            if(this.getParameterByName('appview') === 'odi') {
                this.loginType = 'odi';
            }
            if(this.getParameterByName('appview') === 'calnet'){
                this.loginType = 'calnet';
            }
        }
        
    },
    methods:{
        ...mapMutations("login", ["setNavHistory"]),
    },
    mounted() {
        window.localStorage.setItem("InitialRoute", window.location.href);
        window.onpopstate = () => {
            //browser back button disabled
            window.history.forward();
        };
        this.setNavHistory();
    }

}
</script>

<style scoped>
#tsd-container { 
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
</style>