<template>
  <div class="main-container" :style="gridStyle" v-if="tab.data" :key="refresh">
    <div
      class="card main-container-items"
      v-for="(card, index) in tab.data"
      :key="`${card.env + '_' + card.reloadCount}`"
    >
      <div
        class="card-header"
        :class="!card.env?.toLowerCase()?.includes('qa') ? 'header-color' : ''"
      >
        <div>{{ card.env }}</div>
        <button
          :class="{ 'rotating-icon': card.isRefresh || getRefresh }"
          @click="reloadCardData({ index, tabName: tab.tabName })"
        >
          <span class="reload">&#x21bb;</span>
        </button>
      </div>
      <div class="card-content" v-if="card.data.length">
        <VbgBuildInfoCardBody
          v-for="(cardContent, index) in card.data"
          :key="index"
          :cardContent="cardContent"
        />
      </div>
      <div v-else class="empty-card-content">No data available</div>
    </div>
  </div>
</template>
<script>
import VbgBuildInfoCardBody from "./VbgBuildInfoCardBody.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "VbgBuildInfoCard",
  components: { VbgBuildInfoCardBody },
  data() {
    return { refresh: 1 };
  },
  computed: {
    ...mapGetters("dashboard", ["getRefresh"]),
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${
          this.tab.data.length >= 3 ? 3 : this.tab.data.length
        }, 1fr)`,
        gridAutoRows: `minmax(200px, auto)`,
      };
    },
  },
  props: ["tab"],
  methods: {
    ...mapActions("dashboard", ["updateCardData", "reloadCardData"]),
  },
};
</script>
<style scoped>
.card {
  border: none;
  box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}
.card-header {
  background-color: #2196f3;
  border-color: #2196f3;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.header-color {
  background-color: #ed7000 !important;
}
.card-content {
  background: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 10px;
}
.empty-card-content {
  display: grid;
  grid-auto-rows: 250px;
  align-items: center;
}
.reload {
  font-family: Lucida Sans Unicode;
  font-size: 20px;
}
.rotating-icon {
  display: inline-block;
  animation: rotate 2s linear infinite;
}
.main-container {
  margin: 0 20px;
  display: grid;
  gap: 20px;
  padding: 0px 17px;
  padding-bottom: 15px;
  grid-template-columns: repeat(2, 1fr);
}
.main-container-items {
  text-align: center;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 768px) {
  /* For Tabs: */
  .main-container {
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 7px 12px;
  }
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .main-container {
    grid-template-columns: 1fr !important;
    padding: 10px 0px;
  }
}
@media only screen and (max-width: 400px) {
  .card-content {
    grid-template-columns: 1fr !important;
  }
  .empty-card-content {
    grid-auto-rows: 160px;
  }
}
@media only screen and (max-width: 500px) and (min-width: 401px) {
  .card-content {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
</style>