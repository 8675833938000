import { amrest } from '@/vbgauth/util/amrest-axios.js'
import { playrest } from '@/vbgauth/util/playrest-axios.js'
export default {
    namespaced: true,
    state: {
        secretQuestions: null,
        selectedQuestions: [],
        options: {
            headers: {
                'Accept-API-Version': 'resource=2.0',
                'protocol': '1.0'
            }
        },
        dynamicErrorClass: []
    },
    mutations: {
        setSecretQuestions(state, payload) {
            state.secretQuestions = payload
            for (var i =0 ; i<payload.length; i++) {
                state.selectedQuestions.push({
                    'id': null,
                    'question': null,
                    'answer': null
                })
                state.dynamicErrorClass.push({
                    'question': '',
                    'answer': ''
                })
            }
        }
    },
    actions: {
        getSecretQuestions({commit}) {
            //commit('setLoader', true)
            // /v1/sqa/query
            // http://localhost:9002/secretQuestions
            return playrest.get('/aa/api/v1/sqa/query').then(function (response) {
                //commit('setLoader', false)
                commit('setSecretQuestions', response.data.questions)
            }).catch(function (error) {
                // commit('setLoader', false)
                throw error
            })
        },
        submitSecretQAnswers({state, dispatch}, payload) {
            var sqaArr = []
            for (var i = 0; i < payload.length; i++) {
                var sqaObj = {
                    'sqaId': payload[i].id,
                    'sqAnswer': payload[i].answer
                }
                sqaArr.push(sqaObj)
            }
            var finalPayload = { 'userSQARegistration': sqaArr }
            return amrest.post('json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGSQARegistration&noSession=true', {},  state.options).then(function (response) {
                var data = response.data
                data.callbacks[0].input[0].value = JSON.stringify(finalPayload)
                dispatch('submitSQA', data)
            }).catch((error) => {
                throw error
            })
        },
        submitSQA({state}, payload) {
            return amrest.post('json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGSQARegistration&noSession=true', payload,  state.options).then(function (response) {
                if(response.data.successUrl) {
                    window.location.href = iamvar_serverUrl + iamvar_createNewSessionUrl
                }
            }).catch((error) => {
                throw error
            })
        }
    },
    getters: {
        secretQuestions: state => state.secretQuestions,
        availableQuestions: state => state.availableQuestions,
        selectedQuestions: state => state.selectedQuestions,
        dynamicErrorClass: state => state.dynamicErrorClass 
    }
}
