<style scoped>
.forgot_pass .return_div {
    padding-left: 18px;
}

.forgot_pass .return_caret {
    padding-right: 6px;
}

.forgot_pass .resend-field {
    width: auto;
    height: 20px;
    font-size: 16px;
    /* margin-top: 16px; */
}

.forgot_pass .return_to_signIn {
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    font-feature-settings: 'clig' off, 'liga' off;

    /* [VDS] DeskTab/🖥 Body LG Bold */
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.5px;
}

.forgot_pass .return_to_signIn a:focus {
    color: #000;
}

.forgot_pass .input-f {
   border: 1px solid #6F7171 ;
   border-radius: 5px !important;
   margin-bottom: 12px !important;
   height: 44px !important;
}
.forgot_pass .input-f:focus {
  border: 1px solid black !important;
}
.forgot_pass .input_mr{
  margin-bottom: 8px !important;
  border-color: #6F7171 ;
}
.forgot_pass .input_mr:focus{
  border-color: #000000 !important;
}
.forgot_pass .input_border{
  border: 1px solid #6F7171 !important;
}
.forgot_pass .input_border:focus{
  border: 1px solid #000000 !important;
}
.modal-dialog {
  width: 560px;
  height: 308px;
  margin: 0 auto !important;
  position: relative;
  top: 33%;
}
.username {
  position: relative;
}
.labeltiny {
  color: #000 !important;
  font-size: 12px;
  font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
}
.form-control{
  box-shadow: none !important;
}
.vz-input-field{
  border: 1px solid #000;
  border-radius: 5px !important;
  height: 40px;
}
.validmail {
  background-color: transparent;
}
.themes {
  background-color: rgb(255, 236, 224);
  border: 0.0625rem solid rgb(185, 83, 25);
}
.hidden {
  display: none
}
.save_btn {
    border-radius: 24px;
    background: #000;
    color: #fff;
    padding: 12px 24px;
    text-align: center;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
}
.save_btn_disabled {
    border-radius: 24px;
    background: var(--vds-interactive-disabled-onlight, #D8DADA);
    color: #fff;
    padding: 12px 24px;
    text-align: center;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
}
.save_btn:focus {
    outline: 1px solid #000;
}
.warningsymbol,
.validsymbol {
  width: 18px;
  display: block;
  position: absolute;
  right: 10px;
}
.errortxt {
  padding-top: 5px;
  display: block;
  font-size: 12px;
  font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
  text-decoration: none !important;
}
.toast-container {
  background-color: rgb(255, 236, 224);
  padding: 1.25rem;
  border-radius: 0.25rem;
  margin-top: 64px;
  width: 384px;
  
}
.toast-heading {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
  font-family: 'VzNHGeDS-bold11' !important;
  letter-spacing: 0.03125rem;
  color: rgb(0, 0, 0);
  margin: 0px;
  text-decoration: none;
}
.toast-symbol {
  width: 16px;
}
.toast-link {
  margin-top:15px;
}
.fp_parent{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px !important;
  clear: both;
}
.fp_main_heading{
  text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    font-size: 48px;
    /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
    font-family: 'VzNHGeDS-bold11';
    font-style: normal;
    /* font-weight: 700; */
    line-height: 48px;
    letter-spacing: 0.5px;
}
.fp_sub_heading{
    color: var(--vds-elements-text-icons-borders-primary-onlight, #000);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 16px;
    /* font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif; */
    font-family: 'VzNHGeDS-reg11';
    max-width: 520px;
}
.username .fp_input_label{
    font-family: 'VzNHGeTX-reg11' !important;
    color: #000 !important;
    font-size: 12px !important;
}
.fp_input_field{
  width: 384px ;
  border-radius: 4px !important;
  color: #000 !important;
}
.fp_btns{
margin-top: 48px;
margin-bottom: 24px;
}
.fp_input_parent .form-control{
  border-radius: 4px !important
}
.username{
  display: flex;
  flex-direction: column;
}
.labeltiny{
  color: #000000 !important;
}
.validsymbol{
  position: absolute;
  right: 10px;
  top: 15px !important;
}
.warningsymbol{
  position: absolute;
  right: 10px;
  top: 30px !important;
}
.btn, .btn:hover, .btn:active, .btn:focus {
    border-radius: 36px;
    font-family: "VzNHGeDS-bold11", Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    outline: none !important;
    box-shadow: none!important;
    padding: 12px 24px;
    letter-spacing: 0.5px;
}
.popup_heading{
    font-family: 'VzNHGeDS-bold11';
    font-size: 24px;
    margin-bottom: 40px;
    text-align: center
}
.popup_call_btn{
    width: 200px !important;
    text-align: center !important;
    font-family: 'VzNHGeDS-bold11' !important;
    padding: 12px 24px !important;
    margin-left: 12px !important;
    font-size: 16px !important;
    letter-spacing: 0.5px !important;
    border: 2px solid #000 !important;
    background-color: transparent !important;
    color: #000 !important;
}
.popup_email_btn{
    width: 200px !important;
    text-align: center !important;
    padding: 12px 24px !important;
    font-family: 'VzNHGeDS-bold11' !important;
    letter-spacing: 0.5px !important;
    font-size: 16px !important;
}
.popup_btns{
    text-align: center;
}
.popup_box{
    width: 560px !important;
    height: auto !important;
    border-radius: 8px !important;
}
.fp_help_link, .fp_help_link:hover, .fp_help_link:focus  {
    text-decoration: underline;
    color: #000000 !important;
}
.register_link{
  background: none;
    border-radius: 24px;
    padding: 8px 16px;
    margin-top: 10px;
    border: 1px solid;
    font-size: 12px;
    line-height: 16px;
    /* font-weight: 800; */
    font-family: 'VzNHGeDS-bold11' !important;
    letter-spacing: 0.5px;
  }
  .errMessageSubValue_text{
    padding-top: 10px;
    font-weight: 400 !important;
  font-family: 'VzNHGeDS-reg11' !important;
  color: #000000 !important;
  line-height: 16px;
    letter-spacing: 0.5px;

  }
  
@media screen and (max-width: 540px){
  
    .popup_box{
        margin: 16px !important;
    }
    .popup_call_btn{
        margin-left: 0px !important;
        margin-top: 12px !important;
    }
    .modal-dialog.modal-dialog-centered{
        height: auto !important
    }
    .fp_sub_heading{
        font-size: 20px !important;
    }
  }
/* @media screen and (max-width: 420px){
.fp_input_field{
  width: 320px !important;
  border-radius: 4px !important

}
.fp_input_parent .form-control {
    border-radius: 4px !important;
}
} */
@media screen and (max-width: 767px){
  .fp_parent{
    align-items: flex-start !important;
  }
  .fp_main_heading, .fp_sub_heading {
    text-align: left;
  }
  .username {
    width: 100% !important;
}
.fp_input_field{
  width: 100% !important;
  border-radius: 4px !important

}
.fp_btns, .resend-field {
   margin-right: auto;
   margin-left: auto;

}
.fp_main_heading{
  font-size: 40px;
}
.fp_sub_heading{
  font-size: 20px ;
}
.toast-container{
  margin: auto
}
}
.breadcrum_link{
  transition: color 0.15s ease-out;
    position: relative;
    cursor: pointer;
    font-weight: 400;
    line-height: 1rem;
    color: #000000;
    outline: none;
    box-sizing: border-box;
    font-size: 12px
}
.breadcrum_link:hover{
  border-bottom: 1px solid #000000;

}
.breadcrum_inactive_link{
  font-size: 12px !important;
}


</style>

<template>
    <div class="forgot_pass">
    <div id="vz-gh20"></div>
    <!-- <div class="return_div" tabindex="0">
            <span class="return_caret">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Left Caret Icon">
                        <path id="Vector"
                            d="M7.83301 11.5L2.33301 6L7.83301 0.5L9.2219 1.88889L5.16634 5.94444L9.2219 10L7.83301 11.5Z"
                            fill="black" />
                    </g>
                </svg>
            </span>
            <span class="return_to_signIn">
                <a href="#" @click="$router.back()" tabindex="0">{{ $t("message.back_link") }}</a>
            </span>
        </div> -->
        <!-- <div class="return_div" tabindex="0">
            <span class="return_to_signIn">
                <span href="" @click="onRegRedirect" tabindex="0" class="breadcrum_link" >Home</span>
                <span class="breadcrum_inactive_link"> / Sign in /</span>
            </span>
        </div> -->
        
    <div class="fp_parent">
      <div v-if="getVsbdrForgotAlerts.msg" class="toast-container"
                id="toast-active" tabindex = '0'>
                    <div class="toast-heading" :style="{ display: 'flex', justifyContent:'space-between', alignItems:'flex-start'}">
                        <div class="toast-symbol" :style="{display:'flex', alignItems:'center'}">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 21.6 21.6">
                              <path
                                d="M10.80213,19.80122a2.39567,2.39567,0,0,1-1.705-.707L2.50743,12.50444a2.41244,2.41244,0,0,1,0-3.40913L9.09808,2.50555a2.4159,2.4159,0,0,1,3.40908-.001l6.58967,6.59073a2.41244,2.41244,0,0,1,0,3.40913L12.50716,19.0942A2.394,2.394,0,0,1,10.80213,19.80122Zm-7.4998-9.911a1.289,1.289,0,0,0,0,1.81931L9.893,18.29929a1.31476,1.31476,0,0,0,1.81928,0l6.58967-6.58976a1.289,1.289,0,0,0,0-1.81931L11.71226,3.30047a1.29076,1.29076,0,0,0-1.81928,0ZM9.95,15.05h1.7V13.367H9.95Zm0-6.00953.561,2.635h.56952l.56951-2.635V6.55H9.95Z">
                              </path>
                            </svg>
                        </div>
                        <div class="toast-heading" :style="{ paddingLeft: '5px', display:'flex', alignItems:'flex-start', flexDirection:'column', width:'100%', justifyContent:'flex-start' }">
                          {{getVsbdrForgotAlerts.msg}}
                          <span class="errMessageSubValue_text" v-if="getVsbdrForgotAlerts.subMsg">{{getVsbdrForgotAlerts.subMsg}}</span>
                          <div class="toast-link" :style="{ marignTop: '30px', display: 'inline-block' }" v-if="vsbdrAuthError">
                          <button class="register_link" tabindex="0" @click="onRegRedirect"
                            >{{$t('message.forgot_pd_register_link')}}</button>
                        </div>
                        </div>
                        <div @click="handleToaster" @keydown.enter="handleToaster" :style="{ cursor: 'pointer', display:'flex', alignItems:'center' }" class="toast-symbol" tabindex="0"  aria-label="ErrorMessageCloseicon">
                          <svg tabindex = '0' class="toaster" id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.6 21.6" version="1.1" x="0px" y="0px">
                            <path
                              d="M12.2,10.8l6.8,6.8L17.6,19l-6.8-6.8L4,19l-1.4-1.4l6.8-6.8L2.6,4L4,2.6l6.8,6.8l6.8-6.8L19,4L12.2,10.8z">
                            </path>
                          </svg>
                        </div>
        </div>
    </div>
    <h1 class="fp_main_heading">{{ $t("message.forgot_pd_main_heading") }}</h1>
    <div class="fp_sub_heading">{{ $t("message.forgot_pd_enter_email_heading") }}</div>
    <div class="username">
        <label class="fp_input_label" for ="enterEmailAddress">{{$t('message.forgot_pd_enter_email_address')}}</label>
        <span class="fp_input_parent">
        <input type="text" class="form-control vz-input-field fp_input_field"
            :class="emailError ? 'validmail' : ' themes '" v-model="forgotEmailId" v-on:change="onEmailSubmit"
            id="enterEmailAddress" @input="isEmailValid" tabindex ="0"/>
          </span>
                <svg :class="emailError ? 'hidden' : 'warningsymbol'" id="Layer_3" data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.6 21.6">
                        <path
                            d="M10.80213,19.80122a2.39567,2.39567,0,0,1-1.705-.707L2.50743,12.50444a2.41244,2.41244,0,0,1,0-3.40913L9.09808,2.50555a2.4159,2.4159,0,0,1,3.40908-.001l6.58967,6.59073a2.41244,2.41244,0,0,1,0,3.40913L12.50716,19.0942A2.394,2.394,0,0,1,10.80213,19.80122Zm-7.4998-9.911a1.289,1.289,0,0,0,0,1.81931L9.893,18.29929a1.31476,1.31476,0,0,0,1.81928,0l6.58967-6.58976a1.289,1.289,0,0,0,0-1.81931L11.71226,3.30047a1.29076,1.29076,0,0,0-1.81928,0ZM9.95,15.05h1.7V13.367H9.95Zm0-6.00953.561,2.635h.56952l.56951-2.635V6.55H9.95Z">
                        </path>
                </svg>
                <div id="validatedSymbol" class="hidden">
                    <svg :class="emailError ? 'validsymbol' : 'hidden'" id="LayerValid" data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.6 21.6">
                            <path
                                d="M10.80009,19.80015a9,9,0,1,1,9-9A9.01054,9.01054,0,0,1,10.80009,19.80015Zm0-16.87512a7.87512,7.87512,0,1,0,7.87512,7.87512A7.88411,7.88411,0,0,0,10.80009,2.925ZM14.75,8.63964,13.95474,7.85,9.62367,12.17072,7.64331,10.20631,6.85,10.9979,9.62562,13.75Z">
                            </path>
                    </svg>
                </div>
    <div id="Errortext">
        <a v-if="emailErrortxt" class="errortxt" id="errortxt" style="color: #000;" aria-described ="Errortext" role="alert">{{$t('message.forgot_pd_enter_valid_email')}}</a>
    </div>
    </div>
     <div class="fp_btns">
       <button
         :class="{'save_btn_disabled': isButtonDisabled, 'save_btn': !isButtonDisabled }"
         type="submit"
         name="submitButton"
         id="submitButton"
         @click="onSubmit"
         tabindex="0"
         :disabled="isButtonDisabled"
       >
        {{ $t("message.forgot_pd_reset_link") }}
       </button>
     </div>
    <div style="margin-bottom: 30px;" class="resend-field">
        <a @click="openHelpModal();focusTrap();" href="#" class="resend-field-otr fp_help_link">{{ $t("message.forgot_pd_need_help_link") }}</a>
    </div>
    <div id="needHelpModal" class="modal fade">
      <div class="modal-dialog modal-dialog-centered" role="document">
       <div class="modal-content modal-content-container popup_box">
        <div class="modal-container">
        <div class="close-icon-container">
            <a data-dismiss="modal" @click="closeModal()" @keydown.enter="closeModal()" tabindex="0" id="closeModalIcon" aria-label="modalCloseIcon">
                <img src="@/common/assets/styles/corp/images/close_black.png" style="height:20px; width: 20px;" />
            </a>            
        </div>
        </div>
        <h2 class="popup_heading" tabindex="0">{{ $t("message.forgot_pd_need_help_heading") }}</h2>
            <div class="popup_btns">
            <a
                class="btn primary_black popup_email_btn"
                type="submit"
                name="Email Button"
                tabindex="0"
                target="_blank"
                href='mailto:digitalready@verizon.com'
            >
                {{ $t("message.forgot_pd_email_button") }}
            </a>
            <a
                class="btn primary_black popup_call_btn"
                type="submit"
                name="Call Button"
                tabindex="0"
                id="callClick"
                href='tel:8009164351'
            >
                {{ $t("message.forgot_pd_call_button") }}
          </a>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div id="vz-gf20" style = "clear:both"></div>
</div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default ({
    name: 'forgotPassword',
    data() {
        return {
            emailError: true,
            emailErrortxt: false,
            isButtonDisabled: true,
            selectedLanguage: this.$i18n.locale,
            supportedLanguages: [
                { code: 'en', name: 'English' },
                { code: 'es', name: 'Español' },
            ]
        }
    },
    methods: {
        ...mapActions("login", [
            "updateVsbdrError",
            "updateForgotPasswordMail",
            "updateVsbdrForgotAlerts",
            "updateVsbdrLocale"
        ]),
        openHelpModal() {
            $("#needHelpModal").modal({
                backdrop: "static"
            });
        },
        closeModal() {
            $("#needHelpModal").modal('hide')
        },
        focusTrap() {
          var firstTabble = $("#closeModalIcon");
          var lastTabble = $("#callClick");
          firstTabble.focus();
          lastTabble.on('keydown', function (e) {
              if ((e.which === 9 && !e.shiftkey)) {
                  e.preventDefault();
                  firstTabble.focus();
              }
          });
          firstTabble.on('keydown', function (e) {
              if ((e.which === 9 && e.shiftkey)) {
                  e.preventDefault();
                  lastTabble.focus();
              }
          });
        },
        handleToaster() {
            this.updateVsbdrError(false);
            this.updateVsbdrForgotAlerts({});
        },
        onRegRedirect() {
          window.location.href = vsbdr_reg_url;
        },
        isEmailValid() {
        if ((/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(document.getElementById("enterEmailAddress").value))) {
            this.emailError = true;
            this.emailErrortxt = false;
            this.isButtonDisabled = false;
            document.getElementById("enterEmailAddress").style.backgroundColor = 'rgb(220, 245, 230)'
            document.getElementById("enterEmailAddress").style.border = '1px solid #008331'
            document.getElementById("validatedSymbol").className = "validsymbol"
        } else {
            this.emailError = false;
            this.isButtonDisabled = true;
            this.emailErrortxt = true;
            document.getElementById("Layer_3").style.display="block";
            document.getElementById("enterEmailAddress").style.backgroundColor = 'rgb(255, 236, 224)'
        }
    },
     onEmailSubmit() {
      var passSubmit = document.getElementById("enterEmailAddress")
      passSubmit.addEventListener("keyup", function (e) {
        if (e.code === "Enter") {
          e.preventDefault();
          document.getElementById("submitButton").click();
        }
      });
    },
    onSubmit() {
      const payLoad = {
        secProfileEmail: this.forgotEmailId
      }
      this.updateForgotPasswordMail(payLoad)
    }
    },
    computed: {
        ...mapGetters('login', [
            "vsbdrAuthError",
            "getVsbdrForgotAlerts"
        ]),
     },
    watch: {
      getVsbdrForgotAlerts: function(newVal) {
      if(newVal) {
        setTimeout(() => {
         if(document.getElementById("toast-active")){
            document.getElementById("toast-active").focus();
         }
        });
      }
    }
  }, 
  created: function () {
    const searchParams = new URLSearchParams(window.location.search);
    const storedLanguage = localStorage.getItem('lng');
    const browserLanguage = navigator.language.substring(0, 2);;
    if (storedLanguage) {
        this.$i18n.locale = storedLanguage;
        this.selectedLanguage = storedLanguage;
    } else if (this.supportedLanguages.some(lang => lang.code === browserLanguage)) {
        this.$i18n.locale = browserLanguage;
        this.selectedLanguage = browserLanguage;
    } 
  },
  mounted () {
    this.updateVsbdrLocale(this.$i18n.locale);
    document.documentElement.lang = this.$i18n.locale;
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window, document, 'script', 'dataLayer', 'GTM-KJFSV78');
    if(document.getElementById('closeModalIcon')){
      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          this.closeModal();
        }
      })
    }
    setTimeout(() => {
        const langSwitchLink = document.querySelector('.gnav20-lang-link');
         if(langSwitchLink) {
          document.addEventListener('click', (e)=> {
          const langValue = e.target.getAttribute('data-lang');     
          if(langValue) {
            if(langValue === 'English'  || langValue === 'Inglés') {
                localStorage.setItem("lng","en");
            } else {
                localStorage.setItem("lng","es");
            }
          }
          })
          var locale = this.$i18n.locale;
          const variationValue = locale == 'es' ? 'prospect-es' : 'prospect';
          var globalNavdl = {
            "bu": "corporate",
            "appid": "vsbdr",
            "variation": variationValue,
            "options":{"hide-item":["search"]}
          }
          window.gnavdl = globalNavdl;
         }
       }, 1500);
       var globalNav = document.createElement('script')
       globalNav.setAttribute('src', 'https://www.verizon.com/etc/designs/vzwcom/gnav20/corporate.js')
       globalNav.setAttribute('async', 'async');
       document.head.appendChild(globalNav);
  }
})
</script>
