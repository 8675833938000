<template>
  <div>
    <div
      v-if="watchSubmitLoader"
      style="display: flex; justify-content: center; margin-top: 250px"
    >
      <CustomLoaderComponent></CustomLoaderComponent>
    </div>
    <div v-else style="overflow: hidden; font-family: 'VzNHGeDS-reg11'">
      <div id="vz-gh20" v-if="this.getWhichRealm"></div>
      <div class="new_pwdless_parent_container">
        <BlockUI
          v-if="submitLoader || getTransactionLoader || pollingLoader || forgotLoader"
          :url="url"
        ></BlockUI>
  <div class="new_pwdless_form_container" v-if="getNavHistory.length > 1">
    <div class="new_pwdless_box_container">
            <div class="new_pwdless_box">
              <div class="new_pwdless_content">
                <ErrorMessage
                  :message_details="getCommonErrors[0]"
                  v-if="getCommonErrors.length"
                />
                <NavigationHistory v-if="showBackButton" />               
                <!-- Loading Required components based on route changes -->
                <component :is="getCurrentComponent.name" />
              </div>
            </div>
          </div>
        </div>
        <NewPwdLessLogin v-if="getNavHistory.length == 1 && this.getWhichRealm" />
        <MobilePwdLessLoginComponent
          v-else-if="getNavHistory.length == 1 && !this.getWhichRealm"
        />
        
        <div :class="[{'box_alignments': isWideScreen}, 'container-2 right_banner']" v-if="showAdobeBaner">
            <div class="pwd-default-promo1">
                <p class="pwd-default-promo">Manage your business account online anytime, anywhere.</p>
            </div>
        </div>
      </div>
      <div id="vz-gf20" v-if="this.getWhichRealm"></div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { setPageDetails } from "../../../../../public/adobeTagging";
import ClearWhite from "@/common/assets/styles/corp/images/clear_white.png";
import CustomLoaderComponent from "@/vbgauth/components/CustomLoaderComponent.vue";
import commonMixin from "@/common/mixin/commonMixins";
import MobilePwdLessLoginComponent from "./MobilePwdLessLoginComponent.vue";
import NavigationHistory from "../common/NavigationHistory.vue";
import ErrorMessage from "../common/ErrorMessage.vue";
import NewPwdLessLogin from "./NewPwdLessLogin.vue";
import NewPwdLessPassword from "./NewPwdLessPassword.vue";
import NewOtpDelivery from "../newStepUp/NewOtpDelivery.vue";
import NewOtpValidate from "../newStepUp/NewOtpValidate.vue";
import NewOption from "./NewOption.vue";
import ResetPassword from "./NewResetPassword.vue";
import NewDeliveryMethodLocked from "./NewDeliveryMethodLocked.vue";
// import NewVerifyDelivery from "../newAddMtnFlow/NewVerifyDelivery.vue";
// import NewOTPSummary from "../newAddMtnFlow/NewOTPSummary.vue";
// import NewAddOTPComponent from "../newAddMtnFlow/NewAddOTPComponent.vue";
import NewRootAddDelivery from "../newAddMtnFlow/NewRootAddDelivery.vue";
import CommonErrorMixin from "../common/common-error-mixin";
export default {
  name: "NewPwdLessLoginRootComponent",
  components: {
    NavigationHistory,
    NewPwdLessLogin,
    NewPwdLessPassword,
    NewOtpDelivery,
    NewOtpValidate,
    NewOption,
    NewDeliveryMethodLocked,
    ErrorMessage,
    ResetPassword,
    // NewVerifyDelivery,
    // NewOTPSummary,
    // NewAddOTPComponent,
    NewRootAddDelivery,
    MobilePwdLessLoginComponent,
    CustomLoaderComponent,
  },
  mixins: [commonMixin, CommonErrorMixin],
  data() {
    return {
      test: "NewDeliveryMethodLocked",
      meth: "pass",
      showClickHereMsg: false,
      idmRedirect: "",
      pwdLoginEnable: false,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      watchSubmitLoader: false,
      whichRealm: true,
    };
  },
  computed: {
    isWideScreen(){
      return window.innerWidth > 1271;
    },
    ...mapGetters("login", [
      "getUnifiedErrorCode",
      "getVcgSubmitLoader",
      "formData",
      "isProActive",
      "gotoUrl",
    ]),
    ...mapGetters("cvsstepup", ["urlOnMount", "pollingLoader", "flowType"]),
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
  },
  watch: {
    getVcgSubmitLoader: function (newVal) {
      if (newVal) {
        this.watchSubmitLoader = true;
      } else {
        this.watchSubmitLoader = false;
        this.createGlobalNav();
      }
    },
  },

  async mounted() {
    window.that = this;
    // f.setAttribute('id',"login-form");
    // f.setAttribute('name',"loginForm");
    const hostUrl = window.location.href;
    if (hostUrl.includes("/login/mobile")) {
     // this.whichRealm = false;
      this.setWhichRealm(false)
      this.setNavHistory();
    }
    if(this.getWhichRealm) {
      this.createGlobalNav();
    }
    var rky = window.location.search.match(/Reg_Message=([^\&]+)/) || [0, 0];
    if (rky && rky[1]) {
      // document.getElementById("regMsgP").innerHTML = regMsg[rky[1]] || "";
      // document.getElementById("regMsgS").checked = true;
      this.updateSetUnifiedLoginAlerts(rky[1].toLowerCase());
    }
    let gotoParam = this.getParameterByName("goto");
    let encrpytedTok = this.getParameterByName("t1");
    let uid = this.getParameterByName("loginAssistantUid");
    console.log("gotoParam..", gotoParam, "encrpytedTok..", encrpytedTok);
    if (gotoParam) {
      setGotoUrlInClientLib(gotoParam);
    }
    if (encrpytedTok) {
      console.log("coming inside..,", encrpytedTok);
      //for vbms account link send gotourl in vbgIamclient lib
      this.submitVbmsAccountLinkForm(encrpytedTok);
    }
    //for guest user or regration flow from vcg
    let encrpytedRegTok = this.getParameterByName("rt1");
    if (encrpytedRegTok) {
      console.log("coming inside..,", encrpytedRegTok);
      //for vbms account link send gotourl in vbgIamclient lib
      let payload = {
        encrpytedRegTok: encrpytedRegTok,
      };
      this.submitPwdAMPasswordForm(payload);
    }

    // Enable non proActive flow
    if (uid && this.isProActive && iamvar_loginAssistant) {
      this.updateProActive(false);
      this.setLoginAssist(true);
      this.watchSubmitLoader = true;
      // Decode the base64 uid value
      let decodedUID = atob(uid);
      this["formData"][0].input[0].value = decodedUID.trim();
      let payloadData = {
        formData: JSON.parse(JSON.stringify(this.formData)),
        rememberUser: false,
      };
      this.submitPwdAMLoginForm(payloadData);
      this.updateUsernameForStepup(this.formData[0].input[0].value.trim());
    }
  },
  methods: {
    ...mapActions("login", [
      "setCookieForBlueBanner",
      "UpdatePopupValue",
      "updateSetUnifiedLoginAlerts",
      "updateCVSSubmitLoader",
      "submitVbmsAccountLinkForm",
      "submitPwdAMPasswordForm",
      "submitPwdAMLoginForm",
      "updateProActive",
    ]),
    ...mapActions("cvsstepup", ["updateUsernameForStepup"]),
    ...mapMutations("login", ["setLoginAssist", "setWhichRealm"]),
    getCloseIcon() {
      // return  (
      //   iamvar_cdnUrl +
      //   iamvar_imgSourceContext +
      //   "/business/login/includes/img/close.7e170800.svg"
      // )
      //return this.getUnifiedLoginAlerts[0].type == "success"
      // ? CloseBlack : ClearWhite;
      return ClearWhite;
    },
    bannerContainer() {
      return this.getUnifiedLoginAlerts[0].type == "success" ||
        this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")
        ? "bannerMargin"
        : "";
    },
    deleteAlert(alert) {
      this.getUnifiedLoginAlerts.splice(
        this.getUnifiedLoginAlerts.indexOf(alert),
        1
      );
    },
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png'
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      return index.type + "MessageImage";
    },
    getTypeOfAlert(index) {
      return index.type;
    },
    gethpop(value) {
      this.UpdatePopupValue(value);
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
    },
  },
  async created() {
    const searchParams = new URLSearchParams(window.location.search);
    gnavdl = {
      bu: "smb",
      appid: "unified",
    };
    setPageDetails("unifiedLogin:landing");
    this.setCookieForBlueBanner();
  },
};
</script>
<style>
.new_pwdless_parent_container{
  display: flex;
  /* justify-content: space-evenly !important; */
  /* margin-bottom: 150px; */
}
.container-2 {
  margin-left: 0px;
  margin-bottom: 32px;
}
.right_banner {
  margin-top: 32px;
}
.pwd-default-promo1 {
  background: #f6f6f6;
  height: 650px;
  width: 464px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 24px;
}
.pwd-default-promo {
  height: 128px;
  width: 253.78px;
  color: #a7a7a7;
  font-family: "VzNHGeDS-bold11";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
@media only screen and (min-width: 280px) and (max-width: 1023px) {
  .container-2 {
    display: none !important;
  }
  .pwd-default-promo1 {
    display: none;
  }
  .pwd-default-promo {
    display: none;
  }
}
</style>
