<template>
<div>
<AMHeader></AMHeader>
<AMLoginComponent></AMLoginComponent>
<AMFooter></AMFooter>
</div>
</template>
<script>
import AMFooter from '@/vbgauth/components/AMFooter'
import AMHeader from '@/vbgauth/components/AMHeader'
import AMLoginComponent from '@/vbgauth/components/amlogin/AMLoginComponent'
export default {
  name: 'AMLoginRootComponent',
  components: {
    AMFooter,
    AMHeader,
    AMLoginComponent
  },
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    window.localStorage.setItem("InitialRoute", window.location.href);
  }
}
</script>
<style scoped>
</style>
