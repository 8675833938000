var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vbg-ff-root-container"},[(this.whichRealm)?_c('Header'):_vm._e(),_c('div',{staticClass:"vbg-ff-content-container"},[(
        _vm.getUnlockMyAccountAlerts.length > 0 &&
        !_vm.tokenExpired &&
        !_vm.rspSessionExpired
      )?_c('div',_vm._l((_vm.getUnlockMyAccountAlerts),function(j){return _c('div',{key:j,staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"vbg-ff-messagesInfo",class:_vm.getAlertType(j)},[_c('div',{staticClass:"vbg-ff-msg-container"},[_c('div',{staticClass:"messageWrapper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"messageIcon",staticStyle:{"padding":"0px","margin-right":"12px"}},[_c('div',{staticClass:"messageImage",class:_vm.messageImage(j)})]),(j.type == 'list')?_c('p',{staticClass:"messageContentUn col-xs-11",staticStyle:{"padding":"0px","margin":"0px"},domProps:{"innerHTML":_vm._s(j.msg)}},[_vm._v(" "+_vm._s(j.msg)+" ")]):(j.type == 'error' || j.type == 'warning')?_c('p',{staticClass:"messageContentUn col-xs-11",staticStyle:{"padding":"0px","margin":"0px"},domProps:{"innerHTML":_vm._s(j.msg)}},[_vm._v(" "+_vm._s(j.msg)+" ")]):_c('p',{staticClass:"messageContentUn col-xs-11",staticStyle:{"padding":"0px","margin":"0px","color":"#ffffff"},domProps:{"innerHTML":_vm._s(j.msg)}},[_vm._v(" "+_vm._s(j.msg)+" ")]),_c('div',{staticClass:"closeMessage col-xs-1",staticStyle:{"padding":"0px","margin-left":"16px","display":"grid"}},[(
                      j.type == 'list' ||
                      j.type == 'error' ||
                      j.type == 'warning'
                    )?_c('img',{staticClass:"closeMessageImg close-icon-img",attrs:{"src":require("@/common/assets/styles/corp/images/close_black2.png")},on:{"click":function($event){return _vm.deleteAlert(j)}}}):_c('img',{staticClass:"closeMessageImg close-icon-img",attrs:{"src":require("@/common/assets/styles/corp/images/close.png")},on:{"click":function($event){return _vm.deleteAlert(j)}}})])])])])])])}),0):_vm._e(),(!_vm.tokenExpired && !_vm.rspSessionExpired)?_c('div',{staticClass:"vbg-rsp-root-container"},[_c('ResetSecurityProfileSteps'),(
          _vm.gotoComponent === 'validateUserIdComponent' ||
          _vm.gotoComponent === 'resetSecurityProfileNewPwd'
        )?_c('ValidateUserIdComponent',{attrs:{"tokenVal":_vm.tokenValue}}):(_vm.gotoComponent === 'resetSecurityProfileSqaComponent')?_c('ResetSecurityProfileSqaComponent'):(_vm.gotoComponent === 'resetSecurityProfileOTPSummary')?_c('ResetSecurityProfileOTPSummary'):(_vm.gotoComponent === 'resetSecurityProfileAddOtpComponent')?_c('ResetSecurityProfileAddOtpComponent'):_vm._e()],1):_vm._e(),(_vm.tokenExpired || _vm.rspSessionExpired)?_c('ErrorMessage',{attrs:{"title":_vm.title,"description":_vm.des}}):_vm._e()],1),(this.whichRealm)?_c('Footer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }