import { render, staticRenderFns } from "./vpsHeader.vue?vue&type=template&id=a8b3b798&scoped=true"
import script from "./vpsHeader.vue?vue&type=script&lang=js"
export * from "./vpsHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b3b798",
  null
  
)

export default component.exports