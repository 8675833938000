<template>
    <div>
        <BlockUI v-if="submitLoader" :url="url"></BlockUI>
        <vpsHeader></vpsHeader>
        <div v-if="fuAlerts.length > 0">
            <div class="row" v-for="j in fuAlerts" :key="j">
                <div class="col-sm-12">
                    <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
                        <div class="vbg-ff-msg-container">
                            <div class="messageWrapper" style="display: flex;">
                                <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                                    <div class="messageImage" :class="messageImage(j)"></div>
                                </div>
                                <p class="messageContentUn col-xs-11" v-if="j.type == 'list'" v-html="j.msg"
                                    style="padding:0px;margin:0px">{{j.msg}}</p>
                                <p class="messageContentUn col-xs-11"
                                    v-else-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px"
                                    v-html="j.msg">{{j.msg}}</p>
                                <p class="messageContentUn col-xs-11" v-else
                                    style="padding:0px;margin:0px;color:#ffffff" v-html="j.msg">{{j.msg}}</p>
                                <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                                    <img class="closeMessageImg"
                                        v-if="j.type == 'list' || j.type == 'error' || j.type == 'warning'"
                                        src="@/common/assets/styles/corp/images/close_black.png"
                                        @click="deleteAlert(j)" />
                                    <img class="closeMessageImg" v-else
                                        src="@/common/assets/styles/corp/images/clear_white.png"
                                        @click="deleteAlert(j)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vbg-ff-main-container">
            <div class="vbg-ff-title-container">
                <h1>{{ $t("message.forgot_username_general_title") }}</h1>
            </div>
            <BlockUI v-if="submitLoader" :url="url"></BlockUI>
            <p class="px-0">Please enter the required information below so we may retrieve your User ID.</p>
            <div class="vbg-ff-form-container">
                <v-form @submit.prevent="submit" ref="fuForm" v-model="valid">
                    <div v-if="formInputs">
                        <div>
                            <label class="vbg-ff-input-label" for="email">{{ formInputs[2].label }}</label>
                            <div class="vbg-icon-input-group">
                                <input id="email" v-model="email" type="text" @keydown.space.prevent
                                    @input="trimInput(email)" class="vbg-ff-input-field"
                                    :class="!isValidEmail ? 'vbg-input-warning' : ''"
                                    :autocomplete="formInputs[2].autocomplete" name="email" />
                                <div class="vbg-warning-icon" v-if="!isValidEmail"><img :src="warningIcon" /></div>
                            </div>
                        </div>
                        <div v-if="!isValidEmail">
                            <p class="pmicroEmail" v-html="$t('message.forgot_username_emailAdress_error')">
                            </p>
                        </div>
                        <div v-if="this.jCaptchaFlag">
                            <div>
                                <div class="captcha-blk">
                                    <div class="captcha-txt-blk">
                                        <img id="captchaImg" src=""
                                            :class="getOAuthClass('oauth-flow-jcaptch')" /><br />
                                    </div>
                                </div>
                                <button name="refreshCaptcha" id="refreshCaptcha"
                                    class="refreshCaptcha martop10 marbottom30"
                                    v-on:click="refreshCaptchaImgUrl()">Refresh Image</button>
                            </div>
                            <div>
                                <label class="vbg-ff-input-label" for="captchaInput">Type the characters from the above
                                    image</label>
                                <input type="text" id="captchaInput" name="captchaInput" @keydown.space.prevent
                                    class="form-control vbg-ff-input-field" v-model="captchaText" />
                            </div>
                        </div>

                        <div class="martop60">
                            <invisible-recaptcha
                                :disabled="!fname || !lname || !email || !isEmailValid() || !captchaText"
                                v-if="this.captchaOn" :sitekey="forgotUsrnCaptchaKey" theme="light" badge="bottomright"
                                :callback="onCaptchaVerified" :class="formInputs[3].class" :style="formInputs[3].style"
                                :type="formInputs[3].type">{{ formInputs[3].value }}</invisible-recaptcha>
                            <div class="vbg-mb-btn-container">
                                <button v-if="!this.captchaOn" :disabled="isDisabled()" @click="onCaptchaVerified('')"
                                    :class="isDisabledClass()" :style="formInputs[3].style" :type="formInputs[3].type"
                                    class="btn vbg-ff-btn-continue vbg-ff-btn-width140" id="username-continue-btn"
                                    name="username-continue-btn">
                                    {{ formInputs[3].value }}
                                </button>

                                <button class="btn vbg-ff-btn-cancel vbg-ff-btn-width140" @click="cancelUsrRecovery()"
                                    :style="formInputs[4].style" type="button" id="username-cancel-btn"
                                    name="username-cancel-btn">{{ formInputs[4].value }}</button>

                            </div>
                            <div style="margin-bottom: 80px"></div>
                        </div>
                    </div>
                </v-form>
            </div>
        </div>
        <vpsFooter></vpsFooter>
    </div>
</template>
<script>
import InvisibleRecaptcha from 'vue-invisible-recaptcha'
import { mapGetters, mapActions } from 'vuex'
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import { eventTracking } from '../../../../../public/adobeTagging'
import warningIcon from '@/common/assets/styles/corp/images/warning.png'
import commonMixin from "@/common/mixin/commonMixins";
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
export default {
    name: 'VPSForgotUserIdUserDetails',
    components: {
        "invisible-recaptcha": InvisibleRecaptcha,
        vpsHeader,
        vpsFooter
    },
    mixins: [commonMixin],
    data() {
        return {
            otpEnabled: true,
            contact_admin: iamvar_contact_admin,
            caret: caret_blk,
            valid: false,
            fieldRules: [v => !!v || 'Please fill out this field'],
            fname: null,
            lname: null,
            email: null,
            captchaOn: true,
            captchaText: null,
            jCaptchaFlag: true,
            submittedAlready: false,
            whichRealm: true,
            emailFormatRegex: /^[a-zA-Z0-9](\.?\_?\-?[a-zA-Z0-9]){0,}@[a-zA-Z0-9-_]+\.([a-zA-Z0-9-_]{1,}\.){0,}[a-zA-Z]{2,}$/,
            userValidationInputClass: '',
            warningIcon: warningIcon,
            isValidEmail: true,
            url: iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/circle-loaderWHITE_48x48.gif',
        }
    },
    mounted() {
        this.fuAlerts.splice(0, 1);
        this.changeContextBiocatch('forgot_username_email_page')
        //get updated captcha on page load
        this.$nextTick(() => this.refreshCaptchaImgUrl())
        if (window.location.href.includes('business')) {
            this.readConfig('vbg_config')
        } else if (window.location.href.includes('consumer')) {
            this.readConfig('vcg_config')
        }
        if ((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
            const hostUrl = this.urlOnMount
            if (hostUrl.includes('/account/business/login/mobile')) {
                this.whichRealm = false;
            }
        }
        if (iamvar_realm == 'MOBILE') {
            let returnUrl = this.urlOnMount;
            this.updateUrlOnMount(returnUrl);
        }
        if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
            let iamvar_appCookie = getCookie('X-VZ-C_APP')
            if (iamvar_appCookie == 'MVB') {
                this.whichRealm = false;
            }
        }

        this.readFormInputs('user_details_input_component')

        this.captchaOn = iamvar_enableCaptcha;

        this.jCaptchaFlag = iamvar_enableJCaptcha;
    },
    methods: {
        ...mapActions('cvsstepup', [
            'updateUserDetailsForStepup',
            'updateFlowType',
            'initializeStepup',
            'updateRecaptchaResponse',
            'updateCaptchaText',
            'igCaptchaEndpoint',
            'updateClearInputFields',
            'vpsTriggerForgotuserNameEmail'
        ]
        ),
        ...mapActions('forgotflow', [
            'readConfig',
            'readFormInputs'
        ]
        ),
        ...mapActions('login', [
            'updateUrlOnMount'
        ]),
        getMsgIcon(index) {
            return "@/common/assets/styles/corp/images/" + index.type + ".png";
        },
        messageImage(index) {
            if (index.type == 'error' || index.type == 'warning' || index.type == 'list') {
                return "newErrorMessageImage"
            } else {
                return index.type + "MessageImage";
            }
        },
        getCloseIcon() {
            return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
        },
        deleteAlert(alert) {
            this.fuAlerts.splice(this.fuAlerts.indexOf(alert), 1)
        },
        getAlertType(index) {
            return index.type
        },
        isEmailValid() {
            return (this.email == "") ? "" : (this.emailFormatRegex.test(this.email)) ? true : false;
        },
        onCaptchaVerified(recaptchaToken) {
            this.updateUserDetailsForStepup({
                "email": this.email.trim()
            })
            this.updateCaptchaText(this.captchaText.trim());
            eventTracking('Username Recovery Request Initiated', '');
            this.updateFlowType('forgot_username')
            // push to stepupRootComponent only if the username is valid (returned by the backend)
            this.emaiIDValidation(this.email)
            this.updateRecaptchaResponse(recaptchaToken)
            this.igCaptchaEndpoint({ isVPSFlow: true })
            //this.refreshCaptchaImgUrl()
            //this.refreshCaptchaImgUrl()
            //this.captchaText = null;
        },
        cancelUsrRecovery() {
            this.$store.dispatch('cvsstepup/resetState')
            this.$store.dispatch('forgotflow/resetState')
            var returnUrl = this.getHostName(window.location.href);

            if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
                let iamvar_appCookie = getCookie('X-VZ-C_APP')
                if (iamvar_appCookie == 'MVB') {
                    window.location.href = iamvar_mvbLaunchUrl;
                }
            } else if (!this.urlOnMount) {
                window.location.href = this.sanitizeUrl("https://" + returnUrl + "/account/business/login/vpsunifiedlogin");
            } else {
                window.location.href = this.urlOnMount;
            }

            // checking if user agent is vema then redirect user back to mobile login page
            if (this.userAgent && this.userAgent.toLowerCase() === 'vema') {
                window.location.href = iamvar_vemaLaunchUrl;
            }
        },
        getHostName(url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                return match[2];
            }
            else {
                return null;
            }
        },
        //getCaptchaImgUrl() {
        //  var captchaImgHref = this.getHostName(window.location.href);
        //  let srcURL = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha");
        //  return srcURL;
        //},
        refreshCaptchaImgUrl() {
            var captchaImgHref = this.getHostName(window.location.href);
            document.getElementById("captchaImg").src = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha?cb=" + Math.random());
        },
        getOAuthClass(className = 'oauth-flow') {
            return className
        },
        emaiIDValidation(newVal) {
            if (newVal) {
                if (!this.emailFormatRegex.test(newVal)) {
                    this.userValidationInputClass = "vz-error";
                } else {
                    this.userValidationInputClass = "";
                }
            }
        },
        vpsSingleUserAction() {
            let userList = []
            if (this.vpsUsersData) {
                let rawData = this.vpsUsersData;
                let keyValuePairs = Object.entries(rawData);
                keyValuePairs.forEach(([key, value]) => {
                    let eachData = {
                        guid: key,
                        userid: value,
                    }
                    userList.push(eachData);
                })
            }
            this.vpsTriggerForgotuserNameEmail(userList[0].userid);
        },
        isDisabled() {
            if (
                !this.formComplete
                || (!this.captchaText && this.jCaptchaFlag)
                || !this.isValidEmail || this.submitLoader
            ) {
                return true;
            }
            return false
        },
        isDisabledClass() {
            if (
                !this.formComplete
                || (!this.captchaText && this.jCaptchaFlag)
                || !this.isValidEmail || this.submitLoader
            ) {
                return ' disabled';
            } else {
                return "";
            }
        },
        trimInput() {
            this.email = this.email.trim();
        }
    },
    computed: {
        ...mapGetters('forgotflow', [
            'forgotUsrnCaptchaKey',
            'forgotFlConfig',
            'formInputs',
            'iamvar_enableCaptcha'
        ]),
        ...mapGetters('cvsstepup', [
            'currentStep',
            'cannotRecoverUsername',
            'urlOnMount',
            'fuAlerts',
            'submitLoader',
            'clearInputFields',
            'vpsUsersData'
        ]),
        ...mapGetters("login", ["mybizzFlag", "userAgent"]),
        formComplete() {
            return this.email
        },
        fuAlerts: {
            get: function () {
                return this.$store.getters['cvsstepup/fuAlerts']
            },
            set: function (newVal) {
                this.$store.commit('cvsstepup/setFuAlert', newVal)
            }
        }
    },
    watch: {
        email(newVal) {
            if (newVal) {
                if (!this.emailFormatRegex.test(newVal)) {
                    this.isValidEmail = false
                    return
                }
            }
            this.isValidEmail = true;
            return;
        },

        currentStep: function (newVal) {
            if (newVal === 'vpssingleuser') {
                this.vpsSingleUserAction();
            } else if (newVal === 'vpsmultiuser') {
                this.$router.push({ name: 'VPSForgotUserIdSelect' });
            }
        },
        fuAlerts: function (newVal) {
            if (newVal) {
                this.refreshCaptchaImgUrl();
                this.captchaText = null;
                this.submittedAlready = false;
                window.scrollTo(0, 0);
            }
        },
        clearInputFields: function (newVal) {
            if (newVal) {
                this.email = ''
                this.captchaText = null
                this.updateClearInputFields(!newVal)
            }
        },
    }
}
</script>
<style scoped>
button.primary {
    background-color: white !important;
}

.v-text-field__details {
    padding-top: 4px !important;
}

.vz-input-field:focus {
    border: 1px solid black !important;
    box-shadow: none !important;
}

.vz-input-field {
    height: 44px !important;
}

.contact-ad {
    position: absolute;
    margin-top: 510px;
    padding-bottom: 100px;
}

input {
    padding: 11px 0 11px 10px;
    border-radius: 0px;
    font-size: 14px;
    color: #000;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000;
    box-shadow: none;
    transition: border .4s;
    height: 36px;
}

.refreshCaptcha {
    border: none;
    background: white;
    text-decoration: underline;
}

.vz-error {
    border: #ed7000 1px solid;
    border-bottom: #ed7000 4px solid;
}

.btn.secondary_white {
    margin-right: 1.5rem
}

.pmicroEmail {
    height: 32px;
    width: 301px;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}

.captcha-blk {
    margin-top: 60px;
}

.vbg-warning-icon {
    height: 18.04px;
    width: 18.04px;
}

.vbg-warning-icon {
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 13px;
}

.vbg-warning-icon img {
    height: 18.04px;
    width: 18.04px;
}

.vbg-icon-input-group {
    position: relative;
}

.vbg-icon-input-group input {
    width: 100%;
}

.oauth-flow-continue-btn {
    margin-right: 12px;
}

@media (min-width: 280px) and (max-width: 1024px) {
    .oauth-flow-mt12 {
        margin-top: 12px !important;
    }

    .contact-ad {
        margin-top: 610px;
    }

    .oauth-flow {
        padding-right: 0 !important;
        margin-top: 12px !important;
    }

    .oauth-flow v-form.padleft0.col-xs-12 {
        padding-right: 0 !important;
    }

    .oauth-flow-pr0 {
        padding-right: 0 !important;
    }

    .oauth-flow-jcaptch {
        /*width: 100%;*/
        margin-bottom: 0px;
        height: 48px;
    }

    .captcha-blk {
        height: 50px;
        margin-bottom: 10px;
        background: linear-gradient(270deg, #00FFF0 0%, #FFB404 100%);
        margin-top: 40px;
    }

    .captcha-txt-blk {
        position: absolute;
        margin-top: 24px;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }

    .oauth-flow-continue-btn {
        width: 100%;
        margin-bottom: 12px;
    }

    .oauth-flow-cancel-btn {
        width: 100%;
        margin-left: 0 !important;
        /*margin-bottom: 12px;*/
    }

    .col-sm-6 {
        max-width: 100%;
        width: 100%;
    }

    .oauth-flow-w-100 {
        width: 100% !important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .oauth-flow-continue-btn {
        width: 47% !important;
        margin-bottom: 0 !important;
        /*float: right;*/
    }

    .oauth-flow-cancel-btn {
        width: 47% !important;
        margin-left: 0 !important;
        float: right;
        margin-right: 0 !important;
    }
}

.vbg-button {
    height: 44px;
    width: 140px;
    border-radius: 22px;
    background-color: #000000;
    color: #ffffff;
    font-family: VzNHGeDS-bold11;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
    padding: 12px 34.5px 12px 34.5px;
}

.disabled {
    background-color: #d8dada !important;
}

@media (max-width: 768px) {
    .vbg-mb-btn-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .oauth-flow-continue-btn {
        margin-bottom: 0px !important;
    }

    .oauth-flow-cancel-btn {
        margin-bottom: 12px;
    }
}

/* only for desktop view */
@media (min-width: 1025px) {
    .vz-input-field {
        width: 301px !important;
    }

    .vbg-icon-input-group {
        width: 301px !important;
    }

    .vbg-btn-continue {
        width: 140px !important;
    }

    .vbg-btn-cancel {
        width: 150px !important;
    }
}

.vbg-input-warning {
    border: 1px solid #ED7000 !important;
    border-bottom: 4px solid #ED7000 !important;
}

.vbg-input-warning:focus {
    border: 1px solid #ED7000 !important;
    border-bottom: 4px solid #ED7000 !important;
}

.col-sm-6 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.col-sm-12 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.marbottom30 {
    margin-bottom: 30px !important;
}
</style>
