<template>
    <v-wait for="request_to_complete">
        <template slot="waiting">
            <LoaderComponent></LoaderComponent>
        </template>
        <BlockUI v-if="submitLoader" :url="url"></BlockUI>
        <Header v-if="this.whichRealm"></Header>
        <v-row class="vz-forgot-flow">
          <v-col xs="0" sm="0" md="2"></v-col>
          <v-col cols="12" xs="12" sm="12" md="8" class="padleft15 padright15">
            <v-row>
              <h1 v-if="stepupPageTitle">{{ stepupPageTitle }}</h1>
            </v-row>
            <v-row v-if="alerts.length > 0" class="marright5 marleft5">
              <v-row class="row alert" v-bind:class="getAlertType(j)" v-for="j in alerts" :key="j">{{j.msg}}</v-row>
            </v-row>
            <v-row v-if="stepupPageTitle" class="blackbar martop0"></v-row>
            <v-row>
              <p class="px-0" v-if="!fingerprintSaved">
                <b style="color: #000;">{{ stepupPageDesc }}</b>
                <span v-if="!sqaDisabled && stepupPageDesc">
                  <button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;" v-tooltip="content"></button>
                </span>
                <span v-if="sqaDisabled">
                  <button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;" v-tooltip="login_device_not_recognized_no_sqa_tooltip" popover-placement="left" popover-trigger="click mouseenter mouseleave focus blur"></button>
                </span>
              </p>
            </v-row>
            <AuthOtpDeliveryComponent></AuthOtpDeliveryComponent>
            <AuthOtpValidateComponent></AuthOtpValidateComponent>
            <DeviceOptionsNotAvailable v-if="checkIfResetPasswordOptionAvailable()"></DeviceOptionsNotAvailable>
          </v-col>
          <v-col xs="0" sm="0" md="2"></v-col>
        </v-row>
        <Footer v-if="this.whichRealm"></Footer>
    </v-wait>
</template>
<script>
import Footer from '@/vbgauth/components/Footer'
import Header from '@/vbgauth/components/Header'
import LoaderComponent from '@/vbgauth/components/LoaderComponent'
import AuthOtpDeliveryComponent from '@/vbgauth/components/authstepup/AuthOtpDeliveryComponent'
import AuthOtpValidateComponent from '@/vbgauth/components/authstepup/AuthOtpValidateComponent'
import DeviceOptionsNotAvailable from '@/vbgauth/components/authstepup/DeviceOptionsNotAvailable'
import { mapGetters, mapActions } from 'vuex'
import translations from '@/common/assets/json/messages1.json'
import { mapWaitingActions } from 'vue-wait'
import '@/common/assets/styles/tooltip.css' 
import loadingImage from '@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif'
export default {
  name: 'AuthOtpComponent',
  components: {
    LoaderComponent,
    Footer,
    Header,
    AuthOtpDeliveryComponent,
    AuthOtpValidateComponent,
    DeviceOptionsNotAvailable
  },
  data () {
    return {
        content: translations.en.message['login_device_not_recognized_tooltip'],
        url : loadingImage,
        whichRealm: true
    }
  },
  mounted () {
    this.getOtpDevices();
    if((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount;
      if(hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('iamvar_realm') !== -1) {
      var iamvar_realmCookie = getCookie('iamvar_realm')
      if(iamvar_realmCookie == 'MOBILE') {
       	this.whichRealm = false;
    	}
    } 
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          this.whichRealm = false;
        }
    } 
  },
  methods: {
      getAlertType (index) {
          return 'alert-' + index.type
      },   
      ...mapActions('authstepup', [
                'redirectToOpenam'
            ]
        ),
      ...mapWaitingActions('authstepup', {
            getOtpDevices: 'request_to_complete'
        }),
      checkIfResetPasswordOptionAvailable () {
        if((this.noAccessToOTP && this.emailAged) ||
         (this.userType === 'PrimaryContact' && !this.emailAged && this.noAccessToOTP) ||
         (this.userType !== 'PrimaryContact' && !this.emailAged && this.noAccessToOTP)  
        ) {
          return true
        }
        return false
      }
  },
  watch: {
    otpSent: function(newVal) {
      if(newVal) {
        console.log('Otp has been sent')
        console.log('goto validate component')
      }
    },
    fingerprintSaved: function(newVal) {
      if(newVal) {
        this.redirectToOpenam()
      }
    }
  },
  computed: {
      ...mapGetters('authstepup', [
      'fingerprintSaved',
      'sqaDisabled',
      'alerts',
      'submitLoader',
      'stepupPageTitle',
      'stepupPageDesc',
      'noAccessToOTP',
      'emailAged',
      'userHasPC',
      'userType'
    ]),
    ...mapGetters('cvsstepup', [
      'urlOnMount'
     ]),
    otpSent: {
      get: function () {
        return this.$store.getters['authstepup/otpSent']
      },
      set: function (newVal) {
        this.$store.dispatch('authstepup/updateOtpSent', newVal)
      }
    }
  }
}
</script>
<style scoped>
/*h1 {
    margin: 4.6rem 0 2.50rem !important;
    font-size: 32px !important;
    font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", "Arial", "Helvetica", "sans-serif !important"
}*/
h1 {
    height: 31px;
    width: 332px;
    color: #000;
    font-family: NeueHaasGroteskDisplay!important;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 30.4px;
    font-weight: 700!important;
}
.v-row {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}
.alert-warning {
	background-color: #ffbc3d;
	background-size: 35px 29px;
	color: #000;
	border-color: #ffbc3d;
}
.alert-info {
	background-color: #08c;
	background-size: 35px 29px;
	color: #fff;
	border-color: #08c;
}
h2 {
  font-size: 20px;
  margin: 30px 0 20px;
}

h1, h2, h3, h4 {
  font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 100%;
}
.close {
    float: right !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
    opacity: .2 !important;
    margin-top: -2px !important;
}
.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.vz-input-field:focus {
  border: 1px solid black !important;
}
</style>
