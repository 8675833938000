<template>
  <div class="vz-container">
    <div no-gutters class="sso">
      <div no-gutters style="padding:0px">
        <div class="col-sm-6">
        <v-card flat class="login shaded">
          <BlockUI v-if="submitLoader" :url="url"></BlockUI>
          <div no-gutters>
            <h1>Sign in to your business account.</h1>
            <p><b>Wireless, enterprise, government and education customers</b><br>
            Connect to Verizon Enterprise Center, My Business, ViewPoint, ThingSpaceManage and Total View.</p>
            <v-form v-on:submit.prevent style="width:100%" ref="loginForm" v-model="valid">
            <input
            class="form-control vz-input-field"
            style="min-height: 36px"
            placeholder="Username"
            :rules="fieldRules"
            required
            v-model="formData[0].input[0].value"
            autocomplete="off"
            >
            <input
            class="form-control vz-input-field"
            type="password"
            :rules="fieldRules"
            required
            placeholder="Password"
            autocomplete="new-password"
            v-model="formData[1].input[0].value"
            >
            <div class="vz-error-msg" id="errorMsgVZW" v-if="authError">{{authError.errorMessage}}</div>
            <p class="remember">
             <input class="r" id="rememberVZW" name="rememberMBUserName" type="checkbox" v-model="rememberMe"> <label for="rememberMBUserName">&nbsp;Remember Me</label>
             <span>
                <button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;top:6px;" v-tooltip="rememberMeTooltip"></button>
            </span><input type="hidden" name="rememberUserNameCheckBoxExists" value="Y">
            </p>
            <input type="hidden" name="goto" class="goto" v-model="gotoUrl">
            <p class="forgot">Forgot
              <router-link :to="{ name: 'AMForgotUsrnmComponent'}">
              <a linktrack="Forgot Username" class="vz-text-link" data-loc="SignIn:Forgot">username</a> 
              </router-link> or
              <router-link :to="{ name: 'AMForgotPwdComponent'}">
              <a linktrack="Forgot Password" class="vz-text-link" data-loc="SignIn:Forgot">password</a>?
              </router-link>
            </p>
            <button style="clear:both; display:block;" @click="submitLoginForm()" class="primary" type="submit" id="signInButton">Sign In</button>
            <div class="already" style="display:none"><i class="vzi-info"></i><p>You are a registered user. Please enter your Username to Sign in.</p><a class="primary button" onclick="return sso.closeAlready()">Okay, got it</a></div>
    
            <p>
            <a class="vz-text-link" style="text-decoration: none;" v-bind:href="iamvar_idmUrl+'/uiApp#/resend-welcome-email'">Resend my welcome email</a></p>
            <p>
              <a class="vz-text-link" style="text-decoration:none;" href="https://vecportalqa1.ebiz.verizon.com/vec/public/selfreg/index.html">Register for an account</a>
            </p>
            <p>Having trouble signing in?&nbsp;<a class="vz-text-link" href="https://enterprise.verizon.com/support/#sso-help">Contact us</a></p>
            <p>&nbsp;</p>
            <p><b>Fios, internet, networks and phone customers</b><br>
            Connect to Verizon Business Markets</p>
            <p><span class="forgot"><a href="https://www.verizon.com/business/my-business-signin/" target="_blank" class="vz-text-link">Sign in</a>&nbsp; or&nbsp;&nbsp;<a href="https://www.verizon.com/business/my-business-signin/?goto=https%3A%2F%2Fbusiness.verizon.com%3A443%2FMyBusinessAccount%2Fauth%2FfromSSO.jsp" target="_blank" class="vz-text-link">Register</a></span></p>         
          </v-form>
          </div>
        </v-card>
      </div>
      <div class="col-sm-6">
        <v-card flat class="ssoContent">
          <h2>Quick tasks</h2>
          <LoginComponentProducts></LoginComponentProducts>
          <LoginComponentCommunications></LoginComponentCommunications>
        </v-card>
        <v-card flat class="ssoContent">
          <LoginComponentManagementPortals></LoginComponentManagementPortals>
        </v-card>
      </div>
      <div class="col-sm-6">
        <v-card flat class="ssoContent">
          <LoginComponentToolsResources></LoginComponentToolsResources>
        </v-card>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import loadingImage from '@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif'
import config from '@/common/assets/json/login_page_config.json'
import LoginComponentProducts from '@/vbgauth/components/amlogin/LoginComponentProducts'
import LoginComponentCommunications from '@/vbgauth/components/amlogin/LoginComponentCommunications'
import LoginComponentManagementPortals from '@/vbgauth/components/amlogin/LoginComponentManagementPortals'
import LoginComponentToolsResources from '@/vbgauth/components/amlogin/LoginComponentToolsResources'
import 'vuetify/dist/vuetify.min.css'
import translations from '@/common/assets/json/messages1.json'
// import {logger} from '../../../../public/logger.js'
import 'bootstrap'
export default {
  name: 'AMLoginComponent',
  components: {
    LoginComponentProducts,
    LoginComponentCommunications,
    LoginComponentManagementPortals,
    LoginComponentToolsResources
  },
  data () {
    return {
      url : loadingImage,
      rememberMe: false,
      bodyConfig:  config.body,
      fieldRules: [v => !!v || 'Please fill out this field'],
      valid: false,
      loading: false,
      rememberMeTooltip: translations.en.message['login_rememberme_tooltip']
    }
  },
  mounted () {
    // logger.info('Login component mounted')
    this.updateUrlOnMount(window.location.href)
    this.gotoUrl = this.$route.query.goto
    this.removeExistingVBGCCookie()
    this.getSessionInfo()
    this.resetState()
    this.callRememberMe()
  },
  methods: {
    ...mapActions('login', [
        'submitAMLoginForm',
        'callRememberMe',
        'removeExistingVBGCCookie',
        'getSessionInfo',
        'resetState',
        'updateUrlOnMount'
      ]
    ),
    ...mapActions('cvsstepup', [
        'initializeStepup'
      ]
    ),
    submitLoginForm: function () {
      let self = this
      let payload = {
        formData: this.formData,
        rememberUser: this.rememberMe
      }
      if(self.$refs.loginForm.validate()) {
        self.submitAMLoginForm(payload)
      }
    }
  },
  computed: {
    ...mapGetters('login', [
      'authError',
      'submitLoader',
      'iamvar_enableCaptcha',
      'iamvar_enableJCaptcha',
      'redirectToStepupPage',
      'redirectToSecProfilePage'
    ]),
    ...mapGetters('cvsstepup', [
      'currentStep',
      'cannotRecoverUsername',
      'cannotProceedRecovery',
      'stepupReason'
    ]),
    formData: {
      get: function () {
        return this.$store.getters['login/formData']
      },
      set: function (newVal) {
        this.$store.dispatch('login/updateFormData', newVal)
      }
    },
    gotoUrl: {
      get: function () {
        return this.$store.getters['login/gotoUrl']
      },
      set: function (newVal) {
        this.$store.dispatch('login/updateGotoUrl', newVal)
      }
    }
  },
  watch: {
    redirectToStepupPage: function(newVal) {
      if(newVal) {
        this.initializeStepup()
      }
    },
    redirectToSecProfilePage: function(newVal) {
      if(newVal) {
        window.location.href = newVal
      }
    },
    currentStep: function(newVal) {
       if(newVal === 'FLOW_INITIALIZED') {
         if(this.stepupReason === 'FORGOT_PASSWORD' && this.cannotProceedRecovery){}
         else if(this.stepupReason === 'FORGOT_USERNAME' && this.cannotRecoverUsername){}
         else this.$router.push({ name: 'FFStepupRootComponent'})
       }
     }
  }
}
</script>
<style scoped>
.nocolor--text {
  color: transparent !important;
  background-color: transparent !important;
}
.v-list-item__title {
  font-size: 1rem;
}
.v-application .error--text {
  color: #000 !important;
}
.v-text-field--filled {
  border-radius: 4px !important;
}
.v-input__slot:active {
  border: 1px solid black !important;
}
</style>
