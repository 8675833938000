<template>
    <div style="overflow: hidden">
        <div class="profile_pin_layout">
            <BlockUI v-if="submitLoader" :url="url"></BlockUI>
            <form @submit.prevent="onSubmit()" class="profile_container">
                <h1 class="profile_container_heading">Security Profile registration</h1>
                <div class="profile_container_heading">
                    Go ahead and enter your code below
                </div>
                <div class="input_section">
                    <label class="Createpwd_input_label">PIN</label>
                    <input :type="showSecretPin ? 'text' : 'password'" @blur="" class="createpwd_input" @input="handleInput" tabindex="0" v-model="secretPin" maxlength="6"/>
                    <span class="show_hide" v-if="secretPin" type="button" @click="toggleSecretPin" tabindex="0"
                        @keydown.enter.prevent="toggleSecretPin">{{ showSecretPin ? 'Hide' : 'Show' }}</span>
                </div>
                <div class="input_section btn_container">
                    <input type="submit" class="submit_btn"
                    :class="!secretPin ? 'submit_btn disbaled_btn':'submit_btn enabled_btn'"
                    tabindex="0" :disabled="!secretPin"/>
                </div>
                <div class="bottom_content">
                    <p>Didn't get a code</p>
                    <button class="send_another_btn" @click="resendPin()">Send another</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import commonMixin from "@/common/mixin/commonMixins";
import Header from "@/vbgauth/components/LegalHeader";
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import { mapGetters, mapActions } from 'vuex'


export default {
    name: "SecurityProfileRequestPin",
    mounted() {
        // window.scrollTo(0,0);
        // this.getPassswordBlackList("create");
        // this.readConfig('vbg_config')
        // this.changeContextBiocatch('forgot_password_reset_password_page')
    },
    data() {
        return {
            secretPin: '',
            showSecretPin: false,
            url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
        }
    },
    computed: {
        ...mapGetters('login', ["getVcgMtn"]),
         ...mapGetters('profile', ["uuID", "submitLoader"]),
        // areFirstTwoFieldvalid(){
        //     return (this.validPwd && !this.pwdNotMatched);
        // },

    },
    methods: {
        ...mapActions('profile', [
            'verifyProfileSecurityPin',
            'requestProfileSecurityPin'
        ]),
    onSubmit() {
      let payload = {
            "uuID": this.uuID,
            "otpToken": this.secretPin,
            "deliveryMethodSelected": "SMS",
            "mtn": this.getVcgMtn,
            "countryCode": "1"
      };
      if((this.secretPin !=="")){
        this.verifyProfileSecurityPin(payload);
      }
      this.secretPin="";
    },
    toggleSecretPin() {
      this.showSecretPin = !this.showSecretPin;
      if (this.showSecretPin) {
        setTimeout(() => {
          this.showSecretPin = false;
        }, 10000);
      }
    },
    resendPin() {
      this.secretPin="";
         let payload = {
             "uuID":this.uuID,
            "otpType":"SMS",
            "countryCode":"1",
            "mtn": this.getVcgMtn,
      };
      this.requestProfileSecurityPin(payload);
    },
    handleInput(event){
        const value = event.target.value;
        const numericValue = value.replace(/\D/g,"");
        this.secretPin = numericValue;
    }
    },
    components: {
        Header,
        Footer,
    },
    ForgotFlowFootermixins: [commonMixin],
};
</script>
<style scoped>
body {
    color: #000000 !important;
}

.footer-margin-top {
    margin-top: 64px;
}

.profile_pin_layout {
    display: flex;
    justify-content: center;
}

.profile_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    width: 50%;
}

.profile_container_heading {
    margin: 0;
    font-size: 32px;
    color: #000000;
    line-height: 36px;
    letter-spacing: normal;
    font-family: 'VzNHGeDS-bold11';
    margin-bottom: 12px;
}

.input_section {
    margin-bottom: 16px;
    position: relative;
}

.security_radio_input {
    height: 20px;
    width: 20px;
    border: 4px solid #000000;
}

.btn_container {
    text-align: center;
}

.submit_btn {
    background-color: #000000;
    color: #FFFFFF;
    font-family: 'VzNHGeDS-bold11';
    padding: 12px 24px;
    letter-spacing: 0.5px;
    border-radius: 25px;
    width: 100%;
    margin-top: 4px;
    font-size: 16px;
}

.submit_btn:focus {
    outline: 1px solid #000000;
}

.mtn_container {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.mtn_number_text{
    margin-left: 20px;
}

.show_hide {
    position: absolute;
    top: 38px;
    right: 12px;
    border-bottom: 1px solid #000000;
}
.show_hide:focus {
    outline: 1px solid #000000;
}

.Createpwd_input_label {
    font-size: 12px;
    color: #000000;
    line-height: 16px;
    font-family: 'VzNHGeTX-reg11';
}

.createpwd_input {
    padding: 12px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: 100%;
    border: 1px solid #cccccc;
    border-bottom: 1px solid #000;
    color: #000000;
}

.createpwd_input:focus {
    border: 1px solid #000;
}
.bottom_content>p{
    color: #000000;
    font-size: 16px;
}
.send_another_btn{
    color: #000000;
    text-decoration: underline;
    font-size: 16px;
}

@media only screen and (max-width: 1023px) {
    .footer-margin-top {
        margin-top: 48px;
    }
    .profile_container {
        width: 100%;
    }
    .profile_container_heading {
        font-size: 24px;
        line-height: 28px;
    }
}
.disbaled_btn{
  background-color: #E5E6E6 !important;
  color: #FFFFFF
}
.enabled_btn{
  background-color: #000000;
  color: #ffffff;
}
</style>