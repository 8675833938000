<template>
  <div>
    <vpsHeader></vpsHeader>
    <div class="vps-container center_margin mainContainer">
      <div class="paddingMobile">
        <div class="myprofileContainer">
          <form id="vpsSecurityProfileUsernameEdit" class="signin" @submit.prevent="submitForm">
            <div class="headUserId">
              <h2 class="descEdit myprofileHead">Manage Security Question</h2>
            </div>
            <div class="form-group">
              <span class="labelText">Security question</span>
              <div ref="dropdownRef" class="row dropdown dropdown-toggle" id="drpdn"
                v-bind:class="{ open: showDropdown }" aria-haspopup="true" aria-expanded="false"
                @click="toggleDropdown()" @keyup.enter="toggleDropdown()" tabindex="1">
                <div class="text-flex1">
                  <a id="dropdownMenu1" aria-haspopup="true" role="button">
                    <span>{{ challengeQuestionText }}</span>
                  </a>
                </div>
                <img class="vbg-rsp-dropdown-cheveron" :src="downCaret" />
                <ul class="dropdown-menu col-xs-12 px-0" aria-labelledby="dropdownMenu1">
                  <li v-for="(question, index) in secretQuestions" :key="question.id" role="presentation"
                    :tabindex="index + 1"
                    v-bind:class="{ chosen: (question.question === challengeQuestionText), 'last-item': (index === secretQuestions.length - 1) }"
                    @keyup.enter="questionSelected(question, 'enter')" @keyup="listkeyupHandler($event, index)"
                    @click="questionSelected(question, 'click')" @keydown.tab="handleTabKey" ref="userSelectQuestion">
                    <a role="menuitem" @click="selectQuestion(question)">
                      <span>
                        <span class="sqaText">{{ question.question }}</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <span class="labelText">Security answer</span>
              <input id="sqaAnswer" class="input-f input_mr input_border" type="password"
                :class="!isSQAanswerValid ? 'vps-input-field-warning' : ''" placeholder="********" maxlength="40"
                v-model="saqAnswer" />
              <div v-if="!isSQAanswerValid">
                <p class="vps-input-errortext-style" v-html="$t('message.vps_edit_profile_invalid_sqa_answer')">
                </p>
              </div>
              <p class="rules">Minimum 3 characters, maximum 40 characters, the only special character allowed is a
                period.</p>
            </div>
            <div class="buttonset">
              <button type="submit" class="btn  vbg-ff-btn-continue modal-popup-btn vbg-ff-btn-width140 spacing05">
                <span>Update</span>
              </button>
              <button class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05" @click="onCancel()">
                <span>Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <vpsFooter></vpsFooter>
  </div>
</template>
<script>
import downCaret_blk from "@/common/assets/styles/corp/images/down-caret_blk.png";
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
export default {
  name: 'VPSSecurityProfileSQAEdit',
  components: {
    vpsHeader,
    vpsFooter
  },
  data() {
    return {
      firstName: "testing",
      lastName: "lastName",
      secProfileEmail: "testMail@verizon.com",
      securityQuestion: "what is your nickname ?",
      showDropdown: false,
      isSQAquestionValid: true,
      isSQAanswerValid: true,
      challengeQuestionText: "What was your first job?",
      challengeQuestion: "1",
      downCaret: downCaret_blk,
      saqAnswer: "",
      secretQuestions: [
        {
          "id": "1",
          "question": "Select a question"
        },
        {
          "id": "12",
          "question": "What is the model name of the first car you drove?"
        },
        {
          "id": "10",
          "question": "What is your favorite elementary school teacher's last name?"
        },
        {
          "id": "172",
          "question": "What is your eldest child's middle name?"
        },
        {
          "id": "179",
          "question": "What was your first job?"
        },
        {
          "id": "174",
          "question": "What year do you plan on retiring?"
        },
        {
          "id": "183",
          "question": "What was your childhood nickname?"
        }
      ]
    }
  },
  methods: {
    onCancel() {
      this.$router.push({ name: 'VPSSecurityProfilePage' })
    },
    submitForm() {

    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    questionSelected(selectedQuestion, selectionType) {
      if (selectionType === 'enter') {
        // move focus to button if user 
        // click enter after selecting an option from dropdown

        console.log(selectedQuestion, selectionType)
        this.$nextTick(() => this.$refs.submitButtonRef.focus())
      } else {
        // dont move focus to button if user select from mouse
        this.$refs.dropdownRef.focus()
      }
    },
    listkeyupHandler(e, index) {
      // logic to move up and down in device list with up & down arrow
      const questionList = this.$refs.userSelectQuestion;
      switch (e.key) {
        case 'ArrowUp':
          if (index >= 1) {
            console.log(index)
            questionList[index - 1].focus();
          }
          if (index < 1) {
            questionList[questionList.length - 1].focus();
          }
          break;
        case 'ArrowDown':
          if (index < questionList.length - 1) {
            questionList[index + 1].focus();
          } else {
            questionList[0].focus();
          }
          break;
        default:
          break;
      }
    },
    selectQuestion(selQuestion) {
      if (selQuestion != null) {
        this.challengeQuestion = selQuestion.id;
        this.challengeQuestionText = selQuestion.question;
      }
    },
  },
  computed: {
  },
  watch: {
    challengeQuestionText: function (newVal) {
      newVal.trim() === "Select a question" ? this.isSQAquestionValid = false : this.isSQAquestionValid = true;
    },
    saqAnswer: function (newVal) {
      newVal.trim() === "" ? this.isSQAanswerValid = false : this.isSQAanswerValid = true;
    }
  },
  mounted() {
  }
}
</script>
<style scoped>
.vps-container {
  height: 100%;
  width: auto;
  margin: 40px;
}

.mainContainer {
  margin-top: 100px;
}

.vps-input-field-warning {
  border: 1px solid #ED7000 !important;
  border-bottom: 4px solid #ED7000 !important;
}

.paddingMobile {
  padding: 10px 16px;
  margin-bottom: 100px;
  /* overflow-x: auto; */
}

.myprofileContainer {
  width: 575px;
  padding-top: 5px;
  border-top: 4px solid #000;
}

@media (max-width: 768px) {
  .myprofileContainer {
    width: 100%;
  }
}

.vps-input-errortext-style {
  height: 32px;
  width: 301px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: bold;
}

.headUserId {
  float: left;
  width: 100%;
}

.descEdit {
  margin-bottom: 50px;
  font-size: 16px;
  display: inline;
}

.myprofileHead {
  margin-top: 20px;
  float: left;
}

.labelText {
  font-size: 14px;
  color: #747676;
  padding-bottom: 4px;
  display: block;
}

.input-f {
  width: 420px;
  height: 34px;
  margin-bottom: 0px;
}

.rules {
  margin-top: 10px;
  font-size: 12px;
}

.buttonset {
  margin-top: 50px;
  margin-bottom: 50px;
}

.dropdown {
  width: 420px !important;
  min-height: 34px !important;
  padding: 7px 14.6px 7px 12px !important;
  height: 34px !important;
  font-size: 16px;
}

.dropdown-arrow {
  /* background-size: 14px;
  background-position-x: 95%;
  background-position-y: 8px;
  width: 40px;
  height: 22px;
  padding: 0px;
  text-decoration: none;
  border-bottom: none; */
  position: absolute;
  right: 0px;
  bottom: 11px;
  width: 32.33px;
}

.dropdown-menu {
  box-shadow: none !important;
}

.dropdown-menu li {
  border-bottom: 1px solid #CDCCCB;
}

#dropdownMenu1 span {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
}

.dropdown-menu li.chosen {
  border-left: 4px solid #D52B1E;
}

.text-flex1 {
  flex: 1;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.dropdown-menu>li>a {
  color: #000 !important;
  text-decoration: none !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
}

.vbg-rsp-dropdown-cheveron {
  margin-right: 0px !important;
}

@media (max-width: 1024px) {
  .vbg-icon-input-group {
    width: 100% !important;
  }

  .input-f {
    width: 100% !important;
  }

  .dropdown {
    width: 100% !important;
  }

  .vbg-rsp-input-field {
    width: 100% !important;
  }

  .dropdown {
    min-height: 34px !important;
    height: auto !important;
  }

  .dropdown-menu li.chosen>a {
    color: #000 !important;
    padding-left: 12px !important;
  }

  .dropdown-menu li.chosen {
    border-left: none !important;
  }

  .dropdown-menu>li>a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    margin-left: 0px;
    padding-left: 12px !important;
  }
}

.row.dropdown.dropdown-toggle {
  -ms-flex-align: center;
  align-items: center;
}
</style>