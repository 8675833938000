<template>
  <div>
    <!-- <div>{{ this.isValidEmail }}</div> -->
    <v-form @submit.prevent="submit" ref="fuForm">
      <!-- <h1 class="new_pwdless_main_heading pwdles_main_heading">
        Forgot User ID
      </h1> -->
      <div class="new_pwdless_input_label">Email address</div>
      <div class="vbg-icon-input-group">
      <input 
        id="email"
        v-model="email"
        type="text"
        @keydown.space.prevent
        @input="trimInput(email)"
        class="new_pwdless_input_field" 
        :class="!isValidEmail? 'orange_input': ''" 
        style="margin-bottom: 0px;"
        :autocomplete="formInputs[2].autocomplete"
        name="email"
      />
      <!-- <div 
        class="vbg-warning-icon" 
        v-if="!isValidEmail"
      ><img :src="warningIcon" /></div> -->
      <div v-if="!isValidEmail">
        <p class="pmicroEmail" 
          v-html="$t('message.forgot_username_emailAdress_error')"
        >
        </p>
      </div>
      </div>
      <div v-if="this.jCaptchaFlag">
        <div>
          <div class="captcha-blk">
            <div class="captcha-txt-blk">
              <img
                id="captchaImg"
                src=""
                :class="[
                  getOAuthClass('oauth-flow-jcaptch'),
                  'captcha_img_fu',
                ]"
              /><br />
            </div>
          </div>
          <button
            type="button"
            class="captcha_spacing new_pwdless_forgot_text_link"
            v-on:click="refreshCaptchaImgUrl()"
            name="refreshCaptcha"
            id="refreshCaptcha"
          >
            Refresh Image
          </button>
        </div>
        <div>
          <label class="new_pwdless_input_label" for="captchaInput"
            >Type the characters</label
          >
          <input
            type="text"
            id="captchaInput"
            name="captchaInput"
            @keydown.space.prevent
            class="new_pwdless_input_field"
            v-model="captchaText"
          />
        </div>
      </div>
      <invisible-recaptcha :disabled="!email|| !isEmailValid() || !captchaText" v-if="this.captchaOn" :sitekey="forgotUsrnCaptchaKey" theme="light" badge="bottomright" :callback="onCaptchaVerified" :class="formInputs[3].class" :style="formInputs[3].style" :type="formInputs[3].type">{{ formInputs[3].value }}</invisible-recaptcha>
      <div class="pwd_less_btn_container submit_btn_spacing">
        <div class="new_pwdless_btn_section">
          <button 
            v-if="!this.captchaOn" 
            :disabled="isDisabled()" 
            @click="onCaptchaVerified('')" 
            :class="isDisabledClass()"
            :style="formInputs[3].style" 
            :type="formInputs[3].type"
            class="new_pwdless_submit_btn"
            id="username-continue-btn"
            name="username-continue-btn"
          >
            {{ formInputs[3].value }}
          </button>
        </div>
        <div class="new_pwdless_btn_section new_pwdless_btn_section_mobile">
          <button 
            class="new_pwdless_submit_btn pwd_cancel_btn"
            @click="cancelUsrRecovery()" 
            :style="formInputs[4].style"
            type="button"
            id="username-cancel-btn"
            name="username-cancel-btn"
          >{{ formInputs[4].value }}</button>
        </div>
      </div>
    </v-form>

  </div>
</template>
<script>
import InvisibleRecaptcha from 'vue-invisible-recaptcha'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import {eventTracking} from '../../../../../public/adobeTagging'
import warningIcon from '@/common/assets/styles/corp/images/warning.png'
import commonMixin from "@/common/mixin/commonMixins";
export default {
  name: 'NewAMForgotUsernameUserDetailsComponent',
  components: {
    "invisible-recaptcha": InvisibleRecaptcha
  },
  mixins: [commonMixin],
  data() {
    return {
      otpEnabled: true,
      contact_admin: iamvar_contact_admin,
      caret: caret_blk,
      valid: false,
      fieldRules: [v => !!v || 'Please fill out this field'],
      fname: null,
      lname: null,
      email: null,
      captchaOn: true,
      captchaText: null,
      jCaptchaFlag: true,
      submittedAlready: false,
      whichRealm: true,
      emailFormatRegex: /^[a-zA-Z0-9](\.?\_?\-?[a-zA-Z0-9]){0,}@[a-zA-Z0-9-_]+\.([a-zA-Z0-9-_]{1,}\.){0,}[a-zA-Z]{2,}$/,
      userValidationInputClass: '',
      warningIcon: warningIcon,
      isValidEmail: true,
      loaderGIF: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
    }
  },
  mounted() {
    this.changeContextBiocatch('forgot_username_email_page')
    //get updated captcha on page load
    this.$nextTick(() => this.refreshCaptchaImgUrl())
    if (window.location.href.includes('business')) {
      this.readConfig('vbg_config')
    } else if (window.location.href.includes('consumer')) {
      this.readConfig('vcg_config')
    }
    if ((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if (hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if (iamvar_realm == 'MOBILE') {
      let returnUrl = this.urlOnMount;
      this.updateUrlOnMount(returnUrl);
    }
    if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      let iamvar_appCookie = getCookie('X-VZ-C_APP')
      if (iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }

    this.readFormInputs('user_details_input_component')

    this.captchaOn = iamvar_enableCaptcha;

    this.jCaptchaFlag = iamvar_enableJCaptcha;
  },
  methods: {
    ...mapActions('cvsstepup', [
      'updateUserDetailsForStepup',
      'updateFlowType',
      'initializeStepup',
      'updateRecaptchaResponse',
      'updateCaptchaText',
      'igCaptchaEndpoint',
      'updateClearInputFields'
    ]
    ),
    ...mapActions('forgotflow', [
      'readConfig',
      'readFormInputs'
    ]
    ),
    ...mapActions('login', [
      'updateUrlOnMount'
    ]),
    ...mapMutations("login", ["setCommonErrors"]),
    isEmailValid() {
      return (this.email == "") ? "" : (this.emailFormatRegex.test(this.email)) ? true : false;
    },
    onCaptchaVerified(recaptchaToken) {
      this.setCommonErrors()
      this.updateUserDetailsForStepup({
        "email": this.email.trim()
      })
      this.updateCaptchaText(this.captchaText.trim());
      eventTracking('Username Recovery Request Initiated', '');
      this.updateFlowType('forgot_username')
      // push to stepupRootComponent only if the username is valid (returned by the backend)
      this.emaiIDValidation(this.email)
      this.updateRecaptchaResponse(recaptchaToken)
      this.igCaptchaEndpoint()
      //this.refreshCaptchaImgUrl()
      //this.refreshCaptchaImgUrl()
      //this.captchaText = null;
    },
    cancelUsrRecovery() {
      this.$store.dispatch('cvsstepup/resetState')
      this.$store.dispatch('forgotflow/resetState')
      var returnUrl = this.getHostName(window.location.href);

      if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if (iamvar_appCookie == 'MVB') {
          window.location.href = iamvar_mvbLaunchUrl;
        }
      } else if (!this.urlOnMount) {
        window.location.href = this.sanitizeUrl("https://" + returnUrl + "/account/business/login/unifiedlogin");
      } else {
        window.location.href = this.urlOnMount;
      }

      // checking if user agent is vema then redirect user back to mobile login page
      if (this.userAgent && this.userAgent.toLowerCase() === 'vema') {
        window.location.href = iamvar_vemaLaunchUrl;
      }
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      }
      else {
        return null;
      }
    },
    //getCaptchaImgUrl() {
    //  var captchaImgHref = this.getHostName(window.location.href);
    //  let srcURL = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha");
    //  return srcURL;
    //},
    refreshCaptchaImgUrl() {
      var captchaImgHref = this.getHostName(window.location.href);
      document.getElementById("captchaImg").src = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha?cb=" + Math.random());
    },
    getOAuthClass(className = 'oauth-flow') {
      return className
    },
    emaiIDValidation(newVal) {
      if (newVal) {
        if (!this.emailFormatRegex.test(newVal)) {
          this.userValidationInputClass = "vz-error";
        } else {
          this.userValidationInputClass = "";
        }
      }
    },
    isDisabled() {
      if (
        !this.formComplete
        || (!this.captchaText && this.jCaptchaFlag)
        || !this.isValidEmail || this.submitLoader
      ) {
        return true;
      }
      return false
    },
    isDisabledClass() {
      if (
        !this.formComplete
        || (!this.captchaText && this.jCaptchaFlag)
        || !this.isValidEmail || this.submitLoader
      ) {
        return ' disabled';
      } else {
        return "";
      }
    },
    trimInput() {
      this.email = this.email.trim();
    }
  },
  computed: {
    ...mapGetters('forgotflow', [
      'forgotUsrnCaptchaKey',
      'forgotFlConfig',
      'formInputs',
      'iamvar_enableCaptcha'
    ]),
    ...mapGetters('cvsstepup', [
      'currentStep',
      'cannotRecoverUsername',
      'urlOnMount',
      'fuAlerts',
      'submitLoader',
      'clearInputFields'
    ]),
    ...mapGetters("login", ["mybizzFlag", "userAgent"]),
    formComplete() {
      return this.email
    }
  },
  watch: {
    email(newVal) {
      if (newVal) {
        if (!this.emailFormatRegex.test(newVal)) {
          this.isValidEmail = false
          return
        }
      }
      this.isValidEmail = true;
      return;
    },

    currentStep: function (newVal) {
      if (newVal === 'FLOW_INITIALIZED' && !this.cannotRecoverUsername) {
        this.$router.push({ name: 'FFStepupRootComponent' })
      }
    },
    fuAlerts: function (newVal) {
      if (newVal) {
        //console.log("newVal..",newVal);
        this.refreshCaptchaImgUrl();
        this.captchaText = null;
        this.submittedAlready = false;
        window.scrollTo(0, 0);
      }
    },
    clearInputFields: function (newVal) {
      console.log("watch value", newVal)
      if (newVal) {
        this.email = ''
        this.captchaText = null
        this.updateClearInputFields(!newVal)
      }
    },
  }
}
</script>
<style scoped>
.pwdles_main_heading {
  margin-bottom: 24px;
}

.pwdles_sub_heading {
  margin-bottom: 32px;
}

.new_pwdless_btn_section {
  margin-top: 16px;
  width: 186px;
}
.vbg-icon-input-group {
  margin-bottom: 16px;
}
.orange_input,
.orange_input:focus {
  border: 1px solid #B95319 !important;
  background-color: #FFECE0;
}
</style>
