<template>
  <div>
    <div class="tabs">
      <button
        v-for="tab in getTotalVzAuth"
        :key="tab.tabName"
        @click="selectTab(tab.tabName)"
        :class="{ active: isActive(tab.tabName) }"
      >
        {{ tab.tabName }}
      </button>
      <div ref="dropdown" class="dropdown-main" :class="{ 'is-open': isOpen }">
        <button class="dropdown-toggle1" @click="toggleDropdown">
          <img
            height="30px"
            src="../../../public/jenkins.png"
            style="margin-right: 4px"
          />
          Delivery Pipeline
          <span class="caret"></span>
        </button>
        <ul class="dropdown-content">
          <li><a href="#">Action1</a></li>
          <li><a href="#">Action2</a></li>
          <li><a href="#">Action3</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "VbgTabs",
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    isOpen(value) {
      if (value) document.addEventListener("click", this.closeDropdown);
      else document.removeEventListener("click", this.closeDropdown);
    },
  },
  computed: {
    ...mapGetters("dashboard", ["getActiveTab", "getTotalVzAuth"]),
    isActive() {
      return (tabName) => this.getActiveTab == tabName;
    },
  },
  methods: {
    ...mapActions("dashboard", ["updateActiveTab"]),
    selectTab(tabName) {
      this.updateActiveTab(tabName);
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropdown(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target))
        this.isOpen = false;
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
  },
};
</script>
 <style scoped>
.tabs {
  /* padding: 10px 30px; */
  padding: 10px;
  background: cadetblue;
  margin: 20px 35px;
  border-radius: 3px;
  display: flex;
}
.tab-content {
  padding: 10px 35px;
}
.tabs button {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  outline: none;
  margin-right: 5px;
}
.tabs button.active {
  font-weight: bold;
  background-color: #663399;
  color: white;
}
.tab-content {
  margin-top: 20px;
}
.dropdown-main {
  position: relative;
  display: block;
}
.dropdown-toggle1 {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px !important;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
}
.dropdown-content li {
  list-style-type: none;
}
.dropdown-content li a {
  color: black;
  text-decoration: none;
  display: block;
  padding: 8px 16px;
}
.dropdown-content li a:hover {
  background-color: #f1f1f1;
}
.is-open .dropdown-content {
  display: block;
}
.caret {
  margin-left: 8px;
  border-top: 6px solid #000000;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
@media only screen and (max-width: 600px) {
  .tabs {
    padding: 16px;
    margin: 15px 18px;
    display: block;
  }
  .dropdown-toggle1 {
    padding: 5px 10px !important;
  }
}
</style>