<template>
  <div>
    <BlockUI v-if="submitLoader" :url="url"></BlockUI>
    <div class="cnContainer" :style="getCombinenowAlerts.length > 0 ? 'margin-top: 24px;': ''">
      <div class="cnModal-title">
        <h1 class="combinenow-heading combinenow-h1">{{ $t("message.combineNowTitle") }}</h1>
      </div>
      <div class="cnModal-text">
        <p class="combinenow-p">{{ $t("message.combineNowDesc") }}</p>
      </div>
      <form id="loginBiz" class="signin" @submit.prevent="submitLoginForm">
        <div class="step-1">
          <div class="blackbar"></div>
          <div class="-enter-your-accoun">
            <h3 class="combinenow-heading combinenow-h3">1. Enter your account login for {{accountTitle}}*</h3>
          </div>
          <div class="user-id">
            <label for class="-input-label">User ID</label>
            <input class="input-f" type="text" required v-model="username" maxlength="60" />
            <p class="forgot-link" @click="redirectToForgotFlow('forgotUserId')">Forgot user ID?</p>
          </div>
          <div class="user-pass">
            <label for class="-input-label">Password</label>
            <input class="input-f" type="password" required v-model="password" maxlength="20" />
            <p class="forgot-link" @click="redirectToForgotFlow('forgotUserPassword')">Forgot password?</p>
          </div>
        </div>
        <div style="clear: both;"></div>
        <div class="step-2">
          <div class="blackbarthin"></div>
          <div class="-set-your-default">
            <h3 class="combinenow-heading combinenow-h3">2. Set your default portal view.</h3>
          </div>
          <div class="vz_radio vz_rad">
            <input type="radio" name="portalType" value="WLH" v-model="radioVal" />
            <span class="radio-button-text">My Business Wireless</span>
          </div>
          <div class="vz_radio vz_rad">
            <input type="radio" name="portalType" value="VEC" v-model="radioVal" />
            <span class="radio-button-text">Verizon Enterprise Center</span>
          </div>
        </div>
        <div class="btn-container">
          <button
            class="btn primary_black continue-btn"
            type="submit"
          >Continue</button>
          <p class="skip-now" @click="simplifyContinueSkip()">Skip for now</p>
        </div>
      </form>
      <p class="combine-root-container business-account-text">
        *If you have more than one {{accountTitle}} account, 
        choose the account you prefer to merge.
      </p>
    </div>
  </div>
</template>
<script>
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import { mapGetters, mapActions } from "vuex";
import { setPageDetails } from '../../../../../public/adobeTagging';

export default {
  name: "CombinenowComponent",
  components: {},
  props: [],
  created() { setPageDetails('Combinenow: Combine Now Login Page'); },
  mounted() {
    this.fetchUserId()
  },
  data() {
    return {
      radioVal: "WLH",
      url: loadingImage,
      username: null,
      password: null
    };
  },
  methods: {
    ...mapActions("login", ["sendCombinenowValue", "sendSimplifyContinue","updateMergeStatus","skipfornowCombineNow","UpdatePopupValue", "mybizzForget", "fetchUserId"]),
    ...mapActions('cvsstepup', [ 'updateUserIdFlow' ]),
    forgotPopup() {},
    submitLoginForm() {
      let payload = {
        logInUserID: "",
        toBeMergeUserID: this.username,
        password: this.password,
        preferablePortal: this.radioVal
      };
      this.sendSimplifyContinue(payload);
    },
    simplifyContinueSkip() {
      this.skipfornowCombineNow();
     // redirect user to portal. Since, session will be active so redirect to unifiedlogin after that it will redirect to portal
     // this.$router.push({ path: "/login/unifiedlogin"});
    },
    redirectToForgotFlow(payload) {
      if (payload === 'forgotUserId') {
        this.$router.push({ path: "/login/recovery/forgotusername"});
        this.updateUserIdFlow('BASIC')
      }
      if (payload === 'forgotUserPassword') {
        this.$router.push({ path: "/login/recovery/forgotpassword"});
        }
      
      }
    },
  computed: {
    ...mapGetters("cvsstepup", ["submitLoader"]),
    ...mapGetters('login',["getUnifiedLoginAlerts","userType","getUserSelectedAccount","popupValue", "getCombinenowAlerts"]),
    checkModal() {
      if(this.popupValue == 'forgotUserId' || this.popupValue == 'forgotUserPassword') {
        return 'modal-content-forgot';
      }
    },
    accountTitle() {
      this.userType === 'VZB' ? this.mybizzForget(true) : this.mybizzForget(false);
      return this.userType === 'VZB' ? 'My Business Wireless' : 'Verizon Enterprise Center'
    }
  },
  watch: {
    getUserSelectedAccount: function(selectedAccount) {
      $("#noAccessToOtpModal").modal('hide')
      if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserId'){
        this.$router.push({ path: "/login/recovery/forgotusername" });
        this.updateUserIdFlow('BASIC')
      } else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserPassword'){
        this.$router.push({ path: "/login/recovery/forgotpassword" });
      }
    } 
  }
};
</script>
<style scoped>
.business-account-text {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .combinenow-h3 { font-size: 24px; }
  .footerCss {
    position: static;
  }
  .btn-container {
    display: flex;
    align-items: center;
    margin-top: 32px;
  }
  .business-account-text {
    height: 40px;
    max-width: 562px;
    margin-top: 48px;
  }
  .cnModal-title {
    height: 64px;
    width: 596px;
  }
  .cnModal-text {
    height: 40px;
    width: 596px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .cnContainer {
    /* position: absolute;
    left: 100px; */
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 100px;
    width: 596px;
  }
  .step-1 {
    height: 296px;
    width: 596px;
    /* position: absolute;
    top: 200px; */
  }
  .step-2 {
    margin-top: 48px;
    height: 161px;
    width: 596px;
    /* position: absolute;
    top: 544px; */
  }
  .-enter-your-accoun {
    /* height: 24px; */
    width: 593px;
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 4px;
    /* position: absolute;
    top: 28px;
    left: 3px; */
  }
  .-set-your-default {
    height: 24px;
    width: 596px;
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 25px;
    margin-bottom: 25px;
    /* position: absolute;
    top: 28px;
    left: 3px; */
  }
  .-radio-button-default-unselec {
    height: 44px;
    width: 328px;
    /* position: absolute;
    top: 73px; */
  }
  .user-id {
    height: 98px;
    width: 328px;
    margin-top: 24px;
    /* position: absolute;
    top: 76px;
    left: 3px; */
  }
  .user-pass {
    height: 98px;
    width: 328px;
    /* position: absolute;
    top: 198px;
    left: 3px; */
  }
  .-input-label {
    height: 12px;
    width: 45px;
    color: #000000;
    /* font-family: "Verizon NHG eTX"; */
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
  .input-f {
    box-sizing: border-box;
    height: 44px;
    width: 328px;
    border: 1px solid #d8dada;
    background-color: #ffffff;
    margin-bottom: 14px;
    border-bottom: #000000 1px solid !important;
    padding: 6px 12px;
  }
  .vz_rad {
    top: 0px;
    min-height: 44px;
    float: none;
  }
  .continue-btn {
    width: 188px;
    height: 44px;
    /* margin: 32px 0px 0px; */
    font-size: 16px !important;
    /* position: absolute;
    top: 717px; */
  }
  .skip-now {
    cursor: pointer;
    text-decoration: underline;
    width: 188px;
    height: 20px;
    margin-left: 24px;
    font-size: 16px !important;
    /* position: absolute;
    top: 730px;
    left: 249px; */
  }
  .radio-button-text {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-left: 32px;
  }
  .vz_radio input[type="radio"]:checked + span:before {
    top: 6px !important;
  }
  .vz_radio span:after {
    top: 0px;
  }
}
/*========= For Mobile and Tablet ========*/
@media (max-width: 767px) {
  .business-account-text {
    margin-top: 50px;
    /*width: 289px;*/
  }
  .cnContainer {
    padding: 24px 15px 0 15px;
  }
  .blackbar {
    margin-bottom: 30px;
    /*width: 289px;*/
  }
  .cnModal-text {
    margin-top: 28px;
    margin-bottom: 26px;
  }
  .-set-your-default {
    height: 20px;
    /*width: 289px;*/
    margin-bottom: 24px;
  }
  .user-id {
    margin-top: 14px;
    margin-bottom: 14px;
    max-width: 380px;
  }
  .user-pass {
    margin-bottom: 50px;
    max-width: 380px;
  }
  .blackbarthin {
    margin-top: 15px;
    margin-bottom: 30px;
    /*width: 289px;*/
  }
  .vz_radio {
    float: none;
    height: 44px;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;

  }
  .btn-container {
    text-align: center;
    margin-top: 24px;
    /*width: 289px;*/
  }
  .continue-btn {
    width: 100%;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
  }
  .skip-now {
    margin-top: 30px;
    height: 20px;
    text-decoration: underline;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
  }
}
#noAccessToOtpModal {
  z-index: 105000000;
}
.modal-content-forgot {
  height: 368px;
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .modal-content-forgot {
    height: 100%;
  }
}
</style>
