const digitalDataAvail = () => {
    if (typeof digitalData === "undefined") {
        console.log('********** digitalData not available ***************');
        return false;
    } else {
        console.log('********** digitalData is available ***************');
        return true;
    }
};  

export const setPageDetails = (pageName) => {
    if (digitalDataAvail()) {
        window['digitalData'].pageInfo.siteDomain = window.location.hostname;
        window['digitalData'].pageInfo.siteName = window.location.hostname + window.location.pathname;
        window['digitalData'].pageInfo.siteLocale = ''; // localStorage.language;
        window['digitalData'].pageInfo.country = 'us';
        window['digitalData'].pageInfo.region = 'na';
        window['digitalData'].pageInfo.languageSelected = ''; // localStorage.language;
        window['digitalData'].pageInfo.url = window.location.href;
        window['digitalData'].pageInfo.brand = '';
        window['digitalData'].pageInfo.siteID = '';
        window['digitalData'].pageInfo.previousPageUrl = '';
        window['digitalData'].pageInfo.previousPageName = '';
        window['digitalData'].pageInfo.pageType = 'My Business Wireless';
        window['digitalData'].pageInfo.pageSubType = '';
        window['digitalData'].pageInfo.siteSectionsLevel1 = "my business wireless";
        window['digitalData'].pageInfo.siteSectionsLevel2 = "";
        window['digitalData'].pageInfo.siteSectionsLevel4 = '';
        window['digitalData'].pageInfo.siteSectionsLevel5 = '';
        window['digitalData'].pageInfo.siteSectionsLevel6 = '';
        window['digitalData'].pageInfo.siteSectionsComplete = true;

        window['digitalData'].pageInfo.pageName = 'vbg:my business wireless:account management:' + pageName;
        window['digitalData'].pageInfo.friendlyPageName = "my business wireless:" + pageName;
        window['digitalData'].pageInfo.flowName = 'account management';
        triggerEvent("Virtual Page View", '');
    }
};
export const eventTracking = (eventName, eventData)=> {
    var eventPayload = {
        "eventName": eventName,
        "eventPayload": eventData
    };
    // var visitorData = {
    //     "visitorProfile": {
    //         "ecpdId": "",
    //         // "email":"",
    //         "userId": "",
    //         "globalId": "",
    //         "visitorECID": "",
    //         "userProfileStateAuthentication": "",
    //         "lastSearchedTerm": "",
    //         "creditStatus": "",
    //         "locationInfo": {
    //             "postalCode": "",
    //             "country": "",
    //             "state": "",
    //             "city": ""
    //         },
    //         "registrationInfo": {
    //             "invitationCode": "",
    //             "serviceId": "",
    //             "invitationReference": "invitationReference"
    //         },
    //         "linkedOrganizationsArray": []
    //     }
    // };
   // eventPayload['eventPayload'] = eventData === "" ? "" : visitorData;
    triggerEvent(eventName, eventPayload);
    if (digitalDataAvail()) {
        window['digitalData'].events.push(eventPayload);
    }
};

export const triggerEvent = (eventName, payload) => {
    var cusEvent = new CustomEvent(eventName, {
        detail: payload,
        bubbles: true,
        cancelable: true
    });
    if (typeof digitalData !== "undefined") {
        window.addEventListener(eventName, function (e) {
            console.log(eventName, window['digitalData'], e);
        });
        window.dispatchEvent(cusEvent);
    }
};

export const errorSet = (msg) => {
    console.log('errorSet'+msg)
    var eventPayload = {
        "eventName": "Error Encountered",
        "eventPayload": {
            "errorInfo": {
                "errorID": '',
                "errorMessage": msg
            }
        }
    };     
    triggerEvent('Error Encountered',eventPayload)
    if (typeof digitalData !== "undefined") {
        window['digitalData'].events.push(eventPayload);
    }
}


