<template>
    <div>
        <div id="vz-gh20" v-if="this.whichRealm"></div>
        <div class="vbg-ff-main-container">
            <div class="vbg-ff-content-container">
                <div class="tittle">
                    <h2 class="tittle">{{ $t("message.delivery_method_options_locked_tittle") }}</h2>
                </div>
                <div class="sub-tittle">
                    <p class="messageContentUn" v-html="otp_account_locked">
                    </p>
                </div>
            </div>
        </div>
        <div id="vz-gf20" v-if="this.whichRealm"></div>
    </div>
</template>
<script>

import commonMixin from '@/common/mixin/commonMixins'
import translations from "@/common/assets/json/messages1.json";
export default {
    name: 'DeliveryMethodLocked',
    components: {},
    mixins: [commonMixin],
    data() {
        return {
            whichRealm: true,
            url: "",
            otp_account_locked: translations.en.message['delivery_method_options_locked_support_desc']
            // otp_account_locked: '<p>Contact <a href="https://www.verizon.com/business/support/" target="_blank" > customer support </a> to add a new delivery method so you can log in to your account.</p>'
        };
    },
    mounted() {
        var globalNav = document.createElement('script')
        globalNav.setAttribute('src', 'https://www.verizon.com/business/ngnav/smb.js')
        globalNav.setAttribute('async', 'async');
        document.head.appendChild(globalNav)

        var f = document.createElement("script");
        f.setAttribute('src', iamvar_vbm_login_redirect + '?realm=vzw&service=WlnOneVerizonChain&fromVZT=Y');
        f.setAttribute('async', 'async');
        document.body.appendChild(f);

        if ((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
            const hostUrl = this.urlOnMount
            if (hostUrl.includes('/account/business/login/mobile')) {
                this.whichRealm = false;
            }
        }
        if (document.cookie.indexOf('iamvar_realm') !== -1) {
            let iamvar_realmCookie = getCookie('iamvar_realm')
            if (iamvar_realmCookie == 'MOBILE') {
                this.whichRealm = false;
            }
        }
        if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
            let iamvar_appCookie = getCookie('X-VZ-C_APP')
            if (iamvar_appCookie == 'MVB') {
                this.whichRealm = false;
            }
        }
    }
}
</script>

<style scoped>
.vbg-ff-content-container {
    box-sizing: border-box;
    min-width: 464px;
    max-width: 464px;
    border: 1px solid #979797;
    margin: 56px auto 366px;
    padding: 64px 40px;
    border-radius: 4px;
}

.tittle {
    width: 100%;
    color: #000000;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 12px;
    font-size: 32px;

}

.sub-tittle,
.messageContentUn {
    width: 100%;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
}

.underline {
    text-decoration: underline;
}

@media (max-width: 1024px) {
    .vbg-ff-main-container {
        padding: 24px 16px 64px;
    }

    .vbg-ff-content-container {
        box-sizing: border-box;
        min-width: 240px;
        max-width: 328px;
        margin: auto;
        padding: 48px 24px;
        border: 1px solid #979797;
    }

    .tittle {
        width: 100%;
        color: #000000;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 12px;
        font-size: 32px;
    }

    .sub-tittle,
    .messageContentUn {
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
        color: #000000;
    }

    .underline {
        text-decoration: underline;
    }

}
</style>