
<template>
  <div class="main-container">
    <div
      class="card"
      v-for="(environment, envIndex) in tab.data"
      :key="envIndex"
    >
      <div class="card-header main">
        {{ environment.env }}
      </div>
      <div class="card-content main">
        <div
          class="card"
          v-for="(subEnvData, subEnvIndex) in environment.subEnvironment"
          :key="`${
            environment.env +
            '_' +
            subEnvData.env +
            '_' +
            subEnvData.reloadCount
          }`"
        >
          <div class="card-header inner">
            <div>{{ subEnvData.env }}</div>
            <button
              :class="{ 'rotating-icon': subEnvData.isRefresh || getRefresh }"
              @click="
                reloadCardData({
                  index: envIndex,
                  tabName: tab.tabName,
                  subEnv: subEnvData.env,
                  subEnvIndex,
                })
              "
            >
              <span class="reload">&#x21bb;</span>
            </button>
          </div>
          <div class="card-content inner">
            <VbgHealthCheckCard
              class="container2"
              v-for="(card, cardIndex) in subEnvData.cards"
              :key="cardIndex"
              :card="card"
              :env="environment.env"
              :subEnv="subEnvData.env"
              :index="cardIndex"
              :tabName="tab.tabName"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VbgHealthCheckCard from "./VbgHealthCheckCard.vue";
export default {
  name: "VbgHealthCheck",
  props: ["tab"],
  components: { VbgHealthCheckCard },
  computed: {
    ...mapGetters("dashboard", ["getRefresh"]),
  },
  methods: {
    ...mapActions("dashboard", ["reloadCardData", "getRefresh"]),
  },
};
</script>
<style scoped>
.main-container {
  margin: 0 35px;
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
}
.container2 {
  margin: 0px 5px !important;
}
.card {
  border: none;
  box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}
.card-header {
  background-color: #2196f3;
  border-color: #2196f3;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 1.1em;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.card-header.inner {
  background-color: #ed7000 !important;
}
.card-content {
  background: white;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 20px;
}

.list-group-item {
  display: flex;
}
.list-group-item:first-child {
  border-radius: unset !important;
}
.list-group {
  margin-bottom: 0px !important;
}
.reload {
  font-family: Lucida Sans Unicode;
  font-size: 20px;
}
.rotating-icon {
  display: inline-block;
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 768px) {
  .main-container {
    padding: 7px 0px;
    margin: 0 20px;
  }
  .card-content.inner {
    grid-template-columns: 1fr !important;
  }
}
@media only screen and (max-width: 600px) {
  .card-content.main {
    grid-template-columns: 1fr !important;
  }
}
</style>