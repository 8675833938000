<template>
  <div class="cnContainer">
    <div class="cnModal-title">
      <h1 class="combinenow-heading combinenow-h1">{{ $t("message.combineNowSuccessTitle") }}</h1>
    </div>
    <div class="martop30 cnModal-text">
      <p class="combinenow-p" style="color: #000">{{ $t("message.combineNowSuccessDesc") }}</p>
    </div>
    <div class="cnRectangle martop30"></div>
    <div class="martop30 user-id-container">
      <div class="cnModal-text cnModal-text-user-id cnModal-text-bold">User ID</div>
      <div class="cnModal-text" style="min-width: 2px;">{{uuId}}</div>
    </div>
    <div class="mt24 user-id-container">
      <div class="cnModal-text cnModal-text-user-id cnModal-text-bold">Password</div>
      <div class="cnModal-text">Use your Wireless password</div>
    </div>
    <button class="btn primary_black btn-continue" type="submit" @click="continueToAccount()">Continue to account</button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { combinenowEvents } from '@/common/assets/json/adobe-analytics.json';
import { setPageDetails, eventTracking } from '../../../../../public/adobeTagging';
import commonMixin from '@/common/mixin/commonMixins';
export default {
  name: "CombineNowSuccessComponent",
  components: {},
  mixins: [commonMixin],
  props: [],
  created() { setPageDetails('Combinenow: Combine Now Success Page'); },
  mounted() {
    if (this.preferredPortal !== null && this.userType !== null) {
      this.runAnalytics();
    }
  },
  data() {
    return {};
  },
  methods: {
    runAnalytics() {
      const { eventName, eventPayload } = combinenowEvents.mergeIDSuccess;
      const payload = {
        ...eventPayload,
        default_portal_view: this.preferredPortal,
        type: this.userType
      };

      eventTracking(eventName, payload);
    },
    continueToAccount() {
      let hostName = this.getHostName(window.location.href)
      window.location.href= this.sanitizeUrl("https://"+hostName+"/account/business/logout?goto=https://"+hostName+"/account/silent/auth?authLoginToken="+ this.rToken+"&preferablePortal="+this.preferredPortal);
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      } else {
          return null;
      }
    },
  },
  computed: {
    ...mapGetters('login',["rToken","uuId","preferredPortal", "userType"]),
  },
  watch: {},
};
</script>
<style scoped>
.cnModal-text-bold {
  font-family: "VzNHGeDS-bold11" !important;
}
.cnModal-text {
  font-family: "VzNHGeDS-reg11";
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.cnRectangle {
  box-sizing: border-box;
  border-bottom: 1px solid #d8dada;
}
@media (min-width: 600px) {
  .btn-continue {
    margin-top: 50px;
  }
  .mt24 {
    margin-top: 24px;
  }
  .user-id-container {
    display: flex;
  }
  .cnModal-text-user-id {
    width: 126px !important;
  }
  .cnModal-title {
    height: 64px;
    width: 596px;
  }
  .cnModal-text {
    width: 596px;
  }
  .cnModal-text-pass {
    position: relative;
    top: -24px;
  }
  .cnModal-text-wireless {
    position: relative;
    top: -44px;
    left: 100px;
  }
  .cnModal-text-position {
    position: relative;
    top: -40px;
    left: 100px;
    margin-top: 20px;
  }
  .cnContainer {
    /* position: absolute;
    left: 100px;
    padding-top: 30px; */
    margin-top: 30px;
    margin-bottom: 440px;
    margin-left: 100px;
    width: 596px;
  }
  .btn-continue {
    width: 245px;
    height: 44px;
    font-size: 16px !important;
  }
}
@media (max-width: 600px) {
  .mt24 {
    margin-top: 30px;
  }
  .cnContainer {
    padding: 30px 15px 0 15px;
  }
  .combinenow-heading {
    height: 72px;
  }
  .cnModal-text-position {
    margin-top: 10px;
  }
  .cnModal-text-pass {
    margin-top: 40px;
  }
  .cnModal-text-wireless {
    margin-top: 10px;
  }
  .btn-continue {
    margin-top: 50px;
    width: 289px;
    margin-bottom: 416px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
}
</style>