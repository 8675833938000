<template>
  <div class="container main_container">
      <div class="container-1">
          <BlockUI v-if="submitLoader || getTransactionLoader" :url="url"></BlockUI>
          <form id="loginBiz" class="signin" @submit.prevent="submitLoginForm">
              <div class="pwd-border">
                <div v-if="getUnifiedLoginAlerts.length > 0">
                  <div v-for="j in getUnifiedLoginAlerts" :key="j" class="pwdPushRError" v-bind:class="getAlertType">
                    <div class="pwdless_error">
                    <div class="warningImage" :class="messageImage(j)"></div>
                    <p class="pushTextError" v-html="j.msg">{{j.msg}}</p>
                  </div>
                  <div>
                    <img class="closeImage" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                  </div>
                  </div>
                </div>
                <div class="container-1-1">
                    <h2 class="pwd-log-in">Log in</h2>
                    <p class="pwd-to-your-business-acc">to your Verizon business account</p>

                      <div class="user-id">
                          <label for class="-input-label">User ID</label>
                          <!--<input v-if="getUnifiedLoginAlerts.length > 0" class="input-f pwdLessAlert" type="text" required v-model="formData[0].input[0].value" maxlength="60"/>-->
                          <input id="pwdUserID" name="pwd_userid" class="input-f input_mr input_border" autofocus type="text" required v-model="formData[0].input[0].value" maxlength="60"/>
                          <div class="pwdLoginTextError error_spacing" v-if="this.pwdInlineError != ''" id="inlineError"><p class="inline_err">{{ this.pwdInlineError }}</p></div>
                          
                          <div style="display: flex; align-items: center;" class="elements_below_input forgot_links">
                            <p style="display: flex;" class="check_bg">
                                <input name="remember_me" class="tc check_box_border" type="checkbox" id="ch" v-model="rememberMe"/>
                                <label for="ch" class="remember_text remember_mr">Remember User ID</label>
                            </p>
                            <p style="display: flex;" class="pwd-forgot-link remember_text bottom_underline" id="forgot_userid_link" @click="forgotPopup('forgotUserId')" name="forgot_userid_link">I forgot my User ID</p>
                          </div>
                      </div>

                      <div>
                          <button
                              class="btn primary_black continue_btn"
                              style="width: 100%; margin: 0px 0px 48px;font-size: 16px !important; font-family: 'VzNHGeDS-bold11';"
                              type="submit"
                              id="submitUserID"
                              name="submitUserID"
                          >
                              Continue
                          </button>
                      </div>

                  </div>
                </div>

                <div class="container-1-2">
                    <p style="margin-bottom: 24px;">
                        <label class="label_spacing"
                        >Need access? <a :href="supportUrl" class="pwd-register-link" style="text-decoration:none;">
                          <b style="font-family:VzNHGeDS-bold11">Register for an account</b> <span><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
  <path d="M2.22228 11.5001L0.777832 10.0557L4.83339 6.00011L0.777832 1.94455L2.16672 0.555664L7.66672 6.05566L2.22228 11.5001Z" fill="black"/>
</svg></span>
                          <!-- &nbsp;<img style="width: 12px; height: 12px; margin-left: 4px; margin-top: 5px; margin-bottom: 3px;"
                        :src="caret" /> -->
                      </a></label>
                    </p>
                    <!--<hr class="vz-horizontal" />-->
                    <div>
                        <p class="vz-login-link">
                            <a :href="resendWelcomeEmail" class="bottom_underline">Resend welcome email for MyBusiness Wireless</a>
                        </p>
                        <p class="vz-login-link">
                            <a @click="forgotPopup('payWithoutLogin')" class="bottom_underline">Pay without logging in</a>
                        </p>
                        <p class="vz-login-link">
                            <a :href="quick_login" class="bottom_underline">Complete quick tasks without logging in</a>
                        </p>
                        <p class="vz-login-link">
                            <a :href="Mgmt_portal_login" class="bottom_underline"> Manage additional portals</a>
                        </p>
                        <p class="vz-login-link"><a :href="vcg_personal_login" class="bottom_underline">Log in to your personal account</a></p>
                     </div>
                </div>
            </form>
            <div>
              <div id="noAccessToOtpModal" class="modal fade">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content" :class="checkModal" :style="gethelp">
                    <div class="close-icon-container">
                      <a
                        data-dismiss="modal"
                        @click="closeCombinenow()"
                      >
                        <img src="@/common/assets/styles/corp/images/close_black.png" style="height:20px; width: 20px;" />
                      </a>
                    </div>
                    <ConfirmSecurityDetailsOrDoLaterPopUp v-if="displayPopupClass"/>
                    <pop-up-component v-else ></pop-up-component>
                  </div>
                </div>
              </div>
            </div>  
        </div>
        <div class="container-2">
            <div class="pwd-default-promo1">
                <p class="pwd-default-promo">Manage your business account online anytime, anywhere.</p>
            </div>
        </div>
    </div>
</template>

<script>
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import PopUpComponent from "@/vbgauth/components/hybridExp/popups/PopUpComponent";
import { mapGetters, mapActions } from "vuex";
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import commonMixin from '@/common/mixin/commonMixins';
import $ from "jquery";
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import ClearWhite from "@/common/assets/styles/corp/images/clear_white.png";
import translations from "@/common/assets/json/messages1.json";
import ConfirmSecurityDetailsOrDoLaterPopUp from "@/vbgauth/components/hybridExp/popups/ConfirmSecurityDetailsOrDoLaterPopUp";
import { iamvar_vcgMultipleAccount } from '../../../../public/const.js'
export default {
  name: "PwdLessLogin",
  components: { PopUpComponent,ConfirmSecurityDetailsOrDoLaterPopUp },
  mixins: [commonMixin],
  data() {
    return {
      caret: caret_blk,
      //url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      userId: '',
      userPassword: '',
      rememberMe: false,
      canSubmit: false,
      captchaOn: true,
      SignUpUrl:'',
      vec2Registration: vec2_ent_portal_base_url,
      vcg_personal_login : iamvar_vcg_login,
      wireline_login : iamvar_fios_login,
      quick_login : iamvar_Quick_links,
      Mgmt_portal_login : iamvar_Mgmt_Portals,
      resendWelcomeEmail: iamvar_resendWelcomeEmail,
      show1: false,
      displayPopup:false
    };
  },
  async created() {
      // setPageDetails('Login:landing');
      this.getLoginCaptchaEnabledEnvVar();
      this.checkForCaptchaOffConditions();
  },
  mounted() {
     if(localStorage.getItem('clientId')) {
        localStorage.removeItem('clientId')
    }
   // this.$store.dispatch('login/updateUnifiedLoginAlerts', [] , {root:true})
      window.onpopstate = event => {
      //  this.$store.dispatch('login/updateUnifiedLoginAlerts', [] , {root:true})
       // this.formData[0].input[0].value = "";
        //browser back button disabled
        window.history.forward();
      };
      if (iamvar_pwdLessFlowType == 'basic' && this.getPasswordSuccess != 'success') {
        this.$store.dispatch('login/updateUnifiedLoginAlerts', [] , {root:true})
      }
      // get gotourl from mixins
      this.$store.dispatch('login/updateGotoUrl', this.getGotoUrl());
      this.$store.dispatch('login/updateEncodedGotoUrl', this.getGotoUrl());
      this.removeExistingVBGCCookie();
      this.getSessionInfo();
      this.updateUrlOnMount(window.location.href);
      this.resetState();

      // call rememberMe function only if uid query parameter is not present
      // let params = new URLSearchParams(window.location.search);
      if(!this.getParameterByName('uid')) {
          this.callRememberMe();
      }
      this.processQueryParameters();        
      if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='mdp_agent'){
          this.SignUpUrl=iamvar_mdpSignUpUrl         
      }
      else if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='tsd_agent'){
          this.SignUpUrl=iamvar_thingSpaceSignUpUrl
      }
      else if(this.getParameter('appGroup')==='TSM' || this.getParameter('client_id')==='tss_agent_gz'){
            this.SignUpUrl=iamvar_ccSignUpUrl
      }
      else{
          this.SignUpUrl=iamvar_thingSpaceSignUpUrl
      }
      if(this.getParameterByName('success') =='true' || this.getParameterByName('success') == true) {
        var alertMsg = translations.en.message["forgot_username_display"]
        this.setAlertTypeUpdate('success')
        var msg = [{msg: alertMsg.replace('$0', this.getParameterByName('uid')), type:'success'}]
        this.updateUnifiedLoginAlerts(msg)
      }
      window.localStorage.setItem("InitialRoute", window.location.href);
      setTimeout(()=>{
        this.csidBiocatch()
        this.changeContextBiocatch('pwdless_login_user_id_page')
      },1000)
  },
  beforeDestroy(){
      this.formData[0].input[0].value = "";
  },
  computed: {
    ...mapGetters('login', [
        'authError',
        'submitLoader',
        'iamvar_enableCaptcha',
        'redirectToOptionsPage',
        'redirectToPasswordPage',
        'redirectToSecProfilePage',
        'iamvar_idmUrl',
        'loginCaptchaKey',
        'getAlertType',
        'popupValue',
        'getInterstitialPage',
        'getUserSelectedAccount',
        'getUnifiedErrorCode',
        'rememberMeTrue',
        'combinenowValue',
        'remembermeUserName',
        'getUnifiedLoginAlerts',
        'redirectToStepupPage',
        'getPasswordSuccess',
        'encodedGotoUrl',
        'gotoUrl',
        'displayPopupClass'
    ]),
    ...mapGetters('cvsstepup', [ 'currentStep' ]),
    ...mapGetters('forgotflow',[ "usrName" ]),
    ...mapGetters('cvsstepup', { getTransactionLoader: 'submitLoader' }),
  supportUrl(){
    let isGoToPresent = window.location.href.split('goto=');
    if(isGoToPresent.length>1){
      let registration_url = this.vec2Registration.split('#')
      return registration_url[0]+'?goto='+isGoToPresent[1]+'#'+registration_url[1]
    }
    return this.vec2Registration;
  },
  getHelpCheck(){
      return (this.getUnifiedErrorCode == "ACCOUNT_LOCK_PREVENTION" || this.getUnifiedErrorCode == "ACCOUNT_LOCKED" || this.getUnifiedErrorCode == "ACCOUNT_PREVENTION" || this.getUnifiedErrorCode == "ACCOUNT_LOGIN_LOCKED" || this.getUnifiedErrorCode == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") ? true : false
    },
    isFormComplete() {
        // return this.userId && this.userPassword;
        return this.formData[0].input[0].value.length !== 0 
    },
    formData: {
        get: function () {
            return this.$store.getters['login/formData'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/updateFormData', newVal);
        }
    },
    pwdInlineError: {
      get: function() {
        return this.$store.getters['login/pwdInlineError']
      },
      set: function(newVal) {
        this.$store.commit('login/setPwdInlineError', newVal)
      }
    },
    // gotoUrl: {
    //         get: function () {
    //             return this.$store.getters['login/gotoUrl'];
    //         },
    //         set: function (newVal) {
    //             this.$store.dispatch('login/updateGotoUrl', newVal);
    //             this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
    //         }
    // },
    checkModal() {
      if(this.popupValue == 'forgotUserId' || this.popupValue == 'forgotUserPassword') {
        return 'modal-content-forgot';
      } else if(this.popupValue == 'interstitial' && (this.getUnifiedErrorCode.includes('MYBIZ') && this.getUnifiedErrorCode.includes('VEC'))) {
        return 'modal-content-hybrid';
      } else if(this.popupValue == 'interstitial' && (this.getUnifiedErrorCode.includes('MYBIZ') || this.getUnifiedErrorCode.includes('VEC'))) {
        return 'modal-content-int'
      }else if(this.popupValue == 'payWithoutLogin') {
        return 'modal-content-pwl';
      }else if(this.popupValue == 'combinenowPopupVal'){
        return 'modal-content-cn'
      }else if(this.popupValue == 'gethelp'){
        return 'modal-content-gethelp'
      } else if(this.popupValue =='sdarPopup'){
        return 'sdar_popup';
      } else {
        return ''
      }
     // return [{ 'modal-content-pwl': this.popupValue == 'payWithoutLogin' ? true : false }];
    },
    gethelp(){
      if(this.popupValue == 'gethelp') {
        return 'overflow: auto'
      }
    }
  },
  methods: {
    ...mapActions('login', [
            'submitPwdAMLoginForm',
            'callRememberMe',
            'removeExistingVBGCCookie',
            'getSessionInfo',
            'updateUrlOnMount',
            'resetState',
            'updateCaptchaToken',
            'updateFederalPortalIndicator',
            'UpdatePopupValue',
            'smbFormSubmit',
            'selectAccount',
            'sendCombinenowValue',
            'updateMergeStatus',
            'updateUnifiedLoginAlerts',
            'setAlertTypeUpdate'
        ]
    ),
    ...mapActions('cvsstepup', [ 'initializeStepup', 'updateUserIdFlow', 'updateUsernameForStepup' ]),
    submitLoginForm: function (recaptchaToken) {
        let payload = {
            formData: this.formData,
            rememberUser: this.rememberMe
        };

        // update state with new captcha token
        if(this.captchaOn)
            this.updateCaptchaToken(recaptchaToken);
        this.$store.dispatch('login/updateUnifiedLoginAlerts', [] , {root:true})
        this.submitPwdAMLoginForm(payload);
        this.updateUsernameForStepup(this.formData[0].input[0].value.trim());
        
        // might not be needed since submit button is disabled when form is empty
        /*if(this.$refs.loginForm.validate()) {
            this.submitAMLoginForm(payload);
        }*/

        // TODO: implement valid/invalid logic. For right now, redirect to OTP regardless of result
        // this.$router.push({ path: '/vbg-tsd-login/otp' });
       // this.forgotPopup('combinenowPopupVal');
    },
    bannerContainer() {
      return (this.getUnifiedLoginAlerts[0].type == 'success' || this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")) ? 'bannerMargin' : ''
    },
    getTypeOfAlert (index) {
          return index.type
    },
    showPwdLessAlert() {
      if(this.getUnifiedLoginAlerts[0].type != 'success') {
        return 'pwdLessAlert'
      }
    },
    messageImage(index) {
        if(index.type == 'error' || index.type == 'warning' || index.type == 'list') {
          return "newErrorMessageImage"
        } else if(index.type == 'success') {
          return "newSuccessMessageImage"
        } else if(index.type == 'information') {
          return "newInfoMessageImage"
        } else {
          return index.type+"MessageImage";
        }
      },
    getCloseIcon(){
      return ClearWhite;
    },
    deleteAlert(alert) {
      this.getUnifiedLoginAlerts.splice(this.getUnifiedLoginAlerts.indexOf(alert),1)
    },
    gethpop(value){
      this.UpdatePopupValue(value);
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
    },
    getParameter(url) {
        let decodeUrl=decodeURIComponent(document.location)
        url = url.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + url + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(decodeUrl);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    processQueryParameters() {
        // let params = new URLSearchParams(window.location.search);

        if(this.getParameterByName('uid')) {
            this.formData[0].input[0].value = this.getParameterByName('uid');
        }
        if (this.getParameterByName("loginAssistantUid")) {
        this.formData[0].input[0].value = atob(this.getParameterByName("loginAssistantUid"));
      }
        if(this.getParameterByName('rememberMe')) {
            if(this.getParameterByName('rememberMe') == 'true')
                this.rememberMe = true;
        }
    },
    getLoginCaptchaEnabledEnvVar() {
        this.captchaOn = iamvar_enableLoginCaptcha;
    },
    checkForCaptchaOffConditions() {
        // turn off captcha when instance is related to a federal portal
        let gotoParam = this.getParameterByName('goto');

        // if goto has 'oauth', get redirect_uri and check if redirect_uri has any of the federalPortalKeywords
        if(gotoParam != null && gotoParam.includes('oauth')) {

            // decode goto in order to easily access redirect_uri parameter
            let decodedGoto = decodeURIComponent(gotoParam);
            // let redirectUriParam = this.getParameterByName('redirect_uri');
            let decodedParameters = new URLSearchParams(decodedGoto);
            let redirectUriParam = decodedParameters.get('redirect_uri');

            if(iamvar_federalPortalKeyWords.some(str => redirectUriParam.includes(str))) {
                this.captchaOn = false;
                this.updateFederalPortalIndicator(true);
            }
        } else { // check if goto has any of the federalPortalKeywords
            if(iamvar_federalPortalKeyWords.some(str => gotoParam && gotoParam.includes(str))) {
                this.captchaOn = false;
                this.updateFederalPortalIndicator(true);
            }
        }
    },
    
    forgotPopup(value) {
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
      this.UpdatePopupValue(value);
      var eventPayload = {}
      var eventName = null
      if(value == 'forgotUserId' || value == 'forgotUserPassword') {
        eventName = value == 'forgotUserId' ? 'forgot_user_id' : 'forgot_password'
        eventPayload = {
          "accounts": {
            "1": "My Business Wireless",
            "2": "Verizon Enterprise Center",
            "3": "My Business Wireline Fios, phone, internet & TV",
          }
        }
        eventTracking(eventName,eventPayload)
      } else if(value == 'payWithoutLogin') {
        eventName = 'pay_without_logging_in'
        eventPayload = {
          "accounts": {
            "1": "My Business Wireless",
            "2": "My Business Wireline Fios, phone, internet & TV",
            "3": "Verizon Enterprise Center"
          }
        }
        eventTracking(eventName,eventPayload)
      }
    },
    closeCombinenow() {
      if(this.popupValue == 'combinenowPopupVal') {
        this.updateMergeStatus('closePopup');
      } else if (this.popupValue == 'sdarPopup'){
        this.$store.dispatch('login/UpdatePopupValue', null)
        this.$store.dispatch('login/updateDisplayPopupClass', false)
      }
    }
  },
  watch: {
      displayPopupClass: function(newval){
        if(newval) {
          this.forgotPopup('sdarPopup');
        }
      },
      remembermeUserName: function(newval) {
        if(newval != "" || newval != null) {
          this.formData[0].input[0].value = newval
        }
      },
      usrName: function(newVal) {
        if(newVal != undefined || newVal != null) {
          this.formData[0].input[0].value = newVal
        }
      },
      redirectToStepupPage: function(newVal) {
        if(newVal) {
            this.initializeStepup();
        }
      },
      getUnifiedLoginAlerts: function(newVal) {
        if(this.getUnifiedLoginAlerts.length > 0) {
          window.scrollTo(0,0);
        }
      },
      show1: function(newVal) {
        if(newVal){
          setTimeout(() => {
            this.show1 = false
          },10000)
        }
      },
      redirectToOptionsPage: function(newVal) {
          if(newVal) {
            if(this.gotoUrl != null) {
                  this.$router.push({ name: 'PwdLessOption', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
              } else {
                  this.$router.push({ name: 'PwdLessOption' });
              }
          }
      },
      redirectToPasswordPage: function(newVal) {
          if(newVal) {
            if(this.gotoUrl != null) {
                  this.$router.push({ name: 'PwdLessPassword', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
              } else {
                  this.$router.push({ name: 'PwdLessPassword' });
              }
          }
      },
      rememberMeTrue: function(newVal) {
        if(newVal == 'setToTrue') {
          this.rememberMe = true
        }
      },
      redirectToSecProfilePage: function(newVal) {
          if(newVal) {
              window.location.href = newVal;
          }
      },
      currentStep: function(newVal) {
        if(newVal === 'FLOW_INITIALIZED') {
          if(this.gotoUrl != null) {
            this.$router.push({ name: 'PwdLessOtpDelivery', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
          } else {
            this.$router.push({ name: 'PwdLessOtpDelivery' });
          }
        }
      },
      getInterstitialPage: function(newVal) {
          if(newVal) {
            // if from 2nd or 5th auth call X-VZ-MULTIPLE-ACCOUNT is coming that means there are 2 accounts VBMC(VEC) and VBMS(FIOS)
            if(newVal == iamvar_vcgMultipleAccount){
              this.forgotPopup(iamvar_vcgMultipleAccount)
            } else {
              // if new value is true then it means it's has conflict between any of combination between mybiz/vec/fios 
              this.forgotPopup('interstitial')
            }
            this.$store.commit('login/setInterstitialPage', false)
          }
      },
      getUserSelectedAccount: function(selectedAccount){
         let payload = ''
        $("#noAccessToOtpModal").modal('hide')
          if(selectedAccount && this.popupValue=='interstitial'){
              payload = {
                formData: this.formData,
                rememberUser: this.rememberMe,
                additionalAuth : "VBG_LOGIN"
             };
             if(this.getUnifiedErrorCode.includes("INVALID")){
                if (selectedAccount == "wireline" && this.getUnifiedErrorCode.includes("INVALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if (selectedAccount == "wireless" && this.getUnifiedErrorCode.includes("INVALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if(selectedAccount == "fios" && this.getUnifiedErrorCode.includes("INVALID")){
                    payload['additionalAuth'] = "INVALID_SMB_LOGIN"
                     // to do - "authenticate" call with additional header parameter as "INVALID_SMB_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }
             }else{
                if(selectedAccount == "fios" && this.getUnifiedErrorCode.includes("VALID")){
                    this.smbFormSubmit({username:this.formData[0].input[0].value,password:this.formData[1].input[0].value,rememberUser:this.rememberMe})
                }else if (selectedAccount == "wireline" && this.getUnifiedErrorCode.includes("VALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if (selectedAccount == "wireless" && this.getUnifiedErrorCode.includes("VALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }
             }
          }else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserId'){
            this.$router.push({ path: "/login/recovery/forgotusername" });
            this.updateUserIdFlow('BASIC')
          }else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserPassword'){
            this.$router.push({ path: "/login/recovery/forgotpassword" });
          }else if(selectedAccount=='fios' && this.popupValue=='forgotUserPassword'){
            let fiosURLFP = '';
            let gotoURLFP = this.getParameterByName('goto');
            if(gotoURLFP) {
              // Added goto url to fios url
              fiosURLFP = `${iamvar_fios_fp_url}?goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(gotoURLFP)}&clientId=vbm`
            } else {
              fiosURLFP = iamvar_fios_fp_url+'?clientId=vbm'
            }
            window.location.href = fiosURLFP;
          }else if(selectedAccount=='fios' && this.popupValue=='forgotUserId'){
            let fiosURLFU = '';
            let gotoURLFU = this.getParameterByName('goto');
            if(gotoURLFU) {
              // Added goto url to fios url
              fiosURLFU = `${iamvar_fios_fu_url}&goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(gotoURLFU)}`
            } else {
              fiosURLFU = iamvar_fios_fu_url
            }
            window.location.href = fiosURLFU
          }else if(selectedAccount=='wireless' && this.popupValue=='payWithoutLogin'){
            window.location.href = iamvar_mbt_base_url+'/mbt/manageservice/payments.go/#/billpay/onetimepayment'
          }else if(selectedAccount=='fios' && this.popupValue=='payWithoutLogin'){
            window.location.href = this.wireline_login
          }else if(selectedAccount=='wireline' && this.popupValue=='payWithoutLogin'){
            window.location.href = iamvar_vec_quickpay
          }else if((selectedAccount == 'vbmc' || selectedAccount == 'vbms') && this.popupValue==iamvar_vcgMultipleAccount) {
            payload = {
              formData: this.formData,
              rememberUser: this.rememberMe,
              additionalVcgAuth : selectedAccount == 'vbmc' ? "VBMC" : "VBMS"
            };
            this.submitPwdAMPasswordForm(payload);
          }

          // to reset initial value
        this.selectAccount('null')
      },
      popupValue: function(newVal) {
        if(newVal == 'combinenowPopupVal') {
          this.forgotPopup(newVal)
        }
      }
      
  }
};
</script>

<style scoped>
.pwdPushRError {
display: flex;
height: auto;
margin-top: 1px;
margin-left: 1px;
margin-right: 1px;
align-items: flex-start !important;
padding-top: 16px !important;
padding-bottom: 16px !important;
justify-content: space-between;
border-radius: 4px;
/* margin-bottom: 48px; */
}
.container{
padding-top: 0px !important;
}
.pwdless_error{
display: flex;
align-items: flex-start !important;

}
.pwdPushRError.newError {
    background-color: #FFECE0 !important;
}
.pwdPushRError.error {
    background-color: #FFECE0 !important;
}
.pwdPushRError.list {
    background-color: #FFECE0 !important;
}
.pwdPushRError.success {
    background-color: #DCF5E6 !important;
}
.pwdPushRError.warning {
    background-color: #FFF9DE !important;
}
.pwdPushRError.information {
    background-color: #E2F2FD !important;
}
.newInfoMessageImage {
  margin-top: 16px !important;
  margin-left: 16px !important;
  margin-bottom: 36px !important;
  width: 16px !important;
  height: 16px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.newSuccessMessageImage {
  height: 20px !important;
  width: 20px !important;
  border: 1px solid black !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.pushTextError {
/* margin-top: 16px; */
margin-right: 8px;
/* margin-bottom: 16px; */
margin-left: 8px;
height: auto;
font-size: 12px;
line-height: 16px;
/* width: 380px; */
font-family: 'VzNHGeTX-reg11';
color: #000000 !important;
flex: 1;
}
.pwdLoginTextError {
    height: auto !important;
    font-size: 12px;
    line-height: 16px;
    /* width: 380px; */
    color: #000 !important;
    margin-bottom: 12px;
}
.closeImage {
    /* margin-top: 16px; */
    margin-right: 16px;
    /* margin-bottom: 36px; */
    float: right !important;
    width: 11.7px;
    height: 11.7px;
}
.warningImage {
    /* margin-top: 16px; */
    margin-left: 16px;
    /* margin-bottom: 36px; */
    width: 24px;
    height: 24px;
}
.inlineError {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}
.pwdLessImgAlert {
 border-color: #C44904 !important;
  border: 1px solid;
  background-color: #FFECE0;
  border-radius: 5px;
  background-image: none !important;
  background-position: none !important;
  background-origin: none !important;
}
.pwdLessAlert {
  border-color: #B95319 !important;
  border: 1px solid;
  background-color: #FFECE0;
  border-radius: 5px;
  background-image: url(../../../common/assets/styles/corp/images/error_icon.svg);
  background-position: right;
  background-origin: content-box;
}
.bannerMarginUnset{
  margin:unset;
}
.newMessagesInfo{
    margin: auto;
 }
.pwdLessMessageContent {
  height: 16px;
  font-size: 12px;
  margin-top: 8px; 
  margin-bottom: 16px;
}
.remember_text {
  margin-top: 5px !important;
}
.pwdLessAlert {
  border-color: #B95319 !important;
  border: 1px solid;
  background-color: #FFECE0;
  border-radius: 5px;
}
input[type="checkbox"].tc {
  outline: none !important;
  margin-right: 12px !important;
}

.input-f {
   border: 1px solid #6F7171 ;
   border-radius: 5px !important;
   margin-bottom: 12px !important;
   height: 44px !important;
   width: 100%;
}
.-input-label {
  margin-bottom: 4px !important;
  font-family: 'VzNHGeTX-reg11' !important;
  letter-spacing: normal !important;
}
.user-id {
    height: auto !important;
    margin-bottom: 64px !important;
}

/* mobile styles starts*/
@media only screen and (min-width: 280px) and (max-width: 1200px) {
.container-2{
display: none !important;
}
.container {
    width: 100% !important;
}
.main_container{
    display: flex !important;
    /* justify-content: center; */
  }
  .container-1 {
    max-width: 464px !important;
    height: 100% !important
}
.log-in{
  margin-bottom: 0px !important;
  height: auto !important;
  font-size: 38px;
  padding-bottom: 10px;

}

}
@media only screen and (min-width: 270px) and (max-width: 500px) {
 
.main_container{
    display: flex !important;
    margin-top: 0px !important;
    /* justify-content: center; */
    padding-top: 0;
  }
  .container-1 {
    max-width: 364px !important;
    height: 100% !important;
    /* padding: 10px; */
    
}
.container-1-1 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.log-in{
  margin-bottom: 0px !important;
  height: auto !important;
  font-size: 28px;
  padding-bottom: 10px;
}
.pwd-border {
    border: none !important;
    
}
.to-your-business-acc{
  font-size: 14px !important;
    padding-bottom: 24px !important;
    width: 100% !important;
}
.input-f {
   /* border: 1px solid #6F7171 !important; */
   border-radius: 5px !important;
   height: 44px !important;
   width: 100%;
   margin-bottom: 12px !important;
}
.input-f:focus {
  border: 1px solid black !important;
}
.pwdLessAlert {
  border-color: #B95319 !important;
  border: 1px solid;
  background-color: #FFECE0;
  border-radius: 5px;
  background-image: url(../../../common/assets/styles/corp/images/error_icon.svg);
  background-position: right;
  background-origin: content-box;
}
.elements_below_input{
  display: flex !important;
  justify-content: space-between !important;
}
.pwd-forgot-link{
  margin-left: 0 !important;
}
.remember_text{
  font-size: 12px !important;
  margin-top: 5px;
}
p > label{
  margin-bottom: 0px !important;
  margin-top: 5px;
}
input[type=checkbox]{
  margin-top: 0px !important;
}
.pwd-forgot-link {
    /* height: 20px; */
    color: #000000;
    font-family: "Verizon NHG DS";
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 22px;
    float: right;
    cursor: pointer;
    border-bottom: 1px solid;
    /* text-decoration: none !important; */
}
.user-id {
    height: 98px;
    /* width: 385px; */
    margin-bottom: 14px !important;
}
.container-1-1{
  height: 100% !important;
}

.container-1-2 p label{
  font-size: 12px !important;
}
.vz-login-link{
 font-size: 12px !important;
 /* text-decoration: underline !important; */
 /* border-bottom: 1px solid #000; */
}

}
@media only screen and (min-width: 400px) and (max-width: 500px){
.container-1 {
    max-width: 380px !important;
    
}
.remember_text, .to-your-business-acc, .vz-login-link, .container-1-2 p label {
    font-size: 16px !important;
}
}
@media only screen and (min-width: 350px) and (max-width: 399px){
.container-1 {
    max-width: 330px !important;
    
}
.remember_text, .to-your-business-acc, .vz-login-link, .container-1-2 p label {
font-size: 16px !important;
}
}
@media only screen and (min-width: 310px) and (max-width: 349px){
.container-1 {
max-width: 300px !important;

}
/* .remember_text, .to-your-business-acc, .vz-login-link, .container-1-2 p label {
font-size: 14px !important;
} */
}
@media only screen and (min-width: 280px) and (max-width: 309px){
.container-1 {
    max-width: 270px !important;
    
}}
@media only screen and (min-width: 767px) and (max-width: 1023px){
.sdar_popup > div >.sdar_main_title {
    margin-top: 24px !important;
}
}
@media only screen and (min-width: 540px) {
  .sdar_popup{
  height: auto !important;
  margin: auto !important;
  width: 560px !important;
}
}
.vz-login-link{
 /* text-decoration: underline !important; */
 /* border-bottom: 1px solid #000; */
}
/* mobile styles ends*/
.pwd-log-in {
  margin-bottom: 12px;
  font-size: 32px;
  width: 328px;
  height: 36px;
  color: #000000;
  font-family: 'VzNHGeDS-bold11';
}
.pwd-to-your-business-acc {
  margin-bottom: 24px;
  height: 20px;
  font-size: 16px;
  width: 328px;
  color: #000;
  letter-spacing: 0.5px;
  /* commmenting the below line after discussing with Vivek, 
  after commenting only we are getting the correct font from global css */
  /* font-family: 'Verizon NHG eDS Regular'; */
}
.container {
  margin-top: 48px;
    margin-bottom: 109px;
    /* margin-left: 290px;
    margin-right: 290px; */
    display: flex;
    justify-content: center;
}
.container-1 {
    width: 464px;
    height: 670px;
}
.container-2 {
    margin-left: 172px;
    margin-bottom: 20px;
}
.pwd-border {
  border: solid;
  border-color: #D8DADA;
  border-width: 1px;
  border-radius: 4px;
}
.container-1-1 {
    height: auto;
    margin-top: 48px;
    margin-left: 40px;
    margin-right: 40px;
}
.container-1-2 {
    margin-top: 32px;
}
.pwd-forgot-link {
  /* margin-left: 82px; */
  /* height: 20px; */
  color: #000000;
  font-family: "VzNHGeDS-reg11";
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  float: right;
  cursor: pointer;
  /* text-decoration: underline; */
}
.pwd-default-promo1{
  background: #F6F6F6;
  height: 650px;
  width: 464px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.pwd-default-promo{
  height: 128px;
  width: 253.78px;
  color: #A7A7A7;
  font-family: 'VzNHGeDS-bold11';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.pwd-register-link {
  margin-left: 16px;
  height: 20px;
}
.vz-login-link {
  height: 20px;
  margin-bottom: 24px;
}
@media only screen and (min-width: 280px) and (max-width: 1200px) {
  .pwd-default-promo {
    display: none;
  }
  .pwd-default-promo1 {
    display: none;
  }
}
#noAccessToOtpModal {
  z-index: 105000000;
}
.pwd-forgot-link {
  z-index: 1000;
}
.bottom_underline{
  text-decoration: none !important;
  border-bottom: 1px solid #000 !important;
  line-height: 20px !important;
  padding-bottom: 1px !important;
}
.error_spacing{
  height: auto !important;
  font-family: 'VzNHGeTX-reg11' !important;
  color: #000 !important;
  /*padding-bottom: 4px !important;*/
}
.check_box_border, .check_box_border:hover {
  outline: none !important;
  border: 1px solid #D8DADA !important;
  margin-top: 0 !important;
}
.check_box_border[type="checkbox"]:checked{
  accent-color: #000 !important;
}
.continue_btn{
  height: 44px !important;
}
.input_mr{
  margin-bottom: 8px !important;
  border-color: #6F7171 ;
}
.input_mr:focus{
  border-color: #000000 !important;
}
.remember_mr{
  margin-top: 0px !important;
  margin-bottom: 0px !important;

}

@media only screen and (max-width: 509px) and (min-width: 501px)  {
  .container-1-1 {
    height: auto;
    margin-top: 46px;
    margin-left: 36px !important;
    margin-right: 36px !important;
    margin-bottom: 20px;
}
  }
  .label_spacing{
    margin-bottom: 0 !important;
  }
.vz-login-link>a{
font-size: 16px !important;
line-height: 20px !important; 
letter-spacing: 0.5px;
color:#000000 !important;
font-family: "VzNHGeTX-reg11";
}
.vz-login-link>a:focus{
color: #000000;
}
@media (min-width: 960px){
.container{
max-width: 100% !important;
}
}
.input-f {
    border-bottom: #6F7171 1px solid;
}
.forgot_links{
display: flex;
justify-content: space-between;
align-items: center;
}
.input_border{
  border: 1px solid #6F7171 !important;
}
.input_border:focus{
  border: 1px solid #000000 !important;
}
@media only screen and (max-width: 767px) {
  .inline_err{
    font-family: 12px !important;
    line-height: 16px !important;
    letter-spacing: normal !important;
    font-family: "VzNHGeTX-reg11" !important;
    
  }
.sdar_popup{
  padding:30px 15px !important;
}
.container {
max-width: 100%;
margin-top: 0px !important;
padding-left: 0 !important;
padding-right: 0 !important;
}
    .pwd-border {
      border: none !important;
  /* margin-top: 12px !important; */
}
.pwd-log-in{
  margin-top: 32px;
}
.container-1{
  width: 100% !important;
  max-width: 100% !important;
}
.elements_below_input{
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
.container-1-1{
  margin: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.main_container{
/* margin-top: 32px !important; */
padding-bottom: 0px !important;
margin-bottom: 96px !important;
}
.user-id{
  margin-bottom: 48px !important;
}
.continue_btn{
  margin-bottom: 64px !important;
  line-height: 20px;
letter-spacing: 0.5px;
}
.container-1-2{
  margin-top: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.container-1-1 h2{
  font-size: 24px !important;
  line-height: 28px !important;
  color: #000 !important;
  margin-bottom: 8px !important;
}
.pwd-to-your-business-acc{
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000000 !important;
}
.vz-login-link>a{
font-size: 12px !important;
line-height: 16px !important; 
letter-spacing: normal;
font-family: "VzNHGeTX-reg11";
}
.input_mr{
  margin-bottom: 8px !important;
  border-color: #6F7171 ;
}
.input_mr:focus{
  border-color: #000000 !important;
}
.input_border{
  border: 1px solid #6F7171 !important;
}
.input_border:focus{
  border: 1px solid #000000 !important;
}
}

</style>
