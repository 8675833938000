<template>
  <div><component :is="getDeliveryComponentName"></component></div>
</template>
<script>
import NewVerifyDelivery from "./NewVerifyDelivery.vue";
import NewOTPSummary from "./NewOTPSummary.vue";
import NewAddOTPComponent from "./NewAddOTPComponent.vue";
import NewVerifyOTPComponent from "./NewVerifyOTPComponent.vue";
import { mapMutations, mapGetters } from "vuex/dist/vuex.common.js";
export default {
  components: {
    NewVerifyDelivery,
    NewOTPSummary,
    NewAddOTPComponent,
    NewVerifyOTPComponent,
  },
  name: "NewRootAddDelivery",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("login", ["getDeliveryComponentName"]),
  },
  mounted() {
    this.setHideBanner(true);
    this.setDeliveryComponentName("NewVerifyDelivery");
  },
  methods: {
    ...mapMutations("login", ["setHideBanner", "setDeliveryComponentName"]),
  },
};
</script>
