<template>
  <div>
    <h1 class="new_pwdless_main_heading pwdles_main_heading">
      {{ $t("message.add_delivery_title") }}
    </h1>
    <div class="new_pwdless_sub_heading pwdles_sub_heading">
      {{ $t("message.add_delivery_description") }}
    </div>

    <div class="pwd_less_btn_container">
      <div class="new_pwdless_btn_section">
        <button
          class="new_pwdless_submit_btn"
          type="submit"
          name="submitUserID"
          @click="AddVerificationMethod()"
        >
          {{ $t("message.add_delivery_button_label") }}
        </button>
      </div>
      <div
        class="new_pwdless_btn_section new_pwdless_btn_section_mobile"
        v-if="getSkipOption"
      >
        <button
          class="new_pwdless_submit_btn pwd_cancel_btn"
          id="submitUserID"
          name="submitUserID"
          @click="skipNow()"
        >
          {{ $t("message.add_delivery_skip_label") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions} from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("cvsstepup", ["getSkipOption"]),
  },
  methods: {
    ...mapActions('login', ['redirectToOpenam']),
    AddVerificationMethod() {
      // Sending user selection option to AM
      setSelectedValueInClientLibForCreatingSession("true");
      // redirecting to AM for logging in the user
      this.redirectToOpenam("verifyDelivery");
    },
    skipNow() {
      // Clearing the global variable
      setSelectedValueInClientLibForCreatingSession(null);
      // redirecting to AM for logging in the user
      this.redirectToOpenam();
    },
  },
  mounted(){
    deleteHeader('X-VBG-OSAT-CHOICE');
  }
};
</script>
<style scoped>
.pwdles_main_heading {
  margin-bottom: 12px;
}
</style>