import { render, staticRenderFns } from "./VPSFFStepupRootComponent.vue?vue&type=template&id=51d5be8d&scoped=true"
import script from "./VPSFFStepupRootComponent.vue?vue&type=script&lang=js"
export * from "./VPSFFStepupRootComponent.vue?vue&type=script&lang=js"
import style0 from "./VPSFFStepupRootComponent.vue?vue&type=style&index=0&id=51d5be8d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d5be8d",
  null
  
)

export default component.exports