<template>
  <div class="cnContainer">
    <div class="cnModal-title">
      <h1 class="combinenow-heading combinenow-h1">{{ $t("message.combineNowErrorTitle") }}</h1>
    </div>
    <div class="cnModal-text mt24">
      <p style="color: #000">{{ $t("message.combineNowErrorDesc") }}</p>
    </div>
    <!-- <div class="martop20 cnModal-text">
      <p style="color: #000">Continue to My Business Wireless?</p>
    </div> -->
    <div class="martop30 continue-btn-container">
      <button
        @click="continueToAccount()"
        class="btn primary_black continue-btn combinenow-heading"
        type="button"
      >Continue to account</button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { setPageDetails } from '../../../../../public/adobeTagging';

export default {
  name: "CombinenowErrorComponent",
  props: [],
  created() { setPageDetails('Combinenow: Combine Now Error Page'); },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    ...mapActions("login", [ "skipfornowCombineNow" ]),
    continueToAccount() {
      this.skipfornowCombineNow();
     // redirect user to portal. Since, session will be active so redirect to unifiedlogin after that it will redirect to portal
    // this.$router.push({ path: "/login/unifiedlogin"});
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      } else {
          return null;
      }
    },
  },
  computed: {
    ...mapGetters('login',["rToken","uuId","preferredPortal"]),
  },
  watch: {}
};
</script>
<style scoped>
.cnModal-text {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.mt24 {
  margin-top: 24px;
}
@media (min-width: 600px) {
  .cnModal-title {
    height: 64px;
    width: 596px;
  }
  .cnModal-text {
    width: 596px;
    color: #000000;
  }
  .combinenow-h1 {
    letter-spacing: 0;
    /*line-height: 32px;*/
  }
  /* .cnContainer {
    position: absolute;
    left: 100px;
    width: 289px;
  } */
  .cnContainer {
    margin-top: 46px;
    margin-bottom: 657px;
    margin-left: 100px;
    width: 596px;
  }
  .continue-btn {
    height: 44px;
    width: 245px;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .cnContainer {
    margin: 24px 15px 416px 15px;
  }
  .continue-btn {
    width: 289px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
}
</style>