<template>
    <div>
        <Header></Header>
            <div class="push_reg_container">
                <div id="pushContainer" class="opt-container-1">
                    <BlockUI v-if="submitLoader" :url="url"></BlockUI>
                    
                    <div class="pwdPushRError" id="errorIAMPush" style="display:none" aria-hidden="true">
                        <img class="warningImage" :src="warningImage" />
                        <p class="pushTextError incoming_msg_font" id="IAMerrorMessage"></p>
                        <img class="closeImage" :src="closeImage" @click="deleteAlert()" />
                        
                    </div>
                    <div class="pwdPushRInfo" id="infoIAMPush" style="display:none" aria-hidden="true">
                        <img class="warningImage" :src="infoImage" />
                        <p class="pushTextError incoming_msg_font" id="IAMinfoMessage"></p>
                        <img class="closeImage" :src="closeImage" @click="deleteAlert()" />
                        
                    </div>
                    <div class="opt-container-1-1">
                        <h3 class="opt-header-1">We sent a push notification to your device</h3>
                        <div class="user_details" style="margin: 32px 0px 48px;">
                            <p class="opt-p-1 user_id">User ID</p>
                            <div style="display: flex;">
                                <p class="opt-username">{{this.pwdUsername}}</p>
                                <button class="opt-button-1" id="changeUserID" name="changeUserID" @click="gotoPwdLessLogin()">Change</button>
                            </div>
                        </div> 
                        <img class="mainIcon_desktop" width="64px" height="64px" :src="iconSrc" />    
                        <img class="mainIcon_mobile" width="48px" height="48px" :src="iconSrc" />
                        
                        <p style="margin-top: 12px; margin-bottom: 12px;" class="p-2 dynamic_para_div">Check your device and click on the notification to continue logging in.</p>
                        <div class="wait waitactive" id="notificationWait">
                            This will expire in 
                            <span class="extime timer_font" id="expiretime"></span> minutes.
                        </div>
                        <div id="pushResend" class="resend-field">
                            <a id="pushResendLink" class="pushResendLink"name="pushResendLink" @click="$event.preventDefault();resendNotification();" style="text-decoration: none !important;" href="#">Resend notification</a>
                        </div>
                        <div style="margin-top: 32px;">
                            <p class="checkbox_line check_para check_bg">
                                <input class="tc checkbox_input check_box_border" type="checkbox" id="ch" name="default_login_method" v-model="defaultMethod"/>
                                <label for="ch" class="label_text">Make this my default log in method.</label>
                            </p>
                        </div>
                        
                        <p style="margin-bottom: 48px; margin-top: 64px;" class="another_way_para" @click="supportUrl()" id="loginAnotherWay" name="loginAnotherWay">
                          <b class="anotherCls">
                              <span class="left_caret_spacing">
                              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                               <path d="M6.5 1L1.5 6L6.5 11" stroke="black" stroke-width="2"/>
                              </svg>
                            </span> 
                            <span class="login_another_way">Log in another way</span></b> 
                        </p>

                    </div>

                </div>
                <div class="opt-container-2">
                    <div class="opt-default-promo1">
                        <p class="opt-default-promo">Manage your business account online anytime, anywhere.</p>
                    </div>
                </div>
            </div>
        <Footer class="footer-margin-top"></Footer>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import Header from '@/vbgauth/components/LegalHeader'
import Footer from '@/vbgauth/components/ForgotFlowFooter'
import '@/common/assets/styles/tooltip.css'
import translations from '@/common/assets/json/messages1.json'
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import CustomLoaderComponent from '@/vbgauth/components/CustomLoaderComponent.vue';
import info_blk from "@/common/assets/styles/corp/images/info_blk.png";
import close_image from "@/common/assets/styles/corp/images/close_black.png";
export default {
  name: 'PwdLessPushRegistered',
  components: {
    Header,
    Footer,
    CustomLoaderComponent,
  },
  mixins: [commonMixin],
  data () {
    return {
        
        url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/circle-loaderWHITE_48x48.gif',
        iconSrc : iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/Icon.svg',
        warningImage : iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/warning.png',
        infoImage : info_blk,
        closeImage: close_image,
        //pwdUsername: getCookie("pwdUserName"),
        
    }
  },
  mounted () {
    this.changeContextBiocatch('pwdless_login_send_push_notification_page')

    if (this.pwdUsername == null) {
        this.$router.push({ name: 'PwdLessLoginRootComponent'})
    }
    if(this.getParameterByName("isPushRedirected") === 'true' || this.getParameterByName("isPushRedirected") === true) {
      this.submitPwdLessPushLogin({userName:this.pwdUsername});
    }
    if (this.optionMethod == 'opt1') {
      this.defaultMethod = this.makeDefaultMethodTrue
    }
    document.addEventListener("click", (e) => {
      if (e.target.id == "resendN") {
        this.resendNotification()
      }
    });
  },
  updated() {
    
  },
  beforeDestroy() {
    
  },
  methods: {
    ...mapActions("login", [ "encryptDefaultMethod","submitPwdLessPushLogin" ]),
    gotoPwdLessLogin() {
        if(this.gotoUrl != null) {
            this.$router.push({ name: 'PwdLessLoginRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
        } else {
            this.$router.push({ name: 'PwdLessLoginRootComponent' });
        }
    },
    supportUrl() {
        if(this.gotoUrl != null) {
            this.$router.push({ name: 'PwdLessOption', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
        } else {
            this.$router.push({ name: 'PwdLessOption' });
        }
    },
    resendNotification() {
        this.submitPwdLessPushLogin({userName:this.pwdUsername});
    },
    deleteAlert() {
        document.getElementById('errorIAMPush').setAttribute('style', 'display:none');
        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
    }
  },
  computed: {
    ...mapGetters('login', [ 'gotoUrl', 'pwdUsername', 'submitLoader', 'optionMethod', 'makeDefaultMethodTrue' ]),
    formData: {
        get: function () {
            return this.$store.getters['login/formData'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/updateFormData', newVal);
        }
    },
    defaultMethod: {
      get: function () {
        return this.$store.getters['login/makeDefaultMethodTrue'];
      },
      set: function (newVal) {
        this.$store.dispatch('login/makeDefaultMethodTrue', newVal);
        if (newVal == true) {
            let usernameOption = this.pwdUsername + '|' + 'opt1'
            this.encryptDefaultMethod(usernameOption);
        } else {
          for(var i = 0; i< iamvar_vbgcRemCookieDomain.length; i++) {
            document.cookie = iamvar_makeDefaultMethodName +'=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain='+ iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath+ ';secure=' + iamvar_vbgcRemCookieSecure+';comment=deleting from ui'
          }
        }
      }
    },
  },
  watch: {
    makeDefaultMethodTrue: function(newVal) {
      if(newVal) {
        this.defaultMethod = newVal
      } /*else {
        this.defaultMethod = false
      }*/
    },
  }
}
</script>

<style scoped>
.opt-container-1 {
    width: 464px;
    height: 626px;
    border: solid;
    border-color: #D8DADA;
    border-width: 1px;
    /* margin-bottom: 198px; */
    /* margin-left: 290px; */
    border-radius: 4px;
}
.pwdPushRError {
    /*display: block;*/
    background-color: #FFECE0;
    height: auto;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.pwdPushRInfo {
    /*display: flex !important;*/
    background-color: #E2F2FD;
    height: auto;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.warningImage {
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 36px;
    /* width: 13.98px; */
    width: 16px;
    /* height: 13.98px; */
    height: 16px;
}
.closeImage {
    margin-top: 16px;
    margin-right: 16px;
    /*margin-bottom: 36px;*/
    float: right;
    width: 11.7px;
    height: 11.7px;
}
.pushTextError {
    margin-top: 16px;
    margin-right: 8px;
    margin-bottom: 16px;
    margin-left: 8px;
    height: auto;
    font-size: 12px;
    line-height: 16px;
    flex: 1;
    font-family: 'VzNHGeTX-reg11';
    color: #000000 !important;
    flex: 1 !important;
}
.opt-container-1-1 {
    height: auto;
    margin-top: 46px;
    margin-left: 40px;
    margin-right: 40px;
}
.opt-header-1 {
    max-width: 382px;
    height: 72px;
    font-family: 'VzNHGeDS-bold11';
    font-size: 32px;
    color: #000000 !important;
}
.opt-p-1 {
    width: 298px;
    height: 20px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    color: #000000 !important;
}
.p-2 {
    /* width: 384px; */
    height: 52px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 24px;
    line-height: 26px;
}
.opt-username {
    width: auto;
    height: 24px;
    font-family: 'VzNHGeDS-bold11';
    font-size: 20px;
    margin-top: 4px;
    color: #000000 !important;
}
.opt-button-1 {
    width: 57px;
    height: 26px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    margin-top: 4px;
    margin-left: 12px;
    /*text-decoration: underline;*/
    border-bottom: 1px solid #000;
}
.opt-optionsTable {
    width: 384px;
    height: 321px auto;
    margin-right: 40px;
    margin-top: 48px;
}
.opt-title {
    width: 298px;
    height: 28px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 24px;
    margin-bottom: 8px;
    display: block;
}
.opt-sub-title {
    width: 298px;
    height: 36px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    display: block;
}
.opt-horizontal-1 {
    width: 384px;
    height: 1px;
    fill: solid;
    color: #D8DADA;
    margin-bottom: 23px;
    margin-right: 40px;
}
.anotherCls {
  cursor: pointer;
  font-family: VzNHGeDS-bold11;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #000;
}
.left_caret_spacing {
  padding-right: 4px !important;
}
.checkbox_line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.check_para{
  margin-top: 18px !important;
  margin-bottom: 4px !important;
}
/* .checkbox_input {
  margin: 0 10px !important;
} */
input[type="checkbox"].tc {
  outline: none !important;    
}
.check_box_border, .check_box_border:hover {
  outline: none !important;
  border: 1px solid #D8DADA !important;
}
.check_bg input:checked{
  accent-color: #000 !important;
}
.opt-container-2 {
    margin-left: 172px;
    margin-bottom: 20px;
}
.resend-field {
    /*width: 140px;*/
    height: 20px;
    /*text-decoration: underline;*/
    text-decoration: none !important;
    border-bottom: 1px solid #000;
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    margin-top: 16px;
    letter-spacing: 0.5px;
    display: inline-block;
}
.wait {
  margin-top: 12px;
  width: auto;
  height: 18px;
  font-family: 'VzNHGeDS-bold11';
  font-size: 16px;  
}
.opt-default-promo1{
  background: #F6F6F6;
  height: 650px;
  width: 464px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.opt-default-promo{
  height: 128px;
  width: 253.78px;
  color: #A7A7A7;
  font-family: 'VzNHGeDS-bold11';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.footer-margin-top {
  margin-top: 64px;
}
@media only screen and (min-width: 280px) and (max-width: 1200px) {
  .opt-default-promo {
    display: none;
  }
  .opt-default-promo1 {
    display: none;
  }
}

/* responsive styles  */
.timer_font{
  font-weight: 700 !important;
  padding-left: 2px;
  padding-right: 2px;
  color: #000000 !important;
}
.dynamic_para_div{
/*margin: 12px !important;*/ 
height: auto !important;
color: #000000 !important;

}
.user_id {
margin-top: 32px !important;
font-size: 14px;
color: #000000 !important;
}


.push_reg_container{
  display: flex;
  justify-content: center;
  /* margin-top: 48px; */
  /* margin-left: 18px; */
}
@media only screen and (min-width: 280px) and (max-width: 1200px) {
.opt-container-1,
.opt-container-2 {
  margin-left: 0 !important;
}
}
.opt-container-1{
  height: auto;
}

@media only screen and (min-width: 280px) and (max-width: 1200px) {
  .push_reg_container {
    display: flex;
    justify-content: center;
    /* margin-top: 48px; */
  }
}
@media only screen and (min-width: 400px) and (max-width: 500px) {

  /* .push_reg_container {
    margin-top: 0px !important;
  } */
  .opt-header-1{
font-size: 26px;
  }

  .opt-container-1 {
    margin-bottom: 0 !important;
    width: 420px !important;
    border: none !important;
  }
  .dynamic_para_div{
    font-size: 18px;
    width: 100% !important;
  color: #000000 !important;
  }
  .label_text {
    font-size: 16px !important;
    color: #000000 !important;

  }

}

@media only screen and (min-width: 320px) and (max-width: 399px) {
    .dynamic_para_div{
    font-size: 16px;
color: #000000 !important;
  }
 
  .opt-container-1 {
    margin-bottom: 0 !important;
    width: 344px !important;
    border: none !important;
  }
  /* .push_reg_container {
    margin-top: 0px !important;
  } */
.opt-header-1{
font-size: 22px;
  }
 
  .account_text_para{
    font-size: 16px;
    margin-bottom: 0 !important;
  }

  
  .opt-container-1-1{
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 36px !important; 
    
  }
  .label_text {
    font-size: 16px !important;
    color: #000000 !important;
  }

 
}
@media only screen and (min-width: 280px) and (max-width: 319px) {
    .dynamic_para_div{
    font-size: 14px;
color: #000000 !important;
  }
  .opt-container-1 {
    margin-bottom: 0 !important;
    width: 280px !important;
    border: none !important;
  }
  .opt-container-1-1 {
    margin-left: 0px;
     margin-right: 0px; 
    padding: 0 28px !important;
}
  
  /* .push_reg_container {
    margin-top: 0px !important;
  } */

  .opt-header-1{
font-size: 18px;
  }

  .label_text {
    font-size: 16px !important;
    color: #000000 !important;
  }
}
.label_text {
  margin-bottom: 0 !important;
  color: #000000 !important;
}
.incoming_msg_font{
  font-family: "VzNHGeTX-reg11" !important;
}
.mainIcon_desktop{
    display: block !important;
  }
.mainIcon_mobile{
    display: none !important;
  }
  .push_reg_container{
    margin-top: 48px;
  }
  .waitactive, .pushResendLink, .login_another_way{
    color: #000000 !important
  }
  
@media only screen and (max-width: 1023px) {
  .pwdPushRError{
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 12px;
    border-radius: 4px;
    display: flex;
  }
  .pwdPushRInfo{
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 12px;
    border-radius: 4px;
    display: flex;
  }
  .mainIcon_desktop{
    display: none !important;
  }
  .mainIcon_mobile{
    display: block !important;
  }
  
.container {
max-width: 100%;
}
.opt-container-1{
border: none !important;
width: 100% !important;
margin-bottom: 0px !important;
}
.opt-header-1{
max-width: 100% !important;
height: auto !important;
font-size: 24px !important;
line-height: 28px !important;
margin-top: 32px !important;
color: #000000 !important;
}
.opt-container-1-1{
margin: 0px !important;
padding-left: 16px !important;
padding-right: 16px !important;
}
/* .push_reg_container{
margin-top: 32px !important;
} */
.user_details{
margin: 24px 0px 48px !important;
}
.another_way_para{
margin-bottom: 32px !important;
}
.user_id{
font-size: 12px !important;
line-height: 28px !important;
color: #000000 !important;
margin-top: 24px !important;
font-family: 'VzNHGeTX-reg11' !important;
}
.opt-username{
font-size: 16px;
line-height: 20px;
letter-spacing: 0.5px;
color: #000000 !important;
}
.opt-button-1{
line-height: 20px !important;
letter-spacing: 0.5px !important;
margin-top: 0px  !important;
}
.p-2{
font-size: 20px !important;
line-height: 24px !important;
letter-spacing: normal !important;
color: #000000;
}
.wait{
font-size: 16px !important;
line-height: 20px !important;
letter-spacing: 0.5px !important;
color: #000000;
}
.resend-field{
margin-top: 12px !important;
}
.push_reg_container{
margin-top: 0px !important;
}
.check_box_border{
  margin-top: 0px !important;
}
}
</style>
