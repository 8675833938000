<template>
  <v-row class="mt-1">
    <v-col cols="10" xs="12"><h2 class="martop5 margin-bottom-medium">3. Set up your security profile</h2></v-col>
  </v-row>
</template>
<script>
export default {
  name: 'UserRegSetupSecProfComponent',
  components: {
  },
  data () {
    return {
    }
  }
}
</script>
<style scoped>
h2 {
    font-size: 20px;
    margin: 0 0 20px;
}
</style>
