<template>
    <v-row>
        <v-form>
              <v-row class="padleft15">
                <v-row class="padleft10 martop10">
                  <div class="text-left">
                    <v-dialog v-model="dialog" width="600">
                      <template v-slot:activator="{ on }">
                        <a class="vz-a" v-on="on" @click="resetPasswordOverlay()">{{$t("message.login_otp_page_reset_pd")}}</a>
                      </template>
                      <v-card>
                        <v-card-title primary-title>
                          <v-row style="width:100%">
                            <v-col xs="12">
                              <v-spacer></v-spacer>
                              <button type="button" class="close" aria-label="Close" @click="dialog = false">
				                        <span aria-hidden="true">×</span>
			                        </button>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col xs="12">
                              {{ dialogTitle }}
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>{{ dialogMsg }}</v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-row>
              </v-row>
        </v-form>
    </v-row>
</template>
<script>
import translations from '@/common/assets/json/messages1.json'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'DeviceOptionsNotAvailable',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions('authstepup', [
      'resetPassword'
    ]),
    resetPasswordOverlay() {
      this.resetPassword()
    }
  },
  computed: {
      ...mapGetters('authstepup', [
        'dialogMsg',
        'dialogTitle'
    ]),
    dialog: {
      get: function () {
        return this.$store.getters['authstepup/dialog']
      },
      set: function (newVal) {
        this.$store.dispatch('authstepup/updateShowDialog', newVal)
      }
    }
  },
  watch: {
  }
}
</script>
<style scoped>
h1 {
    margin: 4.6rem 0 2.50rem !important;
    font-size: 32px !important;
    font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", "Arial", "Helvetica", "sans-serif !important"
}
.v-row {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}

h2 {
  font-size: 20px;
  margin: 30px 0 20px;
}

h1, h2, h3, h4 {
  font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 100%;
}
.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

</style>
