var iamvar_serverUrl = '/';
var iamvar_newServerUrl = '/';
var iamvar_clientId = "VBG_IAM";
// var iamvar_enableCaptcha = true;
var iamvar_stepupPageUrl = iamvar_uiContext+'/business/login/stepup';
var iamvar_createNewSessionUrl = 'account/amsession/createnew'; // delete later
var iamvar_vbgcCookieName = 'vbgc';
var iamvar_vbgcCookieHeaderName = 'x-vz-vbgc';
var iamvar_vbgcCookieExpiryInMins = 15;
var iamvar_vbgcCookiePath = '/';
var iamvar_vbgcCookieSecure = true;

var iamvar_amSessionCookiePath = '/';
var iamvar_amSessionCookieSecure = '/';
var iamvar_amSessionCookieExpiryInMins = 120;
 
var iamvar_secProfileHeaderName = 'x-vbg-sec-profile-reg';
var iamvar_pwdResetStepupHeaderName = 'x-vbg-pwd-reset-stepup';
var iamvar_secProfileUrl = 'account/business/profile/preauth/intercept';
//var iamvar_debugEndpoint = '/account/debug/info?output=json';
var iamvar_authErrorHeaderName = 'x-vz-auth-error';
var iamvar_authRemEncrHeaderName = 'x-vz-user';
var iamvar_authRemDecrHeaderName = 'x-vz-rem-user';
var iamvar_vbgcRemCookieName = 'vbgremc';
var iamvar_biometricCookieName = 'x-vz-bm_en';
var iamvar_makeDefaultMethodName = 'defaultm';
var iamvar_vbgcRemCookieExpiryInYears = 1;
var iamvar_vbgcRemCookiePath = '/';
var iamvar_vbgcRemCookieSecure = true;
var vbgInfoMessage='x-vz-info-msg';
var vbgErrorMessage ='x-vz-error-msg';
var vbgSuccessMessage ='x-vz-success-msg';
var vbgWarnMessage ='x-vz-warn-msg';
var iamvar_vbgAccountAged = false; // delete
var iamvar_vbgAccountType = "user"; //delete
var iamvar_vbgUserHasPC = true; //delete

var iamvar_otpAlwaysOnHeaderName = 'x-vz-require-otp';
var iamvar_csqaAlwaysOnHeaderName = 'x-vz-require-cqa';
var iamvar_vcgSecProfileHeaderName = 'x-vcg-sec-profile-reg';
var iamvar_vcgRegIncompleteHeaderName = 'x-vz-cqa-not-set';
var iamvar_vcgRegIncompleteNoMtnHeaderName = 'x-vz-cqa-mtn-not-set';
var iamvar_vcgMigrationLoginToken = 'logintoken';
var iamvar_vcgMultipleAccount = 'x-vz-multiple-account';
var iamvar_Vcg_Throttled_On = 'x-vcg-throttled-on';

export {iamvar_serverUrl, iamvar_newServerUrl, iamvar_clientId, iamvar_secProfileHeaderName, iamvar_pwdResetStepupHeaderName, iamvar_amSessionCookieExpiryInMins, iamvar_amSessionCookiePath, iamvar_amSessionCookieSecure, iamvar_vbgUserHasPC, iamvar_vbgAccountType, iamvar_vbgAccountAged, iamvar_vbgcRemCookieSecure, iamvar_vbgcRemCookiePath, iamvar_vbgcRemCookieExpiryInYears, iamvar_vbgcRemCookieName, iamvar_makeDefaultMethodName, iamvar_authRemDecrHeaderName, iamvar_authRemEncrHeaderName, iamvar_authErrorHeaderName, iamvar_secProfileUrl, iamvar_stepupPageUrl, iamvar_createNewSessionUrl, iamvar_vbgcCookieName, iamvar_vbgcCookieHeaderName, iamvar_vbgcCookieExpiryInMins, iamvar_vbgcCookiePath, iamvar_vbgcCookieSecure, iamvar_otpAlwaysOnHeaderName, vbgInfoMessage, vbgErrorMessage, vbgSuccessMessage, vbgWarnMessage, iamvar_csqaAlwaysOnHeaderName, iamvar_vcgSecProfileHeaderName, iamvar_vcgRegIncompleteHeaderName, iamvar_vcgMigrationLoginToken, iamvar_vcgMultipleAccount, iamvar_vcgRegIncompleteNoMtnHeaderName, iamvar_Vcg_Throttled_On, iamvar_biometricCookieName};
