<template>
  <div>
    <div class="vbg-ff-root-container">
      <vpsHeader></vpsHeader>
      <div class="vbg-ff-content-container">
        <div v-if="alerts.length > 0 && !userValidationError && !userValidationContactAdminError">
          <div class="row" v-for="j in alerts" :key="j">
            <div class="col-sm-12">
              <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
                <div class="vbg-ff-msg-container">
                  <div class="messageWrapper" style="display: flex;">
                    <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                      <div class="messageImage" :class="messageImage(j)"></div>
                    </div>
                    <p class="messageContentUn col-xs-11" v-if="j.type == 'list'" v-html="j.msg"
                      style="padding:0px;margin:0px">{{j.msg}}</p>
                    <p class="messageContentUn col-xs-11" v-else-if="j.type == 'error' || j.type == 'warning'"
                      style="padding:0px;margin:0px" v-html="j.msg">{{j.msg}}</p>
                    <p class="messageContentUn col-xs-11" v-else style="padding:0px;margin:0px;color:#ffffff"
                      v-html="j.msg">{{j.msg}}</p>
                    <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                      <img class="closeMessageImg" v-if="j.type == 'list' || j.type == 'error' || j.type == 'warning'"
                        src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                      <img class="closeMessageImg" v-else src="@/common/assets/styles/corp/images/close_wh.png"
                        @click="deleteAlert(j)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vbg-ff-main-container">
          <div class="vbg-ff-title-container">
            <div v-if="this.whichRealm" class="">
              <h1>{{ $t("message.forgot_pd_general_title") }}</h1>
            </div>
            <div v-else class="">
              <h1>{{ $t("message.forgot_pd_general_title") }}</h1>
            </div>
          </div>
          <VPSForgotPwdUserIdComponent v-if="!userVerified"></VPSForgotPwdUserIdComponent>
          <VPSForgotPwdResetComponent v-if="userVerified && !resetPwdSuccess"></VPSForgotPwdResetComponent>
        </div>
      </div>
      <vpsFooter></vpsFooter>
    </div>
  </div>
</template>
<script>
import translations from '@/common/assets/json/messages1.json'
import VPSForgotPwdUserIdComponent from '@/vbgauth/components/vpsFlow/vpsForgotFlows/VPSForgotPwdUserIdComponent'
import VPSForgotPwdResetComponent from '@/vbgauth/components/vpsFlow/vpsForgotFlows/VPSForgotPwdResetComponent'
import { mapGetters, mapActions } from 'vuex'
import { setPageDetails, errorSet } from '../../../../../public/adobeTagging'
import commonMixin from '@/common/mixin/commonMixins'
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'

export default {
  name: 'VPSForgotPwdFormComponent',
  components: {
    VPSForgotPwdUserIdComponent,
    VPSForgotPwdResetComponent,
    vpsHeader,
    vpsFooter
  },
  data() {
    return {
      whichRealm: true
    }
  },
  mixins: [commonMixin],
  async created() {
    setPageDetails('VPS Forgot Password:Credentials');
  },
  mounted() {
    window.onpopstate = event => {
      this.$store.dispatch('cvsstepup/updateFpAlerts', [], { root: true })
      console.log("updateFpAlerts array is empty")
    };
    if ((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if (hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false
      }
    }
    if (document.cookie.indexOf('iamvar_realm') !== -1) {
      var iamvar_realmCookie = getCookie('iamvar_realm')
      if (iamvar_realmCookie == 'MOBILE') {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      let iamvar_appCookie = getCookie('X-VZ-C_APP')
      if (iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }
    if (this.getParameterByName("userverified") == 'true' || this.getParameterByName("userverified") == true) {
      this.updateUserVerified(true)
      this.updateIvrffStatus(true)
    }
    //In FFOtpcompoment - Route redirecting when user refresh the page
    window.localStorage.setItem("InitialRoute", window.location.href)
  },
  methods: {
    ...mapActions('forgotflow', [
      'updateUserVerified',
      'updateIvrffStatus'
    ]
    ),
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      if (index.type == 'error' || index.type == 'warning' || index.type == 'list') {
        return "newErrorMessageImage"
      } else {
        return index.type + "MessageImage";
      }
    },
    getCloseIcon() {
      return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
    },
    getAlertType(index) {
      errorSet(index.msg);
      window.scrollTo(0, 0);
      return index.type
    },
    deleteAlert(alert) {
      this.alerts.splice(this.alerts.indexOf(alert), 1)
    }
  },
  computed: {
    ...mapGetters('forgotflow', [
      'userVerified',
      'resetPwdSuccess'
    ]),
    ...mapGetters('cvsstepup', [
      'userValidationError',
      'userValidationContactAdminError'
    ]),
    ...mapGetters('login', [
      'urlOnMount'
    ]),
    alerts: {
      get: function () {
        // return this.$store.getters['forgotflow/alerts']
        return this.$store.getters['cvsstepup/fpAlerts']
      },
      set: function (newVal) {
        this.$store.commit('forgotflow/setalert', newVal)
      }
    }
  },
  watch: {
    resetPwdSuccess: function (newVal) {
      if (newVal) {
        // redirecting to the login pages based on the urlOnMount

        if (!this.urlOnMount) {
          this.$router.push({ path: '/login/vpsunifiedlogin' })
        } else {
          const urlStr = '/account/business'
          if (this.urlOnMount.includes(`${urlStr}/login/vpsunifiedlogin`)) {
            const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
            if (gotoURL) {
              this.$router.push({ path: '/login/vpsunifiedlogin', query: { goto: gotoURL } })
            } else {
              this.$router.push({ path: '/login/vpsunifiedlogin' })
            }

          } else if (this.urlOnMount.includes(`${urlStr}/login/pwdlesslogin`)) {
            const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
            if (gotoURL) {
              this.$router.push({ path: '/login/pwdlesslogin', query: { goto: gotoURL } })
            } else {
              this.$router.push({ path: '/login/pwdlesslogin' })
            }

          } else if (this.urlOnMount.includes(`${urlStr}/login/mobile`)) {
            const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
            if (gotoURL) {
              this.$router.push({ name: 'MobileLoginComponent', query: { goto: gotoURL } })
            } else {
              this.$router.push({ name: 'MobileLoginComponent' })
            }
          } else if (this.urlOnMount.includes(`${urlStr}/ilogin`)) {
            const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
            if (gotoURL) {
              this.$router.push({ name: 'iLoginComponent', query: { goto: gotoURL } })
            } else {
              this.$router.push({ name: 'iLoginComponent' })
            }

          } else if (this.urlOnMount.includes(`${urlStr}/login`)) {
            const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
            if (gotoURL) {
              this.$router.push({ name: 'SimpleLoginRoot', query: { goto: gotoURL } })
            } else {
              this.$router.push({ name: 'SimpleLoginRoot' })
            }

          } else {
            this.$router.push({ path: '/login/vpsunifiedlogin' })
          }
        }
      }
    },
  }
}
</script>
<style scoped>
.alert-warning {
  background-color: #ffbc3d;
  background-size: 35px 29px;
  color: #000;
  border-color: #ffbc3d;
}

.alert-info {
  background-color: #08c;
  background-size: 35px 29px;
  color: #fff;
  border-color: #08c;
}

.messageContentUn {
  color: #000000;
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  line-height: 20px;
}

.vbg-ff-main-container {
  padding: 40px 20px !important;
}

.vbg-ff-title-container {
  color: #000000 !important;
}

/*.forgotPswd-conatiner{
  width:600px;
}*/
/*@media  (min-width: 768px) and ( max-width: 1024px) {
  .forgotPswd-conatiner{
  width:100% !important;
  }
}
@media  (min-width: 350px) and ( max-width: 550px){
 .forgotPswd-conatiner{
  width:100% !important;
  }
}*/
/* for Oauth flows */
@media (min-width: 280px) {
  .closeMessageImg {
    position: static !important;
    width: 13px;
    height: 13px;
  }
}

@media (min-width: 280px) and (max-width: 1024px) {
  .oauth-flow .forgotPswd-conatiner {
    width: 100% !important;
    max-width: 100% !important;
  }

  .martop60 {
    margin-top: 0 !important;
  }

  .vbg-ff-main-container {
    padding: 32px 16px !important;
  }

  .vbg-ff-title-container {
    margin-bottom: 8px;
  }

  /*.forgotPswd-conatiner{
  width:100% !important;
  }*/
}

@media (max-width: 1024px) {
  .messageContent {
    flex: 1;
  }

  .col-xs-12.col-md-6.forgotPswd-conatiner {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.bold75 {
  font-family: VzNHGeDS-bold11;
}

@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
}

@media (max-width: 425px) {

  .messageContent,
  .messageContentUn {
    width: 232px;
  }
}

@media (max-width: 1024px) {
  .col-xs-12.col-md-6 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .md-none {
    display: none;
  }

  h1 {
    font-size: 24px !important;
  }
}
</style>
