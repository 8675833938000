<template>
    <div>
    <router-view></router-view>
    </div>
</template>
<script>
export default {
  name: 'ContainerComponent',
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>
<style scoped>
</style>
