<template>
  <v-row class="vz-forgot-flow">
      <v-col xs="0" sm="2"></v-col>
      <v-col cols="12" xs="12" sm="8" class="px-4">
        <v-row>
          <h1 style="width:100%">Welcome</h1>
          <div class="mb-4">At Verizon, we value your privacy and security. Since this is your first time signing in, you willl be taken through the enrollment and account security process.</div>
        </v-row>
        <v-row class="blackbar my-0"></v-row>
        <v-row class="mt-1">
          <v-col cols="10" xs="10"><h2 class="martop5 margin-bottom-medium">1. Validate your identity</h2></v-col>
          <v-col xs="2"><p class="ptiny margin-bottom-large">*Required field</p></v-col>
		    </v-row>
        <UserRegValidateComponent></UserRegValidateComponent>
        <v-row class="martop20 marbottom0 blackbarthin"></v-row>
        <UserRegSetupAccComponent></UserRegSetupAccComponent>
        <v-row class="martop20 marbottom0 blackbarthin"></v-row>
        <UserRegSetupSecProfComponent></UserRegSetupSecProfComponent>
      </v-col>
      <v-col xs="0" sm="2"></v-col>
    </v-row>
</template>
<script>
import UserRegValidateComponent from '@/vbgauth/components/userRegistrationFlow/UserRegValidateComponent'
import UserRegSetupAccComponent from '@/vbgauth/components/userRegistrationFlow/UserRegSetupAccComponent'
import UserRegSetupSecProfComponent from '@/vbgauth/components/userRegistrationFlow/UserRegSetupSecProfComponent'
export default {
  name: 'UserRegMainComponent',
  components: {
    UserRegValidateComponent,
    UserRegSetupAccComponent,
    UserRegSetupSecProfComponent
  },
  data () {
    return {
    }
  }
}
</script>
<style scoped>
h2 {
    font-size: 20px;
    margin: 0 0 20px;
}
</style>
