<template>
    <div>
      <div>
        <div class="mb-24">
          <div class="new_pwdless_main_heading pwdles_main_heading">
            <h1>{{ $t("message.forgot_username_general_title") }}</h1>
          </div>
          <div v-if="userIdFlow == 'SIMPLE' || userIdFlow == 'COMPLEX'" class="mt-16">
            <p>{{ $t("message.new_forgot_username_additional_info") }}</p>
          </div>
        </div>
          <div
            v-if="!this.whichRealm && this.userVerified"
            class="blackbar martop20"
          ></div>
          <div v-if="userVerified"><br /><br /><br /></div>
          <!-- <div v-else class="blackbar martop20"></div> -->
          <!-- <div>
                    <v-spacer></v-spacer><p v-if="!userVerified" class="ptiny">{{ $t("message.all_fields_required") }}</p>
                </div> -->
          <NewAMForgotUsernameUserDetailsComponent
            v-if="userIdFlow == 'BASIC'"
          ></NewAMForgotUsernameUserDetailsComponent>
          <NewAMForgotUserIDInfo
            v-if="userIdFlow == 'SIMPLE' || userIdFlow == 'COMPLEX'"
          ></NewAMForgotUserIDInfo>
          <!-- <SignInButtonComponent v-if="userVerified"></SignInButtonComponent> -->
        
        <!--<div v-if="this.whichRealm" class="col-xs-0 col-md-5"></div>-->
      </div>
    </div>
  </template>
  <script>
  import NewAMForgotUsernameUserDetailsComponent from "./NewAMForgotUsernameUserDetailsComponent.vue";
  import NewAMForgotUserIDInfo from "./NewAMForgotUserIDInfo.vue";
  import { mapGetters, mapActions } from "vuex";
  import translations from "@/common/assets/json/messages1.json";
  import { setPageDetails } from "../../../../../public/adobeTagging";
  export default {
    name: "NewAMForgotUsrnmComponent",
    components: {
        NewAMForgotUsernameUserDetailsComponent,
      NewAMForgotUserIDInfo,
    },
    data() {
      return {
        whichRealm: true,
      };
    },
    async created() {
      setPageDetails("Forgot Username:Credentials");
    },
    mounted() {
      this.fuAlerts.splice(0, 1);
      let appUrl = this.$route.query.appUrl;
      let gotoUrl = this.$route.query.goto;
  
      if (this.urlOnMount != null || this.urlOnMount != undefined) {
        const hostUrl = this.urlOnMount;
        if (hostUrl.includes("/account/business/login/mobile")) {
          this.whichRealm = false;
        }
      }
      if (document.cookie.indexOf("iamvar_realm") !== -1) {
        var iamvar_realmCookie = getCookie("iamvar_realm");
        if (iamvar_realmCookie == "MOBILE") {
          this.whichRealm = false;
        }
      }
      if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
        let iamvar_appCookie = getCookie("X-VZ-C_APP");
        if (iamvar_appCookie == "MVB") {
          this.whichRealm = false;
        }
      }
  
      if (appUrl != undefined) {
        if (gotoUrl != undefined) {
          // encode gotoUrl before appending it to urlOnMount
          gotoUrl = encodeURIComponent(gotoUrl);
          this.$store.dispatch(
            "cvsstepup/updateUrlOnMount",
            appUrl + "?goto=" + gotoUrl
          );
        } else this.$store.dispatch("cvsstepup/updateUrlOnMount", appUrl);
      }
  
      if (gotoUrl != undefined) {
        this.$store.dispatch("cvsstepup/updateGotoUrl");
      }
      window.onpopstate = function () {
        window.history.forward();
      };
  
      // old code
      // if (this.urlOnMount != null || this.urlOnMount != undefined) {
      //   const hostUrl = this.urlOnMount;
      //   if (hostUrl.includes("/account/business/login/mobile")) {
      //     this.whichRealm = false;
      //   }
      // }
      // if (document.cookie.indexOf("iamvar_realm") !== -1) {
      //   var iamvar_realmCookie = getCookie("iamvar_realm");
      //   if (iamvar_realmCookie == "MOBILE") {
      //     this.whichRealm = false;
      //   }
      // }
      // if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      //   let iamvar_appCookie = getCookie("X-VZ-C_APP");
      //   if (iamvar_appCookie == "MVB") {
      //     this.whichRealm = false;
      //   }
      // }
      // //In FFOtpcompoment - Route redirecting when user refresh the page
      // window.localStorage.setItem("InitialRoute", window.location.href);
    },
    beforeDestroy() {
      if (this.whichRealm == false && this.userVerified) {
        window.location.href = iamvar_mvbLaunchUrl;
      }
    },
    methods: {
      getMsgIcon(index) {
        // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
        return "@/common/assets/styles/corp/images/" + index.type + ".png";
      },
      messageImage(index) {
        if (
          index.type == "error" ||
          index.type == "warning" ||
          index.type == "list"
        ) {
          return "newErrorMessageImage";
        } else {
          return index.type + "MessageImage";
        }
      },
      getCloseIcon() {
        return (
          iamvar_cdnUrl +
          iamvar_imgSourceContext +
          "/business/login/includes/img/close_white.png"
        );
      },
      deleteAlert(alert) {
        this.fuAlerts.splice(this.fuAlerts.indexOf(alert), 1);
      },
      getAlertType(index) {
        return index.type;
      },
    },
    computed: {
      ...mapGetters("forgotflow", ["userVerified"]),
      ...mapGetters("cvsstepup", ["urlOnMount", "userIdFlow","flowType"]),
      fuAlerts: {
        get: function () {
          return this.$store.getters["cvsstepup/fuAlerts"];
        },
        set: function (newVal) {
          this.$store.commit("cvsstepup/setFuAlert", newVal);
        },
      },
    },
    watch: {
      /* userVerified: function(newVal) {
        if(newVal) {
          this.$router.push({ name: 'AMForgotUsrnmResultComponent'}).catch(err => {throw err})
        }
      }*/
    },
  };
  </script>
  <style scoped>
  .forgot-flow-container {
    min-height: 95vh;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  @media (max-width: 767px) {
    .forgot-flow-container {
      min-height: 80vh;
    }
  }
  #tsd-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* old code */
  .messageContentUn {
    color: #000000;
    font-size: 16px;
    /* font-weight: bold; */
    letter-spacing: 0.5px;
    line-height: 20px;
  }
  @media (max-width: 320px) {
    .messageWrapper {
      display: flex;
    }
  }
  @media (max-width: 425px) {
    .messageContent,
    .messageContentUn {
      width: 232px;
    }
  }
  @media (max-width: 1024px) {
    .col-xs-12.col-md-6 {
      max-width: 100% !important;
      width: 100% !important;
    }
    .md-none {
      display: none;
    }
    h1 {
      font-size: 24px !important;
    }
  }
  </style>
  