<template>
  <div>
    <div class="cnHeader">
      <img class="cnHlogo" src="@/common/assets/styles/corp/images/vzlogoLarge.png" />
    </div>
    <div class="cnRectangle"></div>
  </div>
</template>
<script>
import { iamvar_serverUrl } from "../../../public/const.js";
import { mapGetters } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import vzlogo from "@/common/assets/styles/corp/images/vzlogoLarge.png";
export default {
  name: "CombinenowHeader",
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      vzlogo: vzlogo
    };
  },
  methods: {
    getLogo() {
      return (
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/img/vzlogoLarge.21ecaab0.png"
      );
    }
  }
};
</script>
<style scoped>
.cnHeader {
  height: 48px;
  width: 208px;
  position: absolute;
  top: 10px;
}
.cnRectangle {
  box-sizing: border-box;
  height: 63px;
  /* width: 1440px; */
  border-bottom: 1px solid #d8dada;
}
.cnHlogo {
  display: block;
  float: left;
  width: 160px;
  position: absolute;
  left: 104px;
}
@media (max-width: 600px) {
  .cnHlogo {
    left: 0;
    margin-left: 15px;
  }
}
</style>