<template>
  <div style="font-family:'VzNHGeDS-reg11'">
    <BlockUI v-if="submitLoader" :url="url"></BlockUI>
    <form id="loginBiz" class="signin" @submit.prevent="submitLoginForm">
      <div class="user-id">
        <label for class="-input-label">User ID</label>
        <input class="input-f" type="text" required v-model="formData[0].input[0].value" maxlength="60"/>
        <p class="forgot-link" @click="forgotPopup('forgotUserId')">
          Forgot user ID?
        </p>
      </div>
      <div class="user-id">
        <label for class="-input-label">Password</label>
        <input class="input-f" :type="show1 ? 'text' : 'password'" required v-model="formData[1].input[0].value" maxlength="20"/>
        <span class="showBtn" v-if="!show1 && formData[1].input[0].value" @click="show1 = !show1">Show</span>
        <span class="showBtn" v-if="show1 && formData[1].input[0].value" @click="show1 = !show1">Hide</span>
        <p class="forgot-link" @click="forgotPopup('forgotUserPassword')">
          Forgot password?
        </p>
      </div>
      <div>
        <p>
          <input class="tc" type="checkbox" id="ch" v-model="rememberMe"/>
          <label for="ch">Remember my user ID</label>
        </p>
      </div>
      <div>
        <button
          class="btn primary_black"
          style="width: 100%; margin: 35px 0px 24px;font-size: 16px !important; font-family: 'VzNHGeDS-bold11';"
          type="submit"
        >
          Log in
        </button>
      </div>
      <p>
        <label
          >Need access? <a :href="supportUrl" class="register-link" style="text-decoration:none;"><b style="font-family:VzNHGeDS-bold11">Register for an account</b>&nbsp;<img
            style="width: 10px; height: 14px"
            :src="caret"
        /></a></label>
      </p>
      <hr class="vz-horizontal" />
      <div>
        <p class="vz-login-link" style="margin-bottom: 16px">
          <a :href="resendWelcomeEmail">Resend welcome email for MyBusiness Wireless</a>
        </p>
        <p class="vz-login-link" style="margin-bottom: 16px">
          <a @click="forgotPopup('payWithoutLogin')">Pay without logging in</a>
        </p>
        <p class="vz-login-link" style="margin-bottom: 16px">
          <a :href="quick_login">Complete quick tasks without logging in</a>
        </p>
        <p class="vz-login-link" style="margin-bottom: 16px">
          <a :href="Mgmt_portal_login"> Manage additional portals</a>
        </p>
        <p class="vz-login-link" style="margin-bottom: 36px"><a :href="vcg_personal_login">Log in to your personal account</a></p>
      </div>
    </form>
    <div>
      <div id="noAccessToOtpModal" class="modal fade">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" :class="checkModal" :style="gethelp">
            <!--<a
              class="col-xs-1 pull-right close-icon-tc"
              data-dismiss="modal"
              style="background-size: 20px; background-position-x: -2%;	background-position-y: 0px;	margin: 20px 20px 0px 0px;padding: 0px; top: -20px; height: 74px; width: 84px"
              @click="closeCombinenow()"
            ><img src="@/common/assets/styles/corp/images/close_black.png" /></a>-->
            <div class="close-icon-container">
              <a
                data-dismiss="modal"
                @click="closeCombinenow()"
              >
                <img src="@/common/assets/styles/corp/images/close_black.png" style="height:20px; width: 20px;" />
              </a>
            </div>
            
            <pop-up-component></pop-up-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import PopUpComponent from "@/vbgauth/components/hybridExp/popups/PopUpComponent";
import { mapGetters, mapActions } from "vuex";
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import commonMixin from '@/common/mixin/commonMixins';
import $ from "jquery";
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
export default {
  name: "UsePassword",
  components: { PopUpComponent,InvisibleRecaptcha },
  mixins: [commonMixin],
  data() {
    return {
      caret: caret_blk,
      //url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      userId: '',
      userPassword: '',
      rememberMe: false,
      canSubmit: false,
      captchaOn: true,
      SignUpUrl:'',
      vec2Registration: vec2_ent_portal_base_url,
      vcg_personal_login : iamvar_vcg_login,
      wireline_login : iamvar_fios_login,
      quick_login : iamvar_Quick_links,
      Mgmt_portal_login : iamvar_Mgmt_Portals,
      resendWelcomeEmail: iamvar_resendWelcomeEmail,
      show1: false
    };
  },
  async created() {
      // setPageDetails('Login:landing');
      this.getLoginCaptchaEnabledEnvVar();
      this.checkForCaptchaOffConditions();
  },
  mounted() {
      this.gotoUrl = this.$route.query.goto;
      if (this.gotoUrl != null) {
        // determine if there is a goto with a hashtag
        let hashValue = window.location.hash.substr(1);
        if (hashValue != "") {
          let hashInUrl = '#' + window.location.hash.substr(1);
          if (this.gotoUrl != null) {
            this.gotoUrl += hashInUrl;
            this.$store.dispatch('login/updateGotoUrl', this.gotoUrl);
          }
        }
      }
      this.removeExistingVBGCCookie();
      this.getSessionInfo();
      this.updateUrlOnMount(window.location.href);
      this.resetState();

      // call rememberMe function only if uid query parameter is not present
      // let params = new URLSearchParams(window.location.search);
      if(!this.getParameterByName('uid')) {
          this.callRememberMe();
      }
      this.processQueryParameters();
      // console.log('idm url in tsd ', iamvar_idmUrl);             
      if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='mdp_agent'){
          this.SignUpUrl=iamvar_mdpSignUpUrl         
      }
      else if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='tsd_agent'){
          this.SignUpUrl=iamvar_thingSpaceSignUpUrl
      }
      else if(this.getParameter('appGroup')==='TSM' || this.getParameter('client_id')==='tss_agent_gz'){
            this.SignUpUrl=iamvar_ccSignUpUrl
      }
      else{
          this.SignUpUrl=iamvar_thingSpaceSignUpUrl
      }
      
  },
  beforeDestroy(){
      this.formData[0].input[0].value = "";
      this.formData[1].input[0].value = "";
  },
  computed: {
    ...mapGetters('login', [
        'authError',
        'submitLoader',
        'iamvar_enableCaptcha',
        'redirectToStepupPage',
        'redirectToSecProfilePage',
        'iamvar_idmUrl',
        'loginCaptchaKey',
        'getAlertType',
        'popupValue',
        'getInterstitialPage',
        'getUserSelectedAccount',
        'getUnifiedErrorCode',
        'rememberMeTrue',
        'combinenowValue',
        'remembermeUserName'
    ]),
    ...mapGetters('cvsstepup', [ 'currentStep' ]),
    ...mapGetters('forgotflow',[ "usrName" ]),
  supportUrl(){
    let isGoToPresent = window.location.href.split('goto=');
    if(isGoToPresent.length>1){
      let registration_url = this.vec2Registration.split('#')
      return registration_url[0]+'?goto='+isGoToPresent[1]+'#'+registration_url[1]
    }
    return this.vec2Registration;
  },
    isFormComplete() {
        // return this.userId && this.userPassword;
        return this.formData[0].input[0].value.length !== 0 &&
                this.formData[1].input[0].value.length !== 0;
    },
    formData: {
        get: function () {
            return this.$store.getters['login/formData'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/updateFormData', newVal);
        }
    },
    gotoUrl: {
            get: function () {
                return this.$store.getters['login/gotoUrl'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateGotoUrl', newVal);
                this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
            }
    },
    checkModal() {
      if(this.popupValue == 'forgotUserId' || this.popupValue == 'forgotUserPassword') {
        return 'modal-content-forgot';
      } else if(this.popupValue == 'interstitial' && (this.getUnifiedErrorCode.includes('MYBIZ') && this.getUnifiedErrorCode.includes('VEC'))) {
        return 'modal-content-hybrid';
      } else if(this.popupValue == 'interstitial' && (this.getUnifiedErrorCode.includes('MYBIZ') || this.getUnifiedErrorCode.includes('VEC'))) {
        return 'modal-content-int'
      }else if(this.popupValue == 'payWithoutLogin') {
        return 'modal-content-pwl';
      }else if(this.popupValue == 'combinenowPopupVal'){
        return 'modal-content-cn'
      }else if(this.popupValue == 'gethelp'){
        return 'modal-content-gethelp'
      } else {
        return ''
      }
     // return [{ 'modal-content-pwl': this.popupValue == 'payWithoutLogin' ? true : false }];
    },
    gethelp(){
      if(this.popupValue == 'gethelp') {
        return 'overflow: auto'
      }
    }
  },
  methods: {
    ...mapActions('login', [
            'submitAMLoginForm',
            'callRememberMe',
            'removeExistingVBGCCookie',
            'getSessionInfo',
            'updateUrlOnMount',
            'resetState',
            'updateCaptchaToken',
            'updateFederalPortalIndicator',
            'UpdatePopupValue',
            'smbFormSubmit',
            'selectAccount',
            'sendCombinenowValue',
            'updateMergeStatus'
        ]
    ),
    ...mapActions('cvsstepup', [ 'initializeStepup', 'updateUserIdFlow' ]),
    submitLoginForm: function (recaptchaToken) {
        let payload = {
            formData: this.formData,
            rememberUser: this.rememberMe
        };
        
        // update state with new captcha token
        if(this.captchaOn)
            this.updateCaptchaToken(recaptchaToken);

        this.submitAMLoginForm(payload);
        
        // might not be needed since submit button is disabled when form is empty
        /*if(this.$refs.loginForm.validate()) {
            this.submitAMLoginForm(payload);
        }*/

        // TODO: implement valid/invalid logic. For right now, redirect to OTP regardless of result
        // this.$router.push({ path: '/vbg-tsd-login/otp' });
       // this.forgotPopup('combinenowPopupVal');
    },
    getParameter(url) {
        let decodeUrl=decodeURIComponent(document.location)
        url = url.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + url + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(decodeUrl);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    processQueryParameters() {
        // let params = new URLSearchParams(window.location.search);

        if(this.getParameterByName('uid')) {
            this.formData[0].input[0].value = this.getParameterByName('uid');
        }
        if(this.getParameterByName('rememberMe')) {
            if(this.getParameterByName('rememberMe') == 'true')
                this.rememberMe = true;
        }
    },
    getLoginCaptchaEnabledEnvVar() {
        console.log('(Env based) captcha is on = ', iamvar_enableLoginCaptcha);
        this.captchaOn = iamvar_enableLoginCaptcha;
    },
    checkForCaptchaOffConditions() {
        // turn off captcha when instance is related to a federal portal
        let gotoParam = this.getParameterByName('goto');

        // if goto has 'oauth', get redirect_uri and check if redirect_uri has any of the federalPortalKeywords
        if(gotoParam != null && gotoParam.includes('oauth')) {

            // decode goto in order to easily access redirect_uri parameter
            let decodedGoto = decodeURIComponent(gotoParam);
            // let redirectUriParam = this.getParameterByName('redirect_uri');
            let decodedParameters = new URLSearchParams(decodedGoto);
            let redirectUriParam = decodedParameters.get('redirect_uri');

            if(iamvar_federalPortalKeyWords.some(str => redirectUriParam.includes(str))) {
                this.captchaOn = false;
                this.updateFederalPortalIndicator(true);
            }
        } else { // check if goto has any of the federalPortalKeywords
            if(iamvar_federalPortalKeyWords.some(str => gotoParam.includes(str))) {
                this.captchaOn = false;
                this.updateFederalPortalIndicator(true);
            }
        }
    },
    
    forgotPopup(value) {
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
      console.log("value here is..", value);
      this.UpdatePopupValue(value);
      var eventPayload = {}
      var eventName = null
      if(value == 'forgotUserId' || value == 'forgotUserPassword') {
        eventName = value == 'forgotUserId' ? 'forgot_user_id' : 'forgot_password'
        eventPayload = {
          "accounts": {
            "1": "My Business Wireless",
            "2": "Verizon Enterprise Center",
            "3": "My Business Wireline Fios, phone, internet & TV",
          }
        }
        eventTracking(eventName,eventPayload)
      } else if(value == 'payWithoutLogin') {
        eventName = 'pay_without_logging_in'
        eventPayload = {
          "accounts": {
            "1": "My Business Wireless",
            "2": "My Business Wireline Fios, phone, internet & TV",
            "3": "Verizon Enterprise Center"
          }
        }
        eventTracking(eventName,eventPayload)
      }
    },
    closeCombinenow() {
      if(this.popupValue == 'combinenowPopupVal') {
        this.updateMergeStatus('closePopup')
      }
    }
  },
  watch: {
      remembermeUserName: function(newval) {
        if(newval != "" || newval != null) {
          console.log("remembermeUserName=", newval)
          this.formData[0].input[0].value = newval
        }
      },
      usrName: function(newVal) {
        if(newVal != undefined || newVal != null) {
          console.log("username=",newVal)
          this.formData[0].input[0].value = newVal
        }
      },
      show1: function(newVal) {
        if(newVal){
          setTimeout(() => {
            this.show1 = false
          },10000)
        }
      },
      redirectToStepupPage: function(newVal) {
          if(newVal) {
              this.initializeStepup();
          }
      },
      rememberMeTrue: function(newVal) {
        if(newVal == 'setToTrue') {
          this.rememberMe = true
        }
      },
      redirectToSecProfilePage: function(newVal) {
          if(newVal) {
              window.location.href = newVal;
          }
      },
      currentStep: function(newVal) {
          if(newVal === 'FLOW_INITIALIZED') {
              if(this.gotoUrl != null) {
                  this.$router.push({ name: 'FFStepupRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
              } else {
                  this.$router.push({ name: 'FFStepupRootComponent' });
              }
          }
      },
      getInterstitialPage: function(newVal) {
          if(newVal) {
              this.forgotPopup('interstitial')
              this.$store.commit('login/setInterstitialPage', false)
          }
      },
      getUserSelectedAccount: function(selectedAccount){
        $("#noAccessToOtpModal").modal('hide')
          if(selectedAccount && this.popupValue=='interstitial'){
             let payload = {
                formData: this.formData,
                rememberUser: this.rememberMe,
                additionalAuth : "VBG_LOGIN"
             };
             if(this.getUnifiedErrorCode.includes("INVALID")){
                if (selectedAccount == "wireline" && this.getUnifiedErrorCode.includes("INVALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if (selectedAccount == "wireless" && this.getUnifiedErrorCode.includes("INVALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if(selectedAccount == "fios" && this.getUnifiedErrorCode.includes("INVALID")){
                    payload['additionalAuth'] = "INVALID_SMB_LOGIN"
                     // to do - "authenticate" call with additional header parameter as "INVALID_SMB_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }
             }else{
                if(selectedAccount == "fios" && this.getUnifiedErrorCode.includes("VALID")){
                    this.smbFormSubmit({username:this.formData[0].input[0].value,password:this.formData[1].input[0].value,rememberUser:this.rememberMe})
                }else if (selectedAccount == "wireline" && this.getUnifiedErrorCode.includes("VALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }else if (selectedAccount == "wireless" && this.getUnifiedErrorCode.includes("VALID")){
                    // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                    this.submitAMLoginForm(payload);
                }
             }
          }else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserId'){
            this.$router.push({ path: "/login/recovery/forgotusername" });
            this.updateUserIdFlow('BASIC')
          }else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserPassword'){
            this.$router.push({ path: "/login/recovery/forgotpassword" });
          }else if(selectedAccount=='fios' && this.popupValue=='forgotUserPassword'){
            let fiosURLFP = '';
            let gotoURLFP = this.getParameterByName('goto');
            if(gotoURLFP) {
              // Added goto url to fios url
              fiosURLFP = `${iamvar_fios_fp_url}?goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(gotoURLFP)}&clientId=vbm`
            } else {
              fiosURLFP = iamvar_fios_fp_url+'?clientId=vbm'
            }
            window.location.href = fiosURLFP;
          }else if(selectedAccount=='fios' && this.popupValue=='forgotUserId'){
            let fiosURLFU = '';
            let gotoURLFU = this.getParameterByName('goto');
            if(gotoURLFU) {
              // Added goto url to fios url
              fiosURLFU = `${iamvar_fios_fu_url}&goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(gotoURLFU)}`
            } else {
              fiosURLFU = iamvar_fios_fu_url
            }
            window.location.href = fiosURLFU
          }else if(selectedAccount=='wireless' && this.popupValue=='payWithoutLogin'){
            window.location.href = iamvar_mbt_base_url+'/mbt/manageservice/payments.go/#/billpay/onetimepayment'
          }else if(selectedAccount=='fios' && this.popupValue=='payWithoutLogin'){
            window.location.href = this.wireline_login
          }else if(selectedAccount=='wireline' && this.popupValue=='payWithoutLogin'){
            window.location.href = iamvar_vec_quickpay
          }

          // to reset initial value
        this.selectAccount('null')
      },
      popupValue: function(newVal) {
        if(newVal == 'combinenowPopupVal') {
          this.forgotPopup(newVal)
        }
      }
      
  }
};
</script>
<style scoped>
.showBtn{
  position: absolute;
  right: 12px;
  margin-top: 12px;
  text-decoration: underline;
  cursor: pointer;
  height: 20px;
  color: #000000;
  font-family: "Verizon NHG eDS";
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
#noAccessToOtpModal {
  z-index: 105000000;
}
@media (max-width: 330px){
  .register-link{
    white-space: pre;
  }
}

</style>
