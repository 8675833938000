<template>
  <div v-if="isActive" class="tab-content">
    <slot></slot>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
mapGetters;
export default {
  name: "VbgTab",
  props: {
    name: { type: String, required: true },
  },
  computed: {
    ...mapGetters("dashboard", ["getActiveTab"]),
    isActive() {
      return this.getActiveTab == this.name;
    },
  },
};
</script>