<template>
    <v-row class="margin-top-large padleft0 col-xs-12">
              <p>{{ $t("message.otp_validate_directions") }}</p>
              <v-form class="padleft15 col-xs-12">
                <v-row class="form-group padright0 padleft0 col-xs-12 col-sm-7 col-md-5">
                  <label class="labeltiny" for="otpCode">{{ $t("message.login_otp_page_passcode_label") }} *</label>
                  <input id="otpCode" name="otpCode" v-model="otpCode" type="password" class="form-control vz-input-field" v-bind:class="otpValidationInputClass" autocomplete="off" :disabled="!otpSent">
                </v-row>
                <v-row class="padleft0 col-xs-12">
                  <v-row v-if="dynamicErrorClasses.otpCode.fieldErrorMsg == ''" style="margin-top:22px;">
                  </v-row>
                  <p class="pmicro" v-if="dynamicErrorClasses.otpCode.fieldErrorMsg != ''">{{dynamicErrorClasses.otpCode.fieldErrorMsg}}
                  </p>
                </v-row>
                <v-row class="padleft0 col-xs-12"></v-row>
                <v-row class="padleft0 col-xs-12 col-sm-7 col-md-5 margin-bottom-medium padding-top-small">  
                  <button type="button" class="btn btn_primary_black" :disabled="!otpSent || !otpCode" v-if="otpAlwaysOn || fingerprintSaved" @click="validateOtpCode(false)">{{$t("message.otp_verify")}}</button>
                  <button type="button" class="btn btn_primary_black marright20" :disabled="!otpSent || !otpCode" v-if="!otpAlwaysOn && !fingerprintSaved" @click="validateOtpCode(true)">{{$t("message.otp_verify")}}{{$t("message.login_register_device")}}</button>
                  <span v-if="!isInFrame">
                    <button @click="cancel()" style="padding:12px 35px;line-height:0;" class="btn btn-default margin-left-medium" type="button">{{$t("message.cancel")}}</button>
                  </span>
                </v-row>
              </v-form>
              <v-row class="padleft15 col-xs-12 padding-bottom-large">
                <v-row v-if="otpCode" class="padleft15">
                  <a class="vz-a" @click="$event.preventDefault();validateOtpCode(false);" href="#">{{$t("message.otp_verify")}}{{$t("message.login_dont_register_device")}}</a>
                  <span>
                    <button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;" v-tooltip="content3"></button>
                  </span>
                </v-row>
              </v-row>
              <v-row class="padleft15 col-xs-12 marbottom40">
                <v-row v-if="otpSent" class="padleft15">
                  <a class="vz-a" @click="$event.preventDefault();resendPasscode();" href="#">{{$t("message.login_otp_page_resend_passcode")}}</a>
                </v-row>
              </v-row>
              <v-row class="padleft15 col-xs-12 marbottom40">
                <v-row class="padleft15">
                  <div class="text-left">
                    <v-dialog v-model="dialog" width="600">
                      <template v-slot:activator="{ on }">
                        <a class="vz-a" v-on="on" @click="openUnableReceiveOverlay()">{{$t("message.login_otp_page_unable_to_receive")}}</a>
                      </template>
                      <v-card>
                        <v-card-title primary-title>
                          <v-row style="width:100%">
                            <v-col xs="12">
                              <v-spacer></v-spacer>
                              <button type="button" class="close" aria-label="Close" @click="dialog = false">
				                        <span aria-hidden="true">×</span>
			                        </button>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col xs="12">
                              {{ dialogTitle }}
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>{{ dialogMsg }}</v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-row>
              </v-row>
            </v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import translations from '@/common/assets/json/messages1.json'
import '@/common/assets/styles/tooltip.css' 
import loadingImage from '@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif'
export default {
  name: 'AuthOtpValidateComponent',
  components: {
  },
  data () {
    return {
        showResendOtpLink: false,
        isInFrame: true,
        otpCode: '',
        otpAlwaysOn: false,
        content3: translations.en.message['login_verify_and_dont_register_tooltip'],
        otpValidationInputClass: ""
    }
  },
  mounted () {
  },
  methods: {
      resendPasscode() {
        let self = this
        self.otpSent = false
        self.showResendOtpLink = false
        this.sendOtpToDevice(this.selectedOtpDevice.guid)
        self.showResendOtpLink = true
      },
      openUnableReceiveOverlay() {
        this.unableToReceiveOtp()
      },
      validateOtpCode (saveFingerprint) {
        let self = this
        self.showResendOtpLink = false
        var otpValidateParams = {
            "otpCode" : self.otpCode,
            "saveFingerprint": saveFingerprint
        }
        this.validateOtp(otpValidateParams)
      },     
      ...mapActions('authstepup', [
                'validateOtp',
                'unableToReceiveOtp'
            ]
        )
  },
  watch: {
    otpSent: function(newVal) {
      if(newVal) {
        // this.$router.push({ path: 'send' })
      }
    },
    dialogMsg: function (newVal) {
      console.log(newVal)
    },
    otpValidationError: function(newVal) {
      if(newVal) {
        this.otpValidationInputClass = "vz-error"
        this.otpCode = ''
      }
    }
  },
  computed: {
      ...mapGetters('authstepup', [
      'dynamicErrorClasses',
      'fingerprintSaved',
      'sqaDisabled',
      'isPwReset',
      'alerts',
      'dialogMsg',
      'dialogTitle',
      'submitLoader',
      'otpValidationError'
    ]),
    otpSent: {
      get: function () {
        return this.$store.getters['authstepup/otpSent']
      },
      set: function (newVal) {
        this.$store.dispatch('authstepup/updateOtpSent', newVal)
      }
    },
    dialog: {
      get: function () {
        return this.$store.getters['authstepup/dialog']
      },
      set: function (newVal) {
        this.$store.dispatch('authstepup/updateShowDialog', newVal)
      }
    }
  }
}
</script>
<style scoped>
h1 {
    margin: 4.6rem 0 2.50rem !important;
    font-size: 32px !important;
    font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", "Arial", "Helvetica", "sans-serif !important"
}
.v-row {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}
.alert-warning {
	background-color: #ffbc3d;
	background-size: 35px 29px;
	color: #000;
	border-color: #ffbc3d;
}
.alert-info {
	background-color: #08c;
	background-size: 35px 29px;
	color: #fff;
	border-color: #08c;
}
h2 {
  font-size: 20px;
  margin: 30px 0 20px;
}

h1, h2, h3, h4 {
  font-family: "BrandFont-Display-Bold","NeueHaasGroteskDisplayBold", "NeueHaasGroteskDisplayMedium", Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 100%;
}
.close {
    float: right !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
    opacity: .2 !important;
    margin-top: -2px !important;
}
.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.vz-input-field:focus {
  border: 1px solid black !important;
}
input {
  padding: 11px 0 11px 10px;
    border-radius: 0px;
    font-size: 14px;
    color: #000;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000;
    box-shadow: none;
    transition: border .4s;
    height: 36px;
}
.v-application .primary {
  background-color: #000 !important; 
  border-color: #000 !important;
}
.vz-error {
  border: #ed7000 1px solid;
  border-bottom: #ed7000 4px solid;
}
</style>
