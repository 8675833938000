import { render, staticRenderFns } from "./VbgBuildInfoCard.vue?vue&type=template&id=954da538&scoped=true"
import script from "./VbgBuildInfoCard.vue?vue&type=script&lang=js"
export * from "./VbgBuildInfoCard.vue?vue&type=script&lang=js"
import style0 from "./VbgBuildInfoCard.vue?vue&type=style&index=0&id=954da538&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "954da538",
  null
  
)

export default component.exports