<template>
  <div class="vbg-ff-root-container">
    <Header v-if="this.whichRealm"></Header>
    <div class="vbg-ff-content-container">
    <div v-if="getUnlockMyAccountAlerts.length > 0">
      <div class="row" v-for="j in getUnlockMyAccountAlerts" :key="j">
        <div class="col-sm-12">
          <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
            <div class="vbg-ff-msg-container">
              <div class="messageWrapper" style="display: flex">
                <div
                  class="messageIcon"
                  style="padding: 0px; margin-right: 12px"
                >
                  <div class="messageImage" :class="messageImage(j)"></div>
                </div>
                <p
                  class="messageContentUn col-xs-11"
                  v-if="j.type == 'list'"
                  v-html="j.msg"
                  style="padding: 0px; margin: 0px"
                >
                  {{ j.msg }}
                </p>
                <p
                  class="messageContentUn col-xs-11"
                  v-else-if="j.type == 'error' || j.type == 'warning'"
                  style="padding: 0px; margin: 0px"
                  v-html="j.msg"
                >
                  {{ j.msg }}
                </p>
                <p
                  class="messageContentUn col-xs-11"
                  v-else
                  style="padding: 0px; margin: 0px; color: #ffffff"
                  v-html="j.msg"
                >
                  {{ j.msg }}
                </p>
                <div
                  class="closeMessage col-xs-1"
                  style="padding: 0px; margin-left: 16px; display: grid;"
                >
                  <img
                    class="closeMessageImg close-icon-img"
                    v-if="
                      j.type == 'list' ||
                      j.type == 'error' ||
                      j.type == 'warning'
                    "
                    src="@/common/assets/styles/corp/images/close_black2.png"
                    @click="deleteAlert(j)"
                  />
                  <img
                    class="closeMessageImg close-icon-img"
                    v-else
                    src="@/common/assets/styles/corp/images/close.png"
                    @click="deleteAlert(j)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <Createsqa v-if="getVcgProfileView == 'resetCsqa'">
     </Createsqa>
     <Createpwd v-if="getVcgProfileView == 'resetSecurityProfile'">
     </Createpwd>
    <SecurityProfileRequest v-if="getVcgProfileView == 'resetCsqaRequestPin'">
    </SecurityProfileRequest>
      <SecurityProfileRequestPin v-if="getVcgProfileView == 'resetCsqaVerifyPin'">
      </SecurityProfileRequestPin>
    </div>
    <Footer v-if="this.whichRealm"></Footer>
  </div>
</template>
<script>
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import Header from "@/vbgauth/components/LegalHeader";
import { mapGetters, mapActions } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import Createpwd from './Createpwd.vue';
import Createsqa from './Createsqa.vue';
import SecurityProfileRequest from './SecurityProfileRequest.vue';
import SecurityProfileRequestPin from './SecurityProfileRequestPin.vue';
import translations from "@/common/assets/json/messages1.json";
import { setPageDetails, errorSet } from "../../../../public/adobeTagging";

export default {
  name: "VcgProfileUpdateRootComponent",
  components: {
    Header,
    Footer,
    Createpwd,
    SecurityProfileRequest,
    Createsqa,
    SecurityProfileRequestPin
  },
  computed: {
    ...mapGetters("login", ["tokenExpired", "getUnlockMyAccountAlerts", "getVcgProfileView"]),
    ...mapGetters("profile", [
      "gotoSQA",
      "gotoOtp",
      "gotoOtpOvw",
      "gotoPassSet",
      "submitLoader",
      "gotoComponent",
      "rspSessionExpired",
      "vbmSessionExpired"
    ]),
   
  },
  data() {
    return {
      whichRealm: true,
      tokenValue: null,
    };
  },
  mixins: [commonMixin],
  mounted() {
    this.updateUrlOnMount(window.location.href);
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      let iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }
    if (this.getParameterByName("goto")) {
      this.$store.dispatch(
        "profile/updateGotoUrl",
        this.getParameterByName("goto")
      );
      setGotoUrlInClientLib(this.getParameterByName("goto"));
    }
  },
  methods: {
    ...mapActions("login", ["updateSetTokenExpired", "updateUrlOnMount", "updateResetSecurityProfile"]),
    ...mapActions("profile", ["updateGotoComponent", "updatedStepsContent"]),
    messageImage(index) {
      if (
        index.type == "error" ||
        index.type == "warning" ||
        index.type == "list"
      ) {
        return "newErrorMessageImage";
      } else {
        return index.type + "MessageImage";
      }
    },
    getAlertType(index) {
      errorSet(index.msg);
      window.scrollTo(0, 0);
      return index.type;
    },
    deleteAlert(alert) {
      this.getUnlockMyAccountAlerts.splice(
        this.getUnlockMyAccountAlerts.indexOf(alert),
        1
      );
    },
    getTypeOfAlert (index) {
          return index.type
    },
  },
  watch: {
    // gotoPassSet: function (newVal) {
    //   if (newVal) {
    //   //  this.$router.push({ path: "resetpassword" });
    //   this.updatedStepsContent(1)
    //   this.updateGotoComponent('resetSecurityProfileNewPwd')

    //   }
    // }
    vbmSessionExpired : function (newVal){
      if(newVal){
        this.$router.push({path : "sessionexpiredpage"})
      }
    }
  },
};
</script>
<style scoped>
.close-icon-img {
  width: 13px;
  height: auto;
  position: relative;
  z-index: 9999;
  cursor: pointer !important;
}
.messageImage {
  width: 18px !important;
  height: 18px !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.vbg-ff-msg-container {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
</style>