<template>
    <div>
        <Header></Header>
    <div class="pwdlessotp_container">
                <BlockUI v-if="submitLoader" :url="url"></BlockUI>
                <v-form>
                <div class="opt-container-1">
                <div v-if="otpAlerts.length > 0" class="pwdPushRError" v-for="j in otpAlerts" :key="j" v-bind:class="getAlertType(j)">
                        <div class="warningImage" :class="messageImage(j)"></div>
                          <p class="pushTextError" v-html="j.msg">{{j.msg}}</p>
                          <img class="closeImage" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                      </div>
                    <div class="opt-container-1-1">
                      
                        <!--<h3 v-if="this.pwdPassWord != ''" class="opt-header-1">Verify with text message or email</h3>-->
                        <h3 class="opt-header-1">Verify with email</h3>
                        <div class = "opt-header-1-1">
                            <p class="opt-p-1">User ID</p>
                            <div style="display: flex;">
                                <p class="opt-username">{{this.pwdUsername}}</p>
                                <button class="opt-button-1 bottom_underline" id="changeUserID" name="changeUserID" @click="gotoPwdLessLogin()">Change</button>
                            </div>
                        </div> 
                        <!-- <img class="mainIcon" style="margin-top: 46px;" width="40" height="30" :src="emailIconSrc" /> -->
            <div class="method_para">
            <p v-if="this.pwdPassWord != ''" style="margin-top: 12px; margin-bottom: 32px;" class="account_text_para">How do you want to verify your account?</p>
            <p v-if="this.pwdPassWord == ''" style="margin-top: 12px; margin-bottom: 32px;" class=" account_text_para">How do you want to verify your account?</p>
            </div>
            <div class="padleft0">
                            <label class="labeltiny">{{ $t("message.login_otp_device_dropdown_label") }}</label>
                        </div>
            <div id="drpdn" style="margin-bottom: 2px; height: 44px !important;" class="form-group">
              <div ref="dropdownRef" class="dropdown dropdown-toggle select_input" v-bind:class="{ open: showDropdown }"
                aria-haspopup="true" aria-expanded="false" @click="toggleDropdown()" @keyup.enter="toggleDropdown()"
                tabindex="2">
                                <div class="col-xs-9" style="padding-right:0px; margin-top: 4px;">
                                    <a id="dropdownMenu1" aria-haspopup="true" role="button" >
                                        <span class=""> {{ selectedOtpDevice.label }}</span>
                                    </a>
                                </div>
                                <a class="col-xs-1 pull-right dropdown-cheveron" style="padding:0px;"></a>
                                <ul ref="dropdownListRef" class="dropdown-menu col-xs-12 px-0 dropdown_styles" aria-labelledby="dropdownMenu1">
                                    <li 
                                        v-for="(device, index) in devices" 
                                        :key="device.deviceID" 
                                        role="presentation" 
                                        v-bind:class="{ 
                                            chosen: (device.deviceID === selectedOtpDevice.deviceID)
                                        }" 
                                        :id="device.deviceID" :tabindex="index+3" 
                                        @keyup.enter="deviceOptionSelected(device, 'enter')"
                                        @keyup="deviceListkeyupHandler($event, index)"
                                        @click="deviceOptionSelected(device, 'click')"
                                        ref="selectDeviceList"
                                    >
                                        <a role="menuitem device_label">
                                            <span class=""></span> {{device.label}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> 
                        <div v-if="this.pwdPassWord == ''" style="margin-top: 24px;">
              <p class="checkbox_line check_para check_bg">
                <input class="tc checkbox_input check_box_border" type="checkbox" id="ch" v-model="defaultMethod" />
                <label for="ch" class="label_text">Make this my default log in method.</label>
                            </p>
                        </div>
                        <div  v-if="this.rememberThisDeviceEnable == 'basic'">
                            <p style="margin-top: 24px;display: flex;align-items: center;" class="check_bg">
                                <input class="tc check_box_border" type="checkbox" id="ch" name="remember_device" v-model="rememberThisDevice"/>
                                <label class="remember_label" for="ch"><b>Remember this device</b></label>
                            </p>
                            <p class="remember-subtag">Remember this device to log in quicker next time.</p>
                        </div>
                        <div class="btn_div">
                            <button
                                class="btn primary_black continue_btn"
                                style="width: 100%; margin: 64px 0px;font-size: 16px !important; font-family: 'VzNHGeDS-bold11';"
                                type="button"
                                :class="isDisabled()"
                                @click="sendOTP()"
                                id="send_passcode"
                                name="send_passcode"
                            >
                                Continue
                            </button>
                        </div>
                        <p @click="supportUrl()" id="loginAnotherWay" name="loginAnotherWay">
                          <b class="anotherCls">
                              <span class="left_caret_spacing">
                              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                               <path d="M6.5 1L1.5 6L6.5 11" stroke="black" stroke-width="2"/>
                              </svg>
                            </span> 
                            <span>Log in another way</span></b> 
                        </p>

                    </div>

                    <div>
                        <div id="noAccessToOtpModal" class="modal fade">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content" :class="checkModal" :style="gethelp">
                                    <div class="close-icon-container">
                                        <a
                                        data-dismiss="modal"
                                        @click="closeCombinenow()"
                                        >
                                            <img src="@/common/assets/styles/corp/images/close_black.png" style="height:20px; width: 20px;" />
                                        </a>
                                    </div>
            
                                    <pop-up-component></pop-up-component>
                                </div>
                            </div>
                        </div>
                    </div>
                                  </div>
                </v-form>
                                <div class="opt-container-2">
                    <div class="opt-default-promo1">
                        <p class="opt-default-promo">Manage your Verizon Partner Solutions transactions online anytime, anywhere.</p>
                    </div>
                </div>
            </div>
        <Footer class="footer-margin-top"></Footer>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import Header from '@/vbgauth/components/vpsHeader'
import Footer from '@/vbgauth/components/vpsFooter'
import '@/common/assets/styles/tooltip.css'
import translations from '@/common/assets/json/messages1.json'
import {setPageDetails,eventTracking,errorSet} from '../../../../../public/adobeTagging';
import CustomLoaderComponent from '@/vbgauth/components/CustomLoaderComponent.vue';
import { mapWaitingActions } from 'vue-wait'

export default {
  name: 'VPSPwdLessOtpDelivery',
  components: {
    Header,
    Footer,
    CustomLoaderComponent,
  },
  mixins: [commonMixin],
  data () {
    return {
        showDropdown: false,
        showResendOtpLink: false,
        checkVerifyPopUp: false,
        wirelessMobileNumber: null,
        userValidationInputClass: '',
        content3: translations.en.message['login_otp_tooltip'],
        idmTooltip: translations.en.message['idm_otp_help_icon_msg'],
        idmTooltipVerify: translations.en.message['idm_otp_help_icon_msg_verify'],
        content4: translations.en.message['wireless_no_help_icon_msg'],
        url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/circle-loaderWHITE_48x48.gif',
        emailIconSrc : iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/email.svg',
        whichRealm: true, 
        displayVerificationLink: false,
        //reSendVerification: true,
        aaEmailTimeout: 15,
        aaSmsTimeout: 10,
        defaultMethod: false,
        //pwdUsername: getCookie("pwdUserName"),
        //pwdPassWord: getCookie("pwdPassWord"),
        rememberThisDeviceEnable: '',
        rememberThisDevice: true,
    }
  },
  mounted () {
    this.changeContextBiocatch('pwdless_login_otp_delivery_page')

    this.gotoUrl = this.$route.query.goto;
    if (this.gotoUrl != null) {
        // determine if there is a goto with a hashtag
        let hashValue = window.location.hash.substr(1);
        if (hashValue != "") {
          let hashInUrl = '#' + window.location.hash.substr(1);
          if (this.gotoUrl != null) {
            this.gotoUrl += hashInUrl;
            this.$store.dispatch('login/updateGotoUrl', this.gotoUrl);
          }
        }
    }
    setGotoUrlInClientLib(this.getParameterByName('goto'))
    this.getOtpDevices()
    // const isPageReloaded = window.performance && window.performance.getEntriesByType('navigation') && window.performance.getEntriesByType('navigation').map((nav) => nav.type).includes('reload')
    // if (isPageReloaded) {
    //   this.initializeStepup();
    // }
    if (this.pwdUsername == null) {
      this.$router.push({ name: 'VPSPwdLessLoginRootComponent'})
    }
    if (this.optionMethod == 'opt2') {
      this.defaultMethod = this.makeDefaultMethodTrue
    }  
     /* Event Listener For Closing Select Box */
    document.addEventListener('click', (e) => {
      if(this.showDropdown && e.target.closest("#drpdn") === null) {
        this.showDropdown = false
      }
    })
    this.rememberThisDeviceEnable = iamvar_pwdLessFlowType
  },
  updated() {
    if(this.showDropdown && this.$refs.selectDeviceList.length > 1) {
      this.$refs.selectDeviceList[1].focus()
    }
  },
  beforeDestroy() {
    $('.modal-backdrop').remove()
  },
  methods: {
    getMsgIcon(index) {
        // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
        return "@/common/assets/styles/corp/images/"+index.type+".png";
      },
      messageImage(index) {
        if(index.type == 'error' || index.type == 'warning' || index.type == 'list') {
          return "newErrorMessageImage"
        } else {
          return index.type+"MessageImage";
        }
      },
      getCloseIcon() {
        return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
      },
      getAlertType (index) {
        //when error occurs take the user to the top screen where the orange banner is shown
        window.scrollTo(0,0);
        return index.type
      }, 
      deleteAlert(alert) {
        this.otpAlerts.splice(this.otpAlerts.indexOf(alert),1)
      },
    gotoPwdLessLogin() {
      if(this.gotoUrl != null) {
          this.$router.push({ name: 'VPSPwdLessLoginRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
      } else {
          this.$router.push({ name: 'VPSPwdLessLoginRootComponent' });
      }
    },
    getOAuthClass(className = 'oauth-flow', subClass) {
      return className + ' ' + subClass;
    },
    closeModal() {
      if(!this.vbgFlow) {
        this.updatecantrcvLink(false);
        this.checkVerifyPopUp = false;
        this.displayVerificationLink = true;
        this.updateVerificationSent(true);
        this.updateSelectedDevice(this.defaultSelectedDevice)
      }
      this.updateOTPAlert([])
      $("#noAccessToOtpModal").modal('hide')
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      } else {
        return null;
      }
    },
    deviceListkeyupHandler(e, index) {
      // logic to move up and down in device list with up & down arrow
      const deviceList = this.$refs.selectDeviceList;
      switch(e.key) {
        case 'ArrowUp':
          if (index > 1) {
            deviceList[index-1].focus();
          } 
          if (index <= 1) {
            deviceList[deviceList.length-1].focus();
          }
        break;
        case 'ArrowDown':
          if (index < deviceList.length - 1) {
            deviceList[index+1].focus();
          } else {
            deviceList[1].focus();
          }
        break;
        default:
        break;
      }      
    },
    ...mapWaitingActions('cvsstepup', {
      getOtpDevices: 'request_to_complete'
    }),
    async resendPasscode() {
      eventTracking('One Time Passcode Resend Request Initiated','');
      let self = this
      self.otpSent = false
      self.showResendOtpLink = true
      this.resendPasscodeToSelectedDevice = true
      //this.resendVerificationToSelectedDevice = true
      this.resendOtpToDevice(this.selectedOtpDevice) 
      //this.reSendVerification = false;
      this.updateVerificationSent(false);
    },
    supportUrl(){
        if(this.gotoUrl != null) {
            this.$router.push({ name: 'VPSPwdLessOption', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
        } else {
            this.$router.push({ name: 'VPSPwdLessOption' });
        }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    deviceOptionSelected(selDevice, selectionType) {
      if(selDevice.label == 'Enter Wireless Number') {
       // this.isShowWirelessNumber = true;
        this.$store.dispatch('cvsstepup/showWirelessNumber',true)
      } else {
       // this.isShowWirelessNumber = false;
        this.$store.dispatch('cvsstepup/showWirelessNumber',false)
      }
      this.updateSelectedDevice(selDevice)
      if(selectionType === 'enter') {
        // move focus to button if user 
        // click enter after selecting an option from dropdown
        this.$nextTick(() => this.$refs.submitButtonRef.focus())
      } else {
        // dont move focus to button if user select from mouse
        this.$refs.dropdownRef.focus()
      }
    },
    isDisabled() {
      if(this.otpSent || this.selectedOtpDevice.deviceID == null || this.selectedOtpDevice.deviceID == 'select-label') {
       // this.mobDisable = true
        return "inactive";
      }
    },
    stayActive() {
      this.pageTimeout = false
    },
    sendOTP() {
      if(this.otpSent || this.selectedOtpDevice.deviceID == null || this.selectedOtpDevice.deviceID == 'select-label') {
        //the send OTP/ Verify button is disabled and should not be clickable
      } else {
        eventTracking('One Time Passcode Request Initiated','');
          // if valid
          /*if(this.wirelessMobileNumber) {
            this.userValidationErrorMethod();
          }*/
          let self = this
            this.$store.dispatch('cvsstepup/updateSetUserDeviceRegistration', this.rememberThisDevice)
          if (this.defaultMethod) {
            let usernameOption = this.pwdUsername + '|' + 'opt2'
            this.encryptDefaultMethod(usernameOption)
          } else {
            if (this.optionMethod == 'opt2') {
              for(var i = 0; i< iamvar_vbgcRemCookieDomain.length; i++) {
                document.cookie = iamvar_makeDefaultMethodName +'=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain='+ iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath+ ';secure=' + iamvar_vbgcRemCookieSecure+';comment=deleting from ui'
              }
            }
          }
          this.sendOtpToDevice(this.selectedOtpDevice.deviceID)
          self.showResendOtpLink = true
      } 
    }, 
    enableSendOTP() {
      this.otpSent = false
    },
    dontHaveOTPAccess() {
      this.updatecantrcvLink(true);
      this.checkVerifyPopUp = false;
      $("#noAccessToOtpModal").modal({
          backdrop: "static"
      });
      this.displayVerificationLink = false
      if(!this.vbgFlow && (this.flowType == 'forgot_password' || this.flowType==='forgot_username' || this.flowType == 'auth')) {
        this.otpSent = true
      }
    },
    userValidationErrorMethod() {
      var phoneno = /^\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/g;
      this.wirelessMobileNumber = this.wirelessMobileNumber ? this.wirelessMobileNumber.replace(/ +/g, "") : null; // removing all whitespaces
      // removing +1 from the US mobile number
      if(this.wirelessMobileNumber && this.wirelessMobileNumber.startsWith('+1')) {
        this.wirelessMobileNumber = this.wirelessMobileNumber.slice(2);
      }
      // checking the US mobile number is valid or not and returning the value in format (+1) (xxx) xxx-xxxx
      if(this.wirelessMobileNumber && this.wirelessMobileNumber.match(phoneno)) {
        this.wirelessMobileNumber = this.wirelessMobileNumber.replace(phoneno, '$1-$2-$3');
       // this.userValidationError = false;
        this.$store.dispatch('cvsstepup/mobilenumberValidate',true)
        this.$store.dispatch('cvsstepup/sendMDN',this.wirelessMobileNumber)
        this.userValidationInputClass = '';  
      //  this.$store.commit('cvsstepup/setHideOtpValidateComponent', false); 
        } else {
        //  this.$store.commit('cvsstepup/setHideOtpValidateComponent', true);
         // this.userValidationError = true;
          this.$store.dispatch('cvsstepup/mobilenumberValidate',false)
          this.$store.dispatch('cvsstepup/sendMDN',null)
          this.userValidationInputClass = 'vz-error';
        }
    },
    ...mapActions('cvsstepup', [
        'sendOtpToDevice',
        'updateSelectedDevice',
        'resendOtpToDevice',
        'updateVerificationSent',
        'updatecantrcvLink',
        'updateOTPAlert',
        'initializeStepup'
    ]),
    ...mapActions('login', [
        'encryptDefaultMethod'
    ]),
    ...mapActions('forgotflow', [
                'updateUserVerified',
                'setFFTransactionId',
                'setFFUsername',
                'getUsername',
                'updateVbgFlow',
                'setIsSmbUser'
            ]
    ), 
  },
  computed: {
    ...mapGetters('login', [ 'gotoUrl', 'pwdUsername', 'makeDefaultMethodTrue', 'optionMethod', 'pwdPassWord' ]),
    formData: {
        get: function () {
            return this.$store.getters['login/formData'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/updateFormData', newVal);
        }
    },
    vzigInstanceCreated: {
      get: function() {
        return this.$store.getters['cvsstepup/vzigInstance']
      }
    },
    pwdAuthOptions : {
        get: function () {
            return this.$store.getters['login/pwdAuthOptions'];
        }
    },
    otpAlerts: {
      get: function() {
        return this.$store.getters['cvsstepup/otpAlerts']
      },
      set: function(newVal) {
        this.$store.commit('cvsstepup/setOTPAlert', newVal)
      }
    },
    defaultMethod: {
      get: function () {
        return this.$store.getters['login/makeDefaultMethodTrue'];
      },
      set: function (newVal) {
        this.$store.dispatch('login/makeDefaultMethodTrue', newVal);
      }
    },
    gotoUrl: {
            get: function () {
                return this.$store.getters['login/gotoUrl'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateGotoUrl', newVal);
                this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
            }
    },
    ...mapGetters('cvsstepup', [
      'devices',
      'stepupReason',
      'otpPageMessages',
      'transactionId',
      'username',
      'flowType',
      'email',
      'submitLoader',
      'sqaEnabled',
      'pageTimeout',
      'isMobilenumberValid',
      'isShowWirelessNumber',
      'wirelessMob',
      'vbgFlow',
      'currentStep',
      'forgotPwdSqaTransactionId',
      'isSmbUser',
      'urlOnMount',
      'verificationSent',
      'cantrcvLink',
      'userDeniedOTP',
      'defaultSelectedDevice',
      'aaThrottled',
      'hideOtpValidateComponent'
    ]),
    otpSent: {
      get: function () {
        return this.$store.getters['cvsstepup/otpSent']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateOtpSent', newVal)
      }
    },
    resendPasscodeToSelectedDevice: {
      get:function() {
        return this.$store.getters['cvsstepup/resendPasscodeToSelectedDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateResendPasscodeToSelectedDevice', newVal)
      }
    },
    resendVerificationToSelectedDevice: {
      get:function() {
        return this.$store.getters['cvsstepup/resendVerificationToSelectedDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateResendVerificationToSelectedDevice', newVal)
      }
    },
    selectedOtpDevice: {
      get: function () {
        return this.$store.getters['cvsstepup/selectedOtpDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateSelectedDevice', newVal)
      }
    },
    pageTimeout: {
      get: function() {
        return this.$store.getters['cvsstepup/pageTimeout']
      },
      set: function(newVal) {
        this.$store.dispatch('cvsstepup/displayPageTimeoutWarning', newVal)
      }
    },
    mobDisable() {
      return this.otpSent || this.selectedOtpDevice.deviceID == null || this.selectedOtpDevice.deviceID == 'select-label' ? true : false;
    },
    disabledAttr() {
      return (this.otpSent || this.selectedOtpDevice.deviceID == null || this.selectedOtpDevice.deviceID == 'select-label' ? true: false);
    },
  },
  watch: {
    otpSent: function(newVal) {
      if(newVal) {
      }
    },
    pageTimeout: function(newVal) {
      if(newVal) {
         $("#pageTimeoutModal").modal({
          backdrop: "static"
        });
      }
    },
    vzigInstanceCreated: function(newVal) {
      if(newVal != null) {
        this.getOtpDevices()
      }
    },
    makeDefaultMethodTrue: function(newVal) {
      if(newVal) {
        this.defaultMethod = newVal
      } /* else {
        this.defaultMethod = false
      }*/
    },
    currentStep: function(newVal) {
      if(!this.vbgFlow && newVal === 'OTP_VALIDATED') {
        $("#noAccessToOtpModal").modal('hide')
        this.updateUserVerified(true)
        this.setFFTransactionId(this.transactionId)
        this.setIsSmbUser(this.isSmbUser)
        if(this.flowType === 'forgot_password') {
          this.setFFUsername(this.username)
          this.$router.push({ name: 'AMForgotPwdComponent'})
        } else if(this.flowType==='forgot_username') {
          this.getUsername(this.email)
          var returnUrl = this.getHostName(window.location.href);
          if (!this.urlOnMount) {
            this.$router.push({ path: '/login/vpsunifiedlogin' })
            //window.location.href = "https://" + returnUrl + "/account/business/login/vpsunifiedlogin";
          } else {
            const urlStr = '/account/business'
            if (this.urlOnMount.includes(`${urlStr}/login/vpsunifiedlogin`)) {
              const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
              if(gotoURL) {
                this.$router.push({ path: '/login/vpsunifiedlogin', query: {goto: gotoURL}})
              } else {
                this.$router.push({ path: '/login/vpsunifiedlogin' })
              }
            } else if (this.urlOnMount.includes(`${urlStr}/login/mobile`)){
              const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
              if(gotoURL) {
                this.$router.push({ name: 'MobileLoginComponent', query: {goto: gotoURL}})
              } else {
                this.$router.push({ name: 'MobileLoginComponent'})
              }
            } else if (this.urlOnMount.includes(`${urlStr}/ilogin`)){
              const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
                if(gotoURL) {
                  this.$router.push({ name: 'iLoginComponent', query: {goto: gotoURL}})
                } else {
                  this.$router.push({ name: 'iLoginComponent'})
                }
            } else if (this.urlOnMount.includes(`${urlStr}/login`)) {
              const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
                if(gotoURL) {
                  this.$router.push({ name: 'SimpleLoginRoot', query: {goto: gotoURL}})
                } else {
                  this.$router.push({ name: 'SimpleLoginRoot'})
                }                    
            } else {
              this.$router.push({ path: '/login/vpsunifiedlogin' })
            }
          }
          //this.$router.push({ path: '/login/unifiedlogin' })
        }
      } else if(!this.vbgFlow && newVal==='OTP_INVALIDATED' && (this.flowType==='forgot_password'|| this.flowType==='forgot_username' || this.flowType == 'auth')) {
        $("#noAccessToOtpModal").modal('hide')
        this.displayVerificationLink = true
      } else if(!this.vbgFlow && (newVal==='IFG_TOKEN_EXPIRED' || newVal==='IFG_TOKEN_PROCESS_ERROR' || newVal==='IFG_TOKEN_INVALID_REQUEST_ERROR') && (this.flowType==='forgot_password'|| this.flowType==='forgot_username'|| this.flowType == 'auth')) {
        $("#noAccessToOtpModal").modal('hide')
        //this.displayVerificationLink = true
        this.displayVerificationLink = false
      }
		},

    resendVerificationToSelectedDevice: function(newVal) {
      if(newVal) {
        if (!this.vbgFlow && (this.flowType == 'forgot_password' || this.flowType==='forgot_username' || this.flowType == 'auth')) {
          this.checkVerifyPopUp = true;
          this.updatecantrcvLink(false);
          $("#noAccessToOtpModal").modal({
            backdrop: "static"
          });
          // this.$store.dispatch('forgotflow/forgotPwdSqaTransactionId',this.forgotPwdSqaTransactionId)
          this.$store.dispatch('forgotflow/updateVbgFlow', false)
          this.displayVerificationLink = false
          this.resendVerificationToSelectedDevice = false
        }
      }
    },
    hideOtpValidateComponent: function(newVal) {
      if(newVal == false) {
        const gotoUrl = this.getParameterByName('goto');
        if(gotoUrl) {
          if(this.rememberThisDevice) {
            this.$router.push({ name: 'VPSPwdLessOtpValidate', query: {goto: gotoUrl}, params: {rememberThisDevice: this.rememberThisDevice} });
          }
          else{
            this.$router.push({ name: 'VPSPwdLessOtpValidate', query: {goto: gotoUrl} });
          }
        } else {
          if(this.rememberThisDevice) {
            this.$router.push({ name: 'VPSPwdLessOtpValidate', params: {rememberThisDevice: this.rememberThisDevice} });
          }
          else{
           this.$router.push({ name: 'VPSPwdLessOtpValidate' });
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.pwdLessMessageContent{
  width: 382px;
  height: 32px;
  font-family: 'VzNHGeDS-reg11';
  font-size: 12px;
  line-height: 16px;
}
.pwdLessMainMessage {
  height: auto !important;
  background-color: #ddaf84 !important;
}
.opt-container-1 {
    width: 464px;
    /* height: 740.23px; */
    border: solid;
    border-color: #D8DADA;
    border-width: 1px;
    margin-bottom: 198px;
    /* margin-left: 290px; */
    border-radius: 4px;
}
.opt-container-1-1 {
    /* height: 485px; */
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 40px;
    margin-right: 40px;
}
.opt-header-1 {
    /* width: 382px; */
    height: auto;
    font-family: 'VzNHGeDS-bold11';
    font-size: 32px;
    /*padding-top: 14px !important; */
}
.remember_label {
  margin-bottom: 0px !important;
  font-family: "VzNHGeDS-bold11",sans-serif;
}
.remember-subtag {
    margin-left: 32px;
    /* width: 296px; */
    /* height: 40px; */
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #000000 !important;
}
#select-label {
  display: none;
}
.pwdPushRError.error {
    display: flex !important;
    background-color: #FFECE0;
    height: auto;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.pwdPushRError.list {
    display: flex !important;
    background-color: #FFECE0;
    height: auto;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.pwdPushRError.success {
    display: flex !important;
    background-color: #DCF5E6;
    height: auto;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.pwdPushRError.warning {
    display: flex !important;
    background-color: #FFF9DE;
    height: auto;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.pwdPushRError.information {
    display: flex !important;
    background-color: #E2F2FD;
    height: auto;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.pushTextError {
    margin-top: 16px;
    margin-right: 8px;
    margin-bottom: 16px;
    margin-left: 8px;
    height: auto;
    font-size: 12px;
    line-height: 16px;
    width: 380px;
    font-family: 'VzNHGeTX-reg11';
    color: #000000 !important;
    flex: 1 !important;
}
.closeImage {
    margin-top: 16px;
    margin-right: 16px;
    margin-bottom: 36px;
    float: right;
    width: 11.7px;
    height: 11.7px;
}
.warningImage {
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 36px;
    width: 24px;
    height: 24px;
}
.opt-p-1 {
    width: 298px;
    height: 20px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
}
.p-2 {
    /* width: 384px; */
    height: 52px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 24px;
    line-height: 26px;
}
.opt-username {
    max-width: 80%;
    height: 24px;
    font-family: 'VzNHGeDS-bold11';
    font-size: 20px;
    margin-top: 4px;
    /* margin-bottom: 14px; */
    word-break: break-all;
}
/* .check_para{
  margin-bottom: 28px !important;
} */
.opt-button-1 {
    width: 57px;
    height: 20px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    margin-top: 4px;
    margin-left: 12px;
    /* text-decoration: underline; */
}
.opt-optionsTable {
    width: 384px;
    height: 321px auto;
    margin-right: 40px;
    margin-top: 48px;
}
.opt-title {
    width: 298px;
    height: 28px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 24px;
    margin-bottom: 8px;
    display: block;
}
.opt-sub-title {
    width: 298px;
    height: 36px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    display: block;
}
.opt-horizontal-1 {
    width: 384px;
    height: 1px;
    fill: solid;
    color: #D8DADA;
    margin-bottom: 23px;
    margin-right: 40px;
}
.anotherCls {
  cursor: pointer;
  font-family: VzNHGeDS-bold11;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #000;
}
.left_caret_spacing {
  padding-right: 4px !important;
}
.opt-container-2 {
    margin-left: 172px;
    margin-bottom: 20px;
}
.opt-default-promo1{
  background: #F6F6F6;
  height: 650px;
  width: 464px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.opt-default-promo{
  height: 128px;
  width: 253.78px;
  color: #A7A7A7;
  font-family: VzNHGeDS-bold11;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
/* .footer-margin-top {
margin-top: 132px;
} */
@media only screen and (max-width: 1200px) {
.opt-default-promo {
display: none;
}
.opt-default-promo1 {
display: none;
}
}

/* responsive styles starts */
.pwdlessotp_container {
  display: flex;
  justify-content: center;
  margin-top: 48px;
  /* margin-left: 18px; */
}
@media only screen and (min-width: 280px) and (max-width: 1200px) {
.opt-container-1,
.opt-container-2 {
  margin-left: 0 !important;
}
}
.opt-default-promo1{
  height: 700px !important;
}
.account_text_para{
  margin-bottom: 26 !important;
}
.select_input{
  border: 1px solid #6F7171 !important;
  border-radius: 4px !important;
  /* margin-bottom: 30px !important; */
}
.checkbox_line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* .checkbox_input {
  margin: 0 10px !important;
} */
.label_text {
  margin-bottom: 0 !important;
}
.opt-header-1{
  height: auto;
}


@media only screen and (min-width: 280px) and (max-width: 1200px) {
  .pwdlessotp_container {
    display: flex;
    justify-content: center;
    margin-top: 48px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .pwdlessotp_container {
    margin-top: 0px !important;
  }
  .opt-header-1{
font-size: 30px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 399px) {
  .pwdlessotp_container {
    margin-top: 0px !important;
  }
.opt-header-1{
font-size: 22px;
}

.account_text_para{
font-size: 16px;
margin-bottom: 0 !important;
}
.opt-header-1-1{
margin-top:32px;
}
.opt-container-1 {
width: 350px !important;
border: none !important;
margin-bottom: 0 !important;
height: 500px;
}
.opt-container-1-1{
margin-left: 0 !important;
margin-right: 0 !important;
}

  .label_text {
    font-size: 14px;
  }
}
@media only screen and (min-width: 280px) and (max-width: 319px) {
  .pwdlessotp_container {
    margin-top: 0px !important;
  }

  .opt-header-1{
font-size: 18px;
  }
 
  .account_text_para{
    font-size: 14px;
    margin-bottom: 0 !important;
  }
  .opt-container-1 {
    width: 320px !important;
    border: none !important;
    margin-bottom: 0 !important;
    height: 500px;
  }

  .label_text {
    font-size: 12px;
  }
}
.method_para{
  margin-top: 36px !important;
}
.bottom_underline{
  text-decoration: none !important;
  border-bottom: 1px solid #000 !important;
  line-height: 1 !important;
}
.continue_btn{
  height: 44px;
}
.dropdown_styles{
    border-radius: 7px !important;
    background: linear-gradient(180deg, rgba(243, 243, 245, 0.80) 0%, rgba(242, 242, 242, 0.80) 100%) !important;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.50) !important;
    backdrop-filter: blur(50px) !important;
    padding: 5px !important;
    border: none !important;
    margin-top: 1px !important;
}
.dropdown-menu li:hover{
  border-left: none !important;
}
.dropdown-menu li.chosen{
  border-left: none !important;
  background-image: url(../../../../common/assets/styles/corp/images/dropdown_check.svg);
  background-position: center left 3%;
}
.dropdown-menu li:focus{
  border-left: none !important;
}
.dropdown-menu li{
  border-bottom: none !important;
}
.dropdown-menu li a{
  text-decoration: none !important;
  padding: 2.5px 19px 2.5px 19px !important;
}
input[type="checkbox"].tc {
  outline: none !important;    
}
.check_box_border, .check_box_border:hover {
  outline: none !important;
  border: 1px solid #D8DADA !important;
  margin-top: 0 !important;
}
.check_box_border[type="checkbox"]:checked{
  accent-color: #000 !important;
}
.check_bg input:checked{
  accent-color: #000 !important;
}
.opt-header-1{
font-size: 32px;
line-height: 38px !important;
text-align: left;
color: #000000;
letter-spacing: 0.5px;
margin-bottom: 32px;
}
.opt-p-1{
font-size: 16px;
line-height: 20px !important;
color: #000000;
letter-spacing: 0.5px;
text-align: left;
}
.opt-username{
font-size: 20px;
line-height: 24px !important;
color: #000000;
letter-spacing: normal;
}
.p-2{
font-size: 24px;
line-height: 26px !important;
letter-spacing: 0.75px;
color: #000000;
}
.labeltiny{
font-size: 12px;
color: #000000 !important;
line-height: 16px !important;
letter-spacing: normal;
text-align: left;
margin-bottom: 4px !important;

}
.account_text_para{
  font-size: 24px !important;
  line-height: 26px !important;
  letter-spacing: 0.75px !important;
}
.labeltiny{
font-family: 'VzNHGeTX-reg11' !important;
}
@media only screen and (max-width: 1023px) {
.opt-container-1{
margin-bottom: 165px !important;
border: none !important;
width: 100% !important;
}
.opt-container-2 {
margin-bottom: 0px;
}
.opt-container-1-1 {
margin-top: 32px;
padding: 0 16px !important; 
margin: 0 !important;
margin-top: 32px !important;
}
.opt-header-1 {
font-size: 24px !important;
line-height: 28px !important;
color: #000000;
letter-spacing: normal;
}
.pwdlessotp_container {
display: block;
margin-top: 0px !important;
}
.opt-header-1-1{
margin-top: 24px;
}
.opt-p-1{
font-size: 12px;
line-height: 16px;
letter-spacing: normal;
text-align: left;
color: #000000;
margin-bottom: 4px !important;
font-family: 'VzNHGeTX-reg11';
}
.opt-username{
font-size: 16px;
line-height: 20px !important;
letter-spacing: 0.5px;
color: #000000;
text-align: left;
margin-bottom: 0px !important;
margin-top: 0px !important;
font-family: 'VzNHGeDS-bold11';
}
.method-para {
margin-top: 49px !important;
}
.method_para {
margin-top: 48px !important;
margin-bottom: 32px !important;
width: 328px !important;
}
.account_text_para {
font-size: 20px;
letter-spacing: normal;
}
.labeltiny{
color: #000000;
font-size: 12px;
line-height: 16px;
text-align: left;
font-family: 'VzNHGeTX-reg11' !important;
}
.continue_btn{
margin-top: 48px !important;
letter-spacing: 0.5px;
}
.remember_label {
  margin-top: 0px !important;
}
.dropdown-menu li {
  border-bottom: 0.1px solid #D8DADA !important;
}
.dropdown-menu li a{
  text-decoration: none !important;
  padding: 12px 12px 6px 23px !important;
  font-size: 14px !important;
  letter-spacing: 0.5px;
  color: #000;
}

.dropdown-menu li:nth-last-child(1){
  border-bottom : none !important;
}
.dropdown_styles{
    border-radius: 12px !important;
    margin-top: 4px !important;
    padding: 0px !important;
    box-shadow: none !important;
}

.select_input {
  border: 1px solid #6F7171 !important;
}

.dropdown-menu li a .device_label {
  font-family: 'VzNHGeTX-reg11' !important;
}
.opt-button-1{
  margin-top: 0 !important;
}

}

</style>