<template>
  <v-row>
    <v-row class="mt-1">
      <v-col cols="10" xs="12"><h2 class="martop5 margin-bottom-small">2. Set up your account</h2>
      </v-col>
    </v-row>
    <v-row class="col-xs-12"><p>Your username must be at least 6 characters.</p></v-row>
    <v-form class="col-xs-12">
      <v-row class="from-group padright0 padleft0 col-xs-12 col-sm-4 margin-top-small">
        <label class="labeltiny" for="uname">User name*</label>
        <input id="uname" name="uname" v-model="uname" type="text" autocomplete="off">
      </v-row>
      <v-row class="padleft0 col-xs-12 margin-top-large margin-bottom-large padding-top-small">  
        <button type="button" class="btn btn_primary_black" :disabled="!uname">Set username</button>
      </v-row>
    </v-form>
    <AMResetPwdFormComponent></AMResetPwdFormComponent>
  </v-row>
</template>
<script>
import AMResetPwdFormComponent from '@/vbgauth/components/amForgotFlow/AMResetPwdFormComponent'
export default {
  name: 'UserRegSetupAccComponent',
  components: {
    AMResetPwdFormComponent
  },
  data () {
    return {
      uname:''
    }
  }
}
</script>
<style scoped>
h2 {
    font-size: 20px;
    margin: 0 0 20px;
}
input {
  padding: 11px 0 11px 10px;
    border-radius: 0px;
    font-size: 14px;
    color: #000;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000;
    box-shadow: none;
    transition: border .4s;
    height: 36px;
}
</style>
