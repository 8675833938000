<template>
  <div>
    <div class="martop40 oauth-pass-container" v-if="!enhanceFpFlow">
      <div class="mb-4 oauth-bold-text">{{ $t("message.pd_reset_instructions") }}</div>
      <ul style="margin-left:20px;">
        <li v-for="req in pwdReqCheckList" :key="req.requirement" style="list-style-type:disc;">
          {{ req.requirement }}
        </li>
      </ul>
    </div>
    <PwdResetComponent></PwdResetComponent>
  </div>
</template>
<script>
import translations from '@/common/assets/json/messages1.json'
import { mapActions, mapGetters } from 'vuex'
import PwdResetComponent from '@/vbgauth/components/core/PwdResetComponent'
export default {
  name: 'AMResetPwdFormComponent',
  components: {
    PwdResetComponent
  },
  data () {
    return {
      tooltipContent: translations.en.message['pd_reset_instructions_tooltip']
    }
  },
  mounted() {
    this.getPassswordBlackList();
    // this.readFormInputs('pwd_reset_component')
  },
   
  methods: {
    ...mapActions('forgotflow', [
        'readFormInputs',
         "getPassswordBlackList"
      ]
    )
   
  },
  computed: {
    ...mapGetters('forgotflow', [
      'pwdReqCheckList',
      'enhanceFpFlow'
    ])
  },
  watch: {
  }
}
</script>
<style scoped>
h2 {
    font-size: 20px;
    margin: 0 0 20px;
}
@media (max-width: 768px) {
  .oauth-pass-container {
    margin-top: 56px !important
  }
  .oauth-bold-text {
    font-weight: bold;
    padding-bottom: 12px;
    color: #000000;
  }
}
</style>
