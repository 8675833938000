<template>
    <div class="vz-container">
        <img :src="getOdiBanner()" id="odi-banner" />
        <div class="row" style="margin: 0px 10% 0px 10%;">
            <div class="col">
                <BlockUI v-if="submitLoader" :url="url" />
                <div class="col" style="margin-bottom: 80px; width: max-content; margin-left: auto; margin-right: auto;">
                    <h2 id="sign-in-title"> Sign In </h2> <br />

                    <span class="vz-error-msg" id="errorMsgVZW" v-if="authError">{{authError.errorMessage}}</span>

                    <form @submit.prevent="submitLoginForm" ref="loginForm">
                        <label class="form-label">Username</label> <br />
                        <input type="text" id="usrnTextBox" v-model="formData[0].input[0].value"> <br />

                        <label class="form-label"> Password </label> <br />
                        <input type="password" v-model="formData[1].input[0].value"> <br />

                        <input type="checkbox" v-model="rememberMe"> <label style="padding: 0 5px 0 5px;"> Remember Me </label> <br />
                        <InvisibleRecaptcha :sitekey="loginCaptchaKey" :disabled="!isFormComplete" v-if="this.captchaOn" id="captcha-submit" theme="light" badge="bottomright" :type="submit" :callback="submitLoginForm"> Sign In </InvisibleRecaptcha>
                        <input type="submit" v-if="!this.captchaOn" value="Sign In" style="width: 25%;" :disabled="!isFormComplete">
                    </form>

                    <br />
                    
                    <div>
                        <router-link :to="{ name: 'AMForgotUsrnmComponent' }" class="black-ink-link" @click.native ="FrUsername()"> Forgot Username </router-link>
                        <a class="black-ink-link" style="text-decoration: none; cursor: default;"> or </a>
                        <router-link :to="{ name: 'AMForgotPwdComponent' }" class="black-ink-link"> password </router-link>
                    </div>
                </div>
            </div>
            <div class="col" style="padding-right: 8%; padding-left: 20%; padding-top: 5%;">
                <div id="register-box">
                    <span style="font-weight: bold;"> Is your company not registered with us yet? </span> <br /> <br />
                    <span> Please </span> 
                    <a href="https://odi-deviceuat.verizonwireless.com/ODI/Registrations"> 
                    <span style="text-decoration: underline;">register with us </span></a>
                    <span> to get your company's OD Device Certification account </span> <br />
                    <span> set up. </span> <br /> <br />
                    <span> This will take you to our registration form where you can supply the information </span> <br />
                    <span> necessary to setup a new company profile and access the Verizon Open </span> <br />
                    <span> Development website. </span> <br /> 
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
import { mapGetters, mapActions } from 'vuex';
import commonMixin from '@/common/mixin/commonMixins';
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
export default {
    data() {
        return {
            name: 'ODIComponent',
            //url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
            //url : loadingImage,
            url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
            userId: '',
            userPassword: '',
            rememberMe: false,
            canSubmit: false,
            captchaOn: true
        }
    },
    async created() {
        

        this.getLoginCaptchaEnabledEnvVar();
    },
        
    components: { InvisibleRecaptcha },
    mixins: [commonMixin],
        mounted() {
        this.gotoUrl = this.$route.query.goto;
        this.removeExistingVBGCCookie();
        this.getSessionInfo();
        this.updateUrlOnMount(window.location.href);
        this.resetState();
        
        // call rememberMe function only if uid query parameter is not present
        // let params = new URLSearchParams(window.location.search);
        if(!this.getParameterByName('uid')) {
            this.callRememberMe();
        }
        this.processOdiParameters();
        // console.log('idm url in tsd ', iamvar_idmUrl);
    },
    methods: {
        ...mapActions('login', [
                'submitAMLoginForm',
                'callRememberMe',
                'removeExistingVBGCCookie',
                'getSessionInfo',
                'updateUrlOnMount',
                'resetState',
                'updateCaptchaToken'
            ]
        ),
        ...mapActions('cvsstepup', [ 'initializeStepup', 'updateUserIdFlow' ]),
        submitLoginForm: function (recaptchaToken) {
            let payload = {
                formData: this.formData,
                rememberUser: this.rememberMe
            };
            console.log('odi captcha token = ', recaptchaToken);
            if(this.captchaOn)
                this.updateCaptchaToken(recaptchaToken);


            this.submitAMLoginForm(payload);
            
            // might not be needed since submit button is disabled when form is empty
            /*if(this.$refs.loginForm.validate()) {
                this.submitAMLoginForm(payload);
            }*/

            // TODO: implement valid/invalid logic. For right now, redirect to OTP regardless of result
            // this.$router.push({ path: '/vbg-tsd-login/otp' });
        },
        getOdiBanner() {
            return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/OD_Dashboard_Banner_01.edb395b8.jpg';
        },
        getLoginCaptchaEnabledEnvVar() {
            console.log('(Env based) captcha is on = ', iamvar_enableLoginCaptcha);
            this.captchaOn = iamvar_enableLoginCaptcha;
        },
        processOdiParameters() {
           // let params = new URLSearchParams(window.location.search);

            if(this.getParameterByName('uid')) {
                this.formData[0].input[0].value = this.getParameterByName('uid');
            }
            if(this.getParameterByName('rememberMe')) {
                if(this.getParameterByName('rememberMe') == 'true')
                    this.rememberMe = true;
            }
        },
        FrUsername() {
            this.updateUserIdFlow('BASIC')
        }
    },
    computed: {
        ...mapGetters('login', [
            'authError',
            'submitLoader',
            'iamvar_enableCaptcha',
            'redirectToStepupPage',
            'redirectToSecProfilePage',
            'iamvar_idmUrl',
            'loginCaptchaKey',
            'iamvar_enableLoginCaptcha'
        ]),
        ...mapGetters('cvsstepup', [ 'currentStep' ]),
        isFormComplete() {
            // return this.userId && this.userPassword;
            return this.formData[0].input[0].value.length !== 0 &&
                   this.formData[1].input[0].value.length !== 0;
        },
        formData: {
            get: function () {
                return this.$store.getters['login/formData'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateFormData', newVal);
            }
        },
        gotoUrl: {
            get: function () {
                return this.$store.getters['login/gotoUrl'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateGotoUrl', newVal);
                this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
            }
        }
    },
    watch: {
        redirectToStepupPage: function(newVal) {
            if(newVal) {
                this.initializeStepup();
            }
        },
        redirectToSecProfilePage: function(newVal) {
            if(newVal) {
                window.location.href = newVal;
            }
        },
        currentStep: function(newVal) {
            if(newVal === 'FLOW_INITIALIZED') {
                if(this.gotoUrl != null) {
                    this.$router.push({ name: 'FFStepupRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
                } else {
                    this.$router.push({ name: 'FFStepupRootComponent' });
                }
            }
        }
    }
}
</script>

<style scoped>
#register-box {
    padding: 10px 10px 10px 10px;
    padding-top: 5px;
    background: #f6f6f6;
    width: max-content;
}

#register-box span {
    font-size: 14px;
}

input[type=text], input[type=password] {
    border-color: #d8dada #d8dada #000000 #d8dada;
    border-radius: 0px;
    color: #000000;
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    border: 1px solid #d8dada;
    background-color: #ffffff;
    background-image: none;
    height: 42px;
    width: 279px;
    box-sizing: border-box;
    border-bottom: 1px solid #000;
}
#sign-in-title {
    height: 31px;
    width: 308px;
    color: #000000;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 30.4px;
    font-weight: bold !important;
}
.form-label {
    height: 12px;
    color: #747676;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: 400 !important;
}
input[type=submit] {
    width: 20%;
    background-color: black;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}
input[type=submit]:disabled {
    width: 20%;
    background-color: #d4d6d9;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: auto;
}
input[type=checkbox] {
    padding: 5px;
    background-color: aqua;
    position: static;
}
.black-ink-link {
    width: 170px;
    color: #000000 !important;
    text-decoration: underline;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16.8px;
}
#odi-banner {
    margin: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background: no-repeat center fixed;     
    background-size: contain;
    width: 70%;
    margin-top: 1%;
    margin-bottom: 2%;
}
#captcha-submit {
    width: auto;
    min-width:120px;
    background-color: black;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}
#captcha-submit:disabled {
    width: auto;
    min-width: 120px;
    background-color: #d4d6d9;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: auto;
}

</style>