<template>
    <div>
        <h2>Additional Management Portals</h2>
          <v-list flat>
            <v-list-item-group>
              <v-row>
                <template v-for="(item, i) in bodyConfig.portals.list">
                  <v-col cols="12" sm="6" style="padding:0 12px 6px 12px">
                    <v-list-item class="vz-text-link" :key="i" :href="item.href" :ripple="false">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </template>
              </v-row>
            </v-list-item-group>
          </v-list>
    </div>
</template>
<script>
import config from '@/common/assets/json/login_page_config.json'
export default {
    name: 'LoginComponentManagementPortals',
    components: {
    },
    data () {
        return {
            bodyConfig: config.body
        }
    }
}
</script>

<style scoped>
.v-list-item__title {
  font-size: 1rem;
}
</style>