<style>
.navbar {
    background-color: #000;
    border-radius: 0px !important;
    padding-bottom: 22px;
}

.header-container {
    margin-top: 0px !important;
    width: 100% !important;
    padding-left: 22px;
}

.parent_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 44vh;
    margin: 0;
    padding: 10px;
    width: 100%
}

.main_heading {
    text-align: center;
}

.main_heading h1 {
    font-size: 48px;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin-bottom: 16px;
}

.sub_heading {
    color: #000;
    text-align: center;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 48px;
}

.btn_div {
    text-align: center;
}

.save_btn {
    border-radius: 24px;
    background: #000;
    color: #fff;
    padding: 12px 24px;
    text-align: center;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
}

@media screen and (max-width: 400px) {
    .parent_div {
        padding: 10px;
    }

    .main_heading h1 {
        font-size: 40px;
        text-align: left;
        font-weight: 700;
    }

    .sub_heading {
        font-size: 20px;
        text-align: left;
        font-weight: 400;
        margin-bottom: 28px;

    }


}
</style>

<template>
    <div>
    <div id="vz-gh20"></div>
        <!-- <nav class="navbar">
            <div class="container header-container">
                <a class="navbar-brand" id="navbar-brand" href="void()">
                    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="checkmark" clip-path="url(#clip0_3873_589)">
                            <path id="Check" d="M0 20H6.2176L12.4344 33.3336L27.9784 0H34.196L15.5432 40H9.3264L0 20Z"
                                fill="#EE0000" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3873_589">
                                <rect width="35" height="40" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </a>
            </div>
        </nav> -->
        <div class="parent_div">
            <div class="main_heading">
                <h1 tabindex="0">{{ $t("message.pass_updated_main_heading") }}</h1>
                <div class="sub_heading">
                    {{ $t("message.pass_updated_success_msg") }}
                </div>
            </div>

            <div class="form_elements">
                <div class="btn_div">
                    <button class="save_btn" @click="onSigninClick">
                        {{ $t("message.pass_updated_signin_btn") }}
                    </button>
                </div>
            </div>
        </div>
  <div id="vz-gf20" style = "clear:both"></div>
</div>
</template>


<script>
import { mapActions } from "vuex";
import commonMixin from '@/common/mixin/commonMixins';
export default {
    mixins: [commonMixin],
    data() {
        return {
        forgotFlowEnable: url.includes('/displayResetPassword'),
        selectedLanguage: this.$i18n.locale,
        supportedLanguages: [
            { code: 'en', name: 'English' },
            { code: 'es', name: 'Español' },
        ]
      }
    },
methods: {
    ...mapActions("login", [
      "updateCitizenCookie",
      "updateCitizenLogout",
      "updateVsbdrLocale"
    ]),
    getHostName (url) {
        var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
        if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
            return match[2];
        }
        else {
            return null;
        }
    },
    onSigninClick () {
        window.location.href = this.sanitizeUrl("https://"+ this.getHostName(window.location.href) + '/citizen/login/');
    }
},   
created: function () {
    const searchParams = new URLSearchParams(window.location.search);
    const storedLanguage = this.forgotFlowEnable ? localStorage.getItem('lng'): localStorage.getItem('ciamlng');
    const browserLanguage = navigator.language.substring(0, 2);
    const localeValue = !this.forgotFlowEnable && searchParams.get('ciamLang');
  if (storedLanguage) {
    this.$i18n.locale = storedLanguage;
    this.selectedLanguage = storedLanguage;
  } else if(!storedLanguage && localeValue) {
    this.$i18n.locale = localeValue;
    this.selectedLanguage = localeValue;
    this.forgotFlowEnable ? localStorage.setItem("lng",localeValue) : localStorage.setItem("ciamlng",localeValue);
  } else if (this.supportedLanguages.some(lang => lang.code === browserLanguage)) {
    this.$i18n.locale = browserLanguage;
    this.selectedLanguage = browserLanguage;
  }
 
  }, 
 mounted() {
    this.updateVsbdrLocale(this.$i18n.locale);
    if(localStorage.getItem('ciamlng')) {
        localStorage.setItem("lng",localStorage.getItem('ciamlng'));
        localStorage.removeItem('ciamlng')
    }
     (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window, document, 'script', 'dataLayer', 'GTM-KJFSV78');
    document.documentElement.lang = this.$i18n.locale;
    setTimeout(() => {
        const langSwitchLink = document.querySelector('.gnav20-lang-link');
         if(langSwitchLink) {
          document.addEventListener('click', (e)=> {
          const langValue = e.target.getAttribute('data-lang');     
          if(langValue) {
            if(langValue === 'English' || langValue === 'Inglés') {
                this.forgotFlowEnable ? localStorage.setItem("lng","en") : localStorage.setItem("ciamlng","en");
            } else {
                this.forgotFlowEnable ? localStorage.setItem("lng","es") : localStorage.setItem("ciamlng","es");
            }
          }
          })
          var locale = this.$i18n.locale;
          var variationValue = locale == 'es' ? 'authenticated-es' : 'authenticated';
          var globalNavdl = {
            "bu": "corporate",
            "appid": "vsbdr",
            "variation": variationValue,
            "options":{"hide-item":["search"]}
          }
          window.gnavdl = globalNavdl;
         }
       }, 1500);
       if(!this.forgotFlowEnable) {
        this.updateCitizenCookie();
       }
   var globalNav = document.createElement('script')
   globalNav.setAttribute('src', 'https://www.verizon.com/etc/designs/vzwcom/gnav20/corporate.js')
   globalNav.setAttribute('async', 'async');
   document.head.appendChild(globalNav)
 }
 };
</script>