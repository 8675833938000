import { getDateTime } from '../../../../public/dateTimeCalculator.js'
export default {
    namespaced: true,
    state: {
        buildInfo: null
    },
    mutations: {
        setBuildInfo(state, payload) {
            state.buildInfo = payload
        }
    },
    actions: {
        getBuildInfo({commit}, payload) {
            var buildEnv = iamvar_envFile.substr(0,iamvar_envFile.indexOf('-')) // "env_dev"
            var rest = iamvar_envFile.substr(iamvar_envFile.indexOf('-')+1) // "1.0.0.210-20210416-134948-b1c910ec.js"
            var buildNum = rest.substr(0, rest.lastIndexOf(".")) // "1.0.0.210-20210416-134948-b1c910ec"
            var buildInfoObj = {
                'context': payload,
                'buildVersion': buildNum,
                'timeStamp': getDateTime(),
                'systemEnv': buildEnv
            }
            commit('setBuildInfo', buildInfoObj)
        }
        
          
    },
    getters: {
        buildInfo: state => state.buildInfo
    }
}
