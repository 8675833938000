<template>
  <div>
    <h1 class="new_pwdless_main_heading pwdles_main_heading">
      {{ $t("message.otp_summary_title") }}
    </h1>
    <table>
      <thead>
        <tr>
          <th class="tborder_gap new_pwdless_pass_user">Delivery method</th>
          <th
            class="tborder_gap new_pwdless_pass_user"
            v-if="otpDeviceList.otpDeviceList.length > 1"
          >
            Remove
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="[
            otpDeviceList.otpDeviceList.length - 1 == name ? 'table-body' : '',
          ]"
          v-for="(value, name) in otpDeviceList.otpDeviceList"
          :key="name"
        >
          <td>
            <!-- <div
              class="secure_text"
              v-if="value.otp_type.toLowerCase() == 'sms'"
            >
              Recommended
            </div> -->
            <!-- <div
              class="failed_text"
              v-if="value.otp_type.toLowerCase() == 'ivr'"
            >
              Delivery failed
            </div> -->
            <span class="new_pwdless_forgot_text"
              >{{ getOTPType(value.otp_type) }} {{ value.otp_value }}</span
            >
            <!-- <div
              class="tbottom_gap new_notification_sub_heading"
              v-if="value.otp_type.toLowerCase() == 'ivr'"
            >
              <a class="warning_icon_gap"
                ><img class="" src="@/common/assets/images/warning.png"
              /></a>
              Action required: Confirm this method is still in use.
            </div> -->
          </td>
          <td v-if="otpDeviceList.otpDeviceList.length > 1">
            <span class="t_coumn_gap2">
              <a
                @click="deleteDevice(value)"
                class="vbg-rsp-device-table-delete-icon"
                ><img
                  class="delete-icon-size"
                  src="@/common/assets/styles/corp/images/delete_bin.png"
              /></a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="new_pwdless_btn_section">
      <button
        type="submit"
        v-if="enableContinueButton"
        class="new_pwdless_submit_btn"
        @click="continueLogin()"
        id="submitUserID"
        name="submitUserID"
        tabindex="0"
      >
        Go to Home page
      </button>
      <button
        class="new_pwdless_submit_btn pwd_cancel_btn"
        type="button"
        tabindex="0"
        @click="showAddDelivery()"
        @click.native="resetMessages"
        :disabled="isButtonDisabled"
        :class="{ disabled: isButtonDisabled }"
      >
        Add delivery method
      </button>
    </div>
    <div id="modal-popup" class="modal fade">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <h3 class="mt-0">Are you sure you want to delete?</h3>
          <a
            data-dismiss="modal"
            class="pull-right close-icon-popup vbg-rsp-close-icon-popup"
          ></a>
          <p class="spacing05">
            You are about to delete {{ deviceToBeDelete }} as a verification
            delivery method.
          </p>
          <div class="modal-popup-btn-box">
            <button
              type="submit"
              data-dismiss="modal"
              class="btn vbg-ff-btn-continue modal-popup-btn vbg-ff-btn-width140 spacing05"
              id="rsp-delete-btn"
              name="rsp-delete-btn"
              @click="conformDeleteDevice()"
            >
              <span>Delete</span>
            </button>
            <button
              class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05"
              data-dismiss="modal"
              id="rsp-cancel-delete-btn"
              name="rsp-cancel-delete-btn"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import translations from "@/common/assets/json/messages1.json";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { setPageDetails, errorSet } from "../../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";

export default {
  name: "NewOTPSummary",
  props: [],
  components: {},
  data() {
    return {
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      delSelectedDevice: "",
      deviceToBeDelete: "",
      max_devices_limit_msg:
        translations.en.message[
          "reset_security_profile_otp_summary_max_devices"
        ],
      isSDARFlow: iamvar_polarisFlowToggle,
    };
  },
  beforeDestroy() {},
  mixins: [commonMixin],
  async created() {
    setPageDetails("Reset Security Profile: Review Delivery methods");
  },
  mounted() {
    this.changeContextBiocatch("rsp_review_delivery_methods_page");
  },
  methods: {
    ...mapActions("profile", [
      "getOtpDeviceList",
      "deleteOTPDevice",
      "callRedirectURL",
      "resetMessages",
      "updateGotoOtp",
      "updateGotoOtpOvw",
    ]),
    ...mapActions("login", ["updateUnlockMyAccountAlerts"]),
    ...mapMutations("login", ["setDeliveryComponentName", "setCommonErrors"]),
    getMsgIcon(index) {
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    getOTPType(otp_type) {
      return otp_type.toLowerCase() == "email"
        ? "Email"
        : otp_type.toLowerCase() == "sms"
        ? "Text"
        : "Call";
    },
    messageImage(index) {
      if (
        index.type == "error" ||
        index.type == "warning" ||
        index.type == "list"
      ) {
        return "newErrorMessageImage";
      } else {
        return index.type + "MessageImage";
      }
    },
    deleteDevice(item) {
      console.log("test value log", item);
      this.deviceToBeDelete = item.otp_value;
      this.delSelectedDevice = item.guid;
      $("#modal-popup").modal({
        backdrop: "static",
      });
    },
    continueLogin() {
      let payload = {
        uuID: this.uuID,
      };
      this.callRedirectURL(payload);
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
      }
      return;
    },
    cancel() {
      this.resetMessages();
      this.$router.push({ path: "overview" });
    },
    conformDeleteDevice() {
      let payload = {
        uuID: this.uuID,
        otpDeviceList: [
          {
            guid: this.delSelectedDevice,
            otp_type: this.deviceToBeDelete,
          },
        ],
      };

      this.deleteOTPDevice(payload);
    },
    moveover() {
      $("#Pc1Info").popover({ trigger: "hover focus" });
    },
    showAddDelivery() {
      this.updateUnlockMyAccountAlerts([]);
      this.setCommonErrors();
      this.getOtpDeviceList(this.uuID);
      this.setDeliveryComponentName("NewAddOTPComponent");
      // this.updateGotoOtpOvw(false);
      // this.updateGotoOtp(true);
    },
  },
  computed: {
    ...mapGetters("profile", [
      "otpDeviceList",
      "uuID",
      "errorMsg",
      "showerrorMsg",
      "infoMsg",
      "showinfoMsg",
      "redirectUrl",
      "submitLoader",
      "changeURL",
      "firstName",
      "lastName",
      "showProcessLoader",
      "sdarUserType",
    ]),
    isButtonDisabled() {
      if (this.otpDeviceList && this.otpDeviceList.otpDeviceList) {
        return this.otpDeviceList.otpDeviceList.length == 5;
      } else {
        return false;
      }
    },
    enableContinueButton() {
      const list = this.otpDeviceList.otpDeviceList;
      return (
        list.length >= 2 &&
        list.find(
          (device) =>
            device.otp_type.toLowerCase() == "sms" ||
            device.otp_type.toLowerCase() == "ivr"
        )
      );
    },
  },
  watch: {
    otpDeviceList: {
      handler(newOTPList) {
        if (newOTPList?.otpDeviceList?.length == 5) {
          this.updateUnlockMyAccountAlerts([
            { type: "information", msg: this.max_devices_limit_msg },
          ]);
        }
      },
    },
    showProcessLoader: function (newVal) {
      if (newVal === true) {
        console.log("redirecting from newotpsummary");
        this.$router.push({ path: "redirecting" });
      }
    },
  },
};
</script>
<style scoped>
.disabled{
  background: #FFFFFF !important;
  color: #D8DADA !important;
  border: 1px solid #D8DADA !important;
}
.messageContentUn {
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 0px;
  margin: 0px;
  color: #ffffff;
}

.vbg-rsp-title-h1 {
  color: #000;
}

.ovrview-msg {
  padding-bottom: 24px;
  max-width: 914px;
}

#modal-popup {
  width: 100% !important;
  position: absolute !important;
}

.modal-dialog {
  width: 560px;
  height: 308px;
  margin: 0 auto !important;
  position: relative;
  top: 33%;
}

.modal-content {
  width: 560px;
  height: 308px;
  padding: 48px !important;
}

#modal-popup h3 {
  margin-bottom: 24px;
  font-family: "VzNHGeDS-bold11", Arial, Helvetica, sans-serif !important;
}

#modal-popup p {
  margin-bottom: 72px;
}

.continue_btn {
  margin-top: 32px;
  margin-right: 12px;
}

.otp_device_email {
  margin-left: 15px;
}

.otp_device_text {
  margin-left: 24px;
}

.otp_device_call {
  margin-left: 25px;
}

.otp-summary-description-text {
  padding-bottom: 32px;
  color: #000;
}
.table-text {
  color: #000;
}
.vbg-rsp-device-table-delete-icon {
  margin-left: 19px;
}

.add-delivery-method-btn {
  margin-top: 32px;
}

.msg-icon-position {
  padding: 0px;
  margin-right: 12px;
}

@media (min-width: 280px) {
  .closeMessageImg {
    position: static !important;
  }
}
@media (max-width: 442px) {
  .vbg-rsp-close-icon-popup {
    margin-top: 48px !important;
  }
}
@media (max-width: 768px) {
  .modal-content {
    padding: 48px 16px !important;
  }
  .vbg-rsp-close-icon-popup {
    left: 0px !important;
    margin-top: 62px !important;
    margin-right: 0px !important;
  }
  .modal-popup-btn {
    height: 44px !important;
    line-height: 18px;
    font-size: 16px;
    padding: 12px;
    text-align: center;
  }
}
@media (min-width: 443px) and (max-width: 768px) {
  .vbg-rsp-close-icon-popup {
    margin: 62px 14px !important;
  }
}
@media (max-width: 1024px) {
  .messageContent {
    flex: 1;
  }
}

@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
}

@media (max-width: 425px) {
  .messageContent,
  .messageContentUn {
    width: 232px;
  }
}

@media (max-width: 1024px) {
  .oauth-flow-w-100 {
    width: 100% !important;
  }
}

@media (max-width: 540px) {
  .modal-dialog {
    top: 0;
  }

  .modal-content {
    height: auto;
  }
}
@media (min-width: 1025px) {
  .continue_btn {
    width: 220px;
    height: 44px;
  }
  .add-delivery-method-btn {
    width: 220px;
    height: 44px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .btn.vbg-ff-btn-cancel {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  .add-delivery-method-btn {
    float: right;
    margin-top: 0px;
    margin-bottom: 64px;
  }

  .continue_btn {
    margin-top: 16px;
    margin-bottom: 12px !important;
  }
}

@media (min-width: 280px) and (max-width: 768px) {
  .modal-dialog {
    width: 100%;
    height: 100%;
  }
  .modal-content {
    width: 100%;
    height: 100%;
  }
  .modal-dialog {
    top: 0;
  }
  .btn-reverse-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
  .add-delivery-method-btn {
    margin-top: 16px;
    margin-bottom: 12px !important;
  }

  .continue_btn {
    float: right;
    margin-top: 0px;
    margin-bottom: 64px;
  }
}

@media (min-width: 280px) and (max-width: 1024px) {
  .oauth-flow-w-100 {
    /* width: 47% !important; */
    margin-right: 0 !important;
  }
}
@media (max-width: 482px) {
  .msg-icon-position {
    padding-bottom: 60px !important;
  }
}
.pwdles_main_heading {
  margin-bottom: 32px;
}
.tborder_gap {
  width: 88%;
  padding-bottom: 16px;
  border-bottom: 1px solid #000000;
  border-top: none;
}
table thead {
  border-top: none;
}
/* .t_column_gap{
  margin-top: 16px;
} */
/* .t_coumn_gap2{
  margin-bottom: 16px;
} */
.tbottom_gap {
  margin-bottom: 8px;
}
table {
  width: 100%;
}
td {
  padding: 16px 0px;
}
tr {
  border-bottom: 1px solid #d8dada;
}
.warning_icon_gap {
  margin-right: 4px;
}
.pwd_cancel_btn {
  margin-top: 16px;
}
.new_notification_sub_heading {
  color: #000000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: normal;
  font-family: "VzNHGeTX-reg11";
  text-align: left;
}
@media only screen and (min-width: 1023px) {
  .new_pwdless_btn_section {
    margin-top: 32px;
    width: 100% !important;
  }
}
</style>
