<template>
  <div>
    <div
      v-if="popupValue != 'combinenowPopupVal' && popupValue != 'gethelp' && popupValue != checkVcgMultipleAccount"
      style="font-family: 'VzNHGeDS-reg11'"
    >
      <div class="main-modal" :class="checkModal">
        <h2 class="main-modal-title" v-if="popupValue == 'forgotUserId'">
          Forgot user ID?
        </h2>
        <h2
          class="main-modal-title"
          v-else-if="popupValue == 'forgotUserPassword'"
        >
          Forgot password?
        </h2>
        <h2
          class="main-modal-title"
          v-else-if="popupValue == 'payWithoutLogin'"
        >
        Pay without logging in
        </h2>
        <h2 class="main-modal-title" v-else-if="popupValue == 'interstitial'">
          Select your account.
        </h2>
        <div class="main-modal-text" v-if="popupValue == 'payWithoutLogin'">
          Select your account.*
        </div>
        <div
          class="main-modal-text"
          v-if="popupValue != 'payWithoutLogin' && popupValue != 'interstitial'"
        >
          Select your account.
        </div>
      </div>
      <div
        class="wireless-modal"
        :class="checkWireless"
        v-if="checkinter() == 'showHybrid'"
        style="cursor: pointer"
        @click="selectAccount('wireless')"
      >
        <h2 class="modal-title">My Business Wireless</h2>
        <h2 class="modal-title-hybrid">Verizon Enterprise Center</h2>
        <img :src="arrow" class="modal-arrow" />
      </div>
      <div
        class="wireless-modal"
        :class="checkWireless"
        v-if="checkinter() == 'showHybrid'"
        style="cursor: pointer"
        @click="selectAccount('wireless')"
      >
        <h2 class="modal-title">My Business Wireless</h2>
        <h2 class="modal-title-hybrid">Verizon Enterprise Center</h2>
        <img :src="arrow" class="modal-arrow" />
      </div>
      <div
        class="wireless-modal"
        :class="checkWireless"
        v-if="
          checkinter() == 'showMybiz' || checkinter() == 'showMybizVecSections'
        "
        style="cursor: pointer"
        @click="
          selectAccount('wireless');
          mybizzForget(true);
        "
      >
        <h2 class="modal-title">My Business Wireless</h2>
        <img :src="arrow" class="modal-arrow" />
      </div>
      <div
        class="wireless-modal"
        :class="checkWireless"
        v-if="checkinter() == 'showVec'"
        style="cursor: pointer"
        @click="
          selectAccount('wireline');
          mybizzForget(false);
        "
      >
        <h2 class="modal-title">Verizon Enterprise Center</h2>
        <img :src="arrow" class="modal-arrow" />
        <!-- <div class="modal-text">
        <b>For products like:</b> Mobile phones and devices, Total, Mobile
        Protection, Internet of Things, One Talk, Security, collaboration and
        productivity tools from our Business, Solutions Store (BuSS)
      </div> -->
      </div>

      <div
        class="vec-modal"
        v-if="checkinter() == 'showMybizVecSections'"
        style="cursor: pointer"
        @click="
          selectAccount('wireline');
          mybizzForget(false);
        "
      >
        <h2 class="modal-title">Verizon Enterprise Center</h2>
        <img :src="arrow" class="modal-arrow" />
      </div>
      <div
        class="fios-modal"
        :class="checkPwl"
        style="cursor: pointer"
        @click="selectAccount('fios')"
      >
        <h2 class="modal-title">
          My Business Wireline Fios, phone, internet & TV
        </h2>
        <img :src="arrow" class="modal-arrow" />
      </div>

      <div class="subtext-modal" id="vec-wireline-quick-pay" data-loc="vec-wireline-quickpay-popup" v-if="popupValue == 'payWithoutLogin'"
        style="cursor: pointer"
        @click="selectAccount('wireline')">
        <h2 class="modal-title">
          Verizon Enterprise Center
        </h2>
        <img :src="arrow" class="modal-arrow" />
      </div>
    </div>
    <div v-else-if="popupValue == 'combinenowPopupVal'">
      <div class="cn-modal">
        <div class="cn-main-modal">
          <h2 class="cn-main-modal-title">
            Simplify your login with one Verizon Business ID.
          </h2>
        </div>
        <div class="cn-modal-text">
          <p>
            Now you can use one Verizon Business ID to log in to both your My
            Business Wireless and Verizon Enterprise Center accounts.
          </p>
        </div>
      </div>
      <div class="combinenow-popup-btn-container">
        <button
          class="btn primary_black combinenow-btn"
          type="submit"
          @click="combinenowClicked()"
        >
          Combine now
        </button>
        <p
          class="combinenow-btn-skip"
          @click="combinenowSkip()"
        >
          Skip for now
        </p>
      </div>
    </div>
    <div v-else-if="popupValue == 'gethelp'">
      <h1 class="main-modal main-modal-title" style="top: 58px">Get Help</h1>
      <div class="gh-wireless">
        <h2 class="gh-heading">My Business Wireless</h2>
        <p class="gh-text" style="top: 69px">1.800.922.0204</p>
      </div>
      <div class="vec-modal" style="top: 216px">
        <h2 class="gh-heading">Verizon Enterprise Center</h2>
        <div class="vec-modal1">
          <h4 class="gh-title">US and Canada Customers</h4>
          <p class="gh-text">
            Live Chat (Sun 8 PM - Sat 8 PM ET)<br />
            1.800.569.8799 (Mon - Fri 9 AM - 6 PM ET)
          </p>
        </div>
        <div class="vec-modal2">
          <h4 class="gh-title">EMEA, APAC, LATAM Customers</h4>
          <p class="gh-text">
            Live Chat (Mon 1 AM - Sun 1 AM GMT) <br />
            00.800.4321.5432 (Mon - Fri 9 AM - 5 PM GMT)
          </p>
        </div>
      </div>
      <div class="gh-fios">
        <h2 class="gh-heading">
          My Business Wireline Fios, phone,<br />
          internet & TV
        </h2>
        <p class="gh-text fios-text">1.800.922.0204</p>
      </div>
    </div>
    <div v-else-if="popupValue == checkVcgMultipleAccount">
      <div class="main-modal-vcgusers" :class="checkModal">
        <h2 class="main-modal-title-vcgusers">
          You have multiple accounts
        </h2>
        <div
          class="main-modal-text-vcgusers"
        >
          Which account would you like to access?
        </div>
      </div>
       <div
        class="vbmc-modal"
        
        style="cursor: pointer"
        @click="
          selectAccount('vbmc');
        "
      >
        <h2 class="modal-title">Verizon Enterprise Center</h2>
        <img :src="arrow" class="modal-arrow" />
      </div>
      <div
        class="vbms-modal"
       
        style="cursor: pointer"
        @click="
          selectAccount('vbms');
        "
      >
        <h2 class="modal-title">Verizon Fios</h2>
        <img :src="arrow" class="modal-arrow" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import right_arrow from "@/common/assets/styles/corp/images/right-arrow.png";
import { iamvar_vcgMultipleAccount } from '../../../../../public/const.js'
export default {
  name: "PopUpComponent",
  components: {},
  data() {
    return {
      arrow: right_arrow,
      checkVcgMultipleAccount: iamvar_vcgMultipleAccount
    };
  },
  computed: {
    ...mapGetters("login", ["popupValue", "getUnifiedErrorCode"]),
    checkPwl() {
      return [
        {
          "fios-modal-forgot":
            this.popupValue == "forgotUserId" ||
            this.popupValue == "forgotUserPassword"
              ? true
              : false,
          "fios-modal-pwl": this.popupValue == "payWithoutLogin" ? true : false,
          "fios-modal-hybrid":
            this.popupValue == "interstitial" &&
            this.getUnifiedErrorCode.includes("MYBIZ") &&
            this.getUnifiedErrorCode.includes("VEC")
              ? true
              : false,
          "fios-modal-int":
            this.popupValue == "interstitial" &&
            (this.getUnifiedErrorCode.includes("MYBIZ") ||
              this.getUnifiedErrorCode.includes("VEC"))
              ? true
              : false,
        },
      ];
    },
    checkWireless() {
      return [
        {
          "wireless-modal-hybrid":
            this.popupValue == "interstitial" &&
            this.getUnifiedErrorCode.includes("MYBIZ") &&
            this.getUnifiedErrorCode.includes("VEC")
              ? true
              : false,
          "wireless-modal-int":
            this.popupValue == "interstitial" &&
            (this.getUnifiedErrorCode.includes("MYBIZ") ||
              this.getUnifiedErrorCode.includes("VEC"))
              ? true
              : false,
        },
      ];
    },
  },
  mounted() {},
  methods: {
    ...mapActions("login", [
      "selectAccount",
      "updateMergeStatus",
      "mybizzForget",
    ]),
    // arrow() {
    //   return (
    //     "https://scache.vzw.com/vbglogin/business/login/includes/img/right-arrow.b9d13948.svg"
    //   );
    // },

    checkinter(inpt) {
      // if(this.popupValue == 'interstitial'){
      //   if(this.getUnifiedErrorCode.includes(inpt)){
      //     return true
      //   }else{
      //     return false
      //   }
      // }else if(this.popupValue == 'payWithoutLogin'){
      //   return true
      // } else {
      //   return true
      // }
      if (this.popupValue == "interstitial") {
        if (
          this.getUnifiedErrorCode.includes("MYBIZ") &&
          this.getUnifiedErrorCode.includes("VEC")
        ) {
          return "showHybrid";
        } else if (this.getUnifiedErrorCode.includes("MYBIZ")) {
          return "showMybiz";
        } else if (this.getUnifiedErrorCode.includes("VEC")) {
          return "showVec";
        }
      } else if (this.popupValue == "payWithoutLogin") {
        return "showMybiz";
      } else if (
        this.popupValue == "forgotUserId" ||
        this.popupValue == "forgotUserPassword"
      ) {
        return "showMybizVecSections";
      }
    },
    async combinenowClicked() {
      $("#noAccessToOtpModal").modal("hide");
      await this.updateMergeStatus("combinenowClick");
      this.$router.push({ name: "CombinenowRootComponent" });
    },
    combinenowSkip() {
      this.updateMergeStatus("closePopup");
    },
  },
};
</script>
<style scoped>
.combinenow-btn {
  width: 188px;
  height: 44px;
  font-size: 16px !important;
  position: absolute;
  top: 198px;
  left: 44px;
  letter-spacing: 0.5px;
}
.combinenow-btn-skip {
  cursor: pointer;
  text-decoration: underline;
  width: 188px;
  height: 20px;
  font-size: 16px !important;
  position: absolute;
  top: 207px;
  left: 256px;
}
.main-modal {
  position: absolute;
  top: 48px;
  left: 48px;
  right: 48px;
}
.main-modal-title {
  color: #000000;
  font-size: 32px;
  line-height: 32px;
  font-family: "VzNHGeDS-bold11";
}
.main-modal-text {
  position: absolute;
  top: 56px;
  width: 464px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.wireless-modal {
  position: absolute;
  top: 157px;
  left: 48px;
  right: 48px;
  border-top: 1px solid;
}
.gh-wireless {
  position: absolute;
  top: 104px;
  left: 48px;
  right: 48px;
  border-top: 1px solid;
}
.wireless-modal-int {
  top: 110px;
}
.modal-title {
  position: absolute;
  top: 29px;
  color: #000000;
  line-height: 24px;
  width: 400px;
  font-family: "VzNHGeDS-bold11";
}
.gh-heading {
  position: absolute;
  top: 30px;
  color: #000000;
  line-height: 24px;
}
.modal-title-hybrid {
  position: absolute;
  top: 61px;
  color: #000000;
  line-height: 24px;
  width: 400px;
  font-family: "VzNHGeDS-bold11";
}
.modal-text {
  position: absolute;
  top: 69px;
  width: 413.72px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.vec-modal {
  position: absolute;
  top: 240px;
  left: 48px;
  right: 48px;
  border-top: 1px solid;
}
.vec-modal1{
  padding-top: 74px
}
.vec-modal2{
  padding-top: 54px
}
.fios-text{
  padding-top: 94px;
}
.gh-title {
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #000000;
}
.gh-text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #000000;
  position: absolute;
}
.gh-fios {
  position: absolute;
  top: 449px;
  left: 48px;
  right: 48px;
  border-top: 1px solid;
}
.fios-modal {
  position: absolute;
  top: 250px;
  left: 48px;
  right: 48px;
  border-top: 1px solid;
}
.fios-modal-pwl {
  top: 250px;
  bottom: 113px;
}
.fios-modal-forgot {
  top: 325px;
  bottom: 113px;
}
.fios-modal-int {
  top: 200px;
}
.fios-modal-hybrid {
  top: 230px;
}
.modal-arrow {
  width: 20px;
  height: 20px;
  /* position: absolute;
  top: 31px;
  left: 442px; */
  margin-top: 31px;
  float: right;
}
.subtext-modal {
  position: absolute;
  top: 350px;
  left: 48px;
  right: 48px;
  border-top: 1px solid;
}
.subtext-modal-text {
  position: absolute;
  top: 25px;
  width: 464px;
  color: #000000;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
}
.cn-modal {
  height: 128px;
  width: 464px;
  position: absolute;
  top: 46px;
  left: 44px;
}
.cn-main-modal-title {
  height: 64px;
  width: 464px;
  color: #000000;
  /* font-family: "Verizon NHG eDS"; */
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.cn-modal-text {
  height: 40px;
  width: 464px;
  color: #000000;
  /* font-family: "Verizon NHG eDS"; */
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  position: relative;
  top: 24px;
}
.vbmc-modal {
  position: absolute;
  top: 157px;
  left: 48px;
  right: 48px;
  border-top: 1px solid;
  }
  .vbms-modal {
    position: absolute;
    top: 246px;
    left: 48px;
    right: 48px;
    border-top: 1px solid;
  }
.main-modal-vcgusers {
  position: absolute;
  top: 48px;
  left: 48px;
  right: 48px;
}
.main-modal-title-vcgusers {
  color: #000000;
  font-size: 32px;
  line-height: 32px;
  font-family: "VzNHGeDS-bold11";
}
.main-modal-text-vcgusers {
  position: absolute;
  top: 56px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 540px) {  
  .cn-modal, .cn-main-modal-title, .cn-modal-text {
    position: static;
    width: 100%;
    height: auto;
  }
  
  .cn-modal-text {
    margin-top: 24px;
  }
  .combinenow-popup-btn-container {
    position: static;
    text-align: center;
  }
  .combinenow-btn, .combinenow-btn-skip {
    position: static;
    width: 100%;
    margin-top: 24px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .main-modal {
    left: 16px;
    right: 16px;
  }
  .main-modal-title {
    font-size: 24px;
    line-height: 24px;
  }
  .main-modal-text {
    top: 40px;
  }
  .wireless-modal {
    top: 132px;
    left: 16px;
    right: 16px;
  }
  .vec-modal1{
    padding-top: 85px;
  }
  .vec-modal2{
    padding-top: 75px;
  }
  .gh-wireless{
    left: 16px;
    right: 16px;
  }
  .gh-fios{
    left: 16px;
    right: 16px;
    top : 490px;
  }
  .modal-arrow {
    left: 245px;
  }
  .modal-title {
    width: 220px;
  }
  .modal-title-hybrid {
    top: 91px;
    width: 220px;
  }
  .modal-text {
    width: 259px;
    top: 90px;
  }
  .vec-modal {
    top: 225px;
    left: 16px;
    right: 16px;
  }
  .fios-modal {
    top: 580px;
    left: 16px;
    right: 16px;
  }
  .fios-modal-pwl {
    top: 225px;
  }
  .fios-modal-forgot {
    top: 315px;
  }
  .fios-modal-int {
    top: 250px;
  }
  .fios-modal-hybrid {
    top: 300px;
  }
  .subtext-modal {
    top: 350px;
    left: 16px;
    right: 16px;
  }
  .subtext-modal-text {
    width: 240px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 426px) {
   .fios-text{
      padding-top: 123px;
    }
}
@media only screen and (max-width: 280px) {
  .main-modal-text {
    top : 68px
  }
  .main-modal {
    top: 25px;
  }
  .wireless-modal {
    top: 125px;
  }
  .modal-title {
    width: 0px;
  }
  .main-modal-title {
    font-size: 25px;
  }
  .vec-modal {
    top: 260px;
  }
  .fios-modal-forgot {
    top: 390px;
  }
  .fios-modal-forgot h2, .vec-modal h2 , .wireless-modal h2 {
    font-size: 21px;
  }
}
@media only screen and (max-width: 426px) {
.main-modal-title-vcgusers {
  font-size: 24px;
  top: 66px !important;
}
.main-modal-text-vcgusers {
  font-size: 16px;
  top: 66px;
}
.vbmc-modal {
  top: 166px;
}
.vbms-modal {
  top: 266px;
}
}
</style>