<template>
  <div style="overflow: hidden">
      <Header></Header>
  <div class="pwdless_pass_container">
            <BlockUI v-if="submitLoader" :url="url"></BlockUI>
              <form id="loginBiz" class="signin" @submit.prevent="submitPasswordForm">
              <div class="opt-container-1">
              <div v-if="getEmptyUnifiedLoginAlerts.length > 0">
                  <div v-for="j in getEmptyUnifiedLoginAlerts" :key="j" class="pwdPushRError" v-bind:class="getAlertType">
                    <div class="pwdless_error">
                    <div class="warningImage" :class="messageImage(j)"></div>
                    <p class="pushTextError" v-html="j.msg">{{j.msg}}</p>
                  </div>
                  <div>
                    <img class="closeImage" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                  </div>
                  </div>
                </div>
                  <div class="opt-container-1-1">
                      <h3 class="opt-header-1">Log in</h3>
                      <div class="user-id-header" style="margin-top: 24px;">
                          <p class="opt-p-1">User ID</p>
                          <div style="display: flex;" class="username_alignment">
                              <p class="opt-username">{{this.pwdUsername}}</p>
                              
                              <button id="changeUserID" name="changeUserID" type="button" class="opt-button-1 bottom_underline link_underline" @click="gotoPwdLessLogin()">Change</button>
                          </div>
                      </div> 
          <div class="user-id">
                          <label for class="-input-label">Password</label>
                          <!-- <input v-if="getUnifiedLoginAlerts.length > 0" class="input-f pwdLessPwdAlert" :type="show1 ? 'text' : 'password'" required v-model="formData[1].input[0].value" @keydown.enter.prevent maxlength="60"/>
                          <input v-else class="input-f pass_input" :type="show1 ? 'text' : 'password'" required v-model="formData[1].input[0].value" @keydown.enter.prevent maxlength="60"/>
                          <span class="showBtn" v-if="!show1 && formData[1].input[0].value" @click="show1 = !show1">Show</span>
                          <span class="showBtn" v-if="show1 && formData[1].input[0].value" @click="show1 = !show1">Hide</span> -->
                          <div  style="position: relative;">
                           <input v-if="getUnifiedLoginAlerts.length > 0"
                            class="input-f pwdLessPwdAlert"
                            :type="show1 ? 'text' : 'password'" required autofocus v-model="formData[1].input[0].value"
                            name="user_password"
                            maxlength="60" />
                          <input v-else id="pwdClick" class="input-f pass_input input_mr" :type="show1 ? 'text' : 'password'" required autofocus
                            v-model="formData[1].input[0].value" v-on:change="onPwdEnter"
                            name="user_password" maxlength="60" />
                          <span :class="(getUnifiedLoginAlerts.length > 0)? 'showBtn showbtnwithicon bottom_underline': 'showBtn bottom_underline'" v-if="!show1 && formData[1].input[0].value" @click="show1 = !show1">Show</span>
                          <span :class="(getUnifiedLoginAlerts.length > 0)? 'showBtn showbtnwithicon bottom_underline': 'showBtn bottom_underline'" v-if="show1 && formData[1].input[0].value" @click="show1 = !show1">Hide</span>
                          </div>
                          <div v-if="getUnifiedLoginAlerts.length > 0">
                          <div class="row bannerMarginUnset" :class="bannerContainer()" v-for="j in getUnifiedLoginAlerts" :key="j">
                              <div v-bind:class="getTypeOfAlert(j)" style="padding:0px;">
                                <div v-if="j.type== 'information' || j.type== 'success'" v-bind:class="getTypeOfAlert(j)" style="max-width:1271px">
                                  <p class="pwdLessMessageContent inline_error_msg" style="padding:0px;margin:0px;color:#fff;" v-html="j.msg"></p>
                                </div>
                                <div v-else v-bind:class="getTypeOfAlert(j)" style="max-width:1271px">
                                  <p class="pwdLessMessageContent inline_error_msg" style="padding:0px;margin:0px;color:#000000" v-html="j.msg"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="elements_below_input">
                              <p id="forgot_password_link" name="forgot_password_link" class="pwd-forgot-link remember_text bottom_underline" style="float:right;cursor: pointer !important;" @click="forgotPopup('forgotUserPassword')">I forgot my password</p>
                          </div>
                      <!-- <br><br> -->
                      <div>
            <p class="checkbox_line check_para check_bg">
              <input name="default_login_method" class="tc checkbox_input check_box_border" type="checkbox" id="ch" v-model="defaultMethod" />
              <label for="ch" class="label_text">Make this my default log in method.</label>
                          </p>
                        </div>
</div>  
                      <div>              
                      </div>  
                      <div class="sub_btn">
                          <button
                              class="btn primary_black continue_btn"
                              id="submitButton"
                              name="submitButton"
                              style="width: 100%; margin: 0px;font-size: 16px !important; font-family: 'VzNHGeDS-bold11';"
                              type="submit"
                          >
                              Log in
                          </button>
                      </div>
                      <p id="loginAnotherWay" name="loginAnotherWay" v-if="this.loginAnotherWayEnable" style="margin-bottom: 48px; margin-top: 64px;" @click="supportUrl()">
                          <b class="anotherCls">
                            <span class="left_caret_spacing">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                             <path d="M6.5 1L1.5 6L6.5 11" stroke="black" stroke-width="2"/>
                            </svg>
                          </span> 
                          <span>Log in another way</span></b> 
                      </p>
<p v-else style="margin-bottom: 48px; margin-top: 64px;"></p>

                  </div>
              </div>
              </form>
              <div class="opt-container-2">
                  <div class="opt-default-promo1">
                      <p class="opt-default-promo">Manage your Verizon Partner Solutions transactions online anytime, anywhere.</p>
                  </div>
              </div>
          </div>
      <Footer class="footer-margin-top"></Footer>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import Header from '@/vbgauth/components/vpsHeader'
import Footer from '@/vbgauth/components/vpsFooter'
import PopUpComponent from "@/vbgauth/components/hybridExp/popups/PopUpComponent"
import {setPageDetails,eventTracking,errorSet} from '../../../../../public/adobeTagging';
export default {
name: 'VPSPwdLessPassword',
components: {
  Header,
  Footer,
  PopUpComponent
},
mixins: [commonMixin],
data () {
  return {
    defaultMethod: false,
    //pwdUsername: getCookie("pwdUserName"),
    url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
    show1: false
  }
},
mounted () {
 // this.$store.dispatch('login/updateUnifiedLoginAlerts', [] , {root:true})
 setGotoUrlInClientLib(this.getParameterByName('goto'))
  this.gotoUrl = this.$route.query.goto;
  if (this.gotoUrl != null) {
      // determine if there is a goto with a hashtag
      let hashValue = window.location.hash.substr(1);
      if (hashValue != "") {
        let hashInUrl = '#' + window.location.hash.substr(1);
        if (this.gotoUrl != null) {
          this.gotoUrl += hashInUrl;
          this.$store.dispatch('login/updateGotoUrl', this.gotoUrl);
        }
      }
  }
  if (this.pwdUsername == null) {
    this.$router.push({ name: 'VPSPwdLessLoginRootComponent'})
  }
  
  if (this.optionMethod == 'opt3') {
    this.defaultMethod = this.makeDefaultMethodTrue
  } 
  
  this.changeContextBiocatch('pwdless_login_password_page')
},
methods: {
  ...mapActions('cvsstepup', [
      'updateOTPAlwaysOn',
      'initializeStepup'
    ]
  ),
  ...mapActions('login', [
    'UpdatePopupValue',
    'submitAMLoginForm',
    'smbFormSubmit',
    'submitPwdAMPasswordForm',
    'updateUrlOnMount', // updates urlOnMount on both cvsstepup and login modules
    'selectAccount',
    'updateVcgThrotteledOn'
  ]),
  gotoPwdLessLogin() {
     this.$store.dispatch('login/updateEmptyUnifiedLoginAlerts', [] , {root:true})
      if(this.gotoUrl != null) {
          this.$router.push({ name: 'VPSPwdLessLoginRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
      } else {
          this.$router.push({ name: 'VPSPwdLessLoginRootComponent' });
      }
  },
  getParameter(url) {
        let decodeUrl=decodeURIComponent(document.location)
        url = url.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + url + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(decodeUrl);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  forgotPopup(value) {
      this.UpdatePopupValue(value);
      var eventPayload = {}
      var eventName = null
      eventName = 'forgot_password';
        eventPayload = {
          "accounts": {
            "1": "Verizon partner solutions",
          }
        }
        eventTracking(eventName,eventPayload);
        this.$router.push({ name: 'VPSForgotPwdFormComponent' });
   
  }, 
  supportUrl(){
      if(this.gotoUrl != null) {
          this.$router.push({ name: 'VPSPwdLessOption', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
      } else {
          this.$router.push({ name: 'VPSPwdLessOption' });
      }
  },
   onPwdEnter() {
    var passSubmit = document.getElementById("pwdClick")
    passSubmit.addEventListener("keyup", function (e) {
      if (e.code === "Enter") {
        e.preventDefault();
        this.submitPasswordForm
      }
    });
  },
  submitPasswordForm: function (recaptchaToken) {
      this.formData[0].input[0].value = this.pwdUsername
      let usernameOption = this.pwdUsername + '|' + 'opt3'
      if(this.defaultMethod == true) {
      let payload = {
          formData: this.formData,
          rememberMethod: this.defaultMethod,
          usernameOption: usernameOption
      };
                  // update state with new captcha token
                  if(this.captchaOn)
          this.updateCaptchaToken(recaptchaToken);
      
        this.submitPwdAMPasswordForm(payload);
      } else {
        let payload = {
          formData: this.formData,
        };
      // update state with new captcha token
      if(this.captchaOn)
          this.updateCaptchaToken(recaptchaToken);

      this.submitPwdAMPasswordForm(payload);
      }
      
  },
  bannerContainer() {
    return (this.getUnifiedLoginAlerts[0].type == 'success' || this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")) ? 'bannerMargin' : ''
  },
  getTypeOfAlert (index) {
        return index.type
  },
  messageImage(index) {
    return index.type+"MessageImage"
  },
  deleteAlert(alert) {
    this.getEmptyUnifiedLoginAlerts.splice(this.getEmptyUnifiedLoginAlerts.indexOf(alert),1)
  },
},
beforeDestroy(){
    this.formData[1].input[0].value = "";
},
computed: {
  ...mapGetters('cvsstepup', [
    'flowInitialized',
    'stepupReason',
    'urlOnMount',
    'otpAlwaysOn',
    'dnrStatus',
    'currentStep'
  ]),
  ...mapGetters('login', [
      'authError',
      'submitLoader',
      'iamvar_enableCaptcha',
      'redirectToOptionsPage',
      'redirectToStepupPage',
      'redirectToSecProfilePage',
      'iamvar_idmUrl',
      'loginCaptchaKey',
      'getAlertType',
      'popupValue',
      'getInterstitialPage',
      'getUserSelectedAccount',
      'getUnifiedErrorCode',
      'rememberMeTrue',
      'makeDefaultMethodTrue',
      'combinenowValue',
      'remembermeUserName',
      'pwdUsername',
      'optionMethod',
      'getUnifiedLoginAlerts',
      'getEmptyUnifiedLoginAlerts',
      'loginAnotherWayEnable',
      'getVcgThrotteledOn'
  ]),
  ...mapGetters('login', [ 'gotoUrl' ]),
  formData: {
      get: function () {
          return this.$store.getters['login/formData'];
      },
      set: function (newVal) {
          this.$store.dispatch('login/updateFormData', newVal);
      }
  },
  defaultMethod: {
    get: function () {
      return this.$store.getters['login/makeDefaultMethodTrue'];
    },
    set: function (newVal) {
      this.$store.dispatch('login/makeDefaultMethodTrue', newVal);
    }
  },
  pwdUsername: {
      get: function () {
          return this.$store.getters['login/pwdUsername'];
      },
      set: function (newVal) {
          this.$store.dispatch('login/setPwdUsername', newVal);
      }
  },
  pwdAuthOptions : {
      get: function () {
          return this.$store.getters['login/pwdAuthOptions'];
      }
  },
  gotoUrl: {
          get: function () {
              return this.$store.getters['login/gotoUrl'];
          },
          set: function (newVal) {
              this.$store.dispatch('login/updateGotoUrl', newVal);
              this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
          }
  },
  checkModal() {
    if(this.popupValue == 'forgotUserId' || this.popupValue == 'forgotUserPassword') {
      return 'modal-content-forgot';
    } else if(this.popupValue == 'interstitial' && (this.getUnifiedErrorCode.includes('MYBIZ') && this.getUnifiedErrorCode.includes('VEC'))) {
      return 'modal-content-hybrid';
    } else if(this.popupValue == 'interstitial' && (this.getUnifiedErrorCode.includes('MYBIZ') || this.getUnifiedErrorCode.includes('VEC'))) {
      return 'modal-content-int'
    }else if(this.popupValue == 'payWithoutLogin') {
      return 'modal-content-pwl';
    }else if(this.popupValue == 'combinenowPopupVal'){
      return 'modal-content-cn'
    }else if(this.popupValue == 'gethelp'){
      return 'modal-content-gethelp'
    } else {
      return ''
    }
   // return [{ 'modal-content-pwl': this.popupValue == 'payWithoutLogin' ? true : false }];
  },
  gethelp(){
    if(this.popupValue == 'gethelp') {
      return 'overflow: auto'
    }
  }
},
watch: {
  getUserSelectedAccount: function(selectedAccount){
      $("#noAccessToOtpModal").modal('hide')
        if(selectedAccount && this.popupValue=='interstitial'){
           let payload = {
              formData: this.formData,
              rememberUser: this.rememberMe,
              additionalAuth : "VBG_LOGIN"
           };
           if(this.getUnifiedErrorCode.includes("INVALID")){
              if (selectedAccount == "wireline" && this.getUnifiedErrorCode.includes("INVALID")){
                  // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                  this.submitAMLoginForm(payload);
              }else if (selectedAccount == "wireless" && this.getUnifiedErrorCode.includes("INVALID")){
                  // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                  this.submitAMLoginForm(payload);
              }else if(selectedAccount == "fios" && this.getUnifiedErrorCode.includes("INVALID")){
                  payload['additionalAuth'] = "INVALID_SMB_LOGIN"
                   // to do - "authenticate" call with additional header parameter as "INVALID_SMB_LOGIN" respectively.
                  this.submitAMLoginForm(payload);
              }
           }else{
              if(selectedAccount == "fios" && this.getUnifiedErrorCode.includes("VALID")){
                if(this.getVcgThrotteledOn){
                  payload['additionalAuth'] = "VALID_SMB_LOGIN"
                  this.submitAMLoginForm(payload);
                  this.updateVcgThrotteledOn(false)
                } else {
                  payload['additionalAuth'] = "VALID_SMB_LOGIN"
                  this.submitAMLoginForm(payload);
                  this.updateVcgThrotteledOn(false)
                 // this.smbFormSubmit({username:this.formData[0].input[0].value,password:this.formData[1].input[0].value,rememberUser:this.rememberMe})
                }
              }else if (selectedAccount == "wireline" && this.getUnifiedErrorCode.includes("VALID")){
                  // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                  this.submitAMLoginForm(payload);
              }else if (selectedAccount == "wireless" && this.getUnifiedErrorCode.includes("VALID")){
                  // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                  this.submitAMLoginForm(payload);
              }
           }
        }else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserId'){
          this.$router.push({ path: "/login/recovery/forgotusername" });
          this.updateUserIdFlow('BASIC')
        }else if((selectedAccount=='wireless' || selectedAccount=='wireline') && this.popupValue=='forgotUserPassword'){
          this.$router.push({ path: "/login/recovery/forgotpassword" });
        }else if(selectedAccount=='fios' && this.popupValue=='forgotUserPassword'){
          let fiosURLFP = '';
          let gotoURLFP = this.getParameterByName('goto');
          if(gotoURLFP) {
            // Added goto url to fios url
            fiosURLFP = `${iamvar_fios_fp_url}?goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(gotoURLFP)}&clientId=vbm`
          } else {
            fiosURLFP = iamvar_fios_fp_url+'?clientId=vbm'
          }
          window.location.href = fiosURLFP;
        }else if(selectedAccount=='fios' && this.popupValue=='forgotUserId'){
          let fiosURLFU = '';
          let gotoURLFU = this.getParameterByName('goto');
          if(gotoURLFU) {
            // Added goto url to fios url
            fiosURLFU = `${iamvar_fios_fu_url}&goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(gotoURLFU)}`
          } else {
            fiosURLFU = iamvar_fios_fu_url
          }
          window.location.href = fiosURLFU
        }else if(selectedAccount=='wireless' && this.popupValue=='payWithoutLogin'){
          window.location.href = iamvar_mbt_base_url+'/mbt/manageservice/payments.go/#/billpay/onetimepayment'
        }else if(selectedAccount=='fios' && this.popupValue=='payWithoutLogin'){
          window.location.href = this.wireline_login
        }else if(selectedAccount=='wireline' && this.popupValue=='payWithoutLogin'){
          window.location.href = iamvar_vec_quickpay
        }else if(selectedAccount=='wholesale' && this.popupValue=='forgotUserId'){
            let wholeSaleURLFU = '';
            let gotoVPSURLFU = this.getParameterByName('goto');
            if(gotoVPSURLFU) {
              // Added goto url to fios url
              wholeSaleURLFU = `${iamvar_vps_fu_url}&goto=${gotoVPSURLFU}`
            } else {
              wholeSaleURLFU = iamvar_vps_fu_url
            }
             window.location.href = wholeSaleURLFU;
          }else if(selectedAccount=='wholesale' && this.popupValue=='forgotUserPassword'){
            let wholeSaleURLFP = '';
            let gotoVPSURLFP = this.getParameterByName('goto');
            if(gotoVPSURLFP) {
              // Added goto url to fios url
              wholeSaleURLFP = `${iamvar_vps_fp_url}&goto=${gotoVPSURLFP}`
            } else {
              wholeSaleURLFP = iamvar_vps_fp_url
            }
            window.location.href = wholeSaleURLFP;
          }

        // to reset initial value
      this.selectAccount('null')
  },
  popupValue: function(newVal) {
      if(newVal == 'combinenowPopupVal') {
        this.forgotPopup(newVal)
      }
  },
  pwdUsername: function (newVal) {
    if(newVal) {
      this.formData[0].input[0].value = newVal
    }
  },
  redirectToStepupPage: function(newVal) {
    if(newVal) {
      this.initializeStepup();
    }
  },
  getInterstitialPage: function(newVal) {
    if(newVal) {
      this.forgotPopup('interstitial')
      this.$store.commit('login/setInterstitialPage', false)
    }
  },
  makeDefaultMethodTrue: function(newVal) {
    //if(newVal == true) {
    if(newVal) {
      this.defaultMethod = newVal
    } //else {
      //this.defaultMethod = false
    //}
  },
  currentStep: function(newVal) {
    if(newVal === 'FLOW_INITIALIZED') {
      if(this.gotoUrl != null) {
        this.$router.push({ name: 'VPSPwdLessOtpDelivery', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
      } else {
        this.$router.push({ name: 'VPSPwdLessOtpDelivery' });
      }
    }
  },
  getUnifiedLoginAlerts: function() {
      if(this.getUnifiedLoginAlerts.length > 0) {
        window.scrollTo(0,0);
        this.formData[1].input[0].value = ''
      }
  },
  show1: function(newVal) {
    if(newVal){
      setTimeout(() => {
        this.show1 = false
      },10000)
    }
  },
  redirectToSecProfilePage: function(newVal) {
    if(newVal) {
      window.location.href = newVal;
    }
  },
}
}
</script>

<style scoped>
.opt-container-1 {
width: 464px;
height: auto;
border: solid;
border-color: #D8DADA;
border-width: 1px;
margin-bottom: 198px;
/* margin-left: 290px; */
border-radius: 4px;
}
.opt-container-1-1 {
height: auto;
margin-top: 48px;
margin-left: 40px;
margin-right: 40px;
}
.opt-header-1 {
/* width: 382px; */
height: 35px;
font-family: 'VzNHGeDS-bold11';
font-size: 32px;
color: #000000;
}
.pwdless_error{
display: flex;
align-items: flex-start !important;
}
.pwdPushRError {
display: flex;
height: auto;
margin-top: 1px;
margin-left: 1px;
margin-right: 1px;
align-items: flex-start !important;
padding-top: 16px !important;
padding-bottom: 16px !important;
justify-content: space-between;
border-radius: 4px;
/* margin-bottom: 48px; */
}
.pwdPushRError.newError {
    background-color: #FFECE0 !important;
}
.pwdPushRError.error {
    background-color: #FFECE0 !important;
}
.pwdPushRError.list {
    background-color: #FFECE0 !important;
}
.pwdPushRError.success {
    background-color: #DCF5E6 !important;
}
.pwdPushRError.warning {
    background-color: #FFF9DE !important;
}
.pwdPushRError.information {
    background-color: #E2F2FD !important;
}
.warningImage {
    /* margin-top: 16px; */
    margin-left: 16px;
    /* margin-bottom: 36px; */
    width: 24px;
    height: 24px;
}
.closeImage {
    /* margin-top: 16px; */
    margin-right: 16px;
    /* margin-bottom: 36px; */
    float: right !important;
    width: 11.7px;
    height: 11.7px;
}
.pushTextError {
/* margin-top: 16px; */
margin-right: 8px;
/* margin-bottom: 16px; */
margin-left: 8px;
height: auto;
font-size: 12px;
line-height: 16px;
/* width: 380px; */
font-family: 'VzNHGeTX-reg11';
color: #000000 !important;
flex: 1;
}
.opt-p-1 {
width: 298px;
/* height: 20px; */
font-family: 'VzNHGeTX-reg11';
font-size: 16px;
color: #000000;
}
.opt-username {
max-width: 80%;
height: 24px;
font-family: 'VzNHGeDS-bold11';
font-size: 20px;
margin-top: 4px;
color: #000000;
word-break: break-all;
}
.opt-button-1 {
width: 57px;
height: auto;
font-family: 'VzNHGeDS-reg11';
font-size: 16px;
margin-top: 4px;
margin-left: 12px;
color: #000000;
/* text-decoration: underline; */
}
.pwd-forgot-link {
color: #000000;
height: 20px;
font-size: 16px;
margin-bottom: 8px !important;
letter-spacing: 0.4px !important;
}
.pwdLessPwdAlert {
border-color: #C44904 !important;
border: 1px solid;
background-color: #FFECE0;
border-radius: 5px;
background-image: url(../../../../common/assets/styles/corp/images/error_icon.svg);
background-position: right;
background-origin: content-box;
}
.opt-optionsTable {
width: 384px;
height: 321px auto;
margin-right: 40px;
margin-top: 48px;
}
.opt-title {
width: 298px;
height: 28px;
font-family: 'VzNHGeDS-reg11';
font-size: 24px;
margin-bottom: 8px;
display: block;
}
.opt-sub-title {
width: 298px;
height: 36px;
font-family: 'VzNHGeDS-reg11';
font-size: 14px;
line-height: 18px;
margin-bottom: 8px;
display: block;
}
.opt-horizontal-1 {
width: 384px;
height: 1px;
fill: solid;
color: #D8DADA;
margin-bottom: 23px;
margin-right: 40px;
}
.opt-container-2 {
margin-left: 172px;
margin-bottom: 20px;
}
.opt-default-promo1{
background: #F6F6F6;
height: 650px;
width: 464px;
align-items: center;
justify-content: center;
display: flex;
}
.opt-default-promo{
height: 128px;
width: 253.78px;
color: #A7A7A7;
font-family: 'VzNHGeDS-bold11';
font-size: 32px;
font-weight: bold;
letter-spacing: 0;
line-height: 32px;
text-align: center;
}
.footer-margin-top {
margin-top: 64px;
}
input[type="checkbox"].tc {
outline: none !important;    
}

.showBtn {
/* position: relative;  */
/* right: -330px;  */
/* top: -48px;  */
position: absolute;
right: 12px;
margin-top: 12px;
border-bottom: 1px solid #000000;
cursor: pointer;
/*height: 20px;*/
color: #000000;
/*font-family: "Verizon NHG eDS";*/
font-size: 16px;
letter-spacing: 0.5px;
line-height: 20px;
}
.showbtnwithicon{
position: absolute;
right: 40px;
top: 0;
}
.input-f {
border: 1px solid #000;
border-radius: 5px !important;
height: 44px;
width: 100%;
margin-bottom: 12px !important;
}
.anotherCls{
cursor: pointer;
font-family: VzNHGeDS-bold11;
font-size: 16px;
font-weight: bold;
letter-spacing: 0.5px;
line-height: 20px;
color: #000;
}

@media only screen and (min-width: 280px) and (max-width: 1200px) {
.opt-default-promo {
display: none;
}
.opt-default-promo1 {
display: none;
}

}

/* responsive styles starts*/
.pwdless_pass_container {
display: flex;
justify-content: center;
margin-top: 48px;
/* margin-right: 72px; */
}
.user-id{
margin-top: 48px !important;
margin-bottom: 64px !important;
height: auto;
}

@media only screen and (min-width: 280px) and (max-width: 1200px) {
.opt-container-1,
.opt-container-2 {
margin-left: 0 !important;
}
}

.pass_input {
border: 1px solid #6F7171 !important;
border-radius: 4px !important;
}

.checkbox_line {
display: flex;
justify-content: flex-start;
align-items: center;
}

/* .checkbox_input {
margin: 0 10px !important;
} */

.label_text {
margin-bottom: 0 !important;
}
@media only screen and (min-width: 501px) and (max-width: 1200px) {
.pwdless_pass_container {
display: flex;
justify-content: center;
margin-top: 48px;
}
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
.pwdless_pass_container {
display: flex;
justify-content: center;
margin-top: 48px;
}

.opt-container-1 {
width: 420px !important;
border: none !important;
}
/* .showBtn{
right: -270px !important;
} */
.pwdLessPwdAlert {
background-position: right -3% center !important;
}  
             
}

@media only screen and (min-width: 320px) and (max-width: 399px) {
.opt-header-1{
font-size: 26px;
}
.pwdless_pass_container {
display: flex;
justify-content: center;
margin-top: 48px;
}

.opt-container-1 {
width: 350px !important;
border: none !important;
}

.label_text {
font-size: 14px;
}
}

@media only screen and (min-width: 280px) and (max-width: 319px) {
.pwdless_pass_container {
display: flex;
justify-content: center;
/* margin-top: 48px; */
}

.opt-container-1 {
width: 320px !important;
border: none !important;
}

.label_text {
font-size: 12px;
}
}
.bottom_underline{
text-decoration: none !important;
border-bottom: 1px solid #000 !important;
line-height: 1 !important;
}
.check_para{
margin-top: 50px !important;
margin-bottom: 0px !important;
}
/* .sub_btn{
margin-top: 40px !important;
} */
.check_box_border, .check_box_border:hover {
outline: none !important;
border: 1px solid #D8DADA !important;
margin-top: 0 !important;
}
.check_box_border[type="checkbox"]:checked{
accent-color: #000 !important;
}
.continue_btn{
height: 44px !important;
}
.check_bg input:checked{
background: #000 !important;
}
.left_caret_spacing{
padding-right: 4px !important;
}
.inline_error_msg{
font-family: 'VzNHGeTX-reg11' !important;
line-height: 16px !important;
font-size: 12px !important;
letter-spacing: normal !important;  
}
.link_underline{
  text-decoration: underline !important;
  text-decoration-skip-ink: auto !important;
  text-underline-offset: 4px !important;
  border-bottom: none !important;
}
.-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
  letter-spacing: normal !important;
}

@media only screen and (max-width: 1023px)  {
.pwdless_pass_container {
margin-top: 32px;
}
.check_para {
    margin-top: 50px !important;
    margin-bottom: 0px !important;
}

.-input-label{
font-family: 'VzNHGeTX-reg11' !important;
font-size: 12px;
line-height: normal;
margin-bottom: 4px !important;
}
.opt-username {
    width: auto;
    font-family: 'VzNHGeDS-bold11';
    font-size: 16px !important;
    letter-spacing: 0.5px;
    margin-top: 0px !important;
    color: #000000;
}

.opt-container-1, .opt-container-1-1 {
width:100vw !important;
margin-top: 0px;
}
.opt-container-1-1 {
margin-left: 0px;
margin-right: 0px;
margin-top: 0px;
padding-left: 12px;
padding-right: 12px;
}
.opt-container-1 {
margin-bottom: 48px !important;
border: none
}
.opt-header-1 {
font-size: 24px;
color: #000000;
}
.user-id-header {
margin-top: 24px;
}
.opt-p-1 {
font-size: 12px;
line-height: 16px !important;
margin-bottom: 4px !important;
color: #000000;
}
.user-id {
margin-top: 32px !important;
margin-bottom: 48px !important;
}
.pwd-forgot-link {
letter-spacing: 0.5px !important;
margin-bottom: 14px !important;
}
.label_text {
font-size: 16px !important;
}
.showBtn {
margin-top: 13px !important;
}
input[type="checkbox"].tc {
margin-right: 8px;
}
.footer-margin-top {
margin-top: 48px;
}

#submitButton {
  letter-spacing: 0.5px;
}
.pass_input {
  border-color: #6F7171 !important;
  border-radius: 4px;
  margin-bottom: 8px !important;
}
.pass_input:focus {
  border-color: #000000 !important;
}
.opt-button-1 {
  letter-spacing: 0.5px !important;
  margin-top: 0px !important;
  color: #000000;
  line-height: 20px !important;
  font-size: 16px !important;
}
.username_alignment{
  display: flex;
  align-items: flex-start;
}
}
</style>