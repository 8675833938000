import { playrest } from '@/vbgauth/util/playrest-axios.js'
import translations from '@/common/assets/json/messages1.json'
import stepUpConfig from '@/common/assets/json/stepup_config.json'
import {iamvar_vbgAccountAged, iamvar_vbgAccountType, iamvar_vbgUserHasPC, iamvar_serverUrl, iamvar_createNewSessionUrl} from '../../../../public/const.js'
import {errorSet,eventTracking} from  '../../../../public/adobeTagging.js';

export default {
    namespaced: true,
    state: {
      submitLoader: false,
      showTlsWarn: true,
      otpDevices: [],
      userQuestions: [],
      selectedOtpDevice: {},
      selectedQuestion: {},
      dynamicClasses: [{otpClass : 'chosen'},{otpClass : ''},{otpClass : ''},{otpClass : ''},{otpClass : ''}],
      dynamicErrorClasses: {
        "validateOtpValue" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "validateOtpCode" : { fieldClass : "form-control" , fieldErrorMsg : ""},
        "acctUid" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "acctPw" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "secProfQue" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "secProfOtpValue" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "secProfOtpCode" : { fieldClass : "form-control", fieldErrorMsg : ""},
        "otpCode" : { fieldClass : "form-control" , fieldErrorMsg : ""},
        "sqa": { fieldClass : "form-control" , fieldErrorMsg : "" }
      },
      otpSent: false,
      validOtpForm: false,
      fingerprintSaved: false,
      unableToReceiveOtpDialog: false,
      sqaDisabled: false,
      isPwReset: false,
      alerts: [],
      sqaSubmitLinkClass: false,
      messages: translations,
      otpDialogMsg: '',
      otpDialogTitle: '',
      stateInitialized: false,
      flowInitialized: null,
      disableSqaFields: false,
      stepupReason: null,
      stepupPageTitle: null,
      stepupPageDesc: null,
      dialog: false,
      noAccessToOTP: false,
      sqaEnabled: true,
      otpEnabled: true,
      noSqa: false,
      otpValidationError: false,
      sqaValidationError: false
    },
    mutations: {
      setOtpDevices(state, payload) {
        state.otpDevices = payload
      },
      setUserQuestions(state, payload) {
        state.userQuestions = [payload]
      },
      setSelectedOtpDevice(state, payload) {
        if(payload) {
          state.otpSent = false
          state.selectedOtpDevice = payload
        } else if (state.otpDevices.length > 0)
          state.selectedOtpDevice = state.otpDevices[0]
          else if(payload === undefined) {
            state.selectedOtpDevice = {}
          }
      },
      setSelectedQuestion(state) {
        if(state.userQuestions.length > 0)
          state.selectedQuestion = state.userQuestions[0]
      },
      setLoader(state, payload) {
        state.submitLoader = payload
      },
      setOtpSent(state, payload) {
        state.otpSent = payload
      },
      setValidOtpForm(state, payload) {
        state.validOtpForm = payload
      },
      setUnableToReceiveOtpDialog(state, payload) {
        state.unableToReceiveOtpDialog = payload
      },
      setalert(state, payload) {
        state.alerts = payload
      },
      setFingerprintSaved(state, payload) {
        state.fingerprintSaved = payload
      },
      setOtpDialogMsg(state, payload) {
        state.otpDialogMsg = payload
      },
      setOtpDialogTitle(state, payload) {
        state.otpDialogTitle = payload
      },
      setStateInitialized(state, payload) {
        state.stateInitialized = payload
      },
      setDisableSqaFields(state, payload) {
        state.disableSqaFields = payload
      },
      setFlowInitialized(state, payload) {
        state.flowInitialized = payload
      },
      setStepupReason(state, payload) {
        state.stepupReason = payload
      },
      setStepupPageTitle(state, payload) {
        state.stepupPageTitle = payload
      },
      setStepupPageDesc(state, payload) {
        state.stepupPageDesc = payload
      },
      setShowDialog(state, payload) {
        state.dialog = payload
      },
      setNoAccessToOTP(state, payload) {
        state.noAccessToOTP = payload
      },
      enableSQAOption(state, payload) {
        state.sqaEnabled = payload
      },
      enableOTPOption(state, payload) {
        state.otpEnabled = payload
      },
      setNoSqaReg(state, payload) {
        state.noSqa = payload
      },
      setOtpValidationError(state, payload) {
        state.otpValidationError = payload
      },
      setSqaValidationError(state, payload) {
        state.sqaValidationError = payload
      }
    },
    actions: {
      getOtpDevices ({commit, state}) {
        commit('setLoader', true)
        // http://localhost:9002/otpDevices
        // /api/aa/v1/otp
        // commit('setalert', [])
        commit('setOtpSent', false)
        return playrest.get('/api/aa/v1/otp').then(function (response) {
          // commit('setalert', [])
          if (Object.keys(response.data).length === 0) {        
            var alerts = state.alerts
            alerts.push({msg: state.messages.en.message["login_otp_no_devices"], type:'danger'})
            errorSet(state.messages.en.message["login_otp_no_devices"]);
            commit('setalert', alerts)
          }
          commit('setOtpDevices', response.data)
          var devices = response.data
          for (var i =0;i<devices.length; i++) {
            if(devices[i].otpType === 'SMS') {
              devices[i].otpType = 'Text'
            } else if (devices[i].otpType === 'IVR') {
              devices[i].otpType = 'Call'
            } else if(devices[i].otpType === 'EMAIL') {
              devices[i].otpType = 'Email'
            }
          }
          commit('setOtpDevices', devices)
          commit('setSelectedOtpDevice')
          commit('setLoader', false)
        }).catch((error) => {
          commit('setLoader', false)
          throw error
        })
      },
      getUserQuestions ({commit, state}) {
        commit('setLoader', true)
        // http://localhost:9002/userQuestions
        // /api/aa/v1/sqa
        commit('setalert', [])
        return playrest.get('/api/aa/v1/sqa').then(function (response) {
          if (Object.keys(response.data).length === 0) {        
            commit('setDisableSqaFields', true)    
            var msg = [{msg: state.messages.en.message["login_sqa_no_questions"], type:'danger'}];
            errorSet(state.messages.en.message['login_sqa_no_questions']);
            commit('setalert', msg)
          }
          commit('setUserQuestions', response.data)
          commit('setSelectedQuestion')
          commit('setLoader', false)
        }).catch((error) => {
          commit('setLoader', false)
          throw error
        })
      },
      updateStepupReason({commit, state}, payload) {
        commit('setStepupReason', payload)
        if(payload === 'DEVICE_NOT_REGISTERED') {
          commit('enableSQAOption', true)
          commit('enableOTPOption', true)
        } else if(payload === 'RISK') {
          commit('enableSQAOption', true)
          commit('enableOTPOption', true)
          if(state.flowInitialized === 'SQA_INITIALIZED') {
            commit('enableOTPOption', false)
          } else if(state.flowInitialized === 'OTP_INITIALIZED') {
            commit('enableSQAOption', false)
          }
        } else if(payload === 'SQA_NOT_REGISTERED') {
          commit('enableSQAOption', false)
          commit('setNoSqaReg', true)
        }
        var stepupReasons = stepUpConfig.stepupReasons
        var reason = stepupReasons.filter(x => x.reason === payload)[0]
        if (reason) {
          var pageTitleKey = reason.page_title_i18n_key
          var pageDescKey = reason.page_desc_i18n_key
          commit('setStepupPageTitle', state.messages.en.message[pageTitleKey])
          commit('setStepupPageDesc', state.messages.en.message[pageDescKey])
        } else {
          // if reason is null
        }
      },
      updateFlowInitialized({commit}, payload) {
        commit('setFlowInitialized', payload)
      },
      updateSelectedDevice({commit}, payload) {
        commit('setSelectedOtpDevice', payload)
      },
      sendOtpToDevice ({commit, state}, payload) {
        commit('setLoader', true)
        commit('setalert', [])
        // http://localhost:9002/sendOtpTo
        // '/api/aa/v1/otp/' + payload + '/send'
        return playrest.get('/api/aa/v1/otp/' + payload + '/send').then(function (response) {
          var msg = []
          commit('setLoader', false)
          if (response.data.STATUS === 'SUCCESS') {
            commit('setOtpSent', true)
            var alertMessageString = state.selectedOtpDevice.displayString;
            if(alertMessageString.indexOf("Email ") == 0) {
              alertMessageString = alertMessageString.replace("Email ","");
            } else if(alertMessageString.indexOf("Text ") == 0) {
              alertMessageString = alertMessageString.replace("Text ","");
            } else if(alertMessageString.indexOf("Call ") == 0) {
              alertMessageString = alertMessageString.replace("Call ","");
            }
            msg = [ {	msg : state.messages.en.message["login_otp_"+state.selectedOtpDevice.otpType+"_code_sent"].replace("$0",alertMessageString),type : 'info'} ];
            commit('setalert', msg)
          }
          else if (response.data>STATUS === 'FAILURE') {
            commit('setOtpSent', false)
            msg = [{msg: state.messages.en.message["login_otp_send_error"], type:'danger'}];
            errorSet(state.messages.en.message['login_otp_send_error']);
            commit('setalert', msg)
          } else {
            msg = state.messages.en.message["login_otp_unable_receive_modal_body"]
            commit('setOtpDialogTitle', 'An error has occurred.')
            commit('setOtpDialogMsg', msg.replace('$0', "Verizon Customer Service at 1-800-922-0204"))
            commit('setShowDialog', true)
          }
        }).catch(function (error) {
          commit('setLoader', false)
          commit('setOtpSent', false)
          var msg = state.messages.en.message["login_otp_unable_receive_modal_body"]
          commit('setOtpDialogTitle', 'An error has occurred.')
          commit('setOtpDialogMsg', msg.replace('$0', "Verizon Customer Service at 1-800-922-0204"))
          commit('setShowDialog', true)
          throw error
        })
      },
      validateOtp ({commit, state, dispatch}, payload) {
        commit('setLoader', true)
        commit('setalert', [])
        // '/api/aa/v1/otp/verify', payload
        // 'http://localhost:9002/validateOtp'
        return playrest.post('/api/aa/v1/otp/verify', payload).then(function (response) {
          commit('setLoader', false)
          if (response.data.status === 'SUCCESS') {
            eventTracking('One Time Passcode Success','')
            commit('setFingerprintSaved', true)
            commit('setOtpValidationError', false)
          } else {
            commit('setFingerprintSaved', false)
            commit('setOtpValidationError', true)
            var msg;
            if(response.data.maxAttemptsReached) {
              msg = [{msg: state.messages.en.message["reg_otp_verify_code_maxattempts_error"], type:'danger'}]
              errorSet(state.messages.en.message['reg_otp_verify_code_maxattempts_error']);
              setTimeout(function () {
                dispatch('getOtpDevices')
              }, 6000)
            } else {
              msg = [{msg: state.messages.en.message["reg_otp_verify_code_error"], type:'danger'}]
              errorSet(state.messages.en.message['reg_otp_verify_code_error']);
            }
            commit('setalert', msg)
          }
          commit('setLoader', false)
        }).catch(function (error) {
          commit('setFingerprintSaved', false)
          commit('setLoader', false)
          throw error
        })
      },
      unableToReceiveOtp ({commit, state}) {
        commit('setLoader', true)
        var msg = state.messages.en.message["login_otp_unable_receive_modal_body"]
        // '/api/aa/v1/otp/logUnableToReceiveOtpEvent'
        // http://localhost:9002/logUnableToReceiveOtpEvent
        commit('setOtpDialogTitle', 'Unable to receive a One Time Passcode?')
        return playrest.get('/api/aa/v1/otp/logUnableToReceiveOtpEvent').then(function (success) {
          commit('setLoader', false)
          var replaceString = msg.replace('$0', success.data)
          commit('setOtpDialogMsg', replaceString)
        }).catch (function (error) {
          commit('setLoader', false)
          var replaceString = msg.replace('$0', 'Customer Support')
          commit('setOtpDialogMsg', replaceString)
          throw error
        })
      },
      resetPassword({commit}) {
        commit('setOtpDialogTitle', 'Want to reset password?')
        if(iamvar_vbgAccountAged) {
          commit('setOtpDialogMsg', 'Password rest link has been sent to your registered email.')
        } else if(!iamvar_vbgAccountAged && iamvar_vbgAccountType === 'PrimaryUser') {
          commit('setOtpDialogMsg', 'Password rest link has been sent to your registered email.')
        } else if(!iamvar_vbgAccountAged && iamvar_vbgAccountType !== 'PrimaryUser' && iamvar_vbgUserHasPC) {
          commit('setOtpDialogMsg', 'Please contact your Primary Contact to reset your password')
        } else if(!iamvar_vbgAccountAged && iamvar_vbgAccountType !== 'PrimaryUser' && !iamvar_vbgUserHasPC) {
          commit('setOtpDialogMsg', 'Please contact Customer Support to reset your password')
        }
        commit('setShowDialog', true)
      },
      validateSqa ({commit, state}, payload) {
        commit('setLoader', true)
        commit('setalert', [])
        // '/api/aa/v1/sqa/verify', payload
        // 'http://localhost:9002/validateSqa'
        playrest.post('/api/aa/v1/sqa/verify', payload).then(function (response) {
          commit('setLoader', false)
          if (response.data.status === 'SUCCESS') {
            commit('setFingerprintSaved', true)
            commit('setSqaValidationError', false)
          } else {
            commit('setFingerprintSaved', false)
            commit('setSqaValidationError', true)
            var msg;
            if(response.data.maxAttemptsReached) {
              msg = [{msg: state.messages.en.message["incorrect_answer_maxattempts_error"], type:'danger'}]
              errorSet(state.messages.en.message['incorrect_answer_maxattempts_error']);
              commit('setDisableSqaFields', true)
            } else {
              msg = [{msg: state.messages.en.message["incorrect_answer_error"], type:'danger'}]
              errorSet(state.messages.en.message['incorrect_answer_error']);
            }
            commit('setalert', msg)
          }
        }).catch(function (error) {
          commit('setFingerprintSaved', false)
          commit('setSqaValidationError', true)
          commit('setLoader', false)
          throw error
        })
      },
      updateOtpSent ({commit}, payload) {
        commit('setOtpSent', payload)
      },
      updateShowDialog ({commit}, payload) {
        commit('setShowDialog', payload)
      },
      updateValidOtpForm ({commit}, payload) {
        commit('setValidOtpForm', payload)
      },
      updateUnableToReceiveOtpDialog({commit}, payload) {
        commit('setUnableToReceiveOtpDialog', payload)
      },
      redirectToOpenam({commit}) {
        commit('setLoader', true)
        window.location.href = iamvar_serverUrl + iamvar_createNewSessionUrl
      },
      updateNoAccessToOTP({commit}, payload) {
        commit('setNoAccessToOTP', payload)
      }
    },
    getters: {
      submitLoader: (state) => state.submitLoader,
      showTlsWarn: (state) => state.showTlsWarn,
      devices: (state) => state.otpDevices,
      userQuestions: (state) => state.userQuestions,
      selectedQuestion: (state) => state.selectedQuestion,
      selectedOtpDevice: (state) => state.selectedOtpDevice,
      dynamicClasses: (state) => state.dynamicClasses,
      dynamicErrorClasses: (state) => state.dynamicErrorClasses,
      otpSent (state) {
        return state.otpSent
      },
      fingerprintSaved: (state) => state.fingerprintSaved,
      unableToReceiveOtpDialog (state) {
        return state.unableToReceiveOtpDialog
      },
      sqaDisabled: (state) => state.sqaDisabled,
      isPwReset: (state) => state.isPwReset,
      alerts: (state) => state.alerts,
      sqaSubmitLinkClass: (state) => state.sqaSubmitLinkClass,
      dialogMsg (state) {
        return state.otpDialogMsg
      },
      dialogTitle (state) {
        return state.otpDialogTitle
      },
      stateInitialized: (state) => state.stateInitialized,
      disableSqaFields: (state) => state.disableSqaFields,
      flowInitialized: (state) => state.flowInitialized,
      stepupPageTitle: (state) => state.stepupPageTitle,
      stepupPageDesc: (state) => state.stepupPageDesc,
      dialog (state) {
        return state.dialog
      },
      emailAged: () => {
        return iamvar_vbgAccountAged
      },
      userType: () => {
        return iamvar_vbgAccountType
      },
      userHasPC: () => {
        return iamvar_vbgUserHasPC
      },
      noAccessToOTP (state) {
        return state.noAccessToOTP
      },
      sqaEnabled: (state) => state.sqaEnabled,
      otpEnabled: (state) => state.otpEnabled,
      noSqa: (state) => state.noSqa,
      otpValidationError: (state) => state.otpValidationError,
      sqaValidationError: (state) => state.sqaValidationError
    }
}
