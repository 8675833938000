<template>
    <div>
        <vpsHeader></vpsHeader>
        <div v-if="vpsProfileAlerts.length > 0">
            <div class="row" v-for="j in vpsProfileAlerts" :key="j">
                <div class="col-sm-12">
                    <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
                        <div class="vbg-ff-msg-container">
                            <div class="messageWrapper" style="display: flex;">
                                <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                                    <div class="messageImage" :class="messageImage(j)"></div>
                                </div>
                                <p class="messageContentUn col-xs-11" v-if="j.type == 'list'" v-html="j.msg"
                                    style="padding:0px;margin:0px">{{j.msg}}</p>
                                <p class="messageContentUn col-xs-11"
                                    v-else-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px"
                                    v-html="j.msg">{{j.msg}}</p>
                                <p class="messageContentUn col-xs-11" v-else
                                    style="padding:0px;margin:0px;color:#ffffff" v-html="j.msg">{{j.msg}}</p>
                                <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                                    <img class="closeMessageImg"
                                        v-if="j.type == 'list' || j.type == 'error' || j.type == 'warning'"
                                        src="@/common/assets/styles/corp/images/close_black.png"
                                        @click="deleteAlert(j)" />
                                    <img class="closeMessageImg" v-else
                                        src="@/common/assets/styles/corp/images/clear_white.png"
                                        @click="deleteAlert(j)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vps-container center_margin mainContainer">
            <div class="paddingMobile">
                <div class="myprofileContainer">
                    <div class="headUserId">
                        <h2 class="myprofileHead">Edit my profile</h2>
                    </div>
                    <p class="descEdit">Edit / modify your profile settings here.</p>
                    <div class="Section1">
                        <ul class="formNotEdit">
                            <li><label>Name</label><span class="textDesc">
                                    <p class="displayText">{{ firstName }} {{ lastName }}</p>
                                </span><span class="edit-link" @click="onEditClick('EditUsername')">Edit</span></li>
                            <li><label> Email address</label><span class="textDesc">
                                    <p class="displayText">{{ emailAddress }}</p>
                                </span><span class="edit-link" @click="onEditClick('EditEmail')">Edit</span></li>
                            <!-- <li><label> Security question</label>
                                <span class="textDesc">
                                    <p class="displayText">{{ securityQuestion }}</p>
                                    <span class="edit-link" @click="onEditClick('EditSQA')">Edit</span>
                                </span>
                            </li> -->
                            <li><label> Login password</label><span class="textDesc">**********</span>
                                <span class="edit-link" @click="onEditClick('EditPWD')">Edit</span>
                            </li>
                        </ul>
                        <div class="buttonset">
                            <button @click="onHanldeClose()"
                                class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05">
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vpsFooter></vpsFooter>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
export default {
    name: 'VPSSecurityProfilePage',
    components: {
        vpsHeader,
        vpsFooter
    },
    data() {
        return {
            securityQuestion: "what is your nickname ?"
        }
    },
    methods: {
        ...mapActions('profile', [
            'getUserProfile',
            'udpateVpsProfilePrevPathVps',
            'udpateVpsProfilePrevPathWholeSale',
            'udpateVpsCapturedEnv',
            'udpateVpsWholeSaleCapturedEnv'
        ]),
        onEditClick(editScreen) {
            if (editScreen === "EditUsername") {
                this.$router.push({ name: 'VPSSecurityProfileUsernameEdit' })
            } else if (editScreen === "EditEmail") {
                this.$router.push({ name: 'VPSSecurityProfileEmailEdit' })
            } else if (editScreen === "EditSQA") {
                this.$router.push({ name: 'VPSSecurityProfileSQAEdit' })
            } else if (editScreen === "EditPWD") {
                this.$router.push({ name: 'VPSSecurityProfilePwdEdit' })
            }
        },
        getMsgIcon(index) {
            return "@/common/assets/styles/corp/images/" + index.type + ".png";
        },
        messageImage(index) {
            if (index.type == 'error' || index.type == 'warning' || index.type == 'list') {
                return "newErrorMessageImage"
            } else {
                return index.type + "MessageImage";
            }
        },
        getCloseIcon() {
            return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
        },
        deleteAlert(alert) {
            this.vpsProfileAlerts.splice(this.vpsProfileAlerts.indexOf(alert), 1)
        },
        getAlertType(index) {
            return index.type
        },
        onHanldeClose() {
            if (this.vpsClientId == 'VPS') {
                if (this.vpsCapturedEnv == 'SIT') {
                    window.location.href = vps_sit_url
                } else if (this.vpsCapturedEnv == 'UAT') {
                    window.location.href = vps_uat_url
                } else {
                    window.location.href = vps_prod_url
                }
            }  else if (this.vpsWholeSaleClientId == 'VPSWholeSale') {
                if (this.vpsWholeSaleCapturedEnv == 'SIT') {
                    window.location.href = vps_wholesale_sit_url
                } else if (this.vpsWholeSaleCapturedEnv == 'UAT') {
                    window.location.href = vps_wholesale_uat_url
                } else {
                    window.location.href = vps_wholesale_prod_url
                }
            } else {
                this.$router.push({ name: 'VPSPwdLessLoginRootComponent' });
            }
        }
    },
    computed: {
        ...mapGetters("profile", [
            "firstName",
            "lastName",
            "sqa",
            "emailAddress",
            "vpsProfileAlerts",
            "vpsClientId",
            "vpsWholeSaleClientId",
            "vpsWholeSaleCapturedEnv",
            "vpsCapturedEnv"

        ]),
        vpsProfileAlerts: {
            get: function () {
                return this.$store.getters['profile/vpsProfileAlerts']
            },
            set: function (newVal) {
                this.$store.commit('profile/setVpsProfileAlerts', newVal)
            }
        }
    },
    watch: {
        vpsProfileAlerts: function (newVal) {
            if (newVal) {
                window.scrollTo(0, 0);
            }
        },
    },
    mounted() {
        const payload = {
            isVPSFlow: true
        }
        this.getUserProfile(payload)
        this.vpsProfileAlerts.splice(0, 1);
    },
    beforeDestroy() {
    },
}
</script>
<style scoped>
.vps-container {
    height: 100%;
    width: auto;
    margin: 40px;
}

.mainContainer {
    margin-top: 100px;
}

.paddingMobile {
    padding: 10px 16px;
    overflow-x: auto;
}

.myprofileContainer {
    width: 575px;
    padding-top: 5px;
    border-top: 4px solid #000;
}

.headUserId {
    float: left;
    width: 100%;
}

.descEdit {
    margin-bottom: 50px;
    font-size: 16px;
    display: inline;
    font-weight: normal;
}

.displayText {
    display: inline;
    font-size: 16px;
}

.Section1 {
    position: relative;
}

@media (max-width: 768px) {
    .myprofileContainer {
        width: 100%;
    }

    .secondaryBtn {
        width: 100%
    }
}

.formNotEdit {
    margin-top: 30px;
}

.formNotEdit li {
    padding: 6px 0;
    border-bottom: 1px solid #000;
    line-height: 46px;
    display: block;
    overflow: hidden;
    word-break: break-word;
}

.buttonset {
    margin-bottom: 50px;
    margin-top: 50px;
}

.formNotEdit li label {
    float: left;
    width: 190px;
    font-family: "NeueHaasGroteskDisplay";
}

.textDesc {
    font-size: 16px;
}

.edit-link {
    float: right;
    font-size: 16px;
    text-decoration: underline;
    display: inline;
    cursor: pointer;
}

.btnContainer {
    margin-top: 50px;
}
</style>