import { render, staticRenderFns } from "./VPSRegistrationUnSuccessful.vue?vue&type=template&id=c9fa25fa&scoped=true"
import script from "./VPSRegistrationUnSuccessful.vue?vue&type=script&lang=js"
export * from "./VPSRegistrationUnSuccessful.vue?vue&type=script&lang=js"
import style0 from "./VPSRegistrationUnSuccessful.vue?vue&type=style&index=0&id=c9fa25fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9fa25fa",
  null
  
)

export default component.exports