<template>
  <div class="vbg-ff-root-container">
    <Header></Header>
    <div class="vbg-ff-content-container">
      <div class="vbg-rsp-root-container">
        <div class="fpem_main_title">Dear Customer</div>
        <div class="fpem_sub_title">
          Your default login method is set to tokenized login and does not
          require a password. Please login with your Verizon business mobile
          number and use tokenized option as password to gain access to My
          Business portal
        </div>
        <div class="btn_container">
          <button class="fpem_sign_btn" @click="navigateToLoginPage">
            Login
          </button>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
  
  <script>
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import Header from "@/vbgauth/components/LegalHeader";
export default {
  name: "ForgotPasswordErrorMessage",
  components: {
    Header,
    Footer,
  },
  methods: {
    navigateToLoginPage() {
      this.$router.push({ path: "/login/unifiedlogin" });
    },
  },
};
</script>
  
  <style>
.fpem_main_title {
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  font-family: "VzNHGeDS-bold11";
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 30px;
}
.fpem_sub_title {
  letter-spacing: 0.5px;
  line-height: 20px;
  font-size: 16px;
  color: #000000;
  font-family: "VzNHGeDS-reg11";
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 50px;
  width: 500px;
}

.fpem_sign_btn {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  background-color: #ffffff;
  font-family: "VzNHGeDS-bold11";
  letter-spacing: 0.5px;
  text-align: center;
  padding: 12px;
  width: 143px;
  border: 1px solid #000000;
  border-radius: 22px;
}
@media only screen and (max-width: 1023px) {
  .fpem_main_title {
    margin-bottom: 25px;
  }
  .fpem_sub_title {
    margin-bottom: 50px;
    width: 100%;
  }

  .fpem_sign_btn {
    width: 100%;
  }
}
</style>
  